import React from "react";

import {
  VisuallyHidden,
  ValidMessage,
  PasswordInput,
  DetailModal,
} from "components";
import { useChangePasswordForm } from "hooks";
import * as S from "./ReqPasswordChangedModal.styled";

const OLD_PW_INPUT_ID = "old-pw-input";
const NEW_PW_INPUT_ID = "new-pw-input";
const NEW_PW_C_INPUT_ID = "new-pw-confirm-input";
const VALID_ERROR_ID_OLD_PW = "error-msg-req-old-password";
const VALID_ERROR_ID_NEW_PW = "error-msg-req-new-password";

interface ReqPasswordChangedModalProps {
  handleModalClose: () => void;
}

const ReqPasswordChangedModal = React.forwardRef(
  (
    { handleModalClose }: ReqPasswordChangedModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const {
      inputRefs,
      form,
      isSubmitDisabled,
      handleSubmit,
      handleChangeForm,
      handleBlurForm,
      handleNextChange,
    } = useChangePasswordForm(handleModalClose);

    return (
      <DetailModal
        ref={ref}
        css={S.detailModal}
        title="비밀번호 변경 요청"
        isPosDisabled={!!isSubmitDisabled}
        cancelBtnName="다음에 변경"
        cancelFn={handleNextChange}
        posBtnName="완료"
        posFnType="submit"
        posFn={handleSubmit}
      >
        <S.ContentWrapper>
          <S.NoticeWrapper>
            <dt>
              안전한 개인정보를 위해 <strong>비밀번호를 변경</strong>
              해주세요
            </dt>
            <dd>
              회원님은 최근 90일간 비밀번호를 변경하지 않으셨습니다.{" "}
              <strong>새로운 비밀번호로 변경</strong>해주세요
            </dd>
          </S.NoticeWrapper>
          <S.OriginPasswordWrapper>
            <S.Label htmlFor={OLD_PW_INPUT_ID}>
              기존 비밀번호 입력 <strong>*</strong>
            </S.Label>
            <PasswordInput
              css={S.passwordInput}
              id={OLD_PW_INPUT_ID}
              ref={(el) => (inputRefs.current["originPassword"] = el)}
              name="originPassword"
              value={form.originPassword.value}
              aria-invalid="true"
              aria-errormessage={VALID_ERROR_ID_OLD_PW}
              validError={!!form.originPassword.error}
              placeholder="기존 비밀번호를 입력하세요"
              data-required="true"
              onChange={handleChangeForm}
              onBlur={handleBlurForm}
            />
            {form.originPassword.error && (
              <ValidMessage css={S.validMessage} id={VALID_ERROR_ID_OLD_PW}>
                {form.originPassword.error}
              </ValidMessage>
            )}
          </S.OriginPasswordWrapper>
          <S.NewPasswordWrapper>
            <S.Label htmlFor={NEW_PW_INPUT_ID}>새 비밀번호 입력</S.Label>
            <S.GuideMessage>
              <span>
                안정성을 위해 영문(대/소문자), 숫자, 특수문자(!*#& 등)를 활용한
                8-16자의 비밀번호를 입력해주세요. 보안을 위해 비밀번호 변경 시
                전 기기에서 로그아웃됩니다.
              </span>
            </S.GuideMessage>
            <PasswordInput
              css={S.passwordInput}
              id={NEW_PW_INPUT_ID}
              ref={(el) => (inputRefs.current["newPassword"] = el)}
              name="newPassword"
              value={form.newPassword.value}
              aria-invalid="true"
              aria-errormessage={VALID_ERROR_ID_NEW_PW}
              validError={!!form.newPassword.error}
              placeholder="새 비밀번호를 입력하세요"
              data-required="true"
              onChange={handleChangeForm}
              onBlur={handleBlurForm}
            />
            <VisuallyHidden as="label" htmlFor={NEW_PW_C_INPUT_ID}>
              새 비밀번호 입력 확인
            </VisuallyHidden>
            <PasswordInput
              css={S.passwordInput}
              id={NEW_PW_C_INPUT_ID}
              ref={(el) => (inputRefs.current["newPasswordConfirm"] = el)}
              name="newPasswordConfirm"
              value={form.newPasswordConfirm.value}
              aria-invalid="true"
              aria-errormessage={VALID_ERROR_ID_NEW_PW}
              validError={!!form.newPasswordConfirm.error}
              placeholder="확인을 위해 새 비밀번호를 한번 더 입력하세요"
              data-required="true"
              onChange={handleChangeForm}
              onBlur={handleBlurForm}
            />
            {(form.newPassword.error || form.newPasswordConfirm.error) && (
              <ValidMessage css={S.validMessage} id={VALID_ERROR_ID_NEW_PW}>
                {form.newPassword.error || form.newPasswordConfirm.error}
              </ValidMessage>
            )}
          </S.NewPasswordWrapper>
        </S.ContentWrapper>
      </DetailModal>
    );
  },
);

ReqPasswordChangedModal.displayName = "ReqPasswordChangedModal";

export default ReqPasswordChangedModal;
