interface FormInitStateType {
  email: { value: string; error: string };
  thumbnail: {
    value: [
      {
        status: "default";
        file: string | File;
        url: string;
      },
    ];
    error: string;
  };
  extras: {
    value: [
      {
        status: "default";
        file: string | File;
        url: string;
      },
    ];
    error: string;
  };
  name: { value: string; error: string };
  zipcode: { value: string; error: string };
  addr: { value: string; error: string };
  addrDetail: { value: string; error: string };
  districtCode: { value: string; error: string };
  latitude: { value: string; error: string };
  longitude: { value: string; error: string };
  phoneLocal: { value: string; error: string };
  phone: { value: string; error: string };
  brands: { value: string[]; error: string };
  operationTime: { value: string[]; error: string };
  intro: { value: string; error: string };
  emailYn: { value: string; error: string };
  memo: { value: string; error: string };
}

export const FORM_INIT_STATE: FormInitStateType = {
  email: { value: "", error: "" },
  thumbnail: {
    value: [
      {
        status: "default",
        file: "",
        url: "",
      },
    ],
    error: "",
  },
  extras: {
    value: [
      {
        status: "default",
        file: "",
        url: "",
      },
    ],
    error: "",
  },
  name: { value: "", error: "" },
  zipcode: { value: "", error: "" },
  addr: { value: "", error: "" },
  addrDetail: { value: "", error: "" },
  districtCode: { value: "", error: "" },
  latitude: { value: "", error: "" },
  longitude: { value: "", error: "" },
  phoneLocal: { value: "", error: "" },
  phone: { value: "", error: "" },
  brands: { value: [], error: "" },
  operationTime: { value: [], error: "" },
  intro: { value: "", error: "" },
  emailYn: { value: "n", error: "" },
  memo: { value: "", error: "" },
};

export const DAY_LIST = ["월", "화", "수", "목", "금", "토", "일"];
