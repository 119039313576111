import { useMutation } from "@tanstack/react-query";

import {
  changePasswordAPI,
  delayPwRetentionPeriodAPI,
  deleteRefreshTokenAPI,
  loginAPI,
  logoutAPI,
  resetPasswordAPI,
  sendAuthLinkAPI,
} from "apis";
import type {
  APIError,
  ChangePasswordQueryModel,
  DelayPwRetentionPeriodServerModel,
  DeleteRefreshTokenQueryModel,
  LoginQueryModel,
  LoginServerModel,
  RequestResetPasswordQueryModel,
  ResetPasswordQueryModel,
} from "types";

export const useDeleteRefreshToken = () => {
  return useMutation<unknown, APIError, DeleteRefreshTokenQueryModel>({
    mutationFn: (req) => deleteRefreshTokenAPI(req),
  });
};

export const useLogin = () => {
  return useMutation<LoginServerModel, APIError, LoginQueryModel>({
    mutationFn: (req) => loginAPI(req),
  });
};

export const useLogout = () => {
  return useMutation<unknown, APIError, string>({
    mutationFn: (req) => logoutAPI(req),
  });
};

export const useSendAuthLink = () => {
  return useMutation<unknown, APIError, RequestResetPasswordQueryModel>({
    mutationFn: (req) => sendAuthLinkAPI(req),
  });
};

export const useResetPassword = () => {
  return useMutation<unknown, APIError, ResetPasswordQueryModel>({
    mutationFn: (req) => resetPasswordAPI(req),
  });
};

export const useChangePassword = () => {
  return useMutation<unknown, APIError, ChangePasswordQueryModel>({
    mutationFn: (req) => changePasswordAPI(req),
  });
};

export const useDelayPwRetentionPeriod = () => {
  return useMutation<DelayPwRetentionPeriodServerModel, APIError, unknown>({
    mutationFn: () => delayPwRetentionPeriodAPI(),
  });
};
