export const CUSTOMER_CENTER_NUMBER = "02-566-0309";

export const MIN_OPTION_TABLE = {
  "00": "00",
  30: "30",
};

export const FAQ_NONE_DATA_TEXT =
  "아직 등록된 질문이 없어요!\n1:1문의에 질문을 남겨주시면 빠르게 답변드릴게요:)";

export const WEEKS = ["일", "월", "화", "수", "목", "금", "토"]; // 일주일 배열

export const LAYOUT_TYPE = Object.freeze({
  PRIVATE: "PRIVATE",
  PUBLIC: "PUBLIC",
});

export const PASSWORD_RETENTION_PERIOD = 90;
