import React from "react";

import { CenterLayout } from "components";
import ReservationHeader from "../container/header/ReservationHeader";
import ReservationCalendar from "../container/calendar/ReservationCalendar";
import useReservationCalendar from "../hooks/useReservationCalendar";
import * as S from "./ReservationSchedule.styled";

const ReservationSchedule = () => {
  const {
    monthYear,
    handleSelectDate,
    changeMonthYear,
    changePrevMonthYear,
    changeNextMonthYear,
    changeCurrentMonthYear,
  } = useReservationCalendar();

  return (
    <CenterLayout css={S.centerLayout}>
      <h2 className="a11y">예약 스케줄</h2>
      <ReservationHeader
        monthYear={monthYear}
        changeMonthYear={changeMonthYear}
        changePrevMonthYear={changePrevMonthYear}
        changeNextMonthYear={changeNextMonthYear}
        changeCurrentMonthYear={changeCurrentMonthYear}
      />
      <ReservationCalendar
        monthYear={monthYear}
        handleSelectDate={handleSelectDate}
      />
    </CenterLayout>
  );
};

export default ReservationSchedule;
