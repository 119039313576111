import React, { useState, useCallback, useEffect, useRef } from "react";

import { Button, Input } from "components";
import { useToast } from "hooks";
import { useUpdateReservationDetail } from "services";
import {
  numberInputWithComma,
  deleteComma,
  getCommaThousandUnitAfterDeleteMarker,
  getCommaThousandUnitInputAfterChange,
  checkPrice,
} from "utils";
import { TOAST_MSG } from "constants/index";
import * as S from "./AmountDialog.styled";

const AMOUNT_ID = "total-amount";

interface AmountDialogProps {
  className?: string;
  isDialogOpen: boolean;
  dialogRef: any;
  handleDialogClose: () => void;
  reservationId: string;
  cost: number;
}

const AmountDialog = ({
  className,
  isDialogOpen,
  dialogRef,
  handleDialogClose,
  reservationId,
  cost,
}: AmountDialogProps) => {
  const inputRef = useRef<null>(null);
  const [costValue, setCostValue] = useState(numberInputWithComma(cost));
  const [cursor, setCursor] = useState<number | null>(null);

  const { mutate: updateReservationDetailMutate } =
    useUpdateReservationDetail();
  const { addToast } = useToast();

  const updateCost = () => {
    const req = {
      reservationId,
      body: { cost: +deleteComma(costValue) },
    };

    updateReservationDetailMutate(req, {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.UPDATE_RESERVATION_COST_DONE);
      },
    });
  };

  const handleChangeCostValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!checkPrice(e.target.value)) return;

    const priceInputInfo = getCommaThousandUnitInputAfterChange(e, costValue);
    if (!priceInputInfo) return;

    setCostValue(priceInputInfo.value);
    setCursor(priceInputInfo.cursor);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const priceInputInfo = getCommaThousandUnitAfterDeleteMarker(e);
    if (!priceInputInfo) return;

    setCostValue(priceInputInfo.value);
    setCursor(priceInputInfo.cursor);
  };

  const handleUpdate = useCallback(() => {
    updateCost();
    handleDialogClose();
  }, [updateCost]);

  const isSubmitDisabled = costValue === "";

  useEffect(() => {
    setCostValue(numberInputWithComma(cost));
  }, [isDialogOpen]);

  useEffect(() => {
    const input = inputRef.current as any;

    input?.setSelectionRange(cursor, cursor);
  }, [inputRef, cursor, costValue]);

  return (
    <>
      {isDialogOpen && (
        <S.DialogRoot className={className} open={isDialogOpen} ref={dialogRef}>
          <S.Content>
            <label htmlFor={AMOUNT_ID}>총 정비 금액</label>
            <S.InputWrapper>
              <Input
                ref={inputRef}
                id={AMOUNT_ID}
                value={costValue ?? 0}
                placeholder="금액을 입력하세요"
                onKeyDown={handleKeyDown}
                onChange={handleChangeCostValue}
              />
              <span>원</span>
            </S.InputWrapper>
          </S.Content>
          <S.ButtonWrapper>
            <Button
              css={S.cancelButton}
              variant="secondarySmall"
              onClick={handleDialogClose}
            >
              취소
            </Button>
            <Button onClick={handleUpdate} disabled={isSubmitDisabled}>
              완료
            </Button>
          </S.ButtonWrapper>
        </S.DialogRoot>
      )}
    </>
  );
};

export default AmountDialog;
