import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { mixin_scrollbar } from "style/mixin";

export const detailModal = () => css`
  width: 666px;
`;

export const ContentTop = styled.div``;

export const ContentTopHeader = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 112px 1fr 156px;
    height: 40px;
    border-bottom: 1px solid ${theme.color.gray_02};
    background-color: ${theme.color.background};

    & > span {
      ${theme.font.medium_14};
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${theme.color.gray_05};
    }
  `}
`;

export const AddButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    border-bottom: 1px solid ${theme.color.gray_02};
    background-color: ${theme.color.white};
  `}
`;

export const addButton = (theme: Theme) => css`
  height: 100%;
  border: 0;
  color: ${theme.color.blue_01};
`;

export const plusIcon = (theme: Theme) => css`
  margin-right: 5px;
  width: 18px;
  height: 18px;
  fill: ${theme.color.blue_01};
`;

export const CommentWrapper = styled.ul`
  ${({ theme }) => css`
    ${mixin_scrollbar(theme)};
    height: 386px;
    border-radius: 4px;
    background-color: ${theme.color.white};
  `}
`;

export const hasNoneData = (theme: Theme) => css`
  & > span {
    color: ${theme.color.gray_05};
  }
`;
