import { css } from "@emotion/react";

import { font } from "./font";
import { color } from "./color";

const mixinButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

const mixinDefault = css`
  ${mixinButton};

  &:disabled {
    color: ${color.gray_05};
    background-color: ${color.gray_02};
  }
`;

const mixinGhost = css`
  ${mixinButton};

  &:disabled {
    color: ${color.gray_05};
  }
`;

const buttonSize = {
  default: css`
    ${font.medium_14};
  `,
  small: css`
    ${font.medium_14};
    height: 34px;
    padding: 0 11px;
  `,
  medium: css`
    ${font.medium_14};
    width: 90px;
    height: 34px;
  `,
  large: css`
    ${font.medium_16};
    width: 100%;
    height: 44px;
  `,
};

const buttonStyle = {
  primary: css`
    border: 1px solid transparent;
    color: ${color.white};
    background-color: ${color.blue_01};

    &:not(:disabled):hover {
      background-color: ${color.blue_03};
    }
  `,
  secondary: css`
    border: 1px solid ${color.gray_03};
    color: ${color.gray_07};
    background-color: ${color.white};

    &:not(:disabled):hover {
      background-color: ${color.blue_02};
    }
  `,
  warning: css`
    color: ${color.error_01};
    background-color: ${color.error_02};
  `,
  ghostPrimary: css`
    color: ${color.blue_01};
    text-decoration: underline;

    &:not(:disabled):hover {
      color: ${color.blue_03};
    }
  `,
  ghostSecondary: css`
    color: ${color.gray_05};
    text-decoration: underline;

    &:not(:disabled):hover {
      color: ${color.blue_01};
    }
  `,
};

export const button = {
  primarySmall: css`
    ${mixinDefault};
    ${buttonSize.small};
    ${buttonStyle.primary};
  `,
  primaryMedium: css`
    ${mixinDefault};
    ${buttonSize.medium};
    ${buttonStyle.primary};
  `,
  primaryLarge: css`
    ${mixinDefault};
    ${buttonSize.large};
    ${buttonStyle.primary};
  `,
  secondarySmall: css`
    ${mixinDefault};
    ${buttonSize.small};
    ${buttonStyle.secondary};
  `,
  secondaryMedium: css`
    ${mixinDefault};
    ${buttonSize.medium};
    ${buttonStyle.secondary};
  `,
  secondaryLarge: css`
    ${mixinDefault};
    ${buttonSize.large};
    ${buttonStyle.secondary};
  `,
  warningMedium: css`
    ${mixinDefault};
    ${buttonSize.medium};
    ${buttonStyle.warning};
  `,
  ghostPrimary: css`
    ${mixinGhost};
    ${buttonStyle.ghostPrimary};
    ${font.medium_14};
  `,
  ghostSecondary: css`
    ${mixinGhost};
    ${buttonStyle.ghostSecondary};
    ${font.medium_14};
  `,
} as const;

export type BtnTheme = typeof button;
