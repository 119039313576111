import React from "react";

import { ExclamationIconImg } from "assets";
import * as S from "./HasNoneData.styled";

interface HasNoneDataProps {
  className?: string;
  children?: React.ReactNode;
  text: string;
  asProp?: any;
}

const HasNoneData = ({
  children,
  className,
  text,
  asProp,
}: HasNoneDataProps) => {
  return (
    <S.Root as={asProp} className={className}>
      <ExclamationIconImg css={S.exclamationIcon} />
      <span>{text}</span>
      {children}
    </S.Root>
  );
};

export default HasNoneData;
