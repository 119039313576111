import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { INPUT_MAX_LENGTH } from "utils/constants";

const useSearchInput = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchValue, setSearchValue] = useState("");

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.replaceAll(" ", "");

      if (value.length > INPUT_MAX_LENGTH.SEARCH_INPUT) return;

      setSearchValue(value);
    },
    [searchParams],
  );

  const handleSearch = useCallback(
    (value: string) => {
      const notResetQueries = ["q", "page"];
      setSearchParams({
        ...Object.fromEntries(
          [...searchParams].filter(
            (item) => !notResetQueries.includes(item[0]),
          ),
        ),
        ...(value && { q: value.trim() }),
      });
    },
    [searchParams],
  );

  const handleResetSearch = useCallback(() => {
    setSearchParams({});
  }, [searchParams]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;
    const input = target[0] as HTMLInputElement;
    const value = input.value;
    const prevValue = searchParams.get("q");

    if (!value && !prevValue) return;

    handleSearch(value);
  };

  useEffect(() => {
    setSearchValue(searchParams.get("q") || "");
  }, [searchParams.get("q")]);

  return {
    searchValue,
    handleChange,
    handleResetSearch,
    handleSubmit,
  };
};

export default useSearchInput;
