import React from "react";

import { Button, ReservationForm, CenterLayout } from "components";
import { VEHICLE_DESC_INFO } from "assets";
import BasicInfo from "./container/basic/BasicInfo";
import RepairInfo from "./container/repair/RepairInfo";
import AdditionInfo from "./container/addition/AdditionInfo";
import TimeInfo from "./container/time/TimeInfo";
import CustomerInqueryForm from "./container/customerInquery/CustomerInqueryForm";
import useAddReservation from "./hooks/useAddReservation";
import * as S from "./AddReservation.styled";

const AddReservation = () => {
  const {
    inputRefs,
    form,
    noshowCnt,
    isSubmitDisabled,
    isSearchedRegNum,
    isDisabledRegNum,
    handleKeyDown,
    handleChangeForm,
    handleBlurForm,
    handleSubmit,
    handleSelectDate,
    handleSearchCustomer,
    handleSelectRegNum,
  } = useAddReservation();

  return (
    <>
      <CenterLayout css={S.centerLayout}>
        <ReservationForm title="예약 추가" handleSubmit={handleSubmit}>
          <ReservationForm.LeftContent>
            <ReservationForm.Field
              css={S.reservationVehicleField}
              title="차량 조회"
            >
              <S.VehicleDesc>{VEHICLE_DESC_INFO}</S.VehicleDesc>
              <CustomerInqueryForm
                inputRefs={inputRefs}
                data={form}
                isDisabledRegNum={!!isDisabledRegNum}
                handleSearchCustomer={handleSearchCustomer}
                handleChangeData={handleChangeForm}
                // handleBlurDataBlur={handleBlurForm} // NOTE: 해당 코드 파악 후 삭제 요망!
                handleSelect={handleSelectRegNum}
              />
            </ReservationForm.Field>
            {isSearchedRegNum && (
              <>
                <ReservationForm.Field title="고객 정보">
                  <BasicInfo
                    data={form}
                    noshowCnt={noshowCnt}
                    inputRefs={inputRefs}
                    handleKeyDown={handleKeyDown}
                    handleChangeData={handleChangeForm}
                    handleBlurData={handleBlurForm}
                  />
                </ReservationForm.Field>
                <ReservationForm.Field title="요청 정보">
                  <RepairInfo
                    data={form}
                    inputRefs={inputRefs}
                    handleChangeData={handleChangeForm}
                    handleBlurData={handleBlurForm}
                    handleSelectDate={handleSelectDate}
                  />
                </ReservationForm.Field>
                <ReservationForm.Field title="추가 정보">
                  <AdditionInfo
                    inputRefs={inputRefs}
                    data={form}
                    handleChangeData={handleChangeForm}
                    handleBlurData={handleBlurForm}
                  />
                </ReservationForm.Field>
              </>
            )}
          </ReservationForm.LeftContent>
          <ReservationForm.RightContent>
            <ReservationForm.Field title="예약 추가">
              <TimeInfo
                data={form}
                inputRefs={inputRefs}
                handleChangeData={handleChangeForm}
                handleBlurData={handleBlurForm}
                handleSelectDate={handleSelectDate}
              />
              <Button
                disabled={isSubmitDisabled}
                variant="primaryLarge"
                onClick={handleSubmit}
              >
                예약 완료
              </Button>
            </ReservationForm.Field>
          </ReservationForm.RightContent>
        </ReservationForm>
      </CenterLayout>
    </>
  );
};

export default AddReservation;
