import { useSetRecoilState } from "recoil";

import { toastState } from "store";

const useToast = () => {
  const setToast = useSetRecoilState(toastState);

  const addToast = (toast: string) => {
    setToast([toast]);
  };

  const removeToast = () => {
    setToast([]);
  };

  return { addToast, removeToast };
};

export default useToast;
