export const PATH = {
  root: "/",
  login: "/login",
  password: "/password",
  expired: "/expired",
  notFound: "/notFound",
  schedule: "/schedule",
  addReservation: "/addReservation",
  reservation: "/reservation",
  reservationDetail: "/reservation/detail",
  reservationSchedule: "/reservation/schedule",
  customer: "/customer",
  customerDetail: "/customer/detail",
  review: "/review",
  configuration: "/configuration",
  repairShop: "/configuration/repairshop",
  staff: "/configuration/staff",
  changePassword: "/configuration/changePassword",
  inquiry: "/configuration/inquiry",
  inquiryCreate: "/configuration/inquiry/create",
  inquiryDetail: "/configuration/inquiry/detail",
  cs: "/cs",
  notice: "/cs/notice",
  event: "/cs/event",
  faq: "/cs/faq",
  csInquiry: "/cs/inquiry",
  diagnosisSolution: "/reservation/diagnosisSolution",
};
