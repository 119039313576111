import React, { useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

import { useDialog, useCalendar } from "hooks";
import { LeftIconImg, RightIconImg } from "assets";
import Month from "../month/Month";
import * as S from "./MonthPicker.styled";

interface MonthPickerProps {
  className?: string;
  cb: any;
  trigger: any;
}

const MonthPicker = ({ className, cb, trigger }: MonthPickerProps) => {
  const [searchParams] = useSearchParams();

  const { isDialogOpen, dialogRef, handleToggleDialogOpen, handleDialogClose } =
    useDialog();

  const {
    monthYear,
    selectedDate,
    handleSelectDate,
    changePrevYear,
    changeNextYear,
    changeMonthYear,
  } = useCalendar(
    searchParams.get("date") ? dayjs(searchParams.get("date")) : dayjs(),
  );

  const handleClickMonth = (date: string) => () => {
    const newDate = dayjs(date).format("YYYY-MM");

    typeof cb === "function" && cb(newDate);

    handleSelectDate(date);
    changeMonthYear(date);
    handleDialogClose();
  };

  useLayoutEffect(() => {
    if (isDialogOpen) {
      const date = searchParams.get("date")
        ? dayjs(searchParams.get("date"))
        : dayjs();
      handleSelectDate(date);
      changeMonthYear(date);
    }
  }, [isDialogOpen]);

  return (
    <S.Root>
      {typeof trigger === "function" &&
        trigger({ handleToggleDialogOpen, isDialogOpen })}
      {isDialogOpen && (
        <S.DialogRoot
          className={className}
          open={isDialogOpen}
          ref={dialogRef}
          aria-modal="true"
        >
          <S.Header>
            <S.Navigation>
              <button
                type="button"
                aria-label="이전 년도 변경"
                onClick={changePrevYear}
              >
                <LeftIconImg css={S.leftIcon} />
              </button>
              <time>{monthYear.year}</time>
              <button
                type="button"
                aria-label="다음 년도 변경"
                onClick={changeNextYear}
              >
                <RightIconImg css={S.rightIcon} />
              </button>
            </S.Navigation>
          </S.Header>
          <S.Months>
            {[...Array(12)].map((_, i) => (
              <Month
                key={i}
                date={monthYear.startMonth.add(i, "M")}
                selectedDate={selectedDate}
                handleSelectDate={handleClickMonth}
              />
            ))}
          </S.Months>
        </S.DialogRoot>
      )}
    </S.Root>
  );
};

export default MonthPicker;
