import { useRef, useCallback, useEffect } from "react";
import dayjs from "dayjs";
import { useImmer } from "use-immer";

import { useToast, useCheckRequired } from "hooks";
import {
  useAcceptReservation,
  useCancelReservation,
  useChangeReservation,
  useUpdateReservationMemo,
} from "services";
import {
  estimateHourFormatter,
  numToStringPhoneNum,
  numericOnly,
  formatPlannedDateTime,
  formatUtcISODateTime,
  checkExpectationTime,
} from "utils";
import {
  TOAST_MSG,
  VALID_MESSAGE,
  APPROVED_MAINTENANCE,
} from "constants/index";
import { ValueOf } from "types";

const INITIAL_STATE = {
  name: { value: "", error: "" },
  phone: { value: "", error: "" },
  regNum: { value: "", error: "" },
  modelName: { value: "", error: "" },
  modelYear: { value: "", error: "" },
  brandId: { value: "", error: "" },
  vin: { value: "", error: "" },
  mileage: { value: "", error: "" },
  axle: { value: "", error: "" },
  seat: { value: "", error: "" },
  userComment: { value: "", error: "" },
  approvedMaintenance: { value: APPROVED_MAINTENANCE.APPROVED, error: "" },
  planned: { value: "", error: "" },
  plannedDate: { value: "", error: "" },
  plannedTimeHour: { value: "", error: "" },
  plannedTimeMin: { value: "", error: "" },
  estimatedTime: { value: "", error: "" },
  estimatedTimeHour: { value: "", error: "" },
  estimatedTimeMin: { value: "", error: "" },
  cancelComment: { value: "", error: "" },
  reservationId: { value: "", error: "" },
  reservationNo: { value: "", error: "" },
  noshows: { value: "", error: "" },
  status: { value: "", error: "" },
  isSelf: { value: "", error: "" },
  memo: { value: "", error: "" },
};

const useAccepted = (initData: any) => {
  const inputRefs = useRef({});
  const isInited = useRef(false);

  const [form, setForm] = useImmer(INITIAL_STATE);

  const { mutate: acceptReservationMutate } = useAcceptReservation();
  const { mutate: changeReservationMutate } = useChangeReservation();
  const { mutate: cancelReservationMutate } = useCancelReservation();
  const { mutate: updateReservationMemoMutate } = useUpdateReservationMemo();
  const { addToast } = useToast();
  const [checkRequired] = useCheckRequired(
    inputRefs,
    form,
    setForm,
    VALID_MESSAGE.REQUIRED_NO_ASTERISK,
  );

  const isSubmitDisabled =
    Object.values(form).filter((data) => data.error !== "").length > 0;

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();

      const type = form.approvedMaintenance.value as ValueOf<
        typeof APPROVED_MAINTENANCE
      >;

      if (checkRequired()) return;

      setForm((draft: any) => {
        for (const key of Object.keys(form)) {
          draft[key].error = "";
        }
      });

      const formatPlannedDate = formatPlannedDateTime(
        form.plannedDate.value,
        form.plannedTimeHour.value,
        form.plannedTimeMin.value,
      );

      switch (type) {
        case "approved":
          {
            const req = {
              reservationId: initData.reservationId,
              body: {
                estimatedTime: `${form.estimatedTimeHour.value}:${form.estimatedTimeMin.value}`,
              },
            };

            acceptReservationMutate(req, {
              onSuccess: () => {
                addToast(TOAST_MSG.SUCCESS.RESERVATION_DONE);
              },
              onError: (err) => {
                switch (err.response?.data.code) {
                  case "INVALID_PLANNED":
                    addToast(TOAST_MSG.FAIL.NOT_CORRECT_DATE);
                }
              },
            });
          }
          break;

        case "change":
          {
            const req = {
              reservationId: initData.reservationId,
              body: {
                planned: formatUtcISODateTime(
                  formatPlannedDate,
                  "YYYY-MM-DD HH:mm",
                ),
                estimatedTime: `${form.estimatedTimeHour.value}:${form.estimatedTimeMin.value}`,
              },
            };

            changeReservationMutate(req, {
              onSuccess: () => {
                addToast(TOAST_MSG.SUCCESS.UPDATE_RESERVATION_DONE);
              },
              onError: (err) => {
                switch (err.response?.data.code) {
                  case "INVALID_PLANNED":
                    addToast(TOAST_MSG.FAIL.NOT_CORRECT_DATE);
                }
              },
            });
          }
          break;

        case "cancel":
          {
            const req = {
              reservationId: initData.reservationId,
              body: {
                cancelComment: form.cancelComment.value,
              },
            };

            cancelReservationMutate(req, {
              onSuccess: () => {
                addToast(TOAST_MSG.SUCCESS.CANCEL_RESERVATION_DONE);
              },
            });
          }
          break;
      }
    },
    [form],
  );

  const handleChangeForm = useCallback(
    (event: any, key: string) => {
      const name = event?.target?.name ?? key;
      let value = event?.target?.value ?? event;
      const error = "";

      switch (name) {
        case "modelYear":
          value = numericOnly(value);
          if (value.length > 4) return;
          break;

        case "plannedDate":
          value = dayjs(value).format("YYYY.MM.DD");
          break;

        case "estimatedTimeHour":
          if (!checkExpectationTime(value)) return;
          value = estimateHourFormatter(value);

          if (value === 0) {
            setForm((draft) => {
              draft.estimatedTimeMin.value = "30";
              draft.estimatedTimeMin.error = "";
            });
          }
          break;

        case "cancelComment":
        case "memo":
          if (value.length > 200) return;
      }

      setForm((draft: any) => {
        draft[name].value = value;
        draft[name].error = error;
      });
    },
    [form],
  );

  const handleBlurForm = useCallback(
    (event: any) => {
      const {
        name,
        dataset: { required },
      } = event.target;
      const error = "";

      if (required && form[name as keyof typeof form].value.length === 0) {
        setForm((draft: any) => {
          draft[name].error = VALID_MESSAGE.REQUIRED_NO_ASTERISK;
        });
        return;
      }

      setForm((draft: any) => {
        draft[name].error = error;
      });
    },
    [form],
  );

  const handleSaveMemo = useCallback(() => {
    const req = {
      reservationId: form.reservationId.value,
      body: {
        memo: form.memo.value.trim() || null,
      },
    };

    updateReservationMemoMutate(req, {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.SAVE_MEMO_DONE);
      },
    });
  }, [form]);

  const handleSelectDate = useCallback(
    (name: string) => (date: any) => {
      handleChangeForm(date, name);
    },
    [],
  );

  const init = () => {
    setForm((draft: any) => {
      for (const key in initData) {
        if (draft[key]) {
          draft[key].value = initData[key] ?? "";
          draft[key].error = "";
        }
      }

      draft.phone.value = numToStringPhoneNum(initData.phone);
      draft.plannedDate.value =
        dayjs(initData.planned).format("YYYY.MM.DD") ?? "";
      draft.plannedTimeHour.value =
        Number(dayjs(initData.planned).format("HH:mm").split(":")[0]) ?? "";
      draft.plannedTimeMin.value =
        dayjs(initData.planned).format("HH:mm").split(":")[1] ?? "";
      draft.approvedMaintenance.value = APPROVED_MAINTENANCE.APPROVED;
      draft.estimatedTimeHour.value = "";
      draft.estimatedTimeMin.value = "";
    });
  };

  useEffect(() => {
    init();
    isInited.current = true;
  }, [initData]);

  useEffect(() => {
    const type = form.approvedMaintenance.value;
    const callbackTable = {
      [APPROVED_MAINTENANCE.CANCEL]: () => {
        addToast(TOAST_MSG.SUCCESS.SELECT_CANCEL_RESERVATION);
        setForm((draft) => {
          draft.estimatedTimeHour.value = "";
          draft.estimatedTimeHour.error = "";
          draft.estimatedTimeMin.value = "";
          draft.estimatedTimeMin.error = "";
          draft.cancelComment.value = "";
          draft.cancelComment.error = "";
        });
      },
      [APPROVED_MAINTENANCE.CHANGE]: () => {
        setForm((draft: any) => {
          draft.plannedDate.value =
            dayjs(initData.planned).format("YYYY.MM.DD") ?? "";
          draft.plannedTimeHour.value =
            +dayjs(initData.planned).format("HH:mm").split(":")[0] ?? "";
          draft.plannedTimeMin.value =
            dayjs(initData.planned).format("HH:mm").split(":")[1] ?? "";
          draft.estimatedTimeHour.value = "";
          draft.estimatedTimeHour.error = "";
          draft.estimatedTimeMin.value = "";
          draft.estimatedTimeMin.error = "";
        });
      },
      [APPROVED_MAINTENANCE.APPROVED]: () => {
        setForm((draft) => {
          draft.plannedDate.value =
            dayjs(initData.planned).format("YYYY.MM.DD") ?? "";
          draft.plannedTimeHour.value =
            dayjs(initData.planned).format("HH:mm").split(":")[0] ?? "";
          draft.plannedTimeMin.value =
            dayjs(initData.planned).format("HH:mm").split(":")[1] ?? "";
          draft.estimatedTimeHour.value = "";
          draft.estimatedTimeHour.error = "";
          draft.estimatedTimeMin.value = "";
          draft.estimatedTimeMin.error = "";
        });
      },
    };
    typeof callbackTable[type] === "function" && callbackTable[type]();
  }, [form.approvedMaintenance.value]);

  return {
    inputRefs,
    form,
    isSubmitDisabled,
    handleSubmit,
    handleChangeForm,
    handleBlurForm,
    handleSelectDate,
    handleSaveMemo,
  };
};

export default useAccepted;
