import React, { useMemo } from "react";

import { CloseIconImg, PlusIconImg } from "assets";
import * as S from "./InquiryImageUploader.styled";

interface InquiryImageUploaderProps {
  inputRefs: any;
  itemKey: string;
  attachList: any[];
  handleChangeForm?: any;
  handleDeleteImage: (id: number) => void;
}

const InquiryImageUploader = ({
  itemKey,
  inputRefs,
  attachList,
  handleChangeForm,
  handleDeleteImage,
}: InquiryImageUploaderProps) => {
  const isAttaches = useMemo(() => attachList?.length > 0, [attachList]);
  const isAttachesFullLength = useMemo(
    () => attachList?.length > 2,
    [attachList],
  );

  const deleteFiles = (id: number) => () => {
    handleDeleteImage(id);
  };

  const handleOnClickClearFile = (e: React.MouseEvent<HTMLInputElement>) => {
    (e.target as HTMLInputElement).value = "";
  };

  return (
    <S.RootStyle>
      <S.UploadLabel disabled={attachList.length >= 3}>
        <S.UploadButton disabled={isAttachesFullLength}>
          <PlusIconImg /> <span>이미지 첨부 ({attachList.length}/3)</span>
        </S.UploadButton>
        <input
          multiple
          type="file"
          name={itemKey}
          id={`add-inquiry-${itemKey}`}
          ref={(el) => (inputRefs.current[itemKey] = el)}
          accept="image/jpg,image/jpeg,image/png,image/webp"
          onClick={handleOnClickClearFile}
          onChange={handleChangeForm}
        />
      </S.UploadLabel>
      {!!attachList.length && (
        <S.FileWrapper isAttaches={isAttaches}>
          {attachList.map((item, i) => (
            <S.File key={i}>
              <img src={URL.createObjectURL(item)} />
              <S.DeleteBtn
                type="button"
                onClick={deleteFiles(i)}
                aria-label="delete inquiry attachment"
              >
                <CloseIconImg />
              </S.DeleteBtn>
            </S.File>
          ))}
        </S.FileWrapper>
      )}
    </S.RootStyle>
  );
};

export default InquiryImageUploader;
