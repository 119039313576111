/* eslint-disable no-useless-escape */
import JSZip from "jszip";

export const getFileExtension = (file: any) => {
  if (!file) return null;
  const regex = /\S+([\.\/]\S+)/;
  return file.match(regex)[1];
};

export const downloadFile = (file: any, fileName: string) => {
  const URL = window.URL ?? window.webkitURL;
  const downloadUrl = URL.createObjectURL(
    new Blob([file.data], { type: file.headers["content-type"] }),
  );
  download(downloadUrl, fileName);
};

export const downloadZip = async (files: any[], zipName: string) => {
  const zip = new JSZip();
  const folder = zip.folder(zipName);
  files.forEach(({ file, name, extension }) =>
    folder?.file(`${name}${extension}`, file.data),
  );
  const blob = await zip.generateAsync({ type: "blob" });
  const URL = window.URL ?? window.webkitURL;
  const downloadUrl = URL.createObjectURL(blob);

  download(downloadUrl, zipName);
};

export const download = (url: string, name: string) => {
  const a = document.createElement("a");

  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 100);
};
