import React, { createContext, useContext } from "react";

import { Button, Select } from "components";
import { CloseIconImg } from "assets";
import * as S from "./SelectAddList.styled";

interface SelectContextType {
  labelTable?: { [key: string]: string };
  selectedItems: string[];
  displayCase?: (value: string) => boolean;
}
const SelectAddListContext = createContext<SelectContextType | null>(null);

interface SelectAddListProps extends SelectContextType {
  className?: string;
  children?: React.ReactNode;
}

const SelectAddList = ({
  children,
  className,
  labelTable,
  displayCase,
  selectedItems,
}: SelectAddListProps) => {
  return (
    <SelectAddListContext.Provider
      value={{ displayCase, labelTable, selectedItems }}
    >
      <S.Root className={className}>{children}</S.Root>
    </SelectAddListContext.Provider>
  );
};
SelectAddList.Select = function SelectItem({ ...restProps }: any) {
  const context = useContext(SelectAddListContext);
  if (!context) return null;

  const { labelTable, displayCase, selectedItems } = context;
  if (!labelTable) return null;

  return (
    <Select css={S.select} labelTable={labelTable ?? {}} {...restProps}>
      {Object.entries(labelTable ?? {})?.map(([value, label]) => {
        if (displayCase && !displayCase(value)) return;

        return (
          <Select.Option
            css={(theme) => S.option(theme, selectedItems.includes(value))}
            key={value}
            value={value}
            disabled={selectedItems.includes(value)}
          >
            <span>{label}</span>
          </Select.Option>
        );
      })}
    </Select>
  );
};

interface ButtonWrapperProps {
  className?: string;
  type?: any;
  handleDeleteAddList: (item: any) => void;
}
SelectAddList.ButtonWrapper = function ButtonWrapperItem({
  className,
  type,
  handleDeleteAddList,
}: ButtonWrapperProps) {
  const context = useContext(SelectAddListContext);
  if (!context) return null;

  const { labelTable, selectedItems } = context;

  return (
    <S.ButtonWrapper className={className}>
      {selectedItems.map((item: any) => (
        <li key={item}>
          <Button
            css={S.button}
            variant="secondaryMedium"
            data-status={type}
            onClick={handleDeleteAddList(item)}
          >
            <span>{labelTable?.[item]}</span>
            <CloseIconImg css={S.closeIcon} />
          </Button>
        </li>
      ))}
    </S.ButtonWrapper>
  );
};

export default SelectAddList;
