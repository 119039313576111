import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  padding-bottom: 24px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const Column = styled.div`
  display: flex;
  width: 100%;
`;

export const Label = styled.label<{ isRequired: boolean }>`
  ${({ isRequired, theme }) => css`
    ${theme.font.regular_14};
    display: block;
    width: 136px;
    margin-bottom: 6px;
    color: ${theme.color.gray_07};

    &::after {
      content: ${isRequired ? "'*'" : null};
      display: inline-block;
      margin-left: 3px;
      color: ${theme.color.error_01};
    }
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const inputMixin = css`
  width: 186px;
  height: 36px;
`;

export const input = css`
  ${inputMixin};
`;

export const SuffixText = styled.span`
  display: inline-block;
  margin-left: 6px;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.02em;
`;

export const select = css`
  ${inputMixin};
`;

export const minSelector = (name: string) => css`
  & > div {
    width: ${name === "plannedTimeMin" && "70px"};
  }
`;

export const dateSelect = css`
  ${inputMixin};

  && + div {
    & > dialog {
      left: -186px;
      top: -354px;

      &::before,
      &::after {
        right: 117px;
      }
    }
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
`;

export const radioButton = css`
  height: 34px;

  &:not(:last-of-type) {
    margin-right: 6px;
  }
`;
