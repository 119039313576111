import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosError,
  AxiosResponse,
} from "axios";

import { auth } from "utils";
// NOTE : class를 export 로 index 에 정의하면 에러가 발생합니다.
import { TokenService } from "utils/helper/tokenService";

const tokenService = new TokenService(auth);

export const BASE_URL = process.env.REACT_APP_BASE_URL;

const createInstance = (baseURL?: string) => {
  const instance = axios.create({ baseURL });
  return setInterceptors(instance);
};

const setInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(onRequest, onRequestError);
  instance.interceptors.response.use(onResponse, onResponseError);
  return instance;
};

const onRequest = (config: AxiosRequestConfig) => {
  if (!config) {
    config = {};
  }

  if (!config.headers) {
    config.headers = {};
  }

  const accessToken = auth.accessToken;
  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  return config;
};

const onRequestError = (err: AxiosError) => {
  return Promise.reject(err);
};

const onResponse = (response: AxiosResponse) => {
  return response;
};

const onResponseError = async (err: AxiosError<any>): Promise<unknown> => {
  const { response: errorResponse } = err;

  if (
    err.name === "Error" &&
    err.message === "Request failed with status code 404"
  ) {
    // window.location.href = "/notFound"; // TODO: get presigned URl 요청 시 key값이 잘못 될 경우 404로 오고 있어서 해당 부분 논의가 필요합니다. 일단 주석처리 하였습니다.
  }

  if (errorResponse?.data.code === "INVALID_ACCESS_TOKEN") {
    return tokenService.resetTokenAndReattemptRequest(err);
  }

  const authErrorCode = ["Unauthorized"];
  if (authErrorCode.includes(errorResponse?.data.code)) {
    tokenService.expireSession();
  }

  return Promise.reject(err);
};

const ax = createInstance(`${BASE_URL}/repairshops`);
const commonAx = createInstance(BASE_URL);
export { ax, commonAx };
