import React from "react";
import { useRecoilValue } from "recoil";
import dayjs from "dayjs";

import { getHolidayInfo } from "store";
import { RESERVATION_TABINFO } from "assets";
import Status from "../status/Status";
import * as S from "./DateBox.styled";

interface DateBoxProps {
  className?: any;
  date: dayjs.Dayjs;
  isLoadingDone: boolean;
  disabled?: boolean;
  reservationStates?: [string, number][];
  handleSelectDate: any;
}

const DateBox = ({
  className,
  date,
  isLoadingDone,
  disabled,
  reservationStates,
  handleSelectDate,
}: DateBoxProps) => {
  const holidays = useRecoilValue(getHolidayInfo);
  const isToday = dayjs().isSame(date, "d");
  const isHoliday = holidays.includes(date.format("YYYYMMDD"));

  return (
    <S.Root
      className={className}
      aria-current={isToday ? "date" : "false"}
      disabled={disabled}
      data-status={isHoliday}
    >
      <time>{date.format("D")}</time>

      {reservationStates?.length && isLoadingDone ? (
        <S.StatusWrapper>
          {reservationStates.map(([status, count]) => (
            <S.DateButton
              key={status}
              type="button"
              aria-current={isToday ? "date" : "false"}
              disabled={disabled}
              onClick={handleSelectDate(date, status)}
            >
              <Status
                tabInfo={RESERVATION_TABINFO}
                status={status}
                count={count}
              />
            </S.DateButton>
          ))}
        </S.StatusWrapper>
      ) : null}
    </S.Root>
  );
};

export default DateBox;
