import React from "react";
import { NavLink, Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { Table, HasNoneData, Button } from "components";
import { isReqResvDialogOpenState } from "store";
import { getNullData } from "utils";
import {
  RESERVATION_NOHISTORY_MSG,
  RESERVATION_TABLE_COLUMNLIST,
} from "assets";
import { PATH } from "constants/index";
import type { DailyReservation, ReservationStatus } from "types";
import useTab from "../../hooks/useTab";
import * as S from "./ReservationTable.styled";

interface ReservationTableProps {
  isDone: boolean;
  reservationArr?: DailyReservation[];
  tabName: ReservationStatus;
}

const ReservationTable = ({
  isDone,
  reservationArr,
  tabName,
}: ReservationTableProps) => {
  const columns = RESERVATION_TABLE_COLUMNLIST[tabName];

  return (
    <Table css={S.root} title="정비 내역 목록" data-status={tabName} hasMoveBtn>
      <Table.Head columnList={columns} />
      <Table.Body
        css={S.tableBody}
        isDone={isDone}
        data={reservationArr ?? []}
        text=""
        PropsTbodyHasNoneData={ReservationTBodyNoneData}
      >
        {reservationArr?.map((rowData) => (
          <Table.Row key={rowData.reservationId} id={rowData.reservationId}>
            {columns.map((columnData, i) => (
              <Table.Cell css={S.cell} key={columnData.key}>
                {i === 0 && (
                  <Link
                    css={S.detailLink}
                    to={`${PATH.reservation}/detail?reservationId=${rowData.reservationId}`}
                  />
                )}
                {Column(columnData.key as keyof DailyReservation, rowData)}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default ReservationTable;

const Column = (key: keyof DailyReservation, data: DailyReservation) => {
  switch (key) {
    case "isSelf":
      return (
        <S.ReservationMethod data-status={data[key]}>
          {data[key]}
        </S.ReservationMethod>
      );

    default:
      return <span>{data[key] ? data[key] : getNullData(key)}</span>;
  }
};

const DialogLink = (name: any, msg: any) => {
  const setReqResvDialogOpen = useSetRecoilState(isReqResvDialogOpenState);

  const handleClickDialogBtn = () => {
    setReqResvDialogOpen(true);
  };

  switch (name) {
    case "planned":
      return (
        <NavLink css={S.configurationLink} to="/configuration/repairshop">
          {msg}
        </NavLink>
      );
    case "accepted":
      return (
        <Button
          css={S.dialogBtn}
          type="button"
          variant="ghostPrimary"
          onClick={handleClickDialogBtn}
        >
          {msg}
        </Button>
      );
    default:
      return;
  }
};

const ReservationTBodyNoneData = () => {
  const { tab } = useTab<ReservationStatus>("planned");

  return (
    <S.TBodyNoneData>
      <tr>
        <HasNoneData
          css={S.noHistory}
          asProp="td"
          text={RESERVATION_NOHISTORY_MSG?.[tab]?.text}
        >
          {DialogLink(tab, RESERVATION_NOHISTORY_MSG?.[tab]?.link)}
        </HasNoneData>
      </tr>
    </S.TBodyNoneData>
  );
};
