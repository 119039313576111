import React, { Fragment } from "react";

import {
  Table,
  YesNoButton,
  HasNoneData,
  MenuDialog,
  Button,
} from "components";
import { useModal } from "hooks";
import { CONVERT_VALUE_TABLE, STAFF_TABLE_DATA } from "assets";
import type { StaffClientItem } from "types";
import StaffEditModal from "./staffedit/StaffEditModal";
import StaffDelModal from "./staffdelete/StaffDelModal";
import StaffAddModal from "./staffadd/StaffAddModal";
import * as S from "./StaffsTable.styled";

interface StaffsTableProps {
  isDone: boolean;
  staffs?: StaffClientItem[];
}

const StaffsTable = ({ isDone, staffs }: StaffsTableProps) => {
  return (
    <Table css={S.root} title="직원 목록">
      <Table.Head columnList={STAFF_TABLE_DATA.columnList} />
      <Table.Body
        css={S.tableBody}
        isDone={isDone}
        data={staffs}
        text="등록된 직원이 없습니다"
        PropsTbodyHasNoneData={StaffTBodyNoneData}
      >
        {staffs?.map((rowData) => (
          <Fragment key={rowData.staffId}>
            <Table.Row id={rowData.staffId}>
              {STAFF_TABLE_DATA.columnList.map((columnData, i) => (
                <Table.Cell css={S.cell} key={columnData.key}>
                  {Column(columnData.key, rowData)}
                  {i === STAFF_TABLE_DATA.columnList.length - 1 && (
                    <S.MenuBtnWrapper>
                      <MenuDialog
                        rowData={rowData}
                        editCompo={StaffEditModal}
                        delCompo={StaffDelModal}
                      />
                    </S.MenuBtnWrapper>
                  )}
                </Table.Cell>
              ))}
            </Table.Row>
          </Fragment>
        ))}
      </Table.Body>
    </Table>
  );
};

export default StaffsTable;

const Column = (key: keyof StaffClientItem, data: StaffClientItem) => {
  switch (key) {
    case "alimtalkYn":
      return (
        <YesNoButton
          status={data[key] === "y"}
          labels={CONVERT_VALUE_TABLE[key]}
        />
      );

    case "isMechanic":
      return (
        <S.JobType data-status={data[key]}>
          {CONVERT_VALUE_TABLE[key][data[key]]}
        </S.JobType>
      );

    default:
      return <span>{data[key]}</span>;
  }
};

const StaffTBodyNoneData = () => {
  const { modalRef, handleModalOpen, handleModalClose } = useModal();

  return (
    <S.StaffTBodyNoneData>
      <tr>
        <HasNoneData
          css={S.staffNoHistory}
          asProp="td"
          text="아직 등록된 직원이 없어요!"
        >
          <Button
            css={S.staffAddButton}
            onClick={handleModalOpen(
              <StaffAddModal
                ref={modalRef}
                handleModalClose={handleModalClose}
              />,
            )}
          >
            직원 추가
          </Button>
        </HasNoneData>
      </tr>
    </S.StaffTBodyNoneData>
  );
};
