import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { mixin_scrollbar } from "style/mixin";

export const Root = styled.div`
  position: relative;
`;

interface SelectButtonProps {
  isSelected: boolean;
  isShowDropdown: number;
  validError: string;
}

export const SelectButton = styled.button<SelectButtonProps>`
  ${({ isSelected, isShowDropdown, validError, theme }) => css`
    ${theme.font.regular_14};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 34px;
    border: 1px solid
      ${isShowDropdown
        ? theme.color.blue_01
        : validError
        ? theme.color.error_01
        : theme.color.gray_03};
    border-radius: 4px;
    padding: 0 11px;
    color: ${!isSelected || isShowDropdown
      ? theme.color.gray_07
      : theme.color.gray_10};
    background-color: ${theme.color.white};

    & > svg {
      transform: ${isShowDropdown ? "rotate(0)" : "rotate(180deg)"};
    }
    &[data-readonly="true"] {
      color: ${theme.color.gray_05};
      background-color: ${theme.color.gray_01};
      & > svg {
        display: none;
      }
    }
    &[disabled] {
      color: ${theme.color.gray_05};
      background-color: ${theme.color.gray_01};
    }
  `}
`;

interface DropdownProps {
  isShowDropdown: boolean;
  isReverse: boolean;
  top?: string;
}

export const Dropdown = styled.ul<DropdownProps>`
  ${({ isShowDropdown, isReverse, theme, top }) => css`
    ${mixin_scrollbar(theme)};
    position: absolute;
    top: ${top};
    display: ${isShowDropdown ? "unset" : "none"};
    width: 100%;
    max-height: calc(34px * 3 + 2px);
    margin-top: 3px;
    border: 1px solid ${theme.color.gray_03};
    border-radius: 3px;
    box-shadow: ${theme.boxshadow.shadow2_2_8};
    z-index: 100;
    &[data-length="0"] {
      border-top: 0;
      border-bottom: 0;
    }
    &[data-length="1"] {
      top: ${isReverse ? "-40px" : "37px"};
    }
    &::-webkit-scrollbar {
      width: 9px;
    }
    &::-webkit-scrollbar-thumb {
      border: 2px solid transparent;
      border-radius: 3px;
      background-color: ${theme.color.gray_03};
      background-clip: padding-box;
    }
    &::-webkit-scrollbar-track {
      border-left: 1px solid ${theme.color.gray_03};
      border-right: 1px solid ${theme.color.gray_03};
      border-radius: 3px;
      background-color: ${theme.color.white};
    }
  `}
`;

export const Option = styled.li<{ selected: boolean }>`
  ${({ selected, theme }) => css`
    & > button {
      ${theme.font.regular_14};
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 34px;
      padding: 0 12px;
      color: ${selected ? theme.color.blue_01 : theme.color.gray_10};
      background-color: ${selected ? theme.color.blue_02 : theme.color.white};

      &:not(:disabled):hover {
        color: ${theme.color.blue_01};
        background-color: ${theme.color.blue_02};
      }
      & > svg {
        width: 18px;
        height: 18px;
        fill: ${selected ? theme.color.blue_01 : theme.color.gray_02};
        pointer-events: none;
      }
      &[disabled] {
        color: ${theme.color.disabled};
      }
    }
  `}
`;
