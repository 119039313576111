import React from "react";

import { StarIconImg } from "assets";
import * as S from "./StarRating.styled";

interface StarRatingProps {
  className?: string;
  rate: number;
}

const StarRating = ({ className, rate }: StarRatingProps) => {
  const integer = Math.floor(rate);
  const decimals = +(rate - integer).toFixed(1);

  const getFillPercent = (
    value: number,
    integerRate: number,
    decimalRate: number,
  ) => {
    if (integerRate >= value) {
      return "0%";
    }

    if (integerRate === value - 1) {
      return `-${100 - decimalRate * 100}%`;
    }

    return "-100%";
  };

  const ratingStarList = Array(5)
    .fill(undefined)
    .map((_, i) => {
      const value = i + 1;
      const fill = getFillPercent(value, integer, decimals);
      return {
        value,
        fill,
      };
    });

  return (
    <S.Root className={className} aria-label={`평점${rate}`}>
      {ratingStarList.map(({ value, fill }) => (
        <StarIconImg
          css={(theme) => S.ratingStar(theme, fill)}
          key={value}
          fill={fill}
        />
      ))}
    </S.Root>
  );
};

export default StarRating;
