import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid ${theme.color.gray_03};
    border-top-width: 6px;
    border-radius: 4px;
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelWrapper = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
  margin-bottom: 6px;
`;

interface CheckboxProps {
  disabled: boolean;
  selected: boolean;
}

export const Checkbox = styled.label<CheckboxProps>`
  ${({ disabled, selected, theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border: 1px solid ${selected ? theme.color.blue_01 : theme.color.gray_03};
    border-radius: 4px;
    cursor: ${disabled ? "unset" : "pointer"};

    & > input {
      display: none;
    }

    & > svg {
      fill: ${selected ? theme.color.blue_01 : theme.color.gray_03};
    }
  `}
`;

const mixinLabel = (theme: Theme) => css`
  ${theme.font.regular_14};
  color: ${theme.color.gray_06};
`;

export const Label = styled.span`
  ${({ theme }) => css`
    ${mixinLabel(theme)};
    margin-bottom: 8px;
  `}
`;

export const DialogLabel = styled.span`
  ${({ theme }) => css`
    ${mixinLabel(theme)}
  `}
`;

export const DateWrapper = styled.div`
  display: flex;
`;

export const dateButton = (
  theme: Theme,
  isWork: boolean,
  selected: boolean,
  isDayClicked: boolean,
) => css`
  ${theme.font.medium_14};
  position: relative;
  height: 100%;
  border: 1px solid ${selected ? theme.color.blue_01 : theme.color.gray_02};
  border-radius: 4px;
  padding: 7px 16px;
  color: ${isWork && theme.color.white};
  background-color: ${isWork && theme.color.blue_01};
  opacity: ${isDayClicked ? 1 : selected ? 1 : 0.4};

  &:not(:last-of-type) {
    margin-right: 6px;
  }

  &:hover {
    opacity: 1;
  }
`;

export const TimeWrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    width: 100%;

    & > div:first-of-type {
      position: relative;
      align-items: center;

      &::after {
        content: "~";
        position: absolute;
        right: -13px;
        font-weight: 500;
        color: ${theme.color.gray_04};
      }
    }
  `}
`;

export const estimatedTime = (theme: Theme) => css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;

  & > div {
    &:not(:last-of-type) {
      margin: 0;
    }

    &:first-of-type::after {
      content: ":";
      font-weight: 500;
      color: ${theme.color.gray_04};
    }

    & > div {
      min-width: 100%;
    }
  }
`;

export const WorkDateWrapper = styled(ContentWrapper)`
  padding: 20px 24px;
`;

export const InnerWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: flex-end;
    column-gap: 10px;
    margin-bottom: 16px;
    padding-bottom: 17px;

    ::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: ${theme.color.gray_02};
    }
  `}
`;

export const RadioWrapper = styled.div`
  display: flex;
  column-gap: 32px;
`;

export const radio = (theme: Theme, checked: boolean) => css`
  input[type="radio"] + label {
    color: ${checked ? theme.color.blue_01 : null};

    &::before {
      margin-right: 4px;
    }
  }
`;

export const memoInput = css`
  height: 34px;
`;

export const ErrorMsg = styled.span<{ hasErr: boolean }>`
  ${({ hasErr, theme }) => css`
    ${theme.font.regular_12};
    display: ${!hasErr && "none"};
    margin-top: 4px;
    color: ${theme.color.error_01};
  `}
`;
