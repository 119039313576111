import React, { useCallback, useLayoutEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

import { Button } from "components";
import { useDialog, useCalendar } from "hooks";
import { LeftIconImg, RightIconImg } from "assets";
import { PATH, WEEKS } from "constants/index";
import Date from "../date/Date";
import * as S from "./DatePicker.styled";

const MAX_GRID_CELL = 42;

interface DatePickerProps {
  className?: string;
  cb: any;
  trigger: any;
  isReverse?: boolean;
  isDisabledBeforeToday?: boolean;
  calendarLargerView?: any;
  initValue?: string;
}

const DatePicker = ({
  className,
  cb,
  trigger,
  isDisabledBeforeToday,
  calendarLargerView,
  initValue,
}: DatePickerProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { isDialogOpen, dialogRef, handleToggleDialogOpen, handleDialogClose } =
    useDialog();

  const {
    monthYear,
    selectedDate,
    handleSelectDate,
    changePrevMonthYear,
    changeNextMonthYear,
    changeMonthYear,
  } = useCalendar(
    searchParams.get("date") ? dayjs(searchParams.get("date")) : dayjs(),
  );

  const handleClickDate = (date: string) => () => {
    const newDate = dayjs(date).format("YYYY-MM-DD");
    typeof cb === "function" && cb(newDate);
    handleSelectDate(date);
    changeMonthYear(date);
    handleDialogClose();
  };

  const handleClickLargerView = useCallback(() => {
    navigate(
      `${PATH.reservationSchedule}?date=${monthYear.value.format("YYYY-MM")}`,
      { state: PATH.reservation },
    );
  }, [monthYear]);

  useLayoutEffect(() => {
    if (isDialogOpen) {
      const date = searchParams.get("date")
        ? dayjs(searchParams.get("date"))
        : dayjs();
      handleSelectDate(
        initValue !== undefined
          ? initValue !== ""
            ? dayjs(initValue)
            : ""
          : date,
      );
      changeMonthYear(date);
    }
  }, [isDialogOpen]);

  return (
    <S.Root>
      {typeof trigger === "function" &&
        trigger({ handleToggleDialogOpen, isDialogOpen })}
      {isDialogOpen && (
        <S.DialogRoot
          className={className}
          open={isDialogOpen}
          ref={dialogRef}
          aria-modal="true"
        >
          <S.Header>
            <S.Navigation>
              <button
                type="button"
                aria-label="이전 달 변경"
                onClick={changePrevMonthYear}
              >
                <LeftIconImg css={S.leftIcon} />
              </button>
              <time>{monthYear.month}월</time>
              <button
                type="button"
                aria-label="다음 달 변경"
                onClick={changeNextMonthYear}
              >
                <RightIconImg css={S.rightIcon} />
              </button>
            </S.Navigation>
            <S.Year>{monthYear.year}</S.Year>
          </S.Header>
          <div>
            <S.Weeks>
              {WEEKS.map((day) => {
                return <S.WeekLi key={day}>{day}</S.WeekLi>;
              })}
            </S.Weeks>
            <S.Dates>
              {[...Array(monthYear.firstDOW)].map((_, i) => (
                <Date
                  key={i}
                  date={monthYear.firstWeekPrevMonthDate.add(i, "d")}
                  disabled
                  handleSelectDate={handleClickDate}
                />
              ))}
              {[...Array(monthYear.lastDate)].map((_, i) => (
                <Date
                  key={i}
                  date={monthYear.startDate.add(i, "d")}
                  selectedDate={selectedDate}
                  isDisabledBeforeToday={isDisabledBeforeToday}
                  handleSelectDate={handleClickDate}
                />
              ))}
              {[
                ...Array(
                  MAX_GRID_CELL - (monthYear.firstDOW + monthYear.lastDate),
                ),
              ].map((_, i) => (
                <Date
                  key={i}
                  date={monthYear.nextMonthStartDate.add(i, "d")}
                  disabled
                  handleSelectDate={handleClickDate}
                />
              ))}
            </S.Dates>
          </div>
          {calendarLargerView && (
            <Button css={S.largerViewBtn} onClick={handleClickLargerView}>
              달력 크게 보기
            </Button>
          )}
        </S.DialogRoot>
      )}
    </S.Root>
  );
};

export default DatePicker;
