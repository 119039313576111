import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.li<{ checked: boolean }>`
  ${({ checked, theme }) => css`
    display: flex;
    height: 36px;
    background-color: ${theme.color.white};

    & > input[type="checkbox"] {
      position: absolute;
      width: 1px;
      height: 1px;
      border: 0;
      padding: 0;
      margin: -1px;
      clip: rect(0, 0, 0, 0);
      overflow: hidden;
    }

    & > input[type="checkbox"] + label {
      display: flex;
      align-items: center;
      column-gap: 10px;
      min-width: 100%;
      min-height: 100%;
      padding: 0 12px;
      background-color: ${checked ? theme.color.blue_02 : theme.color.white};
      cursor: pointer;
      user-select: none;

      & > span {
        ${theme.font.regular_14};
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        color: ${theme.color.gray_09};
      }

      :hover {
        background-color: ${theme.color.blue_02};
      }
    }
  `}
`;
