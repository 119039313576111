import React from "react";
import dayjs from "dayjs";

import {
  Textarea,
  DialogButton,
  MechanicDialog,
  AmountDialog,
} from "components";
import { timeFormatter, numericOnlyWithComma } from "utils";
import { INPUT_MAX_LENGTH } from "constants/index";
import * as S from "./CompletedForm.styled";

const CompletedForm = ({ data }: any) => {
  return (
    <S.Root>
      <S.InfoItem>
        <dt>정비 일시</dt>
        <dd>
          <span>{dayjs(data.accepted).format("YYYY.MM.DD (ddd) a hh:mm")}</span>
        </dd>
      </S.InfoItem>
      <S.InfoItem>
        <dt>예상 정비 시간</dt>
        <dd>
          <span>{timeFormatter(data.estimatedTime, "h시간 mm분 이내")}</span>
        </dd>
      </S.InfoItem>
      <S.InfoItem>
        <dt>담당 정비사</dt>
        <dd>
          <S.StaffsBox>
            {data.staffs.map(({ staffId, staffName }: any) => (
              <S.Staff key={staffId}>{staffName}</S.Staff>
            ))}
            <DialogButton
              css={S.updateDialogButton}
              popup={(
                isDialogOpen: any,
                dialogRef: any,
                handleDialogClose: any,
              ) => {
                return (
                  <MechanicDialog
                    css={S.updateStaffDialog}
                    isDialogOpen={isDialogOpen}
                    dialogRef={dialogRef}
                    handleDialogClose={handleDialogClose}
                    reservationId={data.reservationId}
                    mechanics={data.staffs}
                  />
                );
              }}
            >
              수정
            </DialogButton>
          </S.StaffsBox>
        </dd>
      </S.InfoItem>
      {data?.actualTime && (
        <S.InfoItem>
          <dt>실제 정비시간</dt>
          <dd>
            <span>{timeFormatter(data?.actualTime, "h시간 mm분")}</span>
          </dd>
        </S.InfoItem>
      )}
      <S.InfoItem>
        <dt>총 청구 금액</dt>
        <dd>
          <span>{numericOnlyWithComma(data.cost)}원</span>
          <DialogButton
            css={S.updateDialogButton}
            popup={(
              isDialogOpen: any,
              dialogRef: any,
              handleDialogClose: any,
            ) => {
              return (
                <AmountDialog
                  css={S.updateCostDialog}
                  isDialogOpen={isDialogOpen}
                  dialogRef={dialogRef}
                  handleDialogClose={handleDialogClose}
                  reservationId={data.reservationId}
                  cost={data.cost}
                />
              );
            }}
          >
            수정
          </DialogButton>
        </dd>
      </S.InfoItem>
      <S.InfoItem>
        <dt>정비 소견</dt>
        <dd>
          <Textarea
            css={S.textarea}
            value={data.shopComment ?? ""}
            readOnly={true}
            maxLength={INPUT_MAX_LENGTH.SHOP_COMMENT}
          />
        </dd>
      </S.InfoItem>
    </S.Root>
  );
};

export default CompletedForm;
