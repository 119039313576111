import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const detailModal = css`
  min-width: 936px;
`;

export const UtilContainer = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    height: 51px;
    border-bottom: 1px solid ${theme.color.gray_02};
    padding: 0 32px;
    background-color: ${theme.color.white};

    & > span {
      ${theme.font.medium_14};
      letter-spacing: -0.02em;
      color: ${theme.color.gray_09};
    }
  `}
`;

export const ZoomWrapper = styled.div`
  display: flex;
  column-gap: 12px;
`;

export const RightWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 12px;
`;

export const ModalContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border-radius: 4px;
    padding: 21px 34px;
    background-color: ${theme.color.white};
  `}
`;

export const Slider = styled.div`
  ${({ theme }) => css`
    min-width: 756px;
    height: 506px;
    text-align: center;
    background-color: ${theme.color.gray_01};
  `}
`;

export const Img = styled.img<{ isNormalImg: boolean }>`
  ${({ isNormalImg }) => css`
    width: auto;
    height: 100%;
    cursor: ${isNormalImg ? "grab" : "default"};
    object-fit: contain;
  `}
`;

export const downloadButton = (theme: Theme) => css`
  &:hover path:not(:first-of-type) {
    fill: ${theme.color.blue_03}!important;
  }
`;

export const downloadIcon = css`
  margin-right: 6px;
`;

// export const AudioWrapper = styled.div`
//   display: grid;
//   align-items: center;
//   grid-template-columns: auto 1fr auto;
//   column-gap: 28px;
//   height: 66px;
//   padding: 0 34px;
//   border-top: 1px solid ${({ theme }) => theme.color.gray_03};
//   background-color: ${({ theme }) => theme.color.white};
// `;
