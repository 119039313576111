import React from "react";

import { RESERVATION_STATUS_TABLE } from "constants/index";
import * as S from "./ReservStatusList.styled";

interface ReservStatusListProps {
  children?: React.ReactNode;
}

const ReservStatusList = ({ children }: ReservStatusListProps) => {
  return <S.Root>{children}</S.Root>;
};

interface ReservStatusListProps {
  status?: string;
}

ReservStatusList.Item = function ReservStatusItem({
  status,
}: ReservStatusListProps) {
  return (
    <S.Status status={status as string}>
      {
        RESERVATION_STATUS_TABLE[
          status as keyof typeof RESERVATION_STATUS_TABLE
        ]
      }
    </S.Status>
  );
};

export default ReservStatusList;
