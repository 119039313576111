import { useState, useCallback, useEffect, useMemo } from "react";
import dayjs from "dayjs";

import { useToast } from "hooks";
import { useCreateBreaktimes, useGetBreaktimes } from "services";
import { formatKstISODateTime, formatUtcISODateTime } from "utils";
import { NOT_ALLOWED_TIME_LIST } from "assets";
import { TOAST_MSG } from "constants/index";

const useNoReservation = (handleModalClose: () => void) => {
  const [checkedNotAllowedTime, setCheckedNotAllowedTime] = useState<string[]>(
    [],
  );
  const [nonReservedDate, setNonReservedDate] = useState(
    dayjs().format("YYYY-MM-DD"),
  );
  const isAllChecked = useMemo(
    () => checkedNotAllowedTime?.length === NOT_ALLOWED_TIME_LIST.length,
    [checkedNotAllowedTime],
  );
  const req = {
    query: {
      date: formatKstISODateTime(nonReservedDate),
    },
  };

  const { mutate: createBreaktimesMutate } = useCreateBreaktimes();
  const { data: breaktimes, refetch } = useGetBreaktimes(req);
  const { addToast } = useToast();

  const handleSelectDate = useCallback((date: string) => {
    setNonReservedDate(date);
    setCheckedNotAllowedTime([]);
  }, []);

  const handleToggleAllCheck = useCallback(() => {
    const timeList = NOT_ALLOWED_TIME_LIST.map((time) => time.key);
    isAllChecked
      ? setCheckedNotAllowedTime([])
      : setCheckedNotAllowedTime(timeList);
  }, [isAllChecked]);

  const initSelectDate = useCallback(() => {
    const today = dayjs().format("YYYY-MM-DD");

    setNonReservedDate(today);
  }, []);

  const handleCheckedItem = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked;
      const value = e.target.value;

      if (checked) {
        setCheckedNotAllowedTime((prev) => prev.concat(value).sort());
      } else {
        setCheckedNotAllowedTime((prev) =>
          prev.filter((item) => item !== value),
        );
      }
    },
    [breaktimes],
  );

  const initCheckedItem = useCallback(() => {
    setCheckedNotAllowedTime([]);
    setNonReservedDate(dayjs().format("YYYY-MM-DD"));
  }, []);

  const handleSave = useCallback(() => {
    const notAllowdUtcISOTime = checkedNotAllowedTime.map((time: string) =>
      formatUtcISODateTime(`${nonReservedDate} ${time}`, "YYYY-MM-DD HH:mm"),
    );

    const req = {
      body: {
        date: formatKstISODateTime(nonReservedDate),
        breaktimes: notAllowdUtcISOTime,
      },
    };

    createBreaktimesMutate(req, {
      onSuccess: () => {
        handleModalClose();
        addToast(TOAST_MSG.SUCCESS.SET_BREAKETIME_DONE);
      },
    });
  }, [nonReservedDate, checkedNotAllowedTime]);

  useEffect(() => {
    if (breaktimes) {
      setCheckedNotAllowedTime(breaktimes);
    }
  }, [breaktimes]);

  useEffect(() => {
    refetch();
  }, [nonReservedDate]);

  return {
    nonReservedDate,
    checkedNotAllowedTime,
    isAllChecked,
    initSelectDate,
    handleSelectDate,
    handleToggleAllCheck,
    handleCheckedItem,
    initCheckedItem,
    handleSave,
  };
};

export default useNoReservation;
