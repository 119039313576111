import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const DialogRoot = styled.dialog`
  ${({ theme }) => css`
    position: absolute;
    left: 0;
    width: 280px;
    border: 1px solid ${theme.color.gray_03};
    background-color: ${theme.color.white};
    box-shadow: ${theme.boxshadow.shadow2_2_8};
    z-index: 10;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    padding: 24px 24px 10px;

    & > label {
      display: inline-block;
      margin-bottom: 6px;
      color: ${theme.color.gray_07};
    }
  `}
`;

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 6px;
    width: 100%;
    height: 100%;

    & > input {
      width: 100%;
      height: 34px;
      padding: 0 12px;
      border: 1px solid ${theme.color.gray_03};
      border-radius: 3px;
      color: ${theme.color.gray_09};
    }
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    border-top: 1px solid ${theme.color.gray_03};
    padding: 12px 0;
  `}
`;

export const cancelButton = css`
  margin-right: 4px;
`;
