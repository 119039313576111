import { css } from "@emotion/react";

export const font = {
  regular_12: css`
    font-size: 1.2rem;
    font-weight: 400;
    font-family: "Spoqa Han Sans Neo", sans-serif;
    line-height: 1.25;
  `,
  regular_13: css`
    font-size: 1.3rem;
    font-weight: 400;
    font-family: "Spoqa Han Sans Neo", sans-serif;
    line-height: 1.3846;
  `,
  regular_14: css`
    font-size: 1.4rem;
    font-weight: 400;
    font-family: "Spoqa Han Sans Neo", sans-serif;
    line-height: 1.4285;
  `,
  regular_16: css`
    font-size: 1.6rem;
    font-weight: 400;
    font-family: "Spoqa Han Sans Neo", sans-serif;
    line-height: 1.3125;
  `,
  medium_13: css`
    font-size: 1.3rem;
    font-weight: 500;
    font-family: "Spoqa Han Sans Neo", sans-serif;
    line-height: 1.3846;
  `,
  medium_14: css`
    font-size: 1.4rem;
    font-weight: 500;
    font-family: "Spoqa Han Sans Neo", sans-serif;
    line-height: 1.2857;
  `,
  medium_15: css`
    font-size: 1.5rem;
    font-weight: 500;
    font-family: "Spoqa Han Sans Neo", sans-serif;
    line-height: 1.3125;
  `,
  medium_16: css`
    font-size: 1.6rem;
    font-weight: 500;
    font-family: "Spoqa Han Sans Neo", sans-serif;
    line-height: 1.3125;
  `,
  medium_18: css`
    font-size: 1.8rem;
    font-weight: 500;
    font-family: Spoqa Han Sans Neo, sans-serif;
    line-height: 1.2222;
  `,
  bold_13: css`
    font-size: 1.3rem;
    font-weight: 700;
    font-family: "Spoqa Han Sans Neo", sans-serif;
    line-height: 1.38461;
  `,
  bold_14: css`
    font-size: 1.4rem;
    font-weight: 700;
    font-family: "Spoqa Han Sans Neo", sans-serif;
    line-height: 1.2857;
  `,
  bold_16: css`
    font-size: 1.6rem;
    font-weight: 700;
    font-family: "Spoqa Han Sans Neo", sans-serif;
    line-height: 1.3125;
  `,
  bold_18: css`
    font-size: 1.8rem;
    font-weight: 700;
    font-family: "Spoqa Han Sans Neo", sans-serif;
    line-height: 1.2222;
  `,
  bold_24: css`
    font-size: 2.4rem;
    font-weight: 700;
    font-family: "Spoqa Han Sans Neo", sans-serif;
    line-height: 1.25;
  `,
} as const;

export type FontType = typeof font;
