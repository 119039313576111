import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useLogout } from "services";
import { auth } from "utils";
import { NAV_LIST, LogoutIconImg } from "assets";
import CustomLink from "./customlink/CustomLink";
import Profile from "./profile/Profile";
import * as S from "./Navbar.styled";

const Navbar = () => {
  const navigate = useNavigate();

  const { mutate: logoutMutate } = useLogout();

  const handleLogout = useCallback(() => {
    logoutMutate(auth.refreshToken, {
      onSuccess: () => {
        auth.clear();
        navigate("/");
      },
    });
  }, []);

  return (
    <S.Root>
      <S.Container>
        <Profile />
        <S.NavWrapper>
          {NAV_LIST.map((item) => {
            return <CustomLink key={item.navigatePath} item={item} />;
          })}
        </S.NavWrapper>
        <S.LogoutButton onClick={handleLogout}>
          <LogoutIconImg />
        </S.LogoutButton>
      </S.Container>
    </S.Root>
  );
};

export default Navbar;
