import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div<{ size: string }>`
  ${({ theme, size }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    input[type="range"] {
      -webkit-appearance: none;
      width: 100%;
      height: 8px;
      border-radius: 5px;
      background-image: linear-gradient(
        ${theme.color.blue_01},
        ${theme.color.blue_01}
      );
      background-size: ${size} 100%;
      background-repeat: no-repeat;
      background-color: ${theme.color.gray_03};
      cursor: pointer;
    }
    // 동그라미 부분
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 16px;
      width: 16px;
      background-color: ${theme.color.white};
      border-radius: 50%;
      border: 1px solid ${theme.color.blue_01};
    }

    input[type="range"]::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
      outline: none;
    }
  `}
`;
