import { useState, useCallback } from "react";

const useImgScale = () => {
  const [scale, setScale] = useState(1);

  const handleClickZoomOut = useCallback(() => {
    setScale((prev) => Math.max(Number(prev) - 0.1, 0.1));
  }, []);
  const handleClickZoomIn = useCallback(() => {
    setScale((prev) => Math.min(Number(prev) + 0.1, 2.0));
  }, []);

  const handleChangeScale = useCallback((value: number) => {
    if (value < 1) return;
    setScale(value);
  }, []);

  return {
    scale,
    setScale,
    handleClickZoomOut,
    handleClickZoomIn,
    handleChangeScale,
  };
};

export default useImgScale;
