import React, { useCallback, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useImmer } from "use-immer";
import imageCompression from "browser-image-compression";

import { useToast, useCheckRequired } from "hooks";
import { useCreateInquiry } from "services";
import {
  userStorage,
  checkEmailValidation,
  checkInputLength,
  checkPhoneValidation,
  getFileSize,
} from "utils";
import { INQUIRY_TABLE } from "assets";
import { PATH, TOAST_MSG, VALID_MESSAGE } from "constants/index";

const INITIAL_STATE = {
  category: { value: "", error: "" },
  title: { value: "", error: "" },
  content: { value: "", error: "" },
  attach: {
    value: {
      files: [],
    },
    error: "",
  },
  repairShop: { value: "", error: "" },
  name: { value: "", error: "" },
  email: { value: "", error: "" },
  phoneLocal: { value: "010", error: "" },
  phone: { value: "", error: "" },
};

const useAddInquiry = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const inputRefs = useRef<any>({});

  const [form, setForm] = useImmer(INITIAL_STATE);

  const { mutate: createInquiryMutate } = useCreateInquiry();

  const { addToast } = useToast();

  const isSubmitDisabled =
    Object.values(form).filter((data) => data.error !== "").length > 0;

  const [checkRequired] = useCheckRequired(
    inputRefs,
    form,
    setForm,
    VALID_MESSAGE.REQUIRED,
  );

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (checkRequired()) return;

      setForm((draft: any) => {
        for (const key of Object.keys(form)) {
          draft[key].error = "";
        }
      });

      const req = {
        body: {
          attach: form.attach.value.files,
          title: form.title.value.trim(),
          content: form.content.value.trim(),
          name: form.name.value.trim(),
          email: form.email.value.trim(),
          phone: `${form.phoneLocal.value}${form.phone.value}`,
          repairShop: form.repairShop.value,
          category:
            INQUIRY_TABLE[form.category.value as keyof typeof INQUIRY_TABLE],
        },
      };

      createInquiryMutate(req, {
        onSuccess: (res) => {
          addToast(TOAST_MSG.SUCCESS.ADD_INQUIRY_DONE);
          const isCsPath = pathname.split("/")[1].includes("cs");

          navigate(
            `${isCsPath ? PATH.csInquiry : PATH.inquiry}/detail?helpId=${
              res.data
            }`,
          );
        },
      });
    },
    [form],
  );

  const handleImageCompression = (file: File) => {
    const options = {
      maxSizeMB: 5, // NOTE: 최적 사이즈 찾기, maxWidthOrHeight, maxSizeMB에 따라 이미지 사이즈가 달라집니다.
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    return new Promise((resolve, reject) => {
      try {
        imageCompression(file, options).then((compressedFile) => {
          resolve(compressedFile);
        });
      } catch (error) {
        console.log("Compression Error ", error);
        reject(error);
      }
    });
  };

  const handleChangeForm = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
      const name = event?.target?.name ?? key;
      const files = event?.target?.files ?? name;

      const newFileList = [
        ...form["attach"].value.files,
        ...Object.entries(files),
      ];
      let value = event?.target?.value ?? event;
      let error = "";

      switch (name) {
        case "name":
          value = value.replaceAll(" ", "");
          if (!checkInputLength("INQUIRY_WRITER", value)) return;
          break;

        case "title":
          if (!checkInputLength("INQUIRY_TITLE", value)) return;
          break;

        case "content":
          if (!checkInputLength("INQUIRY_CONTENT", value)) return;
          break;

        case "email":
          if (!checkInputLength("EMAIL", value)) return;
          if (form[name].error && !checkEmailValidation(value)) {
            error = VALID_MESSAGE.EMAIL;
          }
          break;

        case "attach":
          if (files.length === 0) return;
          if (newFileList.length > 3) {
            for (let i = newFileList.length; i > 3; i--) {
              newFileList.pop();
            }
            addToast(TOAST_MSG.FAIL.ADD_INQUIRY_IMG_WARNING);
          }

          await Promise.all(
            newFileList.map(async (item) => {
              if (Array.isArray(item)) {
                const file = item[1];
                const { size } = file;

                if (!file) return;
                if (getFileSize("MB", size) > 10) {
                  addToast(TOAST_MSG.FAIL.UPLOAD_FILE_SIZE);
                  return;
                }

                try {
                  const compressedFile = await handleImageCompression(file);
                  setForm((draft: any) => {
                    draft[name].value.files = [
                      ...draft[name].value.files,
                      compressedFile,
                    ];

                    draft[name].error = error;
                  });
                } catch (error) {
                  console.log("Compression Error ", error);
                }
              }
            }),
          );
          break;

        case "phone":
          if (!checkInputLength("PHONE", value)) return;
          value = value.replace(/[^0-9]/g, "");
          if (form[name].error && !checkPhoneValidation(value)) {
            error = VALID_MESSAGE.PHONE_NUM;
          }
          break;
      }

      if (name !== "attach") {
        setForm((draft: any) => {
          draft[name].value = value;
          draft[name].error = error;
        });
      }
    },
    [form],
  );

  const handleBlurForm = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const {
        value,
        name,
        dataset: { required },
      } = event.target;
      let error = "";

      if (
        required &&
        typeof form[name as keyof typeof form].value === "string" &&
        (form[name as keyof typeof form].value as string).length === 0
      ) {
        setForm((draft: any) => {
          draft[name].error = VALID_MESSAGE.REQUIRED;
        });
        return;
      }

      switch (name) {
        case "title":
          if (!value.trim()) {
            error = VALID_MESSAGE.REQUIRED;
          }
          break;
        case "content":
          if (!value.trim()) {
            error = VALID_MESSAGE.REQUIRED;
          }
          break;
        case "email":
          !checkEmailValidation(value) && (error = VALID_MESSAGE.EMAIL);
          break;

        case "phone":
          if (!checkPhoneValidation(value)) {
            error = VALID_MESSAGE.PHONE_NUM;
          }
          break;
      }

      setForm((draft: any) => {
        draft[name].error = error;
      });
    },
    [form],
  );

  const resetForm = useCallback(() => {
    setForm(INITIAL_STATE);
  }, []);

  const handleDeleteImage = (index: number) => {
    setForm((draft) => {
      draft["attach"].value.files = draft["attach"].value.files.filter(
        (_, idx) => idx !== index,
      );
    });
  };

  useEffect(() => {
    const userInfo = userStorage.load();

    setForm((draft) => {
      draft["repairShop"].value = userInfo.name;
    });
  }, []);

  return {
    form,
    inputRefs,
    isSubmitDisabled,
    attachList: form["attach"].value.files,
    handleSubmit,
    handleChangeForm,
    handleBlurForm,
    resetForm,
    handleDeleteImage,
  };
};

export default useAddInquiry;
