import React from "react";
import { useRecoilValue } from "recoil";
import dayjs from "dayjs";

import { getHolidayInfo } from "store";
import * as S from "./Date.styled";

interface DateProps {
  className?: string;
  date: any;
  selectedDate?: any;
  disabled?: boolean;
  isDisabledBeforeToday?: boolean;
  handleSelectDate: (date: string) => () => void;
}

const Date = ({
  className,
  date,
  selectedDate,
  disabled,
  isDisabledBeforeToday,
  handleSelectDate,
}: DateProps) => {
  const holidays = useRecoilValue(getHolidayInfo);
  const isToday = dayjs().isSame(date, "d");
  const isSelectedDate = selectedDate && selectedDate?.isSame(date, "d");
  const isBeforeToday = dayjs().isAfter(date, "d");
  const isHoliday = holidays.includes(date.format("YYYYMMDD"));

  return (
    <S.Root className={className} data-status={isHoliday}>
      <S.DateButton
        type="button"
        aria-current={isToday ? "date" : "false"}
        aria-selected={isSelectedDate}
        disabled={disabled || (isDisabledBeforeToday && isBeforeToday)}
        onClick={handleSelectDate(date)}
      >
        <time>{date.format("D")}</time>
      </S.DateButton>
    </S.Root>
  );
};

export default Date;
