import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Button, HasNoneData } from "components";
import { PATH } from "constants/index";
import * as S from "./NotFound.styled";

export default function NotFound() {
  const navigate = useNavigate();
  const handleRedirectPrevPage = useCallback(() => {
    // if (!navigate(-1)) { // NOTE: void type 인데 if문으로 활용하고 있어 타입 에러가 발생합니다. 해당 코드 지워도 동작 이슈는 없는 거 같은데 정밀 확인 후 삭제가 필요합니다
    // auth.isAuth ? navigate(PATH.root) : navigate(PATH.login);
    // NOTE: 이전 페이지로 갈 경우 어디로 이동할지 기획이 필요합니다.
    navigate(PATH.root);
    // }
  }, []);

  return (
    <section>
      <HasNoneData text="페이지를 찾을 수 없습니다.">
        <S.DescText>
          입력한 주소가 잘못되었거나, 사용이 일시 중단되어 <br />
          요청한 페이지를 찾을 수 없습니다. <br />
          서비스 이용에 불편을 드려 죄송합니다.
        </S.DescText>
        <Button
          css={S.redirectPrevPageButton}
          variant="secondaryMedium"
          onClick={handleRedirectPrevPage}
        >
          이전페이지
        </Button>
      </HasNoneData>
    </section>
  );
}
