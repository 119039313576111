import React from "react";

import { VisuallyHidden } from "components";
import { CancelIconImg, SearchIconImg } from "assets";
import useSearchInput from "./hooks/useSearchInput";
import * as S from "./SearchInput.styled";

interface SearchInputProps {
  className?: string;
  id: string;
  placeholder?: string;
}

const SearchInput = ({
  className,
  id,

  ...restProps
}: SearchInputProps) => {
  const { searchValue, handleChange, handleResetSearch, handleSubmit } =
    useSearchInput();

  const uid = `search-input-${id}`;

  return (
    <S.Root className={className} onSubmit={handleSubmit}>
      <VisuallyHidden as="label" htmlFor={uid}>
        검색
      </VisuallyHidden>
      <input
        type="text"
        id={uid}
        autoComplete="off"
        value={searchValue}
        onChange={handleChange}
        {...restProps}
      />
      {!searchValue && <SearchIconImg css={S.searchIcon} />}
      <S.ResetBtn
        type="button"
        aria-label="검색 초기화"
        onClick={handleResetSearch}
      >
        {searchValue && <CancelIconImg />}
      </S.ResetBtn>
    </S.Root>
  );
};

export default SearchInput;
