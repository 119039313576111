import React from "react";

import ChangePasswordForm from "./form/ChangePasswordForm";
import * as S from "./ChangePassword.styled";

const ChangePassword = () => {
  return (
    <S.Root>
      <S.Wrapper>
        <S.Header>
          <S.Title>비밀번호 변경</S.Title>
        </S.Header>
        <ChangePasswordForm />
      </S.Wrapper>
    </S.Root>
  );
};

export default ChangePassword;
