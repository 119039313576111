import { useState } from "react";

const useSelectValidation = () => {
  const [isSelectError, setIsSelectError] = useState(false);

  const handleValidation = (
    showDropdown: boolean,
    isClicked: boolean,
    selectedOption: any,
  ) => {
    if (!isClicked) return;
    if (showDropdown === false && selectedOption.length === 0) {
      setIsSelectError(true);
    } else {
      setIsSelectError(false);
    }
  };

  return [isSelectError, handleValidation, setIsSelectError];
};

export default useSelectValidation;
