import React from "react";

import {
  MaxLengthInput,
  Textarea,
  ValidMessage,
  DetailModal,
} from "components";
import { useModal } from "hooks";
import { INPUT_MAX_LENGTH } from "constants/index";
import useUpdateFreqCommentForm from "../hooks/useUpdateFreqComment";
import * as S from "./UpdateFrequentlyUseCommentModal.styled";

const UPDATE_COMMENT_LABEL_ID = "update-comment-label";
const UPDATE_COMMENT_CONTENT_ID = "update-comment-content";

interface UpdateFrequentlyUseCommentModalProps {
  data: any;
}

const UpdateFrequentlyUseCommentModal = React.forwardRef(
  (
    { data }: UpdateFrequentlyUseCommentModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { handleModalClose } = useModal();
    const {
      inputRefs,
      form,
      isSubmitDisabled,
      handleSubmit,
      handleChangeForm,
      handleBlurForm,
    } = useUpdateFreqCommentForm(data, handleModalClose);

    return (
      <DetailModal
        ref={ref}
        css={S.detailModal}
        title="자주 쓰는 댓글 수정"
        isPosDisabled={!!isSubmitDisabled}
        cancelBtnName="취소"
        posBtnName="수정"
        posFnType="submit"
        posFn={handleSubmit}
      >
        <S.Content>
          <S.LabelInputWrapper>
            <div>
              <S.Label htmlFor={UPDATE_COMMENT_LABEL_ID}>댓글명</S.Label>
            </div>
            <MaxLengthInput
              css={S.maxLengthInput}
              id={UPDATE_COMMENT_CONTENT_ID}
              ref={(el) => {
                if (inputRefs.current) {
                  inputRefs.current["title"] = el as HTMLInputElement;
                }
              }}
              name="title"
              value={form.title.value}
              maxLength={INPUT_MAX_LENGTH.FREQ_CMNT_LABEL}
              placeholder="댓글명을 입력하세요"
              data-required="true"
              validError={form.title.error}
              onChange={handleChangeForm}
              onBlur={handleBlurForm}
            />
            {form.title.error && (
              <ValidMessage css={S.validText}>{form.title.error}</ValidMessage>
            )}
          </S.LabelInputWrapper>
          <S.LabelInputWrapper>
            <div>
              <S.Label htmlFor={UPDATE_COMMENT_CONTENT_ID}>내용</S.Label>
            </div>
            <Textarea
              css={S.textarea}
              id={UPDATE_COMMENT_CONTENT_ID}
              ref={(el: any) => {
                if (inputRefs.current) {
                  inputRefs.current["content"] = el;
                }
              }}
              name="content"
              value={form.content.value}
              maxLength={INPUT_MAX_LENGTH.FREQ_CMNT_CONTENT}
              data-required="true"
              placeholder="내용을 입력하세요"
              validError={form.content.error}
              onChange={handleChangeForm}
              onBlur={handleBlurForm}
            />
            {form.content.error && (
              <ValidMessage css={S.validText}>
                {form.content.error}
              </ValidMessage>
            )}
          </S.LabelInputWrapper>
        </S.Content>
      </DetailModal>
    );
  },
);

UpdateFrequentlyUseCommentModal.displayName = "UpdateFrequentlyUseCommentModal";

export default UpdateFrequentlyUseCommentModal;
