import { useState } from "react";

interface UseFrequentlyUsedCommentDropDownProps {
  onChange: (option: string) => void;
}

const useFrequentlyUsedCommentDropDown = ({
  onChange,
}: UseFrequentlyUsedCommentDropDownProps) => {
  const [isSelected, setIsSelected] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const handleOnClickDropDown = () => {
    setIsSelected(true);
  };

  const handleSelectOption = (option: any) => {
    setSelectedOption(option);
    onChange(option);
  };

  const hanldeRadioItemClick = (event: any) => {
    if (event.target.id === "radio-default") {
      setIsSelected(false);
    } else {
      setSelectedOption("default");
      setIsSelected(true);
    }
  };

  return {
    isSelected,
    selectedOption,
    handleSelectOption,
    handleOnClickDropDown,
    hanldeRadioItemClick,
  };
};

export default useFrequentlyUsedCommentDropDown;
