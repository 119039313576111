import React, { forwardRef } from "react";

import * as S from "./Textarea.styled";

type ValidErrorType = boolean | string;

interface TextareaProps {
  className?: string;
  id?: string;
  name?: string;
  placeholder?: string;
  value: string;
  maxLength?: number;
  readOnly?: boolean;
  validError?: ValidErrorType;
  onChange?: any;
  onBlur?: any;
}

const Textarea = (
  {
    className,
    value,
    maxLength,
    readOnly,
    validError,
    ...restProps
  }: TextareaProps,
  ref: React.ForwardedRef<HTMLTextAreaElement>,
) => {
  return (
    <S.Root
      className={className}
      readOnly={readOnly}
      validError={validError as boolean}
    >
      <textarea ref={ref} value={value} readOnly={readOnly} {...restProps} />
      {!readOnly && maxLength && (
        <span>
          {value.length}/{maxLength}
        </span>
      )}
    </S.Root>
  );
};

Textarea.displayName = "Textarea";

export default forwardRef(Textarea);
