import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div<{ border?: string }>`
  ${({ border, theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: ${theme.color.white};

    & > input {
      width: 100%;
      height: 34px;
      border: ${border};
      border-radius: 3px;
      padding: 0 59px 0 12px;

      &::placeholder {
        color: ${theme.color.gray_05};
      }
    }

    & > input:focus {
      border: 1px solid ${theme.color.blue_01};
    }

    & > span {
      ${theme.font.regular_14};
      position: absolute;
      right: 12px;
      color: ${theme.color.gray_04};
    }
  `}
`;
