import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  createContext,
} from "react";

import { useOnClickOutside } from "hooks";
import { UpIconImg, CheckIconImg } from "assets";
import * as S from "./Select.styled";

const SelectContext = createContext({});

interface SelectProps {
  id?: string;
  className?: string;
  children: React.ReactNode;
  forwardRef?: any;
  name?: string;
  selectedOption: string;
  selectedDropdown?: boolean;
  labelTable: any;
  error?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  isReverse?: boolean;
  placeholder?: string;
  validError?: any;
  required?: boolean;
  checkSelectIsClicked?: any;
  onSelect: (option: any, name: any) => void;
  onBlur?: any;
  onClickCallback?: any;
}

interface SelectOptionProps {
  id?: string;
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  value: string | number;
  restProps?: any;
  onBlur?: any;
}

const Select = ({
  id,
  className,
  children,
  forwardRef,
  name = "",
  selectedOption,
  selectedDropdown,
  labelTable,
  readOnly,
  disabled,
  isReverse = false,
  placeholder,
  validError,
  required,
  checkSelectIsClicked,
  onSelect,
  onBlur,
  onClickCallback,
}: SelectProps) => {
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const selectContainerRef = useRef<any>(null);
  const dropdownRef = useRef<any>(null);

  const handleClose = () => {
    setIsShowDropdown(false);
  };

  useOnClickOutside(selectContainerRef, handleClose);

  const handleClickDropBox = () => {
    if (typeof onClickCallback === "function" && !isShowDropdown) {
      onClickCallback();
    }
    handleShowDropdown();
  };

  const handleShowDropdown = () => {
    setIsShowDropdown((prev) => !prev);
    setIsSelected(false);
  };

  const handleSelectOption = (option: any) => (event: any) => {
    const name = event.currentTarget?.name;

    onSelect(option, name);
    setIsShowDropdown(false);
    setIsSelected(true);
  };

  const handleKeyTrap = (event: any) => {
    if (!dropdownRef.current) {
      return;
    }
    const focusableNodeList = dropdownRef.current.querySelectorAll(
      "[href], [tabIndex], button:not([disabled]), input:not([disabled]), textarea, select",
    );
    const shiftKey = event.shiftKey;
    const eventTarget = event.target;
    const firstFocusableNdoe = focusableNodeList[0];
    const lastFocusableNode = focusableNodeList[focusableNodeList.length - 1];
    const isFirstFocusableNode = Object.is(eventTarget, firstFocusableNdoe);
    const isLastFocusableNode = Object.is(eventTarget, lastFocusableNode);

    if (
      (shiftKey && isFirstFocusableNode) ||
      (!shiftKey && isLastFocusableNode)
    ) {
      setIsShowDropdown(false);
    }
  };

  useEffect(() => {
    typeof checkSelectIsClicked === "function" &&
      checkSelectIsClicked(isShowDropdown);
  }, [isShowDropdown]);

  useEffect(() => {
    setIsSelected(selectedDropdown!);
  }, [selectedDropdown]);

  useEffect(() => {
    const handleKeyListener = (event: any) => {
      event.keyCode === 9 && handleKeyTrap(event);
      event.keyCode === 27 && handleClose();
    };

    window.addEventListener("keydown", handleKeyListener);
    return () => {
      window.removeEventListener("keydown", handleKeyListener);
    };
  });

  const uuid = `selectbox-${id}`;

  if (!labelTable) return null;

  return (
    <SelectContext.Provider
      value={{ selectedOption, handleSelectOption, name }}
    >
      <S.Root ref={selectContainerRef} className={className}>
        <S.SelectButton
          type="button"
          ref={forwardRef}
          name={name}
          aria-controls={uuid}
          aria-expanded={isShowDropdown}
          data-required={required ? "true" : ""}
          isShowDropdown={isShowDropdown ? 1 : 0}
          isSelected={isSelected}
          validError={validError as string}
          data-readonly={readOnly}
          disabled={disabled}
          onClick={handleClickDropBox}
          onBlur={onBlur}
        >
          {labelTable[selectedOption] || <span>{placeholder}</span>}
          <UpIconImg />
        </S.SelectButton>

        <S.Dropdown
          isShowDropdown={isShowDropdown}
          id={uuid}
          ref={dropdownRef}
          aria-hidden={!isShowDropdown}
          isReverse={isReverse}
          data-length={Object.keys(labelTable).length}
        >
          {children}
        </S.Dropdown>
      </S.Root>
    </SelectContext.Provider>
  );
};

Select.Option = function OptionItem({
  children,
  className,
  value,
  ...restProps
}: SelectOptionProps) {
  const { selectedOption, handleSelectOption, name } = useContext(
    SelectContext,
  ) as any;

  return (
    <S.Option className={className} selected={selectedOption === value}>
      <button
        type="button"
        name={name}
        onClick={
          typeof handleSelectOption === "function"
            ? handleSelectOption(value)
            : null
        }
        {...restProps}
      >
        {children}
        <CheckIconImg />
      </button>
    </S.Option>
  );
};

export default Select;
