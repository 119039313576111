import React, { useContext, createContext } from "react";

import { useInputValidation, useSelectValidation } from "hooks/index";
import * as S from "./HourInputMinuteSelect.styled";

const HourMinContext = createContext({});

interface HourInputMinuteSelectProps {
  className?: string;
  children: React.ReactNode;
  errorContent: string;
  inputValue: string;
}

interface HourItemProps {
  id: string;
}

interface MinuteItemProps {
  CustomSelect: any;
  selectedOption: any;
  labelTable: any;
  handleChangeMinute: any;
  minContent: string;
}

const HourInputMinuteSelect = ({
  children,
  className,
  errorContent,
  inputValue,
}: HourInputMinuteSelectProps) => {
  const [isInputError, handleOnFocus, handleOnBlur] = useInputValidation(
    inputValue?.length === 0,
  );
  const [isSelectError, checkSelectValidation] = useSelectValidation();
  const error = isInputError || isSelectError;
  return (
    <HourMinContext.Provider
      value={{
        isInputError,
        handleOnFocus,
        handleOnBlur,
        isSelectError,
        checkSelectValidation,
      }}
    >
      <S.Root className={className}>
        <S.Wrapper>{children}</S.Wrapper>
        {error && <S.ErrorContent>{errorContent}</S.ErrorContent>}
      </S.Root>
    </HourMinContext.Provider>
  );
};

HourInputMinuteSelect.Hour = function HourItem({
  id,
  ...restProps
}: HourItemProps) {
  const { isInputError, handleOnFocus, handleOnBlur } = useContext(
    HourMinContext,
  ) as any;

  return (
    <S.Content id={id} isError={isInputError}>
      <input
        id={id}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        {...restProps}
      />
      <span>시간</span>
    </S.Content>
  );
};

HourInputMinuteSelect.Minute = function MinuteItem({
  CustomSelect,
  selectedOption,
  labelTable,
  handleChangeMinute,
  minContent,
  ...restProps
}: MinuteItemProps) {
  const { isSelectError, checkSelectValidation } = useContext(
    HourMinContext,
  ) as any;
  const checkSelectIsClicked = (showDropdown: any, isClicked: boolean) => {
    checkSelectValidation(showDropdown, isClicked, selectedOption);
  };
  return (
    <>
      <CustomSelect
        isError={isSelectError}
        selectedOption={selectedOption}
        labelTable={labelTable}
        checkSelectIsClicked={checkSelectIsClicked}
        onSelect={handleChangeMinute}
        {...restProps}
      >
        {Object.entries(labelTable).map(([value, label]) => (
          <CustomSelect.Option key={value} value={value}>
            {label}
          </CustomSelect.Option>
        ))}
      </CustomSelect>
      <span>{minContent}</span>
    </>
  );
};

export default HourInputMinuteSelect;
