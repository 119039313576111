import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { mixinSettingArea } from "style/mixin";

export const Root = styled.article`
  ${({ theme }) => css`
    ${mixinSettingArea(theme)};
    display: grid;
    grid-template-rows: auto 1fr;
  `}
`;
