import { useState, useCallback } from "react";

const useInputValidation = (conditional: any) => {
  const [errorContent, setErrorContent] = useState("");

  const handleOnFocus = useCallback(() => {
    setErrorContent("");
  }, []);

  const handleOnBlur = useCallback(() => {
    for (const [content, condition] of Object.entries(conditional)) {
      if (condition) {
        setErrorContent(content);
        return;
      }
    }
  }, [conditional]);

  return [errorContent, handleOnFocus, handleOnBlur];
};

export default useInputValidation;
