export const IS_SELF_TABLE = {
  y: true,
  n: false,
};

export const STEP_COLOR_TABLE = Object.freeze({
  planned: { color: "blue_01", timeColor: "", bg: "" },
  accepted: {
    color: "blue_01",
    timeColor: "blue_01",
    bg: "bg_step_complete_reservation",
  },
  repairing: {
    color: "blue_01",
    timeColor: "gray_10",
    bg: "bg_step_proceeding_repair",
  },
  completed: {
    color: "blue_01",
    timeColor: "blue_01",
    bg: "bg_step_complete_repair",
  },
  canceled: {
    color: "error_01",
    timeColor: "",
    bg: "",
  },
});
