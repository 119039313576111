import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { calcTableWidth } from "utils";
import { mixin_scrollbar } from "style/mixin";

export const root = (theme: Theme) => css`
  grid-template-columns: ${calcTableWidth([91, 143, 144, 91, 113, 140])};
  th:nth-of-type(1),
  th:nth-of-type(4) {
    border-right: 1px solid ${theme.color.gray_03};
    min-width: 100px;
  }
`;

export const tableBody = (theme: Theme, hasData: boolean) => css`
  ${mixin_scrollbar(theme)};
  height: ${hasData
    ? `calc(
    100vh - 54px - 38px - 22px - 18px - 252px - 35px - 62px - 32px - 60px -
      70px
  )`
    : `calc(
    100vh - 54px - 38px - 22px - 18px - 252px - 35px - 62px - 32px - 68px
  )`};
`;

export const cell = (theme: Theme) => css`
  display: flex;
  justify-content: space-between;

  & > mark[data-status="cancel"] {
    color: ${theme.color.error_01};
  }

  :nth-of-type(1),
  :nth-of-type(4) {
    border-right: 1px solid ${theme.color.gray_03};
    min-width: 100px;
  }
`;

export const yesNoButton = css`
  & > label {
    min-width: 70px;
  }
`;

export const TruncateCell = styled.span`
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
