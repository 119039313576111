import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { isEmpty } from "lodash-es";

import { LoadingSpinner } from "components";
import { useGetRequestedReservations } from "services";
import { isReqResvDialogOpenState } from "store";
import { PATH } from "constants/index";
import type { RequestedReservation } from "types";
import * as S from "./ReservationReqDialog.styled";

interface ReservationReqDialogProps {
  className?: string;
  available: "y" | "n";
  isDialogOpen: boolean;
  dialogRef: any;
  handleDialogOpen: () => void;
  handleDialogClose: () => void;
}

interface ActiveScreenProps {
  requestedReservations?: RequestedReservation[];
  handleDialogClose: () => void;
}

interface InActiveScreenProps {
  handleDialogClose: () => void;
}

const ReservationReqDialog = ({
  className,
  available,
  isDialogOpen,
  dialogRef,
  handleDialogOpen,
  handleDialogClose,
}: ReservationReqDialogProps) => {
  const [isReqResvDialogOpen, setIsReqResvDialogOpen] = useRecoilState(
    isReqResvDialogOpenState,
  );

  const { isSuccess, data: requestedReservations } =
    useGetRequestedReservations();

  useEffect(() => {
    return () => {
      setIsReqResvDialogOpen(false);
    };
  }, [isDialogOpen]);

  useEffect(() => {
    if (isReqResvDialogOpen) {
      handleDialogOpen();
    }
  }, [isReqResvDialogOpen]);

  return (
    <>
      {(isDialogOpen || isReqResvDialogOpen) && (
        <S.DialogRoot
          className={className}
          open={isDialogOpen}
          aria-modal="true"
          tabIndex={-1}
          ref={dialogRef}
        >
          <S.Container>
            <S.Title>
              <h3>예약 요청</h3>
              <span>{requestedReservations?.length}</span>
            </S.Title>
            {!isSuccess ? (
              <LoadingSpinner />
            ) : available === "y" ? (
              <ActiveScreen
                requestedReservations={requestedReservations}
                handleDialogClose={handleDialogClose}
              />
            ) : (
              <InActiveScreen handleDialogClose={handleDialogClose} />
            )}
          </S.Container>
        </S.DialogRoot>
      )}
    </>
  );
};

export default ReservationReqDialog;

const ActiveScreen = ({
  requestedReservations,
  handleDialogClose,
}: ActiveScreenProps) => {
  const navigate = useNavigate();

  const handleClickItem = (item: any) => () => {
    const { reservationId } = item;
    navigate(`/reservation/detail?reservationId=${reservationId}`);
    handleDialogClose();
  };

  return isEmpty(requestedReservations) ? (
    <S.Desc>
      <p>
        아직 요청받은 예약이 없어요!
        <br />
        운영시간이 제대로 설정됐는지 확인해 보세요 :)
      </p>
      <Link to={PATH.repairShop} onClick={handleDialogClose}>
        운영일 및 시간 설정 &gt;
      </Link>
    </S.Desc>
  ) : (
    <S.RequestedReservWrapper>
      {requestedReservations?.map((item) => (
        <S.RequestedReserveItem key={item.reservationId}>
          <S.RequestedReserveBtn type="button" onClick={handleClickItem(item)}>
            <S.BtnTop>
              <time>{item.date}</time>
              <span>{item.name}님</span>
            </S.BtnTop>
            <S.BtnBottom>
              <time>{item.requested}</time>
            </S.BtnBottom>
          </S.RequestedReserveBtn>
        </S.RequestedReserveItem>
      ))}
    </S.RequestedReservWrapper>
  );
};

const InActiveScreen = ({ handleDialogClose }: InActiveScreenProps) => {
  return (
    <S.Desc>
      <p>
        인앱 예약 활성화를 원하시면
        <br />
        트럭닥터 고객센터에 문의해주세요!
      </p>
      <Link to={PATH.csInquiry} onClick={handleDialogClose}>
        고객센터 바로가기 &gt;
      </Link>
    </S.Desc>
  );
};
