import React, { useState, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { CenterLayout, Pagination } from "components";
import { useFilterParams, useSetMetaTags } from "hooks";
import { useGetCustomers } from "services";
import type { GetCustomersQueryModel } from "types";
import CustomerHeader from "./container/header/CustomerHeader";
import CustomerTable from "./container/table/CustomerTable";

export default function Customer() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedSortOption, setSelectedSortOption] = useState("name");

  const { filters } = useFilterParams<GetCustomersQueryModel["query"]>([
    "sort",
    "q",
    "page",
  ]);
  const { data, isSuccess } = useGetCustomers({ query: filters });

  const handleSelectSortOption = useCallback(
    (option: string) => {
      setSelectedSortOption(option);
      setSearchParams({
        ...Object.fromEntries(
          [...searchParams].filter((item) => item[0] !== "page"),
        ),
        sort: option,
      });
    },
    [searchParams],
  );

  useSetMetaTags("고객관리 | 정비소");

  return (
    <CenterLayout>
      <CustomerHeader
        totalCount={data?.pageInfo?.totalData ?? 0}
        selectedSortOption={selectedSortOption}
        handleSelectSortOption={handleSelectSortOption}
      />
      <CustomerTable isDone={isSuccess} customers={data?.customers} />
      {isSuccess && <Pagination pageInfo={data?.pageInfo} />}
    </CenterLayout>
  );
}
