import React, { useRef, useCallback, useEffect } from "react";
import { useImmer } from "use-immer";

import { useCheckRequired } from "hooks";
import {
  checkCellPhoneValidation,
  checkInputLength,
  checkPhoneValidation,
  getPhoneInfo,
} from "utils";
import { FORM_INIT_STATE } from "assets";
import { VALID_MESSAGE } from "constants/index";

const useUpdateRepairshop = (initData: any) => {
  const inputRefs = useRef<any>({});

  const [form, setForm] = useImmer(FORM_INIT_STATE);

  const [checkRequired] = useCheckRequired(
    inputRefs,
    form,
    setForm,
    VALID_MESSAGE.REQUIRED_NO_ASTERISK,
  );

  const handleDeleteImage = useCallback(
    (name: string, idx = 0) =>
      () => {
        setForm((draft: any) => {
          if (draft[name].value.length > 1) {
            draft[name].value = draft[name].value.filter(
              (_: any, i: number) => i !== idx,
            );
          } else {
            draft[name].value[idx].status = "default";
          }
        });
      },
    [form],
  );

  const handleChangeImage = useCallback(
    (name: string, file: File, idx = 0) => {
      const extension = file.type.split("/")[1];
      setForm((draft: any) => {
        draft[name].value[idx] = { file, status: extension };
      });
    },
    [form],
  );

  const handleAddBrand = useCallback(
    (brand: any) => {
      setForm((draft: any) => {
        draft.brands.error = "";
        draft.brands.value = [...new Set([...draft.brands.value, brand])];
      });
    },
    [form],
  );

  const handleDeleteBrand = useCallback(
    (brand: any) => () => {
      setForm((draft) => {
        draft.brands.value = draft.brands.value.filter(
          (item) => item !== brand,
        );
      });
    },
    [form],
  );

  const handleSelectAddr = useCallback((address: any) => {
    setForm((draft) => {
      draft.addr.value = address.addr;
      draft.zipcode.value = address.zipcode;
      draft.districtCode.value = address.districtCode;
      draft.latitude.value = address.lat;
      draft.longitude.value = address.lng;
    });
  }, []);

  const handleChangeForm = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
      const name = event?.target?.name ?? key;
      let value = event?.target?.value ?? event;
      let error = "";

      switch (name) {
        case "phoneLocal": {
          const isOtherTypeSelected =
            (!form.phoneLocal.value && !!value) ||
            (form.phoneLocal.value && !value);

          if (isOtherTypeSelected) {
            setForm((draft) => {
              draft["phone"].value = "";
              draft["phone"].error = "";
            });
          }
          break;
        }

        case "phone":
          if (form.phoneLocal.value) {
            if (!checkInputLength("PHONE", value)) return;
          } else {
            if (!checkInputLength("CELL_PHONE", value)) return;
          }

          value = value.replace(/[^0-9]/g, "");

          if (form[name].error) {
            if (form.phoneLocal.value && !checkPhoneValidation(value)) {
              error = VALID_MESSAGE.PHONE_NUM;
            }
            if (!form.phoneLocal.value && !checkCellPhoneValidation) {
              error = VALID_MESSAGE.TELEPHONE_NUM;
            }
          }

          break;

        case "emailYn": {
          const checked = event.target.checked;
          value = checked ? "y" : "n";
          break;
        }

        case "name":
          if (!checkInputLength("REPAIR_SHOP_NAME", value)) return;
          break;

        case "addrDetail":
          if (!checkInputLength("ADDR_DETAIL", value)) return;
          break;

        case "memo":
          if (!checkInputLength("OPERATE_TIME_MEMO", value)) return;
          break;

        case "intro":
          if (!checkInputLength("INTRODUCTION", value)) return;
          break;
      }

      setForm((draft: any) => {
        draft[name].value = value;
        draft[name].error = error;
      });
    },
    [form],
  );

  const handleBlurForm = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const {
        value,
        name,
        dataset: { required },
      } = event.target;
      let error = "";

      if (required && form[name as keyof typeof form].value.length === 0) {
        setForm((draft: any) => {
          draft[name].error = VALID_MESSAGE.REQUIRED_NO_ASTERISK;
        });
        return;
      }

      switch (name) {
        case "name":
          if (!value.trim()) {
            error = VALID_MESSAGE.REQUIRED_NO_ASTERISK;
          }
          break;
        case "phone":
          if (form.phoneLocal.value) {
            if (!checkPhoneValidation(value)) {
              error = VALID_MESSAGE.PHONE_NUM;
            }
          } else {
            if (!checkCellPhoneValidation(value)) {
              error = VALID_MESSAGE.TELEPHONE_NUM;
            }
          }

          break;
      }

      setForm((draft: any) => {
        draft[name].error = error;
      });
    },
    [form],
  );

  useEffect(() => {
    if (initData) {
      const { areaCode, phone } = getPhoneInfo(initData.phone || "");

      setForm((draft: any) => {
        draft.name.value = initData.name ?? "";
        draft.addr.value = initData.addr ?? "";
        draft.zipcode.value = initData.zipcode ?? "";
        draft.addrDetail.value = initData.addrDetail ?? "";
        draft.thumbnail.value[0].status = initData.thumbnail ?? "";
        draft.thumbnail.value[0].url = initData?.thumbnailUrl ?? "";
        draft.extras.value.length = 0;
        initData?.extra1 &&
          (draft.extras.value[0] = {
            status: initData?.extra1 ?? "",
            url: initData?.extra1Url ?? "",
          });
        initData?.extra2 &&
          draft.extras.value.push({
            status: initData?.extra2 ?? "",
            url: initData?.extra2Url ?? "",
          });
        initData?.extra3 &&
          draft.extras.value.push({
            status: initData?.extra3 ?? "",
            url: initData?.extra3Url ?? "",
          });
        initData?.extra4 &&
          draft.extras.value.push({
            status: initData?.extra4 ?? "",
            url: initData?.extra4Url ?? "",
          });

        draft.email.value = initData.email ?? "";
        draft.intro.value = initData.intro ?? "";
        draft.emailYn.value = initData.emailYn ?? "";
        draft.phoneLocal.value = areaCode ?? "";
        draft.phone.value = phone;
        draft.operationTime.value = initData.operationTime;
        draft.brands.value = initData.brands.reduce(
          (acc: any, cur: any) => [...acc, cur.brandId],
          [],
        );
        draft.memo.value = initData.memo ?? "";
      });
    }
  }, [initData]);

  return {
    inputRefs,
    form,
    checkRequired,
    handleChangeForm,
    handleBlurForm,
    handleSelectAddr,
    handleAddBrand,
    handleDeleteBrand,
    handleDeleteImage,
    handleChangeImage,
  };
};

export default useUpdateRepairshop;
