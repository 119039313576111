import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div``;

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  span {
    margin-left: 1px;
  }
`;

export const Content = styled.label<{ isError: boolean }>`
  ${({ theme, isError }) => css`
    height: 100%;
    input {
      width: 50px;
      height: 100%;
      text-align: center;
      border: 1px solid ${isError ? theme.color.error_01 : theme.color.gray_03};
      border-radius: 3px;
      color: ${isError ? theme.color.error_01 : theme.color.gray_10};
      background-color: ${theme.color.white};
    }
    margin-right: 12px;
  `}
`;

export const ErrorContent = styled.p`
  ${({ theme }) => css`
    margin-top: 6px;
    font-size: 12px;
    height: 15px;
    color: ${theme.color.error_01};
  `}
`;
