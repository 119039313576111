import { useState, useCallback } from "react";

const useCarousel = (TOTAL_SLIDES: number, initSlide = 0) => {
  const [currentSlide, setCurrentSlide] = useState(initSlide);

  const handleNextSlide = useCallback(() => {
    if (currentSlide >= TOTAL_SLIDES) {
      return;
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  }, [currentSlide]);

  const handlePrevSlide = useCallback(() => {
    if (currentSlide === 0) {
      return;
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  }, [currentSlide]);

  return {
    currentSlide,
    handleNextSlide,
    handlePrevSlide,
  };
};

export default useCarousel;
