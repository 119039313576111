import {
  HOMEPAGE_URL,
  PERSONAL_INFORMATION_POLICY_URL,
  TERMS_OF_USE_URL,
} from "constants/index";

export const LINKS = [
  {
    name: "회사소개",
    path: HOMEPAGE_URL,
  },
  {
    name: "서비스 이용약관",
    path: TERMS_OF_USE_URL,
  },
  {
    name: "개인정보 처리방침",
    path: PERSONAL_INFORMATION_POLICY_URL,
  },
];
