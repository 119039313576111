import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { calcTableBodyMaxHeight } from "utils";

export const root = (theme: Theme) => css`
  grid-template-columns: 130px auto;
  padding: 0 20px;

  & > th:first-of-type,
  td:first-of-type {
    border-right: 1px solid ${theme.color.gray_03};
  }
`;

export const tableBody = css`
  min-height: 291.5px;
  max-height: calc(
    ${calcTableBodyMaxHeight([
      "22px",
      "16px",
      "72px",
      "8px",
      "28px",
      "24px",
      "4px",
      "10px",
    ])}
  );
`;

export const faQLink = (theme: Theme) => css`
  ${theme.button.secondarySmall}
  height: 36px;
  padding: 0px 14px;
`;

export const FaQTBodyNoneData = styled.tbody`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  & > tr > td > svg {
    width: 54px;
    height: 54px;
  }
`;

export const faQNoHistory = (theme: Theme) => css`
  position: static;
  transform: none;

  & > span {
    ${theme.font.medium_16};
    text-align: center;
    line-height: 26px;
    margin-bottom: 16px;
    color: ${theme.color.gray_05};
  }
`;
