import React from "react";

import { MobileInput, Select, Input, DetailModal } from "components";
import { useGetBrands } from "services";
import { ADD_CUSTOMER_FORM } from "assets";
import useEditCustomer from "../../../hooks/useEditCustomer";
import * as S from "./CustomerEditModal.styled";

interface CustomerEditModalProps {
  data: any;
  handleModalClose: () => void;
}

const CustomerEditModal = React.forwardRef(
  (
    { data, handleModalClose }: CustomerEditModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { data: brands } = useGetBrands();
    const {
      inputRefs,
      form,
      isSubmitDisabled,
      handleSubmit,
      handleChangeForm,
      handleBlurForm,
    } = useEditCustomer(data, handleModalClose);

    return (
      <DetailModal
        ref={ref}
        title="고객 정보 수정"
        isPosDisabled={!!isSubmitDisabled}
        cancelBtnName="취소"
        posBtnName="수정"
        posFnType="submit"
        posFn={handleSubmit}
      >
        <S.CustomerContainer>
          {ADD_CUSTOMER_FORM.map((item) => (
            <S.InputWrapper key={item.label} isRequired={item.isRequired}>
              <dt>
                <label htmlFor={`edit-customer-${item.key}`}>
                  {item.label}
                </label>
              </dt>
              <dd>
                {item.type === "input" ? (
                  <Input
                    css={S.input}
                    id={`edit-customer-${item.key}`}
                    ref={(el) => (inputRefs.current[item.key] = el)}
                    data-required={item.isRequired ? "true" : ""}
                    name={item.key}
                    value={form[item.key as keyof typeof form].value}
                    placeholder={item.placeholder}
                    validError={form[item.key as keyof typeof form].error}
                    onChange={handleChangeForm}
                    onBlur={handleBlurForm}
                  />
                ) : item.type === "phone" ? (
                  <MobileInput
                    ref={inputRefs}
                    isRequired={item.isRequired}
                    data={form}
                    phoneKey={item.key}
                    phoneLocalKey={`${item.key}Local`}
                    placeholder={item.placeholder}
                    readOnly={item.isReadOnly}
                    handleChange={handleChangeForm}
                    handleBlur={handleBlurForm}
                  />
                ) : (
                  <Select
                    css={S.select}
                    id={`edit-customer-${item.key}`}
                    forwardRef={(el: any) =>
                      (inputRefs.current[item.key as keyof typeof form] = el)
                    }
                    name={item.key}
                    selectedOption={form[item.key as keyof typeof form].value}
                    labelTable={brands}
                    required={item.isRequired}
                    placeholder={item.placeholder}
                    validError={form[item.key as keyof typeof form].error}
                    onSelect={handleChangeForm}
                    onBlur={handleBlurForm}
                  >
                    {Object.entries(brands ?? {}).map(([value, label]) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </dd>
              {form[item.key as keyof typeof form].error && (
                <S.ValidText>
                  {form[item.key as keyof typeof form].error}
                </S.ValidText>
              )}
            </S.InputWrapper>
          ))}
        </S.CustomerContainer>
      </DetailModal>
    );
  },
);

CustomerEditModal.displayName = "CustomerEditModal";

export default CustomerEditModal;
