import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Header = styled.div`
  ${({ theme }) => css`
    position: sticky;
    top: ${theme.size.HEADER_HEIGHT};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 38px 0 16px;
    background-color: ${theme.color.background};
    z-index: 11;

    & > h3 {
      ${theme.font.bold_18};
      color: ${theme.color.gray_09};
    }
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  justify-content: space-between;
`;

export const LeftContainer = styled.div`
  width: 100%;
`;

export const RightContainer = styled.div`
  position: sticky; // NOTE: 다른 페이지와 동일하게 하기 위해 속성 추가
  top: 128px;
  min-width: 434px;
  width: 434px;
  height: max-content;
`;
