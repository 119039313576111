import React, { useState, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Pagination } from "components";
import { useFilterParams } from "hooks";
import { useGetInquiries } from "services";
import { INQUIRY_FILTER } from "assets";
import type { GetInquiryQueryModel } from "types";
import SettingInquiryTable from "./inquiryTable/SettingInquiryTable";
import SettingInquiryHeader from "./inquiryHeader/SettingInquiryHeader";
import * as S from "./SettingInquiry.styled";

const SettingInquiry = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedSortOption, setSelectedSortOption] = useState("all");

  const category = searchParams.get("category");

  const { filters } = useFilterParams<GetInquiryQueryModel["query"]>(
    category !== "전체" ? ["category", "page"] : ["page"],
    { page: "1", pageSize: "20" },
  );

  const { data, isSuccess } = useGetInquiries({
    query: filters,
  });

  const handleSelectSortOption = useCallback(
    (option: string) => {
      setSelectedSortOption(option);
      setSearchParams({
        ...Object.fromEntries(
          [...searchParams].filter((item) => item[0] !== "page"),
        ),
        category: INQUIRY_FILTER[option as keyof typeof INQUIRY_FILTER],
      });
    },
    [searchParams, setSearchParams, setSelectedSortOption],
  );

  useEffect(() => {
    const categoryInUrl = searchParams.get("category");

    Object.entries(INQUIRY_FILTER).forEach(
      ([key, value]) => categoryInUrl === value && setSelectedSortOption(key),
    );
  }, [searchParams]);

  return (
    <S.Root>
      <SettingInquiryHeader
        INQUIRY_FILTER={INQUIRY_FILTER}
        selectedSortOption={selectedSortOption}
        handleSelectSortOption={handleSelectSortOption}
      />
      <SettingInquiryTable isDone={isSuccess} inquiries={data?.helps ?? []} />
      {isSuccess && <Pagination pageInfo={data?.pageInfo} />}
    </S.Root>
  );
};

export default SettingInquiry;
