import React, { useCallback } from "react";
import { Link, useLocation } from "react-router-dom";

import { Table } from "components";
import * as S from "./SettingInquiryTableRow.styled";

interface SettingInquiryTableRowProps {
  rowData: any;
  columnList: { key: string; label: string }[];
}

const SettingInquiryTableRow = ({
  rowData,
  columnList,
}: SettingInquiryTableRowProps) => {
  const location = useLocation();

  const makeHighlightWord = useCallback((value: string) => {
    if (value === "status") {
      return (
        <S.Status data-status={!!rowData[value]}>
          <span>{rowData[value] ? "답변완료" : "미답변"}</span>
        </S.Status>
      );
    } else {
      return <span>{rowData[value]}</span>;
    }
  }, []);

  return (
    <>
      <Table.Row id={rowData.helpId}>
        {columnList.map((columnData, i) => (
          <Table.Cell css={S.cell} key={columnData.key}>
            {i === 0 && (
              <Link
                css={S.detailLink}
                to={`${location.pathname}/detail?helpId=${rowData.helpId}`}
              />
            )}
            {makeHighlightWord(columnData["key"])}
          </Table.Cell>
        ))}
      </Table.Row>
    </>
  );
};

export default SettingInquiryTableRow;
