import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { mixinSettingArea } from "style/mixin";

export const Root = styled.article`
  ${({ theme }) => css`
    ${mixinSettingArea(theme)};
    min-height: 536px;
  `}
`;

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 24px;
`;

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${theme.color.gray_02};
    padding-bottom: 8px;
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    ${theme.font.medium_18};
    color: ${theme.color.gray_09};
  `}
`;
