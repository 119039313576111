import { useState, useCallback, useRef, useEffect } from "react";

import { useOnClickOutside } from "hooks/index";

const useDialog = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogOpener, setDialogOpener] = useState<any>(null);
  const dialogRef = useRef<any>(null);

  const handleDialogOpen = useCallback((event: any) => {
    event?.stopPropagation();
    setDialogOpener(document.activeElement as any);
    setIsDialogOpen(true);
    setTimeout(() => dialogRef.current?.focus());
  }, []);

  const handleDialogClose = useCallback(() => {
    setIsDialogOpen(false);
    dialogOpener && dialogOpener.focus();
  }, [dialogOpener]);

  const handleToggleDialogOpen = useCallback(
    (event: any) => {
      isDialogOpen ? handleDialogClose() : handleDialogOpen(event);
      dialogOpener && dialogOpener.focus();
    },

    [handleDialogOpen, handleDialogClose, isDialogOpen],
  );

  useOnClickOutside(dialogRef, handleDialogClose, dialogOpener);

  useEffect(() => {
    const keyListenerMap = new Map([[27, handleDialogClose]]);

    const handleKeyListener = (event: any) => {
      const listener = keyListenerMap.get(event.keyCode);
      typeof listener === "function" && listener();
    };

    window.addEventListener("keydown", handleKeyListener);

    return () => {
      window.removeEventListener("keydown", handleKeyListener);
    };
  }, []);

  return {
    isDialogOpen,
    dialogRef,
    handleDialogOpen,
    handleDialogClose,
    handleToggleDialogOpen,
    setIsDialogOpen,
  };
};

export default useDialog;
