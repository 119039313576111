import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { useRecoilState } from "recoil";
import { isEmpty } from "lodash-es";

import { VisuallyHidden, LoadingSpinner } from "components";
import { detailInfoRowOpenState } from "store";
import HasNoneData from "../hasNoneData/HasNoneData";
import * as S from "./Table.styled";

const TableContext = createContext({});

const TABLE_ROW_ID_PREFIX = "table-row";
const TABLE_CLICK_ROW_ID_PREFIX = "click-table-row";
const TABLE_DETAIL_ROW_ID_PREFIX = "table-detail-row";

interface TableProps {
  className?: string;
  children: React.ReactNode;
  title?: string;
  hasMoveBtn?: boolean;
  "data-status"?: string;
}

interface TableBodyProps {
  className?: string;
  children?: React.ReactNode;
  isDone?: boolean;
  data?: any;
  text: string;
  PropsTbodyHasNoneData?: any;
}

interface TbodyHasNoneDataProps {
  className?: string;
  text: string;
}

interface TableRowProps {
  children: React.ReactNode;
  id: string;
}

interface TableClickRowProps extends TableRowProps {
  handleClick: any;
}

interface TableDetailRowProps extends TableRowProps {
  className?: string;
}

interface TableDataProps {
  className?: string;
  children: React.ReactNode;
}

const Table = ({
  className,
  children,
  title,
  hasMoveBtn = false,
  "data-status": dataStatus,
}: TableProps) => {
  const [detailInfoRowClicked, setDetailInfoRowClicked] = useRecoilState(
    detailInfoRowOpenState,
  );

  return (
    <S.Root>
      <TableContext.Provider
        value={{
          className,
          dataStatus,
          hasMoveBtn,
          detailInfoRowClicked,
          setDetailInfoRowClicked,
        }}
      >
        <VisuallyHidden as="caption">{title}</VisuallyHidden>
        {children}
      </TableContext.Provider>
    </S.Root>
  );
};

Table.Head = function TableHeader({
  columnList = [],
}: {
  columnList: readonly { label: string; key: string }[];
}) {
  const { className, dataStatus } = useContext(TableContext) as any;
  return (
    <S.Thead>
      <S.Trow className={className} data-status={dataStatus}>
        {columnList.map((column: any) => (
          <S.Th key={column.key} scope="col">
            {column.label}
          </S.Th>
        ))}
      </S.Trow>
    </S.Thead>
  );
};

Table.Body = function TableBody({
  children,
  className,
  isDone,
  data,
  text,
  PropsTbodyHasNoneData,
}: TableBodyProps) {
  if (!isDone) {
    return <TbodyLoading />;
  }

  if (isEmpty(data)) {
    if (PropsTbodyHasNoneData) {
      return <PropsTbodyHasNoneData />;
    } else {
      return <TbodyHasNoneData className={className} text={text} />;
    }
  }

  return <S.Tbody className={className}>{children}</S.Tbody>;
};

const TbodyLoading = () => {
  return (
    <S.Tbody>
      <tr>
        <td>
          <LoadingSpinner />
        </td>
      </tr>
    </S.Tbody>
  );
};

const TbodyHasNoneData = ({ className, text }: TbodyHasNoneDataProps) => {
  return (
    <S.Tbody className={className}>
      <tr>
        <HasNoneData asProp="td" text={text} />
      </tr>
    </S.Tbody>
  );
};

Table.Row = function TableRow({ children, id }: TableRowProps) {
  const rowId = `${TABLE_ROW_ID_PREFIX}-${id}`;
  const { className, dataStatus } = useContext(TableContext) as any;

  return (
    <S.Trow id={rowId} className={className} data-status={dataStatus}>
      {children}
    </S.Trow>
  );
};

Table.ClickRow = function ClickRow({
  handleClick,
  children,
  id,
}: TableClickRowProps) {
  const rowId = `${TABLE_CLICK_ROW_ID_PREFIX}-${id}`;
  const detailId = `${TABLE_DETAIL_ROW_ID_PREFIX}-${id}`;
  const [domReady, setDomReady] = useState(false);
  const {
    className,
    dataStatus,
    hasMoveBtn,
    detailInfoRowClicked,
    setDetailInfoRowClicked,
  } = useContext(TableContext) as any;

  const handleOpenRowDetailInfo = (id: string) => () => {
    if (detailInfoRowClicked === id) {
      setDetailInfoRowClicked("");
    } else {
      handleClick(id);
      setDetailInfoRowClicked(id);
    }
  };

  useEffect(() => {
    setDomReady(true);

    return () => {
      setDetailInfoRowClicked("");
    };
  }, []);

  return (
    <>
      <S.Trow click id={rowId} className={className} data-status={dataStatus}>
        {children}
      </S.Trow>

      {domReady &&
        !hasMoveBtn &&
        createPortal(
          <>
            <S.DetailOpenButton
              type="button"
              aria-controls={detailId}
              aria-expanded={detailInfoRowClicked === id}
              onClick={handleOpenRowDetailInfo(id)}
            >
              <VisuallyHidden>디테일 정보 열기</VisuallyHidden>
            </S.DetailOpenButton>
          </>,
          (document.querySelector as any)(`#${rowId} > td:first-child`),
        )}
    </>
  );
};

Table.DetailRow = function TableDetailRow({
  children,
  id,
  className,
}: TableDetailRowProps) {
  const detailId = `${TABLE_DETAIL_ROW_ID_PREFIX}-${id}`;
  const { detailInfoRowClicked } = useContext(TableContext) as any;
  const rowRef = useRef<any>(null);

  useEffect(() => {
    if (id === detailInfoRowClicked) {
      setTimeout(() => rowRef.current.focus());
    }
  }, [id === detailInfoRowClicked]);

  return (
    <>
      {id === detailInfoRowClicked && (
        <S.DetailRow
          id={detailId}
          className={className}
          ref={rowRef}
          tabIndex={0}
        >
          <S.DetailInfoWrapper>{children}</S.DetailInfoWrapper>
        </S.DetailRow>
      )}
    </>
  );
};

Table.Cell = function TableData({ children, className }: TableDataProps) {
  return <S.Td className={className}>{children}</S.Td>;
};

export default Table;
