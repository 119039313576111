import React from "react";

import { ValidMessage, Button } from "components";
import { CloseIconImg } from "assets";
import { CUSTOMER_CENTER_NUMBER } from "constants/index";
import useFindPassword from "../hooks/useFindPassword";
import * as S from "./FindPasswordDialog.styled";

const FIND_PW_DIALOG_EMAIL_ID = "find-password-dialog-email";
const VALID_ERORR_ID = "error-msg-find-password";

interface FindPasswordDialogProps {
  className?: string;
  handleFindPasswordDialog: () => void;
}

const FindPasswordDialog = ({
  className,
  handleFindPasswordDialog,
}: FindPasswordDialogProps) => {
  const { inputRef, email, handleChangeEmail, handleBlurEmail, handleSubmit } =
    useFindPassword();

  return (
    <S.FindPasswordDialog
      className={className}
      open={true}
      aria-modal="true"
      tabIndex={-1}
    >
      <S.Container>
        <S.CloseButton
          type="button"
          aria-label="close"
          onClick={handleFindPasswordDialog}
        >
          <CloseIconImg />
        </S.CloseButton>
        <S.Heading>
          <h3>비밀번호 찾기</h3>
          <p>
            로그인 시 입력하는 ID(이메일)를 입력하시면
            <br /> 해당 이메일로 비밀번호 재설정 링크를 보내드립니다.
          </p>
        </S.Heading>
        <S.Form onSubmit={handleSubmit}>
          <S.EmailInputWrapper>
            <label htmlFor={FIND_PW_DIALOG_EMAIL_ID}>ID(이메일) 입력</label>
            <S.EmailInput
              id={FIND_PW_DIALOG_EMAIL_ID}
              ref={inputRef}
              value={email.value}
              autoComplete="off"
              placeholder="이메일 주소를 입력하세요."
              aria-invalid="true"
              aria-errormessage={VALID_ERORR_ID}
              validError={!!email.error}
              onChange={handleChangeEmail}
              onBlur={handleBlurEmail}
            />
            {email.error && (
              <ValidMessage css={S.validMessage} id={VALID_ERORR_ID}>
                {email.error}
              </ValidMessage>
            )}
          </S.EmailInputWrapper>
          <Button
            css={S.authLinkButton}
            type="submit"
            variant="primaryLarge"
            disabled={!!email.error}
          >
            인증링크 전송
          </Button>
          <S.GuideWord>
            ID를 잊으셨나요? <br /> 트럭닥터 고객센터(
            {CUSTOMER_CENTER_NUMBER})로 연락해주시기 바랍니다.
          </S.GuideWord>
        </S.Form>
      </S.Container>
    </S.FindPasswordDialog>
  );
};

export default FindPasswordDialog;
