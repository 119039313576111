import React from "react";

import { MobileInput, Input, Radio, DetailModal } from "components";
import { STAFF_TABLE_DATA } from "assets";
import type { StaffClientItem } from "types";
import useEditStaff from "../../hooks/useEditStaff";
import * as S from "./StaffEditModal.styled";

interface StaffEditModalProps {
  data: StaffClientItem;
  handleModalClose: () => void;
}

const StaffEditModal = React.forwardRef(
  (
    { data, handleModalClose }: StaffEditModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const {
      inputRefs,
      form,
      isSubmitDisabled,
      handleSubmit,
      handleChangeForm,
      handleBlurForm,
    } = useEditStaff(data, handleModalClose);

    return (
      <DetailModal
        ref={ref}
        title="직원 정보 수정"
        isPosDisabled={!!isSubmitDisabled}
        cancelBtnName="취소"
        posBtnName="수정"
        posFnType="submit"
        posFn={handleSubmit}
      >
        <S.Container>
          {STAFF_TABLE_DATA.detailInfoList.map((item) => (
            <S.InputWrapper key={item.key}>
              {item.type === "input" ? (
                <S.Label
                  as="label"
                  htmlFor={`${item.type}-${item.key}`}
                  isRequired={item.isRequired}
                >
                  {item.label}
                </S.Label>
              ) : (
                <S.Label isRequired={item.isRequired}>{item.label}</S.Label>
              )}
              {item.type === "input" ? (
                <Input
                  css={S.input}
                  id={`${item.type}-${item.key}`}
                  ref={(el) => (inputRefs.current[item.key] = el)}
                  name={item.key}
                  value={form[item.key as keyof typeof form].value}
                  data-required={item.isRequired ? "true" : ""}
                  placeholder={item.placeholder}
                  validError={form[item.key as keyof typeof form].error}
                  onChange={handleChangeForm}
                  onBlur={handleBlurForm}
                />
              ) : item.type === "phone" ? (
                <MobileInput
                  ref={inputRefs}
                  isRequired={item.isRequired}
                  type="mobile"
                  data={form}
                  phoneKey={item.key}
                  phoneLocalKey={`${item.key}Local`}
                  placeholder={item.placeholder ?? ""}
                  readOnly={item.isReadOnly}
                  handleChange={handleChangeForm}
                  handleBlur={handleBlurForm}
                />
              ) : (
                <S.RadioWrapper>
                  {"radioItems" in item &&
                    item.radioItems.map((radio, idx) => (
                      <Radio
                        css={S.radioItem}
                        key={radio.label}
                        id={`${item.key}-${idx}`}
                        name={item.key}
                        value={radio.value}
                        checked={
                          radio.value ===
                          form[item.key as keyof typeof form].value
                        }
                        onChange={handleChangeForm}
                      >
                        {radio.label}
                      </Radio>
                    ))}
                </S.RadioWrapper>
              )}
              {form[item.key as keyof typeof form]?.error && (
                <S.ValidText>
                  {form[item.key as keyof typeof form].error}
                </S.ValidText>
              )}
            </S.InputWrapper>
          ))}
        </S.Container>
      </DetailModal>
    );
  },
);

StaffEditModal.displayName = "StaffEditModal";

export default StaffEditModal;
