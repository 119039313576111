import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const DescText = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    margin: 16px 0 20px;
    color: ${theme.color.gray_06};
    text-align: center;
  `}
`;

export const redirectPrevPageButton = css`
  width: 100px;
`;
