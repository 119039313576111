import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

export const Root = styled.header`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-bottom: 8px;
`;

export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    ${theme.font.bold_18};
    color: ${theme.color.gray_09};
    column-gap: 5px;
  `}
`;

export const SearchFilterWrapper = styled.div`
  display: flex;
`;

export const sortSelect = css`
  width: 130px;
  margin-right: 4px;

  & > button {
    height: 36px;
  }

  & > ul {
    max-height: calc(36px * 4 + 5px);
  }
`;

export const searchInput = css`
  width: 332px;
  margin-right: 8px;
`;

export const plusIcon = (theme: Theme) => css`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  fill: ${theme.color.white};
`;
