import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
`;

export const DateBtn = styled.button`
  ${({ theme }) => css`
    ${theme.button.secondarySmall};
    width: 40px;
    height: 30px;
    margin-right: 4px;
    padding: 0;

    & > svg {
      width: 20px;
      height: 20px;
    }
  `}
`;

export const DateText = styled.time`
  ${({ theme }) => css`
    ${theme.font.bold_18};
    display: flex;
    align-items: center;
    margin-right: 24px;
    color: ${theme.color.gray_09};
  `}
`;

export const todayBtn = (theme: Theme, isToday: boolean) => css`
  height: 30px;
  margin-right: 4px;

  &:not(:disabled):hover {
    background-color: ${isToday && theme.color.blue_01};
  }
`;
