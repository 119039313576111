import { useState, useCallback, useEffect, useRef } from "react";
import { isEmpty } from "lodash-es";

import { useGetCustomerByRegNum } from "services";

const useDropdownInput = (
  value: string,
  searchValue: string,
  handleSelect: any,
  handleSearch: any,
) => {
  const listBoxRef = useRef<any>(null);
  const listItemRef = useRef<any>([]);
  const isSelected = useRef<boolean>(false);

  const { data: customerDatas } = useGetCustomerByRegNum();
  const filteredCustomerDatas = value
    ? customerDatas?.filter((truck) => truck.regNum.includes(value)) ?? []
    : [];
  filteredCustomerDatas?.sort(
    (a, b) =>
      b.regNum.split(value).length - a.regNum.split(value).length ||
      a.regNum.length - b.regNum.length,
  );

  const [isUnfold, setIsUnfold] = useState(false);
  const [selectIdx, setSelectIdx] = useState(-1);

  const handleFocus = () => {
    setIsUnfold(true);
  };

  const closeDropdown = () => {
    setIsUnfold(false);
    setSelectIdx(-1);
  };

  const handleEnter = (i: number) => (e: any) => {
    e.target.blur();

    const idx = i === -1 ? 0 : i;
    !isEmpty(filteredCustomerDatas) &&
      handleSelect(filteredCustomerDatas[idx].regNum);
    closeDropdown();
    handleSearch(e);
  };

  const handleBlur = (e: any) => {
    e?.target?.blur();
    closeDropdown();
  };

  const handleSelectOption = (regNum: string) => () => {
    isSelected.current = true;
    handleSelect(regNum);
    setIsUnfold(false);
  };

  const handleScrollItem = (i: number) => {
    const dropdownBoxPosBottom =
      listBoxRef.current.getBoundingClientRect().bottom;
    const dropdownBoxPosTop = listBoxRef.current.getBoundingClientRect().top;
    const dropdownItemPosTop =
      listItemRef.current[i].getBoundingClientRect().top;

    if (
      dropdownBoxPosBottom < dropdownItemPosTop ||
      dropdownBoxPosTop > dropdownItemPosTop
    ) {
      listItemRef.current[i].scrollIntoView();
    }
  };

  const closureArrowKeyFunc = () => {
    return (e: any) => {
      e.preventDefault();
      if (isEmpty(filteredCustomerDatas)) return;

      const keyCode = e.keyCode,
        firstValue = filteredCustomerDatas[0],
        lastValue = filteredCustomerDatas.at(-1);

      isSelected.current = true;

      switch (keyCode) {
        case 38:
          {
            if (selectIdx <= 0) {
              setSelectIdx(filteredCustomerDatas.length - 1);
              handleSelect(lastValue?.regNum, true);
              handleScrollItem(filteredCustomerDatas.length - 1);
              return;
            }
            setSelectIdx((prev) => prev - 1);
            handleSelect(filteredCustomerDatas[selectIdx - 1].regNum, true);
            handleScrollItem(selectIdx - 1);
          }
          break;

        case 40: {
          if (selectIdx === filteredCustomerDatas.length - 1) {
            setSelectIdx(0);
            handleSelect(firstValue.regNum, true);
            handleScrollItem(0);
            return;
          }

          setSelectIdx((prev) => prev + 1);
          handleSelect(filteredCustomerDatas[selectIdx + 1].regNum, true);
          handleScrollItem(selectIdx + 1);
          break;
        }
      }
    };
  };

  const keyListenerMap = new Map([
    [38, closureArrowKeyFunc()],
    [40, closureArrowKeyFunc()],
    [13, handleEnter(selectIdx)],
  ]);

  const handleKeyDown = useCallback(
    (e: any) => {
      const handleKeyListener = (e: any) => {
        const listener = keyListenerMap.get(e.keyCode);
        typeof listener === "function" && listener(e);
      };

      handleKeyListener(e);
    },
    [filteredCustomerDatas, selectIdx, value, searchValue],
  );

  useEffect(() => {
    if (!isEmpty(filteredCustomerDatas) && selectIdx === -1) {
      handleSelect(filteredCustomerDatas[0].regNum, true);
    }
  }, [filteredCustomerDatas]);

  return {
    listBoxRef,
    listItemRef,
    isUnfold,
    selectIdx,
    dropdownDatas: filteredCustomerDatas,
    handleFocus,
    handleBlur,
    handleSelectOption,
    handleKeyDown,
  };
};

export default useDropdownInput;
