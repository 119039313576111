import React from "react";

import { PreviewModal } from "components";
import { useModal } from "hooks";
import { formatKstYYYYMMDD } from "utils";
import useLoadInquiryDetail from "../container/hooks/useLoadInquiryDetail";
import * as S from "./SettingInquiryDetail.styled";

const SettingInquiryDetail = () => {
  const { modalRef, handleModalOpen, handleModalClose } = useModal();
  const { repairshopName, inquiryDetailList, previewAttachedFiles } =
    useLoadInquiryDetail();

  return (
    <>
      <S.InquiryWrapper>
        <S.Header>
          <h3>1:1 문의 상세</h3>
          <time>작성일시 {formatKstYYYYMMDD(inquiryDetailList?.created)}</time>
        </S.Header>
        <S.Container>
          <S.ContentWrapper>
            <dt>문의유형</dt>
            <dd>{inquiryDetailList?.category}</dd>
          </S.ContentWrapper>
          <S.ContentWrapper>
            <dt>제목</dt>
            <dd>{inquiryDetailList?.title}</dd>
          </S.ContentWrapper>
          <S.ContentWrapper>
            <dt>문의내용</dt>
            <dd>{inquiryDetailList?.content}</dd>
          </S.ContentWrapper>
          {previewAttachedFiles?.length !== 0 && (
            <S.ContentWrapper>
              <dt>첨부이미지</dt>
              <dd>
                <S.ImgWrapper>
                  {previewAttachedFiles?.map(
                    ({ presignedUrl }, i: number) =>
                      presignedUrl && (
                        <S.ImgBtn
                          key={i}
                          type="button"
                          aria-label="Inquiry Attachment Image"
                          onClick={handleModalOpen(
                            <PreviewModal
                              ref={modalRef}
                              hasDownload={false}
                              files={{ images: previewAttachedFiles }}
                              selectedImgIndex={i}
                              handleModalClose={handleModalClose}
                            />,
                          )}
                        >
                          <img src={presignedUrl} alt="attachment-image" />
                        </S.ImgBtn>
                      ),
                  )}
                </S.ImgWrapper>
              </dd>
            </S.ContentWrapper>
          )}
          <S.ContentWrapper>
            <dt>정비소명</dt>
            <dd>{repairshopName}</dd>
          </S.ContentWrapper>
          <S.ContentWrapper>
            <dt>작성자명</dt>
            <dd>{inquiryDetailList?.name}</dd>
          </S.ContentWrapper>
          <S.ContentWrapper>
            <dt>연락처</dt>
            <dd>{inquiryDetailList?.phone}</dd>
          </S.ContentWrapper>
          <S.ContentWrapper>
            <dt>이메일</dt>
            <dd>{inquiryDetailList?.email}</dd>
          </S.ContentWrapper>
        </S.Container>
      </S.InquiryWrapper>
      {inquiryDetailList?.answerCreated && (
        <S.AnswerWrapper>
          <S.Header>
            <h3>답변 상세</h3>
            <time>
              작성일시 {formatKstYYYYMMDD(inquiryDetailList?.answerCreated)}
            </time>
          </S.Header>
          <S.Container>
            <S.ContentWrapper>
              <dt>제목</dt>
              <dd>{inquiryDetailList?.answerTitle}</dd>
            </S.ContentWrapper>
            <S.ContentWrapper>
              <dt>답변 내용</dt>
              <dd>{inquiryDetailList?.answerContent}</dd>
            </S.ContentWrapper>
          </S.Container>
        </S.AnswerWrapper>
      )}
    </>
  );
};

export default SettingInquiryDetail;
