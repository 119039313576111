import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const centerLayout = css`
  position: relative;
  display: grid;
  grid-template-columns: 222px 1fr;
  grid-template-rows: auto 1fr;
  gap: 26px 24px;
`;

export const PageHeader = styled.header`
  grid-row: 1;
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const PageTitle = styled.h2`
  ${({ theme }) => css`
    ${theme.font.bold_18};
    color: ${theme.color.gray_09};
  `}
`;

export const CSInfoBox = styled.div`
  ${({ theme }) => css`
    ${theme.font.bold_14};
    color: ${theme.color.blue_01};

    & > span {
      margin-left: 4px;
      font-weight: normal;
      color: ${theme.color.gray_04};
    }
  `}
`;
