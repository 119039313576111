import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  padding-bottom: 28px;
`;

export const InfoItem = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: grid;
    grid-template-columns: 132px 1fr;
    margin-bottom: 14px;

    &:nth-of-type(n + 3):nth-of-type(-n + 5) {
      margin-bottom: 12px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  `}
`;

export const TimeWrapper = styled.div`
  position: relative;
`;

export const Label = styled.label<{
  as?: string;
  isRequired?: boolean;
}>`
  ${({ isRequired, theme }) => css`
    color: ${theme.color.gray_09};

    &::after {
      content: ${isRequired ? "'*'" : null};
      display: inline-block;
      margin-left: 3px;
      color: ${theme.color.error_01};
    }
  `}
`;

export const InputWrapper = styled.div`
  width: 100%;
`;

export const SuffixWrapper = styled.div`
  display: flex;
`;

export const mixinEl = css`
  width: 184px;
`;

export const input = css`
  ${mixinEl};
`;

export const staffAddList = css`
  flex-direction: column;
`;

export const select = css`
  ${mixinEl};

  & > button {
    ${mixinEl};
  }
`;

export const staffItems = css`
  grid-template-columns: repeat(4, 102px);
  margin-top: 4px;
  margin-left: 0;
`;

export const dateInputMixin = css`
  width: 50px;
  height: 34px;
`;

export const hourInput = css`
  &:not(:last-of-type) {
    margin-right: 9px;
  }

  & > input {
    ${dateInputMixin};
  }
`;

export const minSelect = css`
  & > input {
    ${dateInputMixin};
  }
`;

export const SuffixText = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: flex;
    align-items: center;
    margin-left: 6px;
    color: ${theme.color.gray_10};
  `}
`;

export const textarea = css`
  min-height: 282px;
  padding-left: 11px;

  & > textarea {
    padding-right: 11px;
  }
`;

const textMixins = (theme: Theme) => css`
  ${theme.font.regular_12};
  margin-top: 4px;
`;

export const ValidText = styled.p`
  ${({ theme }) => css`
    ${textMixins(theme)};
    color: ${theme.color.error_01};
  `}
`;

export const TipText = styled.p`
  ${({ theme }) => css`
    ${textMixins(theme)};
    color: ${theme.color.gray_06};
  `}
`;

export const ButtonWrapper = styled.div`
  display: inline-block;
`;

export const updateDialogButton = (theme: Theme) => css`
  ${theme.button.ghostPrimary};
  margin-left: 4px;
`;
