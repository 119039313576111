import React from "react";

import { VisuallyHidden, Select, Button } from "components";
import { useModal } from "hooks";
import {
  REVIEW_SORT_OPTION_ANSWER_STATUS_TABLE,
  REVIEW_SORT_OPTION_TABLE,
  CommentIconImg,
} from "assets";
import FrequentlyUsedCommentsModal from "../frequently/modal/FrequentlyUsedCommentsModal";
import * as S from "./ReviewHeader.styled";

interface ReviewHeaderProps {
  totalCount: number;
  totalRating?: any;
  selectedSortOption: any;
  handleSelectSortOption: any;
  selectedSortAnsweredOption: any;
  handleSelectSortAnsweredOption: any;
}

const ReviewHeader = ({
  totalCount,
  selectedSortOption,
  handleSelectSortOption,
  selectedSortAnsweredOption,
  handleSelectSortAnsweredOption,
}: ReviewHeaderProps) => {
  const { modalRef, handleModalOpen } = useModal();

  return (
    <S.Root>
      <S.ReviewInfoWrapper>
        <S.TitleWrapper>
          <h2>리뷰 관리</h2> <VisuallyHidden>리뷰 총 갯수</VisuallyHidden>
          &nbsp; ({totalCount})
        </S.TitleWrapper>
      </S.ReviewInfoWrapper>
      <S.UtilWrapper>
        <Select
          css={S.sortSelect}
          id="review-sort"
          selectedOption={selectedSortOption}
          labelTable={REVIEW_SORT_OPTION_TABLE}
          onSelect={handleSelectSortOption}
        >
          {Object.entries(REVIEW_SORT_OPTION_TABLE).map(([value, label]) => (
            <Select.Option key={label} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
        <Select
          css={S.sortSelect}
          id="review-sort"
          selectedOption={selectedSortAnsweredOption}
          labelTable={REVIEW_SORT_OPTION_ANSWER_STATUS_TABLE}
          onSelect={handleSelectSortAnsweredOption}
        >
          {Object.entries(REVIEW_SORT_OPTION_ANSWER_STATUS_TABLE).map(
            ([value, label]) => (
              <Select.Option key={label} value={value}>
                {label}
              </Select.Option>
            ),
          )}
        </Select>

        <Button
          css={S.commentSettingButton}
          icon={<CommentIconImg />}
          onClick={handleModalOpen(
            <FrequentlyUsedCommentsModal ref={modalRef} />,
          )}
        >
          댓글 설정
        </Button>
      </S.UtilWrapper>
    </S.Root>
  );
};

export default ReviewHeader;
