import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Comment = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;

    & > p {
      font-size: 14px;
      letter-spacing: -0.01em;
      line-height: 1.5;
      color: ${theme.color.gray_06};
      white-space: pre-line;
    }
  `}
`;

export const commentIcon = css`
  margin-right: 9.3px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const deleteButton = css`
  margin-right: 4px;
`;
