import React, { createContext, useContext, forwardRef } from "react";

import {
  Button,
  DaumPostDialog,
  Textarea,
  MaxLengthInput,
  Input,
  SelectAddList,
} from "components";
import { CheckboxIconImg } from "assets";
import * as S from "./LabelContent.styled";

const LabelContentContext = createContext({});

interface LabelContentProps {
  className?: string;
  children: any;
  id?: string;
  label: string;
  err?: string;
  isRequired?: boolean;
}

interface InputProps {
  className?: string;
  name?: string;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, key: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

interface LengthInputProps {
  className?: string;
  name: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
}

interface TextareaProps {
  className?: string;
  name: string;
  placeholder: string;
  maxLength: number;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, key: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

interface AddressInputProps {
  className?: string;
  zipCode: string;
  address: string;
  addrDetail: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, key: string) => void;
  onSelect: any;
}

interface AddListProps {
  labelTable?: { [key: string]: string };
  name: string;
  placeholder: string;
  selectedItems: any;
  onSelect: any;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  onDeleteItem: any;
}

interface CheckBoxProps {
  className?: string;
  children: React.ReactNode;
  name: string;
  checked: boolean;
  onChange: any;
}

const LabelContent = ({
  children,
  className,
  id,
  label,
  err,
  isRequired,
}: LabelContentProps) => {
  return (
    <LabelContentContext.Provider value={{ id, err, isRequired }}>
      <S.Root className={className}>
        <S.Label htmlFor={id} isRequired={isRequired}>
          {label}
        </S.Label>
        {children}
        {err && <S.ValidText>{err}</S.ValidText>}
      </S.Root>
    </LabelContentContext.Provider>
  );
};

LabelContent.Input = forwardRef(function LabelInput(
  {
    className,
    name,
    placeholder,
    readOnly,
    disabled,
    value,
    onChange,
    onBlur,
  }: InputProps,
  ref,
) {
  const { id, isRequired, err } = useContext(LabelContentContext) as any;
  return (
    <Input
      css={(theme) => S.input(theme, err)}
      ref={ref}
      id={id}
      className={className}
      data-required={isRequired ? "true" : ""}
      readOnly={readOnly}
      disabled={disabled}
      name={name}
      placeholder={placeholder}
      value={value}
      validError={err}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
});

LabelContent.LengthInput = forwardRef(function LengthInput(
  { className, name, placeholder, value, onChange, onBlur }: LengthInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const { id, isRequired, err } = useContext(LabelContentContext) as any;

  return (
    <MaxLengthInput
      css={(theme) => S.lengthInput(theme, err)}
      ref={ref}
      id={id}
      className={className}
      data-required={isRequired ? "true" : ""}
      name={name}
      maxLength={30}
      placeholder={placeholder}
      value={value}
      validError={err}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
});

LabelContent.Textarea = forwardRef(function SizeInput(
  {
    className,
    name,
    placeholder,
    maxLength,
    value,
    onChange,
    onBlur,
  }: TextareaProps,
  ref: React.ForwardedRef<HTMLTextAreaElement>,
) {
  const { id, isRequired, err } = useContext(LabelContentContext) as any;

  return (
    <Textarea
      ref={ref}
      id={id}
      className={className}
      data-required={isRequired ? "true" : ""}
      name={name}
      placeholder={placeholder}
      maxLength={maxLength}
      value={value}
      validError={err}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
});

LabelContent.AddressInput = function AddressInput({
  className,
  zipCode,
  address,
  addrDetail,
  onChange,
  onSelect,
}: AddressInputProps) {
  const { err } = useContext(LabelContentContext) as any;

  return (
    <S.AddressWrapper className={className}>
      <S.AddressSearch>
        <Input
          css={(theme) => S.input(theme, err)}
          readOnly
          name="zipcode"
          validError={err}
          value={zipCode}
        />
        <DaumPostDialog
          onSelect={onSelect}
          trigger={({
            handleToggleDialogOpen,
            isDialogOpen,
          }: {
            handleToggleDialogOpen: () => void;
            isDialogOpen: boolean;
          }) => (
            <Button
              aria-haspopup="true"
              aria-pressed={isDialogOpen}
              onClick={handleToggleDialogOpen}
            >
              주소검색
            </Button>
          )}
        />
      </S.AddressSearch>
      <Input
        css={(theme) => S.input(theme, err)}
        readOnly
        name="addr"
        validError={err}
        value={address}
      />
      <Input
        css={(theme) => S.input(theme)}
        name="addrDetail"
        placeholder="상세 주소를 입력해주세요"
        value={addrDetail}
        onChange={onChange}
      />
    </S.AddressWrapper>
  );
};

LabelContent.AddList = forwardRef(function AddList(
  {
    labelTable = {},
    name,
    placeholder,
    selectedItems,
    onSelect,
    onBlur,
    onDeleteItem,
  }: AddListProps,
  ref: any,
) {
  const { id, isRequired, err } = useContext(LabelContentContext) as any;

  return (
    <SelectAddList
      css={S.selectAddList}
      labelTable={labelTable}
      selectedItems={selectedItems}
    >
      <SelectAddList.Select
        css={S.selectAddListSelect}
        id={id}
        forwardRef={ref}
        name={name}
        required={isRequired}
        validError={err}
        placeholder={placeholder}
        onSelect={onSelect}
        onBlur={onBlur}
      />
      {selectedItems.length > 0 && (
        <SelectAddList.ButtonWrapper
          css={S.selectItems}
          handleDeleteAddList={onDeleteItem}
          type={id}
        />
      )}
    </SelectAddList>
  );
});

LabelContent.Checkbox = forwardRef(function FileUploader(
  { children, className, name, checked, onChange }: CheckBoxProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  return (
    <S.CheckboxWrapper className={className} checked={checked} tabIndex={0}>
      <input
        ref={ref}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <CheckboxIconImg />
      {children}
    </S.CheckboxWrapper>
  );
});

export default LabelContent;
