import { useRef, useEffect } from "react";

import { usePan, useImgDeg, useImgScale } from "hooks";

const usePreview = (currentSlide: number) => {
  const imagesRef = useRef<any>([]);

  const { deg, setDeg, handleClickRotate } = useImgDeg();

  const { scale, handleClickZoomOut, handleClickZoomIn, handleChangeScale } =
    useImgScale();

  const { position, onMouseDown, resetPosition } = usePan(false);

  useEffect(() => {
    if (imagesRef.current[currentSlide]) {
      imagesRef.current[
        currentSlide
      ].style.transform = `translate(${position.translateX}px, ${position.translateY}px) scale(${scale}) rotate(-${deg}deg)`;
    }
  }, [position]);

  useEffect(() => {
    if (imagesRef.current[currentSlide]) {
      imagesRef.current[
        currentSlide
      ].style.transform = `translate(${position.translateX}px, ${position.translateY}px) scale(${scale}) rotate(-${deg}deg)`;
      imagesRef.current.map(
        (el: any, idx: number) =>
          idx !== currentSlide && (el.style.transform = `scale(0)`),
      );
    }
  }, [scale, currentSlide]);

  useEffect(() => {
    if (imagesRef.current[currentSlide]) {
      imagesRef.current[
        currentSlide
      ].style.transform = `translate(${position.translateX}px, ${position.translateY}px) scale(${scale}) rotate(-${deg}deg)`;
      imagesRef.current.map(
        (el: any, idx: number) =>
          idx !== currentSlide && (el.style.transform = `scale(0)`),
      );
    }
  }, [deg]);

  useEffect(() => {
    resetPosition();
    setDeg(0);
  }, [currentSlide]);

  return {
    imagesRef,
    scale,
    handleClickRotate,
    handleClickZoomOut,
    handleClickZoomIn,
    handleChangeScale,
    onMouseDown,
  };
};

export default usePreview;
