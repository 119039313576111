import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { mixin_scrollbar } from "style/mixin";

export const DialogRoot = styled.dialog`
  ${({ theme }) => css`
    position: absolute;
    top: calc(34px + 4px);
    left: -137px;
    display: flex;
    flex-direction: column;
    width: 400px;
    min-height: 330px;
    border: 1px solid ${theme.color.gray_03};
    border-radius: 4px;
    padding-top: 23px;
  `}
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: 28px;
    padding: 0 24px;

    & > h3 {
      ${theme.font.medium_16};
      margin-right: 4px;
      color: ${theme.color.gray_09};
    }

    & > span {
      ${theme.font.bold_16};
      color: ${theme.color.blue_01};
    }
  `}
`;

export const Desc = styled.div`
  ${({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    & > p {
      ${theme.font.regular_16};
      margin-bottom: 21px;
      text-align: center;
      color: ${theme.color.gray_06};
    }

    & > a {
      ${theme.font.medium_16};
      color: ${theme.color.blue_01};
      text-decoration: underline;
      cursor: pointer;
    }
  `}
`;

export const RequestedReservWrapper = styled.ul`
  ${({ theme }) => css`
    ${mixin_scrollbar(theme)};
    max-height: calc(100vh - 216px);
    overflow: hidden;
    width: 100%;
    background-color: ${theme.color.white};
    border-radius: 0 0 4px 4px;
  `}
`;

export const RequestedReserveItem = styled.li`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${theme.color.gray_02};

    &:hover {
      background-color: ${theme.color.point_01_opacity_10};
    }
  `}
`;

export const RequestedReserveBtn = styled.button`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 100%;
    border-radius: 2px;
    padding: 11px 21px;
    color: ${theme.color.gray_09};
  `}
`;

export const BtnTop = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_15};
    display: flex;
    align-items: center;
    color: ${theme.color.gray_09};

    ::before {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 10px;
      margin-right: 12px;
      background-color: ${theme.color.blue_01};
    }

    & > time {
      margin-right: 8px;
    }
  `}
`;

export const BtnBottom = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_12};
    display: flex;
    align-items: center;
    color: ${theme.color.gray_05};

    ::before {
      content: "";
      width: 12px;
      height: 12px;
      margin-right: 12px;
    }

    & > time {
      margin-right: 8px;
    }
  `}
`;
