import {
  useMutation,
  useQuery,
  UseQueryOptions,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import dayjs from "dayjs";
import type { AxiosError } from "axios";

import {
  acceptReservationAPI,
  cancelReservationAPI,
  changeReservationAPI,
  createReservationAPI,
  finishReservationAPI,
  getReservationDetailAPI,
  getReservationExcelAPI,
  getReservationsDailyAPI,
  getReservationsMonthlyAPI,
  startReservationAPI,
  updateReservationDetailAPI,
  updateReservationEstimatedTimeAPI,
  updateReservationMemoAPI,
} from "apis";
import {
  checkVinValidation,
  download,
  formatDate,
  getMonthDates,
  numToStringPhoneNum,
  reservationNumFormatter,
  getMonthlyDate,
} from "utils";
import type {
  APIError,
  AcceptReservationQueryModel,
  CancelReservationQueryModel,
  ChangeReservationQueryModel,
  CreateReservationQueryModel,
  FinishReservationQueryModel,
  GetReservationsDailyQueryModel,
  GetReservationsDailyServerModel,
  GetReservationsMonthlyClientModel,
  GetReservationsMonthlyQueryModel,
  ReservationStatus,
  StartReservationQueryModel,
  UpdateReservationDetailQueryModel,
  UpdateReservationEstimatedTimeQueryModel,
  UpdateReservationMemoQueryModel,
} from "types";

const reservationKeys = {
  all: ["reservation"] as const,
  lists: () => [...reservationKeys.all, "list"] as const,
  list: (
    type: string,
    filters: GetReservationsDailyQueryModel | GetReservationsMonthlyQueryModel,
  ) => [...reservationKeys.lists(), { type, filters }] as const,
  details: () => [...reservationKeys.all, "detail"] as const,
  detail: (id: string) => [...reservationKeys.details(), id] as const,
};

export const useGetReservationsMonthly = (
  filters: GetReservationsMonthlyQueryModel,
): UseQueryResult<GetReservationsMonthlyClientModel> => {
  const dates = getMonthDates(filters.query.date);

  return useQuery({
    queryKey: reservationKeys.list("monthly", filters),
    queryFn: () => getReservationsMonthlyAPI(filters),
    select: (res) => ({
      ...getMonthlyDate(res.reservations, dates),
    }),
  });
};

export const useGetReservationsDaily = (
  filters: GetReservationsDailyQueryModel,
  options?: UseQueryOptions<
    GetReservationsDailyServerModel,
    AxiosError,
    GetReservationsDailyServerModel,
    ReturnType<typeof reservationKeys.list>
  >,
) => {
  const req = {
    query: {
      ...filters.query,
      status: filters.query.tab as ReservationStatus,
    },
  };

  delete req.query.tab;

  return useQuery({
    ...options,
    queryKey: reservationKeys.list("daily", req),
    queryFn: () => getReservationsDailyAPI(req),
    select: (res) => ({
      ...res,
      reservations: res.reservations.map((reservation) => ({
        ...reservation,
        planned: dayjs(reservation.planned).format("A HH:mm"),
        phone: numToStringPhoneNum(reservation.phone),
        isSelf: reservation.isSelf === "y" ? "자체 예약" : "앱 예약",
        reservationNo: reservationNumFormatter(reservation.reservationNo),
        vin: reservation.vin
          ? checkVinValidation(reservation.vin)
            ? reservation.vin
            : "-"
          : "-",
      })),
    }),
  });
};

export const useGetReservationExcel = (
  filters: GetReservationsDailyQueryModel,
) => {
  const req = {
    query: {
      ...filters.query,
      status: filters.query.tab as ReservationStatus,
    },
  };

  delete req.query.tab;

  return useQuery({
    queryKey: reservationKeys.list("excel", req),
    queryFn: () => getReservationExcelAPI(req),
    enabled: false,
    onSuccess: (res) => {
      const url = window.URL.createObjectURL(new Blob([res]));

      download(
        url,
        `${formatDate(req.query.date, "YYYY-MM-DD")}_${req.query.status}.xlsx`,
      );
    },
  });
};

export const useCreateReservation = () => {
  return useMutation<unknown, APIError, CreateReservationQueryModel>({
    mutationFn: (req) => createReservationAPI(req),
  });
};

export const useGetReservationsDetail = (reservationId: string) => {
  return useQuery({
    queryKey: reservationKeys.detail(reservationId),
    queryFn: () => getReservationDetailAPI(reservationId),
  });
};

export const useUpdateReservationMemo = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, UpdateReservationMemoQueryModel>({
    mutationFn: (req) => updateReservationMemoAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(reservationKeys.details());
    },
  });
};

export const useUpdateReservationDetail = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, UpdateReservationDetailQueryModel>({
    mutationFn: (req) => updateReservationDetailAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(reservationKeys.details());
    },
  });
};

export const useAcceptReservation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, AcceptReservationQueryModel>({
    mutationFn: (req) => acceptReservationAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(reservationKeys.details());
    },
  });
};

export const useChangeReservation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, ChangeReservationQueryModel>({
    mutationFn: (req) => changeReservationAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(reservationKeys.details());
    },
  });
};

export const useCancelReservation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, CancelReservationQueryModel>({
    mutationFn: (req) => cancelReservationAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(reservationKeys.details());
    },
  });
};

export const useStartReservation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, StartReservationQueryModel>({
    mutationFn: (req) => startReservationAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(reservationKeys.details());
    },
  });
};

export const useFinishReservation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, FinishReservationQueryModel>({
    mutationFn: (req) => finishReservationAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(reservationKeys.details());
    },
  });
};

export const useUpdateReservationEstimatedTime = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    APIError,
    UpdateReservationEstimatedTimeQueryModel
  >({
    mutationFn: (req) => updateReservationEstimatedTimeAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(reservationKeys.details());
    },
  });
};
