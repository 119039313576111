import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const InfoItem = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: grid;
    grid-template-columns: 132px 1fr;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    & > dt {
      color: ${theme.color.gray_05};

      &::after {
        display: inline-block;
        margin-left: 3px;
        font-size: 14px;
        color: ${theme.color.error_01};
      }
    }

    & > dd {
      position: relative;
      display: flex;
      color: ${theme.color.gray_09};
    }
  `}
`;

export const textarea = css`
  min-height: 262px;
`;
