import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const flex_mixin = css`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
`;

export const Root = styled.section`
  ${({ theme }) => css`
    ${flex_mixin};
    width: 100%;
    height: 100vh;
    background-color: ${theme.color.background};
  `}
`;

export const ContentWrapper = styled.div`
  ${flex_mixin};
`;

export const ContentItem = styled.dl`
  ${flex_mixin};
  margin: 24px 0 48px;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    margin-bottom: 16px;
    ${theme.font.bold_18}
    color: ${theme.color.gray_08};
  `}
`;

export const Desc = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_14}
    color: ${theme.color.gray_06};
    text-align: center;
    white-space: pre-line;
  `}
`;

export const BrowserWrapper = styled.div`
  display: -ms-flexbox;
  display: flex;
`;

export const BrowserItem = styled.div`
  ${({ theme }) => css`
    ${flex_mixin}

    :not(:last-of-type) {
      margin-right: 72px;
    }

    & > span {
      margin-top: 20px;
      ${theme.font.medium_13};
      color: ${theme.color.gray_07};
    }
  `}
`;

export const exclamationIcon = (theme: Theme) => css`
  fill: ${theme.color.gray_05};
  stroke: ${theme.color.gray_05};
`;
