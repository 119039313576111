import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  display: flex;
`;

export const calendarBtn = (theme: Theme) => css`
  ${theme.button.secondarySmall};
  height: 30px;
  padding: 0;
`;
