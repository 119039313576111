import styled from "@emotion/styled";
import { css } from "@emotion/react";

import type { ButtonVariant } from "./Button";

export const Root = styled.button<{ hasIcon: boolean; variant: ButtonVariant }>`
  ${({ hasIcon, variant, theme }) => css`
    ${variant === "primarySmall" && theme.button.primarySmall};
    ${variant === "primaryMedium" && theme.button.primaryMedium};
    ${variant === "primaryLarge" && theme.button.primaryLarge};
    ${variant === "secondarySmall" && theme.button.secondarySmall};
    ${variant === "secondaryMedium" && theme.button.secondaryMedium};
    ${variant === "secondaryLarge" && theme.button.secondaryLarge};
    ${variant === "warningMedium" && theme.button.warningMedium};
    ${variant === "ghostPrimary" && theme.button.ghostPrimary};
    ${variant === "ghostSecondary" && theme.button.ghostSecondary};

    & > svg {
      margin-right: ${hasIcon && "4px"};
    }
  `}
`;
