import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const pulseKeyframes = keyframes`
    50% {
        fill: #868686;
      }
    to {
        fill: rgba(134, 134, 134, 0.4);
      }`;

const IntroKeyframse = keyframes`
    from {
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
    }
    to {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
`;

export const Root = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);
  animation: ${IntroKeyframse} 0.6s;

  svg {
    fill: #868686;

    path:nth-of-type(1) {
      animation: ${pulseKeyframes} 1s infinite linear;
    }
    path:nth-of-type(2) {
      animation: ${pulseKeyframes} 1s -0.083s infinite linear;
    }
    path:nth-of-type(3) {
      animation: ${pulseKeyframes} 1s -0.166s infinite linear;
    }
    path:nth-of-type(4) {
      animation: ${pulseKeyframes} 1s -0.249s infinite linear;
    }
    path:nth-of-type(5) {
      animation: ${pulseKeyframes} 1s -0.332s infinite linear;
    }
    path:nth-of-type(6) {
      animation: ${pulseKeyframes} 1s -0.415s infinite linear;
    }
    path:nth-of-type(7) {
      animation: ${pulseKeyframes} 1s -0.498s infinite linear;
    }
    path:nth-of-type(8) {
      animation: ${pulseKeyframes} 1s -0.581s infinite linear;
    }
    path:nth-of-type(9) {
      animation: ${pulseKeyframes} 1s -0.664s infinite linear;
    }
    path:nth-of-type(10) {
      animation: ${pulseKeyframes} 1s -0.747s infinite linear;
    }
    path:nth-of-type(11) {
      animation: ${pulseKeyframes} 1s -0.83s infinite linear;
    }
    path:nth-of-type(12) {
      animation: ${pulseKeyframes} 1s -0.913s infinite linear;
    }
  }
`;
