import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const baseModal = css`
  width: 494px;
  border-top: 0;
  border-radius: 0;

  & > div:first-of-type {
    height: 44px;
  }

  & > div:nth-of-type(2) {
    border: 0;
  }
`;

export const Header = styled.div`
  height: auto;
  border: 0;
  padding: 0;

  & > button {
    margin: 0;
  }
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0;
    padding: 0 68px 32px;
    background-color: ${theme.color.white};
  `}
`;

export const exclamationIcon = (theme: Theme) => css`
  width: 44px;
  height: 44px;
  margin-bottom: 28px;
  fill: ${theme.color.blue_01};
  stroke: ${theme.color.blue_01};
`;

export const TextWrapper = styled.dl`
  ${({ theme }) => css`
    margin-bottom: 24px;

    & > dt {
      ${theme.font.medium_18};
      margin-bottom: 16px;
      color: ${theme.color.gray_08};
      text-align: center;
    }

    & > dd {
      ${theme.font.regular_14};
      color: ${theme.color.gray_06};
      letter-spacing: -0.02em;
      text-align: center;
    }
  `}
`;

export const TelText = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_18};
    letter-spacing: -0.02em;
    color: ${theme.color.blue_01};
  `}
`;
