import React, { useCallback } from "react";

import {
  MaxLengthInput,
  Textarea,
  ValidMessage,
  DetailModal,
} from "components";
import { useModal } from "hooks";
import { INPUT_MAX_LENGTH } from "constants/index";
import useAddFreqComment from "../hooks/useAddFreqComment";
import * as S from "./AddFrequentlyUseCommentModal.styled";

const ADD_COMMENT_LABEL_ID = "add-comment-label";
const ADD_COMMENT_CONTENT_ID = "add-comment-content";

const AddFrequentlyUseCommentModal = React.forwardRef(
  (_, ref: React.ForwardedRef<HTMLDialogElement>) => {
    const { handleModalClose } = useModal();
    const handleClose = useCallback(() => {
      handleModalClose();
      resetForm();
    }, [handleModalClose]);

    const {
      inputRefs,
      form,
      isSubmitDisabled,
      handleSubmit,
      handleChangeForm,
      handleBlurForm,
      resetForm,
    } = useAddFreqComment(handleClose);

    return (
      <DetailModal
        ref={ref}
        css={S.detailModal}
        title="자주 쓰는 댓글 추가"
        isPosDisabled={!!isSubmitDisabled}
        cancelBtnName="취소"
        posBtnName="등록"
        posFnType="submit"
        posFn={handleSubmit}
      >
        <S.Content>
          <S.LabelInputWrapper>
            <div>
              <S.Label htmlFor={ADD_COMMENT_LABEL_ID}>댓글명</S.Label>
            </div>
            <MaxLengthInput
              css={S.maxLengthInput}
              ref={(el) => {
                if (inputRefs.current) {
                  inputRefs.current["title"] = el as HTMLInputElement;
                }
              }}
              id={ADD_COMMENT_LABEL_ID}
              name="title"
              value={form.title.value}
              maxLength={INPUT_MAX_LENGTH.FREQ_CMNT_LABEL}
              data-required="true"
              placeholder="댓글명을 입력하세요"
              validError={form.title.error}
              onChange={handleChangeForm}
              onBlur={handleBlurForm}
            />
            {form.title.error && (
              <ValidMessage css={S.validText}>{form.title.error}</ValidMessage>
            )}
          </S.LabelInputWrapper>
          <S.LabelInputWrapper>
            <div>
              <S.Label htmlFor={ADD_COMMENT_CONTENT_ID}>내용</S.Label>
            </div>
            <Textarea
              css={S.textarea}
              id={ADD_COMMENT_CONTENT_ID}
              ref={(el: any) => {
                if (inputRefs.current) {
                  inputRefs.current["content"] = el;
                }
              }}
              name="content"
              value={form.content.value}
              maxLength={INPUT_MAX_LENGTH.FREQ_CMNT_CONTENT}
              data-required="true"
              placeholder="내용을 입력하세요"
              validError={form.content.error}
              onChange={handleChangeForm}
              onBlur={handleBlurForm}
            />
            {form.content.error && (
              <ValidMessage css={S.validText}>
                {form.content.error}
              </ValidMessage>
            )}
          </S.LabelInputWrapper>
        </S.Content>
      </DetailModal>
    );
  },
);

AddFrequentlyUseCommentModal.displayName = "AddFrequentlyUseCommentModal";

export default AddFrequentlyUseCommentModal;
