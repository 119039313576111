import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Wrapper = styled.article`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    min-height: 314px;
    border: 1px solid ${theme.color.gray_02};
    border-radius: 3px;
    padding: 28px 32px;
    background-color: ${theme.color.white};
  `}
`;

export const Title = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_16};
  `}
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 136px 1fr;
  row-gap: 12px;
`;

export const ContentTitle = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_07};
  `}
`;

export const ContentPrice = styled.span<{ isTotal?: boolean }>`
  ${({ theme, isTotal }) => css`
    ${isTotal ? theme.font.bold_14 : theme.font.regular_14};
    justify-self: end;
    color: ${isTotal ? theme.color.blue_01 : theme.color.gray_10};
  `}
`;
