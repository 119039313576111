import { css, Theme } from "@emotion/react";

import { mixinNavElement } from "style/mixin";

export const link = (theme: Theme, selected: boolean, content: string) => css`
  ${mixinNavElement};
  position: relative;
  justify-content: center;
  width: 100%;
  padding-left: 0;
  text-decoration: none;
  fill: ${selected ? theme.color.blue_01 : theme.color.gray_05};

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    display: ${!selected && "none"};
    width: 5px;
    height: 100%;
    background-color: ${theme.color.blue_01};
  }

  :hover {
    background-color: ${theme.color.point_01_opacity_20};

    :after {
      ${theme.font.medium_13};
      content: "${content}";
      position: fixed;
      left: calc(${theme.size.NAV_WIDTH} + 8px);
      border-radius: 4px;
      padding: 4px 12px;
      color: ${theme.color.white};
      background-color: ${theme.color.gray_07};
    }
  }
`;
