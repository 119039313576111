import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.input<{ validError?: boolean }>`
  ${({ validError, theme }) => css`
    width: 100%;
    height: 34px;
    border: 1px solid ${validError ? theme.color.error_01 : theme.color.gray_03};
    border-radius: 4px;
    padding: 0 10px;
    color: ${theme.color.gray_09};
    background-color: ${theme.color.white};

    &:focus {
      border-color: ${validError ? theme.color.error_01 : theme.color.blue_01};
      outline: ${validError && "none"};
    }

    &::placeholder {
      color: ${theme.color.gray_05};
    }

    &[readonly] {
      color: ${theme.color.gray_08};
      background-color: ${theme.color.gray_01};
    }

    &[disabled] {
      border: 1px solid ${theme.color.gray_03};
      color: ${theme.color.gray_07};
      background-color: ${theme.color.gray_02};
    }
  `}
`;
