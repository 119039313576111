import React from "react";

import { Button } from "components";
import { useModal, useToast } from "hooks";
import { useDeleteFreqComment } from "services";
import { TOAST_MSG } from "constants/index";
import UpdateFrequentlyUseCommentModal from "../../updateFrequently/UpdateFrequentlyUseCommentModal";
import * as S from "./FrequentlyUsedComment.styled";

interface FrequentlyUsedCommentProps {
  data: any;
}

const FrequentlyUsedComment = ({ data }: FrequentlyUsedCommentProps) => {
  const { mutate: deleteFreqCommentMutate } = useDeleteFreqComment();

  const { addToast } = useToast();
  const { modalRef, handleModalOpen } = useModal();

  const handleDeleteComment = () => {
    deleteFreqCommentMutate(data.templateId, {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.DELETE_FREQ_CMNT_DONE);
      },
    });
  };

  return (
    <S.Root>
      <span>{data.title}</span>
      <S.ContentWrapper>{data.content}</S.ContentWrapper>
      <div>
        <Button
          css={S.deleteButton}
          type="button"
          variant="secondarySmall"
          onClick={handleDeleteComment}
        >
          삭제
        </Button>
        <Button
          type="button"
          onClick={handleModalOpen(
            <UpdateFrequentlyUseCommentModal ref={modalRef} data={data} />,
          )}
        >
          수정
        </Button>
      </div>
    </S.Root>
  );
};

export default FrequentlyUsedComment;
