import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.button<{ selected: boolean }>`
  ${({ selected, theme }) => css`
    ${theme.font.regular_14}
    display: flex;
    align-items: center;
    color: ${selected ? theme.color.blue_01 : theme.color.gray_04};

    & > span {
      margin-left: 4px;
    }
  `}
`;
