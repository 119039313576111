export const INPUT_MAX_LENGTH = Object.freeze({
  FREQ_CMNT_LABEL: 10,
  FREQ_CMNT_CONTENT: 300,
  REPORT_REVIEW_CONTENT: 100,
  MAINTENANCE_OPINION: 200,
  REASONS_NOT_AVAILABLE_CONTENT: 200,
  USER_COMMENT: 200,
  SHOP_COMMENT: 500,
  REVIEW_COMMENT: 300,
  EMAIL: 250,
  PASSWORD: 16,
  PHONE: 8,
  CELL_PHONE: 12,
  NAME: 20,
  MODEL_NAME: 50,
  REGNUM: 10,
  VIN: 17,
  MODEL_YEAR: 4,
  COMMON_TEXTAREA: 200,
  COMMENT_TITLE: 10,
  COMMENT_CONTENT: 300,
  REPAIR_SHOP_NAME: 50,
  ADDR_DETAIL: 250,
  OPERATE_TIME_MEMO: 50,
  INTRODUCTION: 300,
  STAFF_POSITION: 10,
  INQUIRY_TITLE: 30,
  INQUIRY_CONTENT: 500,
  INQUIRY_WRITER: 20,
});

export const INPUT_MAX_VALUE = Object.freeze({
  DRIVING_DISTANCE: 999999999,
  PRICE: 1000000000,
  EXPECTATION_TIME: 838,
  ACTUAL_TIME: 838,
});
