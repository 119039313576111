import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const ToastItem = styled.div<{ isIncludeNewLine?: boolean }>`
  ${({ isIncludeNewLine, theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${isIncludeNewLine ? "518px" : "360px"};
    height: max-content;
    border-radius: 3px;
    padding: 19px 0;
    background-color: ${theme.color.gray_10_85};
    text-align: center;
    pointer-events: none;

    & > p {
      ${theme.font.medium_16};
      color: ${theme.color.white};
    }
  `}
`;
