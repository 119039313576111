import React from "react";
import "dayjs/locale/ko";

import { EstimatedTime, ValidMessage } from "components";
import { formatMaintenanceDateTime } from "utils";
import * as S from "./ApprovedForm.styled";

interface ApprovedFormProps {
  id: any;
  inputRefs: any;
  data: any;
  reservationData: any;
  tabIndex: number;
  handleChangeData: () => void;
  handleBlurData: () => void;
}

const ApprovedForm = ({
  id,
  inputRefs,
  data,
  reservationData,
  handleChangeData,
  handleBlurData,
}: ApprovedFormProps) => {
  return (
    <div id={id} tabIndex={0}>
      <S.InfoItem isRequired={true}>
        <span>정비 일시</span>
        <S.DateInput
          value={formatMaintenanceDateTime(reservationData.planned)}
          readOnly
        />
      </S.InfoItem>
      <S.InfoItem isRequired={true}>
        <label>예상 정비 시간</label>
        <EstimatedTime
          id={`plannedForm-estimatedTime`}
          onChange={handleChangeData}
          onBlur={handleBlurData}
        >
          <EstimatedTime.Hour
            forwardRef={(el: any) =>
              (inputRefs.current["estimatedTimeHour"] = el)
            }
            name="estimatedTimeHour"
            value={data.estimatedTimeHour.value}
            validError={
              data.estimatedTimeHour.error || data.estimatedTimeMin.error
            }
            data-required={true}
            label="시간"
          />
          <EstimatedTime.SelectMin
            label="분 이내"
            forwardRef={(el: any) =>
              (inputRefs.current["estimatedTimeMin"] = el)
            }
            id="estimatedTimeMin"
            name="estimatedTimeMin"
            value={data.estimatedTimeMin.value}
            validError={
              !data.estimatedTimeMin.value &&
              (data.estimatedTimeHour.error || data.estimatedTimeMin.error)
            }
            required={true}
            zeroDisable={+data.estimatedTimeHour.value === 0}
          />
        </EstimatedTime>
        {(data.estimatedTimeHour.error || data.estimatedTimeMin.error) && (
          <ValidMessage css={S.validText}>
            {data.estimatedTimeHour.error || data.estimatedTimeMin.error}
          </ValidMessage>
        )}
      </S.InfoItem>
    </div>
  );
};

export default ApprovedForm;
