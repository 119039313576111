import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  padding-bottom: 28px;
`;

export const InfoItem = styled.div<{ isRequired: boolean }>`
  ${({ isRequired, theme }) => css`
    ${theme.font.regular_14};
    display: grid;
    grid-template-columns: 132px 1fr;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    & > span {
      color: ${theme.color.gray_09};

      &::after {
        content: ${isRequired ? "'*'" : null};
        display: inline-block;
        margin-left: 3px;
        font-size: 14px;
        color: ${theme.color.error_01};
      }
    }
  `}
`;

export const RadioWrapper = styled.div`
  display: flex;
`;

export const radioButton = css`
  height: 34px;

  &:not(:last-of-type) {
    margin-right: 6px;
  }
`;

export const validText = (theme: Theme) => css`
  ${theme.font.regular_12};
  position: static;
  margin-top: 6px;
  white-space: normal;
`;
