import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";

import { Layout } from "components";
import { auth } from "utils";
import { LAYOUT_TYPE, PATH } from "constants/index";

const PublicRoute = () => {
  const location = useLocation();

  return auth.isAuth ? (
    location.state?.from ? (
      <Navigate
        to={location.state?.from}
        state={{ from: location.state?.from }}
      />
    ) : (
      <Navigate to={PATH.root} state={{ from: location }} />
    )
  ) : (
    <Layout type={LAYOUT_TYPE.PUBLIC}>
      <Outlet />
    </Layout>
  );
};

export default PublicRoute;
