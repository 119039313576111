import React from "react";

import * as S from "./Status.styled";

interface StatusProps {
  tabInfo: any;
  status: any;
  count: any;
}

const Status = ({ tabInfo, status, count }: StatusProps) => {
  return (
    <S.Root data-status={status}>
      <span>{tabInfo[status].label}</span>
      <span>{count}</span>
    </S.Root>
  );
};

export default Status;
