import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_13};
    position: relative;
    display: flex;
    justify-content: space-between;
    color: ${theme.color.gray_08};

    &[data-status="accepted"],
    &[data-status="repairing"] > span {
      color: ${theme.color.blue_01};
    }

    &[data-status="canceled"] > span {
      color: ${theme.color.gray_05};
    }
  `}
`;
