import React from "react";

import { Table, HasNoneData } from "components";
import { NOTICE_TABLE_LIST } from "assets";
import NoticeTableRow from "./row/NoticeTableRow";
import * as S from "./NoticeTable.styled";

interface NoticeTableProps {
  isDone: boolean;
  notices: any[];
}

const NoticeTable = ({ isDone, notices }: NoticeTableProps) => {
  return (
    <Table css={S.root} title="공지사항 리스트">
      <Table.Head columnList={NOTICE_TABLE_LIST} />
      <Table.Body
        css={S.tableBody}
        isDone={isDone}
        data={notices}
        text="아직 등록된 공지가 없어요!"
        PropsTbodyHasNoneData={NoticeNoneData}
      >
        {notices.map((rowData) => (
          <NoticeTableRow
            rowData={rowData}
            key={rowData.noticeId}
            columnList={NOTICE_TABLE_LIST}
          />
        ))}
      </Table.Body>
    </Table>
  );
};
export default NoticeTable;

const NoticeNoneData = () => {
  return (
    <S.NoticeTBodyNoneData>
      <tr>
        <HasNoneData
          css={S.noticeNoHistory}
          asProp="td"
          text="아직 등록된 공지가 없어요!"
        />
      </tr>
    </S.NoticeTBodyNoneData>
  );
};
