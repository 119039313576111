import { includes } from "lodash-es";

import type { StringKeyOf } from "types";
import { formatUtcISODateTime, getDateFormat } from "./formatter";

export const getFilterParams = <T>(
  searchParams: URL["searchParams"],
  params: StringKeyOf<T>[],
  init?: Partial<T>,
) => {
  return Object.keys(Object.fromEntries(searchParams)).reduce((acc, query) => {
    const queryValue = searchParams.getAll(query);
    let value = queryValue.join(",");

    if (!includes(params, query)) return acc;

    if (query === "date") {
      const dateFormat = getDateFormat(value);
      if (!dateFormat) return acc;

      value = formatUtcISODateTime(value, dateFormat) ?? "";
    }

    return {
      ...acc,
      [query]: value,
    };
  }, getDefaultQuery(init) as T & { page?: string; pageSize?: string });
};

const getDefaultQuery = <T>(init?: T) => ({
  ...(init ?? {}),
});
