import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { mixin_scrollbar } from "style/mixin";

export const Root = styled.div<{ validError: boolean; readOnly?: boolean }>`
  ${({ validError, theme }) => css`
    position: relative;
    width: 100%;
    height: 106px;
    border: 1px solid ${validError ? theme.color.error_01 : theme.color.gray_03};
    border-radius: 3px;
    padding: 11px 0px 31px 19px;
    background-color: ${theme.color.white};

    &[readonly] {
      background-color: ${theme.color.background};
    }

    & > textarea {
      ${theme.font.regular_14};
      ${mixin_scrollbar(theme)}
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: 3px;
      padding-right: 20px;
      color: ${theme.color.gray_10};
      z-index: 9;
      resize: none;

      &::placeholder,
      &[readonly] {
        color: ${theme.color.gray_05};
      }

      &[readonly] {
        background-color: ${theme.color.background};
      }
    }

    & > span {
      ${theme.font.regular_14};
      position: absolute;
      right: 12px;
      bottom: 12px;
      color: ${theme.color.gray_04};
      z-index: 10;
    }

    &:focus-within {
      border: 1px solid ${theme.color.blue_01};
    }
  `}
`;
