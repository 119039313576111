import React from "react";

import { ImageUploadModal } from "components";
import { useImagePreview, useModal } from "hooks";
import { CloseIconImg, PlusIconImg } from "assets";
import * as S from "./ImageContainer.styled";

// const MAX_IMG_SIZE = 50000000;
const MAX_FILE_LENGTH = 4;

interface ImageContainerProps {
  children: React.ReactNode;
}

interface ImageHandlerProps {
  handleChangeImage: any;
  handleDeleteImage: any;
}

interface ProfileItemProps extends ImageHandlerProps {
  image: any;
  isRequired: boolean;
}

interface AdditionalItemProps extends ImageHandlerProps {
  images: any[];
}

export default function ImageContainer({ children }: ImageContainerProps) {
  return (
    <div>
      <S.Title>정비소 이미지</S.Title>
      <S.DescWrapper>
        <p>
          기사님께 보이는 사진입니다. 대표이미지는 필수 입력 정보이며,
          추가이미지는 최대 4개까지 등록 가능합니다.
        </p>
      </S.DescWrapper>
      <S.Wrapper>{children}</S.Wrapper>
    </div>
  );
}

ImageContainer.Profile = function ProfileItem({
  image,
  isRequired,
  handleChangeImage,
  handleDeleteImage,
}: ProfileItemProps) {
  const [fileDataUrl] = useImagePreview(image.file);
  const { modalRef, handleModalOpen, handleModalClose } = useModal();
  return (
    <div>
      <S.Title isRequired={isRequired}>대표 이미지</S.Title>
      {image.status !== "default" && (fileDataUrl || image.url) ? (
        <S.ImageBox>
          <img src={fileDataUrl || image.url} alt="profile-image" />
          <S.DeleteBtn onClick={handleDeleteImage("thumbnail")}>
            <CloseIconImg />
          </S.DeleteBtn>
        </S.ImageBox>
      ) : (
        <S.ImageUploadBtn
          type="button"
          onClick={handleModalOpen(
            <ImageUploadModal
              ref={modalRef}
              title="대표 이미지 업로드"
              name="thumbnail"
              handleUploadFile={handleChangeImage}
              handleModalClose={handleModalClose}
            />,
          )}
        >
          <S.IconWrapper>
            <PlusIconImg css={S.plusIcon} />
          </S.IconWrapper>
        </S.ImageUploadBtn>
      )}
    </div>
  );
};
ImageContainer.Additional = function AdditionalItem({
  images,
  handleChangeImage,
  handleDeleteImage,
}: AdditionalItemProps) {
  const { modalRef, handleModalOpen, handleModalClose } = useModal();

  const [fileDataUrl1] = useImagePreview(images[0]?.file);
  const [fileDataUrl2] = useImagePreview(images[1]?.file);
  const [fileDataUrl3] = useImagePreview(images[2]?.file);
  const [fileDataUrl4] = useImagePreview(images[3]?.file);
  const fileDataUrls = [fileDataUrl1, fileDataUrl2, fileDataUrl3, fileDataUrl4];
  const slotIdx = images.filter((image) => image.status !== "default").length;

  return (
    <div>
      <S.Title>추가 이미지</S.Title>
      <S.PreviewImgWrapper>
        {images.map((image, index) => {
          return image.status !== "default" &&
            (fileDataUrls[index] || image.url) ? (
            <S.ImageBox key={index}>
              <img
                src={fileDataUrls[index] || image.url}
                alt="additional-image"
              />
              <S.DeleteBtn onClick={handleDeleteImage("extras", index)}>
                <CloseIconImg />
              </S.DeleteBtn>
            </S.ImageBox>
          ) : null;
        })}

        {slotIdx < MAX_FILE_LENGTH && (
          <S.ImageUploadBtn
            type="button"
            aria-haspopup="true"
            onClick={handleModalOpen(
              <ImageUploadModal
                ref={modalRef}
                title="추가 이미지 업로드"
                name="extras"
                idx={slotIdx}
                handleUploadFile={handleChangeImage}
                handleModalClose={handleModalClose}
              />,
            )}
          >
            <S.IconWrapper>
              <PlusIconImg css={S.plusIcon} />
            </S.IconWrapper>
          </S.ImageUploadBtn>
        )}
      </S.PreviewImgWrapper>
    </div>
  );
};
