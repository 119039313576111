import React from "react";
import dayjs from "dayjs";

import * as S from "./Month.styled";

interface MonthProps {
  className?: string;
  date: any;
  selectedDate: any;
  disabled?: boolean;
  handleSelectDate: (date: string) => () => void;
}

const Month = ({
  className,
  date,
  selectedDate,
  disabled,
  handleSelectDate,
}: MonthProps) => {
  const isSameMonth = dayjs().isSame(date, "M");
  const isSelectedDate = selectedDate?.isSame(date, "M");

  return (
    <S.Root className={className}>
      <S.DateButton
        type="button"
        aria-current={isSameMonth ? "date" : "false"}
        aria-selected={isSelectedDate}
        disabled={disabled}
        onClick={handleSelectDate(date)}
      >
        <time>{date.format("MM")}월</time>
      </S.DateButton>
    </S.Root>
  );
};

export default Month;
