import React from "react";
import { atom } from "recoil";

const modalCompoState = atom<React.ReactNode[]>({
  key: "modalCompoState",
  default: [],
});

const isModalOpenState = atom({
  key: "isModalOpenState",
  default: false,
});

const isReqResvDialogOpenState = atom({
  key: "isReqResvDialogOpenState",
  default: false,
});

const toastState = atom<string[]>({
  key: "toastState",
  default: [],
});

export {
  modalCompoState,
  toastState,
  isModalOpenState,
  isReqResvDialogOpenState,
};
