import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

export const detailModal = css`
  width: 532px;
`;

export const Content = styled.div`
  padding: 24px 35px;
`;

export const Form = styled.form`
  border-radius: 4px;
  padding-top: 24px;
`;

export const ReasonReportSelectWrapper = styled.div`
  display: flex;
  margin-bottom: 4px;
`;

export const Label = styled.label<{ isRequired: boolean }>`
  ${({ isRequired, theme }) => css`
    ${theme.font.regular_14};
    min-width: 124px;
    margin-right: 6px;
    color: ${theme.color.gray_07};

    &::after {
      content: ${isRequired ? "'*'" : null};
      margin-left: 2px;
      color: ${theme.color.error_01};
    }
  `}
`;

export const select = css`
  width: 184px;

  & > ul {
    max-height: 160px;
  }
`;

export const ReasonReportDetailWrapper = styled.div`
  display: flex;
`;

export const ReasonReportTextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const textarea = css`
  width: 330px;
  height: 124px;
  padding-left: 11px;
  padding-right: 11px;
`;

export const buttonWrapper = css`
  display: flex;
  column-gap: 4px;
`;

export const ValidText = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_12};
    margin-top: 4px;
    color: ${theme.color.error_01};
  `}
`;
