import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    input[type="radio"] {
      position: absolute;
      width: 1px;
      height: 1px;
      border: 0;
      padding: 0;
      margin: -1px;
      clip: rect(0, 0, 0, 0);
      overflow: hidden;
    }

    input[type="radio"] + label {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 14px;
      letter-spacing: -0.01em;
      color: ${theme.color.gray_07};

      &::before {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 4px;
        border: 1px solid ${theme.color.gray_03};
        border-radius: 50%;
        text-align: center;
        background-color: ${theme.color.white};
      }
    }

    input[type="radio"]:checked + label::before {
      content: "";
      border: 8px solid ${theme.color.blue_01};
    }

    input[type="radio"]:checked + label::after {
      content: "";
      position: absolute;
      left: 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${theme.color.white};
    }
  `}
`;
