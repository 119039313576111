import React, { useEffect } from "react";

import {
  TimeCheckBox,
  DateSelectInput,
  Tooltip,
  Button,
  DetailModal,
} from "components";
import { useNoReservation } from "hooks";
import { NOT_ALLOWED_TIME_LIST, NOT_ALLOWED_TIME_TABLE } from "assets";
import { TOOLTIP_MSG } from "constants/index";
import * as S from "./UnavailableTimeModal.styled";

interface UnavailableTimeModalProps {
  handleModalClose: () => void;
}

const UnavailableTimeModal = React.forwardRef(
  (
    { handleModalClose }: UnavailableTimeModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const NOT_ALLOWED_DATE_ID = "not-allowed-date";
    const NOT_ALLOWED_TIME_ID = "not-allowed-time";
    const NOT_ALLOWED_SETTING_TIME_ID = "not-allowed-setting-time";

    const {
      nonReservedDate,
      checkedNotAllowedTime,
      isAllChecked,
      initSelectDate,
      handleSelectDate,
      handleToggleAllCheck,
      handleCheckedItem,
      initCheckedItem,
      handleSave,
    } = useNoReservation(handleModalClose);

    useEffect(() => {
      initSelectDate();

      return () => {
        initCheckedItem();
      };
    }, []);

    return (
      <DetailModal
        ref={ref}
        css={S.detailModal}
        title="앱 예약 불가 시간 설정"
        posBtnName="저장"
        posFn={handleSave}
        tooltip={
          <Tooltip
            css={S.tooltip}
            text={TOOLTIP_MSG.RESERVATION_UNAVALIABLE_TIME}
          />
        }
      >
        <S.Content>
          <S.ContentLeft>
            <div>
              <S.Label htmlFor={NOT_ALLOWED_DATE_ID}>
                예약 불가일 <span>*</span>
              </S.Label>
              <DateSelectInput
                id={NOT_ALLOWED_DATE_ID}
                value={nonReservedDate}
                isDisabledBeforeToday={true}
                handleSelectDate={handleSelectDate}
              />
            </div>
            <div>
              <S.Label id={NOT_ALLOWED_TIME_ID}>
                예약 불가 시간 <span>*</span>
              </S.Label>
              <S.NonReservedTimeCheckList
                role="group"
                aria-labelledby={NOT_ALLOWED_TIME_ID}
              >
                {NOT_ALLOWED_TIME_LIST.map((item) => (
                  <TimeCheckBox
                    key={item.key}
                    id={item.key}
                    value={item.key}
                    data-label={item.label}
                    checked={checkedNotAllowedTime.includes(item.key)}
                    onChange={handleCheckedItem}
                  >
                    {item.label}
                  </TimeCheckBox>
                ))}
              </S.NonReservedTimeCheckList>
              <Button
                css={(theme) => S.allCheckedToggleButton(theme, isAllChecked)}
                variant="secondaryMedium"
                onClick={handleToggleAllCheck}
              >
                {isAllChecked ? "선택 해제" : "전체 선택"}
              </Button>
            </div>
          </S.ContentLeft>
          <div>
            <S.SettingTimeWrapper>
              <div>
                <S.Label id={NOT_ALLOWED_SETTING_TIME_ID}>설정 시간</S.Label>
                <span>
                  *설정 전 요청받은 예약 건은 취소 되지 않고,
                  <br />
                  그대로 표기됩니다.
                </span>
              </div>

              <S.SettingTimeCheckList>
                {checkedNotAllowedTime.map((time: any) => (
                  <TimeCheckBox
                    key={time}
                    id={time}
                    value={time}
                    checked={checkedNotAllowedTime.includes(time)}
                    onChange={handleCheckedItem}
                  >
                    {
                      NOT_ALLOWED_TIME_TABLE[
                        time as keyof typeof NOT_ALLOWED_TIME_TABLE
                      ]
                    }
                  </TimeCheckBox>
                ))}
              </S.SettingTimeCheckList>
            </S.SettingTimeWrapper>
          </div>
        </S.Content>
      </DetailModal>
    );
  },
);

UnavailableTimeModal.displayName = "UnavailableTimeModal";

export default UnavailableTimeModal;
