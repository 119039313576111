import { useRef, useCallback } from "react";
import { useImmer } from "use-immer";

import { useCheckRequired, useToast } from "hooks";
import { useCreateFreqComment } from "services";
import { checkInputLength } from "utils";
import { TOAST_MSG, VALID_MESSAGE } from "constants/index";

interface FormStateProps {
  title: { value: string; error: string };
  content: { value: string; error: string };
}

const useAddFreqComment = (handleClose: () => void) => {
  const inputRefs = useRef<Record<string, HTMLInputElement>>({});

  const [form, setForm] = useImmer<FormStateProps>({
    title: { value: "", error: "" },
    content: { value: "", error: "" },
  });

  const { mutate: createFreqCommentMutate } = useCreateFreqComment();

  const { addToast } = useToast();

  const isSubmitDisabled =
    Object.values(form).filter((data) => data.error !== "").length > 0;

  const [checkRequired] = useCheckRequired(
    inputRefs,
    form,
    setForm,
    VALID_MESSAGE.REQUIRED_NO_ASTERISK,
  );

  const handleSubmit = useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      if (checkRequired()) return;

      setForm((draft) => {
        for (const key of Object.keys(form)) {
          draft[key as keyof FormStateProps].error = "";
        }
      });
      const req = {
        query: {
          title: form.title.value.trim(),
          content: form.content.value.trim(),
        },
      };

      createFreqCommentMutate(req, {
        onSuccess: () => {
          addToast(TOAST_MSG.SUCCESS.ADD_FREQ_CMNT_DONE);
          handleClose();
        },
      });
    },
    [form],
  );

  const handleChangeForm = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      const error = "";

      switch (name) {
        case "title":
          if (!checkInputLength("COMMENT_TITLE", value)) return;
          break;

        case "content":
          if (!checkInputLength("COMMENT_CONTENT", value)) return;
          break;
      }

      setForm((draft) => {
        draft[name as keyof FormStateProps].value = value;
        draft[name as keyof FormStateProps].error = error;
      });
    },
    [],
  );

  const handleBlurForm = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        value,
        name,
        dataset: { required },
      } = event.target;

      if (required && !value.trim()) {
        setForm((draft) => {
          draft[name as keyof FormStateProps].error =
            VALID_MESSAGE.REQUIRED_NO_ASTERISK;
        });
        return;
      }
    },
    [form],
  );

  const resetForm = useCallback(() => {
    setForm((draft) => {
      draft.title.value = "";
      draft.title.error = "";
      draft.content.value = "";
      draft.content.error = "";
    });
  }, []);

  return {
    inputRefs,
    form,
    isSubmitDisabled,
    handleSubmit,
    handleChangeForm,
    handleBlurForm,
    resetForm,
  };
};

export default useAddFreqComment;
