import React, { useState, forwardRef } from "react";

import { EyeIconImg } from "assets";
import * as S from "./PasswordInput.styled";

interface PasswordInputProps {
  className?: string;
  id: string;
  name?: string;
  value?: string;
  validError?: any;
  placeholder?: string;
  onChange?: any;
  onBlur?: any;
}

const PasswordInput = (
  { id, className, ...restProps }: PasswordInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleToggleShowPassword = () => {
    setIsShowPassword((prev) => !prev);
  };

  return (
    <S.Root className={className}>
      <S.Input
        type={isShowPassword ? "text" : "password"}
        ref={ref}
        id={id}
        {...restProps}
      />
      <S.ToggleButton
        type="button"
        aria-label={isShowPassword ? "비밀번호 숨기기" : "비밀번호 보기"}
        aria-pressed={isShowPassword}
        onClick={handleToggleShowPassword}
      >
        <EyeIconImg css={(theme) => S.eyeIcon(theme, isShowPassword ? 1 : 0)} />
      </S.ToggleButton>
    </S.Root>
  );
};

export default forwardRef(PasswordInput);
