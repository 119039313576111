import { css, Theme } from "@emotion/react";

export const root = (theme: Theme) => css`
  & > input[type="radio"] + label {
    ${theme.button.secondarySmall}
    height: 100%;
    cursor: pointer;
    user-select: none;

    &::before {
      content: none;
    }
  }

  & > input[type="radio"]:checked + label {
    ${theme.button.primarySmall};

    &:not(:disabled):hover {
      background-color: ${theme.color.blue_01};
    }

    &::before {
      content: none;
    }
  }

  input[type="radio"]:checked + label::after {
    display: none;
  }
`;
