import React from "react";
import { useSearchParams } from "react-router-dom";

import { useFilterParams, useSetMetaTags } from "hooks";
import { useGetReservationsMonthly } from "services";
import { WEEKS } from "constants/index";
import type { GetReservationsMonthlyQueryModel } from "types";
import DateBox from "../box/DateBox";
import * as S from "./ReservationCalendar.styled";

const MAX_GRID_CELL = 42;

interface ReservationCalendarProps {
  monthYear: any;
  handleSelectDate: any;
}

const ReservationCalendar = ({
  monthYear,
  handleSelectDate,
}: ReservationCalendarProps) => {
  const [searchParams] = useSearchParams();
  const { filters } = useFilterParams<
    GetReservationsMonthlyQueryModel["query"]
  >(["date"]);

  const { isSuccess, data: reservationInfo } = useGetReservationsMonthly({
    query: filters,
  });

  useSetMetaTags("정비관리 | 상세조회");

  const isLoadingDone =
    isSuccess && monthYear.value.format("YYYY-MM") === searchParams.get("date");
  return (
    <S.Root>
      <S.WeekWrapper>
        {WEEKS.map((day) => {
          return <S.WeekLi key={day}>{day}</S.WeekLi>;
        })}
      </S.WeekWrapper>
      <S.DateWrapper>
        {[...Array(monthYear.firstDOW)].map((_, i) => (
          <DateBox
            key={i}
            isLoadingDone={isLoadingDone}
            date={monthYear.firstWeekPrevMonthDate.add(i, "d")}
            disabled
            handleSelectDate={handleSelectDate}
          />
        ))}
        {[...Array(monthYear.lastDate)].map((_, i) => (
          <DateBox
            key={i}
            isLoadingDone={isLoadingDone}
            date={monthYear.startDate.add(i, "d")}
            reservationStates={reservationInfo?.[i]}
            handleSelectDate={handleSelectDate}
          />
        ))}
        {[
          ...Array(MAX_GRID_CELL - (monthYear.firstDOW + monthYear.lastDate)),
        ].map((_, i) => (
          <DateBox
            key={i}
            isLoadingDone={isLoadingDone}
            date={monthYear.nextMonthStartDate.add(i, "d")}
            disabled
            handleSelectDate={handleSelectDate}
          />
        ))}
      </S.DateWrapper>
    </S.Root>
  );
};

export default ReservationCalendar;
