import React from "react";

import {
  Select,
  Textarea,
  EstimatedTime,
  Input,
  RadioButton,
  DateSelectInput,
} from "components";
import { RESERVATION_REQUEST_INFO } from "assets";
import { INPUT_MAX_LENGTH } from "constants/index";
import * as S from "./RepairInfo.styled";

interface RepairInfoProps {
  inputRefs: any;
  data: any;
  handleChangeData: any;
  handleBlurData: any;
  handleSelectDate: any;
}

const RepairInfo = ({
  inputRefs,
  data,
  handleChangeData,
  handleBlurData,
  handleSelectDate,
}: RepairInfoProps) => {
  return (
    <S.Root>
      <S.InfoWrapper>
        {RESERVATION_REQUEST_INFO.map((item) => (
          <S.Column key={item.key}>
            <S.Label
              htmlFor={`repairInfo-input-${item.key}`}
              isRequired={item.isRequired}
            >
              {item.label}
            </S.Label>
            <S.ContentWrapper>
              {item.type === "text" ? (
                <>
                  <Input
                    css={S.input}
                    id={`repairInfo-input-${item.key}`}
                    ref={(el) => (inputRefs.current[item.key] = el)}
                    name={item.key}
                    value={data[item.key].value}
                    data-required={item.isRequired ? "true" : ""}
                    placeholder={item.placeholder}
                    suffixText={item?.suffixText}
                    onChange={handleChangeData}
                    onBlur={handleBlurData}
                  />
                  {item.suffixText && (
                    <S.SuffixText>{item.suffixText}</S.SuffixText>
                  )}
                </>
              ) : item.type === "select" ? (
                <Select
                  css={S.select}
                  id={item.key}
                  name={item.key}
                  required={item.isRequired}
                  forwardRef={(el: any) => (inputRefs.current[item.key] = el)}
                  labelTable={item.labelTable}
                  validError={data[item.key].error}
                  placeholder={item.placeholder}
                  selectedOption={data[item.key].value}
                  onSelect={handleChangeData}
                  onBlur={handleBlurData}
                >
                  {Object.entries(item.labelTable ?? {}).map(
                    ([value, label]: [string, any]) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    ),
                  )}
                </Select>
              ) : item.type === "radio" ? (
                <S.RadioWrapper>
                  {item.radioItems?.map((radio) => (
                    <RadioButton
                      css={S.radioButton}
                      key={radio.value}
                      id={`radio-repairInfo-${radio.value}`}
                      name={item.key}
                      value={radio.value}
                      checked={data[item.key].value === radio.value.toString()}
                      onChange={handleChangeData}
                    >
                      {radio.label}
                    </RadioButton>
                  ))}
                </S.RadioWrapper>
              ) : item.type === "textarea" ? (
                <Textarea
                  id={`repairInfo-input-${item.key}`}
                  name={item.key}
                  value={data[item.key].value}
                  maxLength={INPUT_MAX_LENGTH.USER_COMMENT}
                  placeholder={item.placeholder}
                  onChange={handleChangeData}
                />
              ) : item.type === "time" ? (
                <EstimatedTime
                  id={`repairInfo-${item.key}`}
                  onChange={handleChangeData}
                  onBlur={handleBlurData}
                >
                  {item.key === "plannedTimeHour" ? (
                    <EstimatedTime.SelectHour
                      id={item.key}
                      forwardRef={(el: any) =>
                        (inputRefs.current[item.key] = el)
                      }
                      name={item.key}
                      value={data[item.key].value}
                      required={item.isRequired}
                      validError={data[item.key].error}
                      label={item.hourLabel}
                    />
                  ) : (
                    <EstimatedTime.Hour
                      id={item.key}
                      forwardRef={(el: any) =>
                        (inputRefs.current[item.key] = el)
                      }
                      name={item.key}
                      value={data[item.key].value}
                      validError={data[item.key].error}
                      required={item.isRequired}
                      label={item.hourLabel}
                    />
                  )}
                  <EstimatedTime.SelectMin
                    css={S.minSelector(item.minKey!)}
                    id={item.minKey}
                    forwardRef={(el: any) =>
                      (inputRefs.current[item.minKey as any] = el)
                    }
                    name={item.minKey!}
                    value={data[item.minKey as any].value}
                    validError={data[item.minKey as any].error}
                    required={item.isRequired}
                    halfDisable={
                      item.minKey === "plannedTimeMin" &&
                      data.plannedTimeHour.value === 24
                    }
                    zeroDisable={
                      item.minKey === "estimatedTimeMin" &&
                      +data.estimatedTimeHour.value === 0
                    } // TODO Refactoring
                  />
                </EstimatedTime>
              ) : (
                <DateSelectInput
                  css={S.dateSelect}
                  id={`repairInfo-input-${item.key}`}
                  forwardRef={(el: any) => (inputRefs.current[item.key] = el)}
                  name={item.key}
                  value={data[item.key].value}
                  required={item.isRequired}
                  placeholder={item.placeholder}
                  validError={data[item.key].error}
                  isDisabledBeforeToday={true}
                  isReverse={true}
                  handleSelectDate={handleSelectDate(item.key)}
                  onBlur={handleBlurData}
                />
              )}
            </S.ContentWrapper>
          </S.Column>
        ))}
      </S.InfoWrapper>
    </S.Root>
  );
};

export default RepairInfo;
