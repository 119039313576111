import { useRef, useCallback, useEffect } from "react";
import { useImmer } from "use-immer";

import { useCheckRequired, useToast } from "hooks";
import { useCreateStaff } from "services";
import { checkInputLength, checkPhoneValidation } from "utils";
import { TOAST_MSG, VALID_MESSAGE } from "constants/index";

const INITIAL_STATE = {
  name: { value: "", error: "" },
  phoneLocal: { value: "010", error: "" },
  phone: { value: "", error: "" },
  position: { value: "", error: "" },
  isMechanic: { value: "y", error: "" },
  alimtalkYn: { value: "y", error: "" },
};

const useAddStaff = (handleModalClose: () => void) => {
  const inputRefs = useRef<any>({});
  const { mutate: createStaffMutate } = useCreateStaff();

  const [form, setForm] = useImmer(INITIAL_STATE);

  const isSubmitDisabled =
    Object.values(form).filter((data) => data.error !== "").length > 0;

  const { addToast } = useToast();
  const [checkRequired] = useCheckRequired(
    inputRefs,
    form,
    setForm,
    VALID_MESSAGE.REQUIRED_NO_ASTERISK,
  );

  const handleSubmit = useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      if (checkRequired()) return;

      setForm((draft: any) => {
        for (const key of Object.keys(form)) {
          draft[key].error = "";
        }
      });

      const body = Object.entries(form).reduce((acc, cur) => {
        const key = cur[0];
        const value = cur[1].value.trim();

        if (key === "phoneLocal") return { ...acc };

        return { ...acc, [key]: value };
      }, {} as any);

      body.phone = `${form.phoneLocal.value}${form.phone.value}`;

      const req = {
        body,
      };

      createStaffMutate(req, {
        onSuccess: () => {
          handleModalClose();
          addToast(TOAST_MSG.SUCCESS.ADD_STAFF_DONE);
        },
        onError: (err) => {
          if (!err.response) return;

          const { code, message } = err.response.data;
          if (code === "PHONE_ALREADY_EXISTS") {
            setForm((draft) => {
              draft.phone.error = message;
            });
            inputRefs.current["phone"].focus();
          }
        },
      });
    },
    [form],
  );

  const handleChangeForm = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
      const name = event?.target?.name ?? key;
      let value = event?.target?.value ?? event;
      let error = "";

      switch (name) {
        case "name":
          value = value.replaceAll(" ", "");
          if (!checkInputLength("NAME", value)) return;
          if (form[name].error) {
            error = VALID_MESSAGE.FAIL;
          }
          break;

        case "position": {
          if (!checkInputLength("STAFF_POSITION", value)) return;
          if (form[name].error) {
            error = VALID_MESSAGE.FAIL;
          }
          break;
        }

        case "phone":
          if (!checkInputLength("PHONE", value)) return;

          value = value.replace(/[^0-9]/g, "");

          if (form[name].error && !checkPhoneValidation(value)) {
            error = VALID_MESSAGE.PHONE_NUM;
          }
          break;
      }

      setForm((draft: any) => {
        draft[name].value = value;
        draft[name].error = error;
      });
    },
    [form],
  );

  const handleBlurForm = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const {
        value,
        name,
        dataset: { required },
      } = event.target;
      let error = "";

      if (required && form[name as keyof typeof form].value.length === 0) {
        setForm((draft: any) => {
          draft[name].error = VALID_MESSAGE.REQUIRED_NO_ASTERISK;
        });
        return;
      }

      switch (name) {
        case "phone":
          if (!checkPhoneValidation(value)) {
            error = VALID_MESSAGE.PHONE_NUM;
          }
          break;
        case "position":
          if (!value.trim()) {
            error = VALID_MESSAGE.REQUIRED_NO_ASTERISK;
          }
          break;
      }

      setForm((draft: any) => {
        draft[name].error = error;
      });
    },
    [form],
  );

  useEffect(() => {
    return () => {
      setForm(INITIAL_STATE);
    };
  }, []);

  return {
    inputRefs,
    form,
    isSubmitDisabled,
    handleSubmit,
    handleChangeForm,
    handleBlurForm,
  };
};

export default useAddStaff;
