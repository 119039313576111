import { useRef, useCallback, useEffect } from "react";
import { useImmer } from "use-immer";
import { isEmpty } from "lodash-es";

import { useCheckRequired, useToast } from "hooks";
import { useUpdateCustomer } from "services";
import {
  checkRegNumValidation,
  numericOnly,
  vinFormatter,
  checkVinValidation,
  deleteHyphen,
  getPhoneInfo,
  checkInputLength,
  checkVinExact,
} from "utils";
import { TOAST_MSG, VALID_MESSAGE } from "constants/index";
import type { UpdateCustomerQueryModel } from "types";

const INITIAL_STATE = {
  name: { value: "", error: "" },
  phoneLocal: { value: "010", error: "" },
  phone: { value: "", error: "" },
  vin: { value: "", error: "" },
  regNum: { value: "", error: "" },
  brandId: { value: "", error: "" },
  modelName: { value: "", error: "" },
  modelYear: { value: "", error: "" },
};

const useEditCustomer = (initData: any, handleModalClose: () => void) => {
  const inputRefs = useRef<any>({});

  const [form, setForm] = useImmer(INITIAL_STATE);

  const { mutate: updateCustomerMutate } = useUpdateCustomer();
  const { addToast } = useToast();

  const isSubmitDisabled =
    Object.values(form).filter((data) => data.error !== "").length > 0;

  const [checkRequired] = useCheckRequired(
    inputRefs,
    form,
    setForm,
    VALID_MESSAGE.REQUIRED_NO_ASTERISK,
  );

  const handleSubmit = useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      if (checkRequired()) return;

      setForm((draft: any) => {
        for (const key of Object.keys(form)) {
          draft[key].error = "";
        }
      });

      const body = Object.entries(form).reduce((acc, cur) => {
        const key = cur[0];
        const value = cur[1].value;

        if (key === "phoneLocal") return { ...acc };

        if (key === "modelYear") {
          return { ...acc, [key]: value };
        }

        return { ...acc, [key]: value.trim() };
      }, {} as UpdateCustomerQueryModel["body"]);

      body.regNum = form.regNum.value.replace(" ", "");
      body.phone = `${form.phoneLocal.value}${form.phone.value}`;

      const req = {
        customerId: initData?.customerId,
        body,
      };

      updateCustomerMutate(req, {
        onSuccess: () => {
          addToast(TOAST_MSG.SUCCESS.UPDATE_CUSTOMER_DONE);
          handleModalClose();
        },
        onError: (err) => {
          if (!err.response) return;

          const { code, message } = err.response.data;

          switch (code) {
            case "INVALID_MODEL_YEAR": {
              inputRefs.current.modelYear.focus();
              setForm((draft) => {
                draft.modelYear.error = message;
              });
              break;
            }

            case "REG_NUM_ALREADY_EXISTS": {
              inputRefs.current.regNum.focus();
              setForm((draft) => {
                draft.regNum.error = message;
              });
              break;
            }
          }
        },
      });
    },
    [form],
  );

  const handleChangeForm = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
      const name = event?.target?.name ?? key;
      let value = event?.target?.value ?? event;
      let error = "";

      switch (name) {
        case "name":
          value = value.replaceAll(" ", "");
          if (!checkInputLength("NAME", value)) return;
          break;

        case "regNum":
          value = value.replaceAll(" ", "");
          if (!checkInputLength("REGNUM", value)) return;
          value = value.trim();
          if (form[name].error && !checkRegNumValidation(value)) {
            error = VALID_MESSAGE.REGNUM;
          }
          break;

        case "phone":
          if (!checkInputLength("PHONE", value)) return;
          if (form[name].error && !checkRegNumValidation(value)) {
            error = VALID_MESSAGE.PHONE_NUM;
          }
          break;

        case "modelName":
          if (!checkInputLength("MODEL_NAME", value)) return;
          break;

        case "modelYear":
          value = numericOnly(value);
          if (!checkInputLength("MODEL_YEAR", value)) return;
          break;

        case "vin":
          value = vinFormatter(value);
          if (!checkInputLength("VIN", value)) return;
          if (form[name].error) {
            if (!checkVinExact(value) && value.length) {
              error = VALID_MESSAGE.VIN;
            } else if (!checkVinValidation(value)) {
              error = VALID_MESSAGE.VIN_INCORRECT;
            }
          }
          break;
      }

      setForm((draft: any) => {
        draft[name].value = value;
        draft[name].error = error;
      });
    },
    [form],
  );

  const handleBlurForm = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const {
        value,
        name,
        dataset: { required },
      } = event.target;
      let error = "";

      if (required && form[name as keyof typeof form].value.length === 0) {
        setForm((draft: any) => {
          draft[name].error = VALID_MESSAGE.REQUIRED_NO_ASTERISK;
        });
        return;
      }

      switch (name) {
        case "regNum":
          if (!checkRegNumValidation(value)) {
            error = VALID_MESSAGE.REGNUM;
          }
          break;

        case "phone":
          if (value.length < 7 || value.length > 12) {
            error = VALID_MESSAGE.PHONE_NUM;
          }
          break;

        case "vin":
          if (!checkVinExact(value) && value.length) {
            error = VALID_MESSAGE.VIN;
          } else if (!checkVinValidation(value)) {
            error = VALID_MESSAGE.VIN_INCORRECT;
          }
          break;
        case "modelName":
          if (!value.trim()) {
            error = VALID_MESSAGE.REQUIRED_NO_ASTERISK;
          }
          break;
      }

      setForm((draft: any) => {
        draft[name].error = error;
      });
    },
    [form],
  );

  useEffect(() => {
    if (!isEmpty(initData)) {
      const { areaCode, phone } = getPhoneInfo(
        deleteHyphen(initData.phone) || "",
      );

      setForm((draft: any) => {
        for (const key in initData) {
          if (draft[key]) {
            draft[key].value = initData[key] ?? "";
            draft[key].error = "";
          }
        }

        draft.vin.value = initData.vin
          ? checkVinValidation(initData.vin)
            ? initData.vin
            : ""
          : "";
        draft.phoneLocal.value = areaCode ?? "";
        draft.phone.value = phone;
      });
    }
  }, [initData]);

  return {
    inputRefs,
    form,
    isSubmitDisabled,
    handleSubmit,
    handleChangeForm,
    handleBlurForm,
  };
};

export default useEditCustomer;
