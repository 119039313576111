import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { calcSmallTableWidth, calcTableBodyMaxHeight } from "utils";

export const inquiryTable = (theme: Theme) => css`
  grid-template-columns: ${calcSmallTableWidth([130, 449, 180])};

  th:not(:last-of-type) {
    border-right: 1px solid ${theme.color.gray_03};
  }
`;

export const inquiryTableBody = css`
  min-height: 286.5px;
  max-height: calc(
    ${calcTableBodyMaxHeight([
      "22px",
      "16px",
      "77px",
      "8px",
      "28px",
      "24px",
      "4px",
      "10px",
    ])}
  );
`;

export const inquiryLink = (theme: Theme) => css`
  ${theme.button.secondarySmall}
  height: 36px;
  padding: 0px 14px;
`;

export const InquiryTBodyNoneData = styled.tbody`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  & > tr > td > svg {
    width: 54px;
    height: 54px;
  }
`;

export const inquiryNoHistory = (theme: Theme) => css`
  position: static;
  transform: none;

  & > span {
    ${theme.font.medium_16};
    margin-bottom: 16px;
    color: ${theme.color.gray_05};
  }
`;
