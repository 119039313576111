import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  LoadingSpinner,
  IntersectionArea,
  HasNoneData,
  CenterLayout,
} from "components";
import { useSetMetaTags } from "hooks";
import ReviewHeader from "./container/header/ReviewHeader";
import ReviewComment from "./container/ReviewComment";
import useLoadReview from "./container/hooks/useLoadReview";
import * as S from "./Review.styled";

const Review = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedSortOption, setSelectedSortOption] = useState(
    searchParams.get("sort") ?? "recent",
  );
  const [selectedSortAnsweredOption, setSelectedSortAnsweredOption] = useState(
    searchParams.get("answered") ?? "total",
  );

  const { totalCnt, totalAvg, reviews, isSuccess, loadMoreReviews } =
    useLoadReview();

  const handleSelectSortOption = (option: any) => {
    setSelectedSortOption(option);
    setSearchParams({
      ...Object.fromEntries(searchParams),
      sort: option,
    });
  };

  const handleSelectSortAnsweredOption = (option: any) => {
    setSelectedSortAnsweredOption(option);
    setSearchParams({
      ...Object.fromEntries(searchParams),
      answered: option,
    });
  };

  useSetMetaTags("리뷰관리 | 정비소");

  return (
    <CenterLayout>
      <ReviewHeader
        totalCount={totalCnt!}
        totalRating={totalAvg}
        selectedSortOption={selectedSortOption}
        handleSelectSortOption={handleSelectSortOption}
        selectedSortAnsweredOption={selectedSortAnsweredOption}
        handleSelectSortAnsweredOption={handleSelectSortAnsweredOption}
      />
      <S.ReviewContainer>
        {reviews?.length === 0 && isSuccess ? (
          <HasNoneData text="리뷰가 없습니다" />
        ) : (
          <ul>
            {isSuccess || reviews.length > 0 ? (
              reviews?.map((data: any, i: any) => (
                <ReviewComment
                  key={data?.reservationId + i}
                  isLastEl={totalCnt! - 1 === i}
                  reviewData={data}
                />
              ))
            ) : (
              <LoadingSpinner />
            )}
          </ul>
        )}
      </S.ReviewContainer>
      <IntersectionArea onImpression={loadMoreReviews} />
    </CenterLayout>
  );
};

export default Review;
