import React from "react";

import { Select, SearchInput, Button } from "components";
import { useModal } from "hooks";
import { STAFF_SORT_OPTION_TABLE } from "assets";
import StaffAddModal from "../staffmodal/staffadd/StaffAddModal";
import * as S from "./ManagementStaffHeader.styled";

interface ManagementStaffHeaderProps {
  selectedSortOption: string;
  handleSelectSortOption: (option: string) => void;
}

const ManagementStaffHeader = ({
  selectedSortOption,
  handleSelectSortOption,
}: ManagementStaffHeaderProps) => {
  const { modalRef, handleModalOpen, handleModalClose } = useModal();

  return (
    <S.Root>
      <S.TitleWrapper>
        <h3>직원 관리</h3>
      </S.TitleWrapper>
      <S.UtilWrapper>
        <Select
          css={S.sortSelect}
          id="staff-sort"
          selectedOption={selectedSortOption}
          labelTable={STAFF_SORT_OPTION_TABLE}
          onSelect={handleSelectSortOption}
        >
          {Object.entries(STAFF_SORT_OPTION_TABLE).map(([value, label]) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
        <SearchInput
          css={S.searchInput}
          id="staff"
          placeholder="직원 이름을 입력해주세요."
        />
        <Button
          css={S.addButton}
          onClick={handleModalOpen(
            <StaffAddModal
              ref={modalRef}
              handleModalClose={handleModalClose}
            />,
          )}
        >
          직원 추가
        </Button>
      </S.UtilWrapper>
    </S.Root>
  );
};

export default ManagementStaffHeader;
