import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;
  margin-bottom: 12px;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    ${theme.font.medium_18};
    color: ${theme.color.gray_09};
  `}
`;

export const sortSelect = css`
  width: 126px;
  margin-right: 6px;
`;
