import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: flex-start;
  margin-bottom: 8px;
`;

export const ReviewInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  margin-right: 15px;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.5;
`;

export const UtilWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  height: 38px;
`;

export const commentSettingButton = (theme: Theme) => css`
  height: 38px;

  & > svg > path {
    fill: ${theme.color.white};
  }
`;

export const sortSelect = css`
  width: 126px;

  & > button {
    height: 38px;
  }
`;
