import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Toast = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: ${theme.zIndex.MODAL};

    & > div:not(:first-of-type) {
      margin-top: 8px;
    }
  `}
`;
