import { useCallback, useEffect, useRef } from "react";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import dayjs from "dayjs";

import { useMonthYear } from "hooks";
import { PATH } from "constants/index";

const useReservationCalendar = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const isMounted = useRef(false);

  const {
    monthYear,
    changePrevMonthYear,
    changeNextMonthYear,
    changeCurrentMonthYear,
    changeMonthYear,
  } = useMonthYear(
    searchParams.get("date") ? dayjs(searchParams.get("date")) : dayjs(),
  );

  const handleSelectDate = useCallback(
    (date: any, tabName: any) => () => {
      const selectDate = date.format("YYYY-MM-DD");
      moveDetailPage(selectDate, tabName);
    },
    [],
  );

  const moveDetailPage = (date: any, tabName: any) => {
    navigate({
      pathname: `${PATH.reservation}`,
      search: `${createSearchParams({ tab: tabName, date })}`,
    });
  };

  useEffect(() => {
    if (monthYear.value) {
      if (isMounted.current) {
        const date = dayjs(monthYear.value).format("YYYY-MM");

        setSearchParams({ date });
      }
      isMounted.current = true;
    }
  }, [monthYear.value]);

  return {
    monthYear,
    changePrevMonthYear,
    changeNextMonthYear,
    changeCurrentMonthYear,
    changeMonthYear,
    handleSelectDate,
  };
};

export default useReservationCalendar;
