import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { STEP_COLOR_TABLE } from "constants/index";

export const Root = styled.ul`
  display: flex;
`;

export const Status = styled.li<{ status: string }>`
  ${({ theme, status }) => css`
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: ${theme.color.gray_07};

    &::before {
      content: "";
      height: 100%;
      margin-right: 4px;
      padding-right: 4px;
      border-radius: 2px;
      background-color: ${theme.color[
        STEP_COLOR_TABLE[status as keyof typeof STEP_COLOR_TABLE]
          ?.color as keyof typeof theme.color
      ]};
    }

    &:not(:last-of-type) {
      margin-right: 12px;
    }
  `}
`;
