import React, { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Table } from "components";
import { CUSTOMER_TABLE_DATA } from "assets";
import type { CustomerClientItem } from "types";
import * as S from "./CustomerTable.styled";

interface CustomerTableProps {
  isDone: boolean;
  customers?: CustomerClientItem[];
}

const CustomerTable = ({ isDone, customers }: CustomerTableProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleRouteToDetail = useCallback((id: string) => {
    navigate(`detail?customerId=${id}`);
  }, []);

  const makeHighlightSearchWord = (value: string | number, key: string) => {
    const searchWord = searchParams.get("q") ?? "";
    const regex = new RegExp(searchWord, "gim");
    const strText = "" + (value ?? "-");
    const changeText = strText.match(regex)
      ? strText.match(regex)?.[0]
      : strText;
    const appendWord =
      key === "totalVisits" || key === "noshows" ? `${strText}건` : strText;
    const text =
      searchWord.length === 0
        ? appendWord
        : appendWord.replaceAll(regex, `<mark>${changeText}</mark>`);

    return { __html: text };
  };

  return (
    <Table css={S.root} title="고객 목록">
      <Table.Head columnList={CUSTOMER_TABLE_DATA.columnList} />
      <Table.Body
        css={S.tableBody}
        isDone={isDone}
        data={customers}
        text="등록된 고객이 없습니다"
      >
        {customers?.map((rowData) => (
          <Table.ClickRow
            key={rowData.customerId}
            id={rowData.customerId}
            handleClick={handleRouteToDetail}
          >
            {CUSTOMER_TABLE_DATA.columnList.map((columnData) => (
              <Table.Cell css={S.cell} key={columnData.key}>
                <span
                  dangerouslySetInnerHTML={makeHighlightSearchWord(
                    rowData[columnData.key],
                    columnData.key,
                  )}
                />
              </Table.Cell>
            ))}
          </Table.ClickRow>
        ))}
      </Table.Body>
    </Table>
  );
};

export default CustomerTable;
