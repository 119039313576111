import React, { forwardRef } from "react";

import { Input } from "components";
import * as S from "./MaxLengthInput.styled";

interface MaxLengthInputProps {
  id?: string;
  name?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  value?: string;
  maxLength?: number;
  validError?: any;
  error?: any;
  onFocus?: any;
  onBlur?: any;
  onChange?: any;
}

const MaxLengthInput = forwardRef(
  (
    { className, value, maxLength, ...restProps }: MaxLengthInputProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <S.Root className={className}>
        <Input ref={ref} value={value} {...restProps} />
        {maxLength && (
          <span>
            {value?.length}/{maxLength}
          </span>
        )}
      </S.Root>
    );
  },
);

MaxLengthInput.displayName = "MaxLengthInput";

export default MaxLengthInput;
