import React, { memo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import * as S from "./CustomLink.styled";

interface CustomLinkProps {
  item: any;
}

const CustomLink = memo(({ item }: CustomLinkProps) => {
  const { navigatePath = "", includePath, Icon, content } = item;

  const location = useLocation();

  const selected = includePath.includes(location.pathname);

  return (
    <NavLink
      css={(theme) => S.link(theme, selected, content)}
      to={navigatePath}
      content={content}
    >
      <Icon />
    </NavLink>
  );
});

export default CustomLink;

CustomLink.displayName = "CustomLink";
