import React, { useCallback } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { ConfirmModal } from "components";
import { useToast } from "hooks";
import { useSendAuthLink } from "services";
import { PATH, TOAST_MSG } from "constants/index";
import * as S from "./LinkTimeExpiredModal.styled";
interface LinkTimeExpiredModalProps {
  handleModalClose: () => void;
}

const LinkTimeExpiredModal = React.forwardRef(
  (
    { handleModalClose }: LinkTimeExpiredModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { mutate: sendAuthLinkMutate } = useSendAuthLink();
    const { addToast } = useToast();

    const handleResendLink = useCallback(() => {
      const req = {
        body: {
          email: searchParams.get("email")!,
        },
      };
      sendAuthLinkMutate(req, {
        onSuccess: () => {
          addToast(TOAST_MSG.SUCCESS.SEND_AUTH_LINK_DONE);
          handleModalClose();
          navigate(PATH.login);
        },
      });
    }, []);

    return (
      <ConfirmModal
        ref={ref}
        css={S.confirmModal}
        title="유효하지 않은 링크"
        desc={`유효시간이 만료되었거나 잘못된 링크입니다.\n재전송버튼을 눌러 인증메일을 다시 확인하거나\n트럭닥터 고객센터로 문의바랍니다 :)`}
        activeBtnName="재전송"
        activeFn={handleResendLink}
      />
    );
  },
);

LinkTimeExpiredModal.displayName = "LinkTimeExpiredModal";

export default LinkTimeExpiredModal;
