import React, { useMemo } from "react";
import dayjs from "dayjs";

import { useFilterParams } from "hooks";
import { useGetReservationsDaily } from "services";
import { formatUtcISODateTime } from "utils";
import type { GetReservationsDailyQueryModel } from "types";
import * as S from "./TabList.styled";

interface TabListProps {
  tabInfo: any;
  currentTab: any;
  handleChangeTab: any;
}

const TabList = ({ tabInfo, currentTab, handleChangeTab }: TabListProps) => {
  const { filters } = useFilterParams<GetReservationsDailyQueryModel["query"]>(
    ["date", "status", "page", "tab"],
    {
      date: formatUtcISODateTime(dayjs().format("YYYY-MM-DD"), "YYYY-MM-DD"),
      tab: "planned",
    },
  );

  const { data: reservationInfo } = useGetReservationsDaily(
    {
      query: filters,
    },
    { keepPreviousData: true },
  );

  const TabListAmount = useMemo(
    () =>
      Object.values(reservationInfo?.statusCount ?? {}).map((cnt) =>
        cnt ? cnt : 0,
      ),
    [reservationInfo?.statusCount],
  );

  return (
    <S.Root role="tablist">
      {Object.values(tabInfo).map((tab: any, idx) => {
        const selected = currentTab === tab.value;
        return (
          <S.Tab
            key={tab.value}
            role="tab"
            aria-controls={tab.panel}
            aria-selected={selected}
          >
            <button type="button" onClick={handleChangeTab(tab.value)}>
              <span>
                {tab.label} {TabListAmount[idx] || 0}
              </span>
              <S.Line data-select={selected} />
            </button>
          </S.Tab>
        );
      })}
    </S.Root>
  );
};

export default TabList;
