import { useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useImmer } from "use-immer";

import { useCheckRequired, useToast } from "hooks";
import { useChangePassword, useDelayPwRetentionPeriod } from "services";
import { repairShopInfo } from "store";
import {
  checkPasswordValidation,
  checkPasswordConfirmValidation,
  checkInputLength,
  auth,
  makeCryptoFunction,
} from "utils";
import { PATH, TOAST_MSG, VALID_MESSAGE } from "constants/index";
import type { RepairShopInfo } from "types";

const useChangePasswordForm = (handleModalClose?: () => void) => {
  const navigate = useNavigate();

  const inputRefs = useRef<any>({});

  const [form, setForm] = useImmer({
    originPassword: { value: "", error: "" },
    newPassword: { value: "", error: "" },
    newPasswordConfirm: { value: "", error: "" },
  });

  const [user, setUser] = useRecoilState<RepairShopInfo>(repairShopInfo);
  const { addToast } = useToast();

  const [checkRequired] = useCheckRequired(inputRefs, form, setForm);
  const { mutate: changePasswordMutate } = useChangePassword();
  const { mutate: delayPwRetentionPeriodMutate } = useDelayPwRetentionPeriod();

  const isSubmitDisabled =
    form.originPassword.error ||
    form.newPassword.error ||
    form.newPasswordConfirm.error;

  const handleSubmit = useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      if (checkRequired()) return;
      if (validCheck()) return;

      const req = {
        body: {
          password: makeCryptoFunction(form.originPassword.value),
          newPassword: makeCryptoFunction(form.newPassword.value),
        },
      };

      changePasswordMutate(req, {
        onSuccess: () => {
          auth.clear();
          handleModalClose && handleModalClose();
          addToast(TOAST_MSG.SUCCESS.CHANGE_PASSWORD_DONE);
          navigate(PATH.login);
        },
        onError: (err) => {
          switch (err.response?.data.code) {
            case "PASSWORD_MISMATCHED":
              setForm((draft) => {
                draft.originPassword.error = VALID_MESSAGE.PW_C;
              });
              inputRefs.current["originPassword"]?.focus();
              break;

            case "CANNOT_USE_SAME_PASSWORD":
              setForm((draft) => {
                draft.newPassword.error = VALID_MESSAGE.CHANGE_PW;
                draft.newPasswordConfirm.error = VALID_MESSAGE.CHANGE_PW;
              });
              inputRefs.current["newPassword"]?.focus();
              break;
          }
        },
      });
    },
    [form],
  );

  const validCheck = () => {
    let result = false;
    if (!checkPasswordValidation(form.newPassword.value)) {
      setForm((draft) => {
        draft.newPassword.error = VALID_MESSAGE.PW;
      });
      result = true;
    }

    if (form.newPassword.value !== form.newPasswordConfirm.value) {
      setForm((draft) => {
        draft.newPasswordConfirm.error = VALID_MESSAGE.PW_C;
      });
      result = true;
    }

    return result;
  };

  const handleChangeForm = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      let error = "";

      switch (name) {
        case "originPassword":
        case "newPassword":
          if (!checkInputLength("PASSWORD", value)) return;
          if (form[name].error && !checkPasswordValidation(value)) {
            error = VALID_MESSAGE.PW;
          }
          break;

        case "newPasswordConfirm":
          if (!checkInputLength("PASSWORD", value)) return;
          if (
            form[name].error &&
            !checkPasswordConfirmValidation(value, form.newPassword.value)
          ) {
            error = VALID_MESSAGE.PW_C;
          }
          break;
      }

      setForm((draft: any) => {
        draft[name].value = value;
        draft[name].error = error;
      });
    },
    [form],
  );

  const handleBlurForm = useCallback(
    (event: any) => {
      const {
        name,
        value,
        dataset: { required },
      } = event.target;
      let error = "";

      if (required && value.length === 0) {
        setForm((draft: any) => {
          draft[name].error = VALID_MESSAGE.REQUIRED;
        });

        return;
      }

      switch (name) {
        case "originPassword":
          !checkPasswordValidation(value) && (error = VALID_MESSAGE.PW);
          break;
        case "newPassword":
          if (!checkPasswordValidation(value)) {
            error = VALID_MESSAGE.PW;
          } else if (
            !checkPasswordConfirmValidation(
              value,
              form.newPasswordConfirm.value,
            )
          ) {
            setForm((draft) => {
              draft.newPasswordConfirm.error = VALID_MESSAGE.PW_C;
            });
          } else {
            setForm((draft) => {
              draft.newPasswordConfirm.error = "";
            });
          }
          break;

        case "newPasswordConfirm":
          !checkPasswordConfirmValidation(value, form.newPassword.value) &&
            (error = VALID_MESSAGE.PW_C);
          break;
      }

      setForm((draft: any) => {
        draft[name].error = error;
      });
    },
    [form],
  );

  const handleNextChange = useCallback(() => {
    delayPwRetentionPeriodMutate(undefined, {
      onSuccess: (data) => {
        const userInfo = {
          ...user,
          pwUpdated: data.pwUpdated,
        };

        setUser(userInfo);
        typeof handleModalClose === "function" && handleModalClose();
      },
    });
  }, []);

  return {
    inputRefs,
    form,
    isSubmitDisabled,
    handleSubmit,
    handleChangeForm,
    handleBlurForm,
    handleNextChange,
  };
};

export default useChangePasswordForm;
