import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 12px;
  margin-bottom: 12px;
`;

export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_18};
    width: 100%;
    border-bottom: 1px solid ${theme.color.gray_02};
    padding-bottom: 8px;
    color: ${theme.color.gray_09};
  `}
`;

export const SearchFilterWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const sortSelect = css`
  width: 130px;

  & > button {
    height: 36px;
    border-radius: 3px;
    padding: 0 7px 0 11px;
  }
`;

export const linkButton = (theme: Theme) => css`
  ${theme.button.primarySmall};
  height: 36px;

  > svg {
    fill: ${theme.color.white};
  }
`;
