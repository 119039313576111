import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  & > div {
    display: flex;
    width: 100%;
  }
`;

export const Label = styled.label<{ isRequired: boolean }>`
  ${({ isRequired, theme }) => css`
    ${theme.font.regular_14};
    display: block;
    min-width: 136px;
    margin-bottom: 6px;
    color: ${theme.color.gray_07};

    &::after {
      content: ${isRequired ? "'*'" : null};
      display: inline-block;
      margin-left: 3px;
      color: ${theme.color.error_01};
    }
  `}
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    width: 100%;
  `}
`;

export const estimatedTime = css`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TimeWrapper = styled.div`
  display: flex;
`;

export const hourSelector = css`
  &:not(:last-of-type) {
    margin-right: 9px;
  }
`;

export const minSelector = (name: any) => css`
  & > div {
    width: ${name === "plannedTimeMin" && "70px"};
  }
`;

export const dateSelect = css`
  display: flex;
  flex-direction: column;
  width: 184px;

  && dialog {
    top: 37px;
    left: -236px;

    &::before,
    &::after {
      right: 95px;
    }
  }
`;

export const plannedTime = css`
  margin-top: 4px;

  & > div:first-of-type {
    margin-right: 9px;
  }
`;

export const plannedHour = css`
  & > div {
    width: 78px;
  }
`;

export const plannedMin = css`
  & > div {
    width: 78px;
  }
`;

export const ValidText = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_12};
    margin-top: 6px;
    color: ${theme.color.error_01};
  `}
`;
