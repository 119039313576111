import { useQuery } from "@tanstack/react-query";

import { getNoticeDetailAPI, getNoticesAPI } from "apis";
import { formatDate } from "utils";
import type { GetNoticesQueryModel } from "types";

const noticeKeys = {
  all: ["faq"] as const,
  lists: () => [...noticeKeys.all, "list"],
  list: (filters: GetNoticesQueryModel) => [...noticeKeys.lists(), { filters }],
  details: () => [...noticeKeys.all, "detail"],
  detail: (noticeId: string) => [...noticeKeys.details(), noticeId],
};

export const useGetNotices = (filters: GetNoticesQueryModel) => {
  return useQuery({
    queryKey: noticeKeys.list(filters),
    queryFn: () => getNoticesAPI(filters),
    select: (res) => ({
      notices: res.notices.map((notice) => ({
        ...notice,
        created: formatDate(notice.created, "YY.MM.DD. HH:mm"),
      })),
      pageInfo: res.pageInfo,
    }),
  });
};

export const useGetNoticeDetail = (req: string) => {
  return useQuery({
    queryKey: noticeKeys.detail(req),
    queryFn: () => getNoticeDetailAPI(req),
    select: (res) => ({
      ...res,
      created: formatDate(res.created, "YYYY.MM.DD"),
    }),
  });
};
