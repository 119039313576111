import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Button, LoadingSpinner } from "components";
import { comma } from "utils";
import { PATH } from "constants/index";
import type { GetDiagnosisSolutionServerModel } from "types";
import * as S from "./DiagnosisSolutionResult.styled";

interface DiagnosisSolutionResultProps {
  data?: GetDiagnosisSolutionServerModel["damage"];
}

const DiagnosisSolutionResult = ({ data }: DiagnosisSolutionResultProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleBackToReservationDetail = (): void => {
    navigate(
      `${PATH.reservation}/detail?reservationId=${searchParams.get(
        "reservationId",
      )}`,
    );
  };
  return (
    <S.Wrapper>
      <S.Title>진단 결과</S.Title>
      {data ? (
        <>
          <S.ContentWrapper>
            <S.ContentTitle>Breakage(파손)</S.ContentTitle>
            <S.ContentPrice>{comma(data.breakage)}원</S.ContentPrice>
            <S.ContentTitle>Crushed(찌그러짐)</S.ContentTitle>
            <S.ContentPrice>{comma(data.crushed)}원</S.ContentPrice>
            <S.ContentTitle>Scratch(스크래치)</S.ContentTitle>
            <S.ContentPrice>{comma(data.scratch)}원</S.ContentPrice>
            <S.ContentTitle>Separated(이격)</S.ContentTitle>
            <S.ContentPrice>{comma(data.separated)}원</S.ContentPrice>
            <S.ContentTitle>예상 수리 비용</S.ContentTitle>
            <S.ContentPrice isTotal>{comma(data.totalCost)}원</S.ContentPrice>
          </S.ContentWrapper>
          <Button
            variant="primaryLarge"
            onClick={handleBackToReservationDetail}
          >
            확인
          </Button>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </S.Wrapper>
  );
};

export default DiagnosisSolutionResult;
