import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const baseModal = (theme: Theme) => css`
  width: 666px;
  border-top: 6px solid ${theme.color.blue_01};
  border-radius: 4px;
  background-color: ${theme.color.white};
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 35px;
`;

export const closeButton = css`
  position: absolute;
  right: 24px;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.font.bold_16};
    color: ${theme.color.blue_01};
  `}
`;

export const Desc = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_06};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    border-top: 1px solid ${theme.color.gray_03};
    border-bottom: 1px solid ${theme.color.gray_03};
    color: ${theme.color.gray_06};
    background-color: ${theme.color.gray_00};
  `}
`;

export const BottomWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 6px;
    border-radius: 0 0 4px 4px;
    padding: 12px 0;
    background-color: ${theme.color.gray_00};
  `}
`;
