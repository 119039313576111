import { v4 as uuidv4 } from "uuid";

import type {
  GetInquiryQueryModel,
  GetInquiryServerModel,
  GetInquiryDetailQueryModel,
  CreateInquiryQueryModel,
} from "types";
import { ax } from "../axios";
import { getPresignedUrlAPI, postPresignedUrlAPI } from "../common/common";

export const getInquirysAPI = async (
  req: GetInquiryQueryModel,
): Promise<GetInquiryServerModel> => {
  const params = {
    page: req.query.page,
    category: req.query.category,
  };

  const { data } = await ax.get("/helps", { params });

  return data;
};

export const createInquiryAPI = async (req: CreateInquiryQueryModel) => {
  const attachFileList: string[] = [];
  const postPresignedFileList = req.body.attach.map(async (file, index) => {
    const extionsion = file.type.split("/")[1];
    attachFileList.push(`help/${uuidv4()}.${extionsion}`);

    return await postPresignedUrlAPI({
      s3Key: attachFileList[index],
      file,
    });
  });

  await Promise.all(postPresignedFileList);

  const attach = attachFileList.map((url) => url.replace("help/", ""));
  const reqData = {
    body: {
      title: req.body.title,
      content: req.body.content,
      repairShop: req.body.repairShop,
      category: req.body.category,
      phone: req.body.phone,
      attach,
      email: req.body.email,
      name: req.body.name,
    },
  };

  return await ax.post("/helps", reqData.body);
};

export const getInquiryDetailAPI = async (
  helpId: string,
): Promise<GetInquiryDetailQueryModel> => {
  const { data } = await ax.get(`/helps/${helpId}`);

  const files = [data.attach1, data.attach2, data.attach3];

  const presignedDatas = await Promise.all(
    files.map(async (url) => await getPresignedUrlAPI(url)),
  );

  const attachedFileList = presignedDatas.map((url) => url ?? null);

  return {
    ...data,
    attachedFileList,
  };
};
