import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Pagination } from "components";
import { useFilterParams } from "hooks";
import { useGetFaqs } from "services";
import { FAQ_FILTER } from "assets";
import type { GetFaqsQueryModel } from "types";
import FaQTable from "./Container/table/FaQTable";
import FaQHeader from "./Container/header/FaQHeader";
import * as S from "./FaQ.styled";

const FaQ = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedSortOption, setSelectedSortOption] = useState("all");

  const category = searchParams.get("category");
  const { filters } = useFilterParams<GetFaqsQueryModel["query"]>(
    category !== "전체" ? ["category", "page"] : ["page"],
    { page: "1", pageSize: "20" },
  );

  const { data, isSuccess } = useGetFaqs({
    query: filters,
  });

  const handleSelectSortOption = useCallback(
    (option: string) => {
      setSelectedSortOption(option);
      setSearchParams({
        ...Object.fromEntries(searchParams),
        category: FAQ_FILTER[option as keyof typeof FAQ_FILTER],
      });
    },
    [searchParams, setSearchParams, setSelectedSortOption],
  );

  useEffect(() => {
    const categoryInUrl = searchParams.get("category");
    Object.entries(FAQ_FILTER).forEach(
      ([key, value]) => categoryInUrl === value && setSelectedSortOption(key),
    );
  }, [searchParams]);

  return (
    <S.Root>
      <FaQHeader
        selectedSortOption={selectedSortOption}
        handleSelectSortOption={handleSelectSortOption}
      />
      <FaQTable isDone={isSuccess} faqs={data?.faqs ?? []} />
      {isSuccess && <Pagination pageInfo={data?.pageInfo} />}
    </S.Root>
  );
};

export default FaQ;
