import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const cell = (theme: Theme) => css`
  &:not(:last-of-type) {
    border-right: 1px solid ${theme.color.gray_03};
  }
`;

export const Status = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_13};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 26px;
    border-radius: 4px;
    color: ${theme.color.gray_05};
    background-color: ${theme.color.gray_02};

    &[data-status="true"] {
      color: ${theme.color.blue_01};
      background-color: ${theme.color.blue_02};
    }
  `}
`;

export const detailLink = (theme: Theme) => css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:hover {
    background-color: ${theme.color.point_01_opacity_10};
  }
`;
