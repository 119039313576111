import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
`;

export const DateSelectorWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    & > p {
      align-self: flex-end;
      margin: 0 0 2px 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: -0.02em;
      color: ${theme.color.gray_04};
    }
  `}
`;
