import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { mixinSettingArea, mixin_scrollbar } from "style/mixin";

export const Root = styled.article`
  ${({ theme }) => css`
    ${mixinSettingArea(theme)};
    height: fit-content;
    padding: 20px 0;
  `}
`;

const mixinLine = (theme: Theme, posY: string) => css`
  ::before {
    content: "";
    position: absolute;
    left: 39px;
    ${posY}: 0;
    height: 1px;
    width: calc(100% - 80px);
    border-bottom: 1px solid ${theme.color.gray_02};
  }
`;

export const Header = styled.header`
  ${({ theme }) => css`
    ${mixinLine(theme, "bottom")}
    ${theme.font.medium_18};
    position: relative;
    padding: 0 40px 8px 40px;
  `}
`;

export const Container = styled.form`
  ${({ theme }) => css`
    ${mixin_scrollbar(theme)};
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    max-height: calc(
      100vh - 54px - 40px - 22px - 16px - 64px - 36px - 60px - 56px - 59px
    );
    padding: 20px 40px;
  `}
`;

export const InputWrapper = styled.dl`
  display: flex;
  flex-direction: column;

  & > dt {
    margin-bottom: 6px;
  }
`;

export const LabelWrapper = styled.dd`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_07};

    span {
      color: ${theme.color.error_01};
    }
  `}
`;

export const select = css`
  width: 465px;

  & > ul {
    max-height: 133px;

    > li > button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 32px;
    }
  }
`;

export const mobileInput = css`
  width: 465px;

  & > input {
    height: 34px;
  }
`;

export const input = (name: string) => css`
  width: ${name === "title" ? "100% " : "465px"};
  height: 34px;
`;

export const textarea = css`
  height: 150px;
  min-height: 150px;
`;

export const ValidText = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_12};
    margin-top: 4px;
    color: ${theme.color.error_01};
  `}
`;

export const SubmitWrapper = styled.div`
  ${({ theme }) => css`
    ${mixinLine(theme, "top")}
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 12px;
    padding-top: 20px;

    & > div {
      display: flex;
      align-items: center;
      column-gap: 6px;

      a {
        ${theme.font.regular_14};
        margin: 0;
        padding: 0;
        color: ${theme.color.blue_01};
        text-decoration: underline;
      }
    }
  `}
`;

export const button = css`
  min-width: 100px;
`;
