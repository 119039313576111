import React from "react";

import { Select } from "components";
import { FAQ_FILTER } from "assets";
import * as S from "./FaQHeader.styled";

interface FaQHeaderProps {
  selectedSortOption: string;
  handleSelectSortOption: (option: string) => void;
}

const FaQHeader = ({
  selectedSortOption,
  handleSelectSortOption,
}: FaQHeaderProps) => {
  return (
    <S.Root>
      <S.Title>자주 묻는 질문(FAQ)</S.Title>
      <Select
        css={S.sortSelect}
        id="staff-sort"
        labelTable={FAQ_FILTER}
        selectedOption={selectedSortOption}
        onSelect={handleSelectSortOption}
      >
        {Object.entries(FAQ_FILTER).map(([value, label]) => {
          return (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          );
        })}
      </Select>
    </S.Root>
  );
};

export default FaQHeader;
