import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.dl`
  padding-bottom: 28px;
`;

export const InfoItem = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: grid;
    grid-template-columns: 132px 1fr;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    & > dt {
      color: ${theme.color.gray_06};

      &::after {
        display: inline-block;
        margin-left: 3px;
        font-size: 14px;
        color: ${theme.color.error_01};
      }
    }

    & > dd {
      position: relative;
      display: flex;
      color: ${theme.color.gray_09};
    }
  `}
`;

export const updateDialogButton = (theme: Theme) => css`
  ${theme.button.ghostPrimary};
  margin-left: 9px;
`;

export const StaffsBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  width: 100%;
  column-gap: 9px;

  & > button {
    margin-left: 0;
  }
`;

export const Staff = styled.span`
  position: relative;
  line-height: 1.43;
`;

const updateDialogMixin = css`
  top: 20px;
  left: 0;
`;

export const updateStaffDialog = css`
  ${updateDialogMixin};
`;

export const updateCostDialog = css`
  ${updateDialogMixin};
`;

export const textarea = css`
  min-height: 262px;
`;
