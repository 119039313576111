import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const DateWrapper = styled.div`
  display: flex;
`;

export const dateInput = (theme: Theme) => css`
  ${theme.font.regular_14};
  padding: 0 32px 0 12px;

  &[readOnly] {
    color: ${theme.color.gray_10};
    background-color: ${theme.color.white};
  }
`;

export const calendar = (isReverse: boolean) => css`
  top: ${isReverse ? "36px" : "34px"};
  left: -278px;

  &&::before,
  &&::after {
    left: unset;
    right: 28px;
    ${isReverse
      ? { transform: "rotate(180deg)", top: "unset", bottom: "-19px" }
      : { transform: "rotate(0)" }}
  }
`;

export const calendarBtn = css`
  && {
    height: 34px;
  }
  position: absolute;
  right: 9px;
  border: none;
  background-color: inherit;
`;
