import React from "react";

import { ConfirmModal } from "components";
import * as S from "./TruckSearchResultModal.styled";

interface TruckSearchResultModalProps {
  handleCancel: () => void;
  handleClose?: () => void;
  handleActive: () => void;
}

const TruckSearchResultModal = React.forwardRef(
  (
    { handleCancel, handleClose, handleActive }: TruckSearchResultModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    return (
      <ConfirmModal
        ref={ref}
        css={S.baseModal}
        title="차량 조회 결과"
        desc={`해당 차량의 정비 완료 기록이 확인되었습니다.\n작성하신 정보를 삭제하고 정비 이력에 저장된 정보를 불러올까요?`}
        cancelBtnName="차량번호만 변경"
        activeBtnName="불러오기"
        cancelFn={handleCancel}
        closeFn={handleClose}
        activeFn={handleActive}
      />
    );
  },
);

TruckSearchResultModal.displayName = "TruckSearchResultModal";

export default TruckSearchResultModal;
