import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Header = styled.div`
  ${({ theme }) => css`
    position: sticky;
    top: ${theme.size.HEADER_HEIGHT};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0 16px;
    background-color: ${theme.color.background};
    z-index: 11;

    & > h3 {
      ${theme.font.bold_18};
      color: ${theme.color.gray_09};
    }

    & > time {
      ${theme.font.regular_14};
      color: ${theme.color.gray_05};
    }
  `}
`;

export const Form = styled.form`
  display: flex;
  column-gap: 16px;
  justify-content: space-between;
`;

export const LeftContainer = styled.div`
  width: 100%;
`;

export const RightContainer = styled.div`
  position: sticky;
  top: 128px;
  min-width: 434px;
  width: 434px;
  height: max-content;
`;

export const FieldWrapper = styled.article`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border: 1px solid ${theme.color.gray_02};
    border-radius: 3px;
    padding: 28px 32px;
    background-color: ${theme.color.white};

    &[data-status="default"] {
      padding: 28px 32px;
    }

    & > h4 {
      ${theme.font.medium_16};
      margin-bottom: 20px;
      color: ${theme.color.gray_10};
    }
  `}
`;

export const btn = css`
  align-self: flex-end;
  margin-top: 7px;
`;
