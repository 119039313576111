import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";

import {
  getReviewsAPI,
  getFreqCommentsAPI,
  deleteFreqCommentAPI,
  createFreqCommentAPI,
  updateFreqCommentsAPI,
  createCommentByReviewIdAPI,
  updateCommentByReviewIdAPI,
  deleteCommentByReviewIdAPI,
  reportReviewAPI,
} from "apis";
import type {
  APIError,
  GetReviewQueryModel,
  CreateFreqCommentQueryModel,
  UpdateFreqCommentQueryModel,
  CreateReviewQueryModel,
  UpdateReviewQueryModel,
  DeleteReviewQueryModel,
} from "types";

export const reviewKeys = {
  all: ["review"] as const,
  lists: () => [...reviewKeys.all, "list"],
  list: (filters: GetReviewQueryModel) => [...reviewKeys.lists(), { filters }],
  freqLists: () => [...reviewKeys.all, "freqList"],
  freqList: () => [...reviewKeys.freqLists()],
};

export const useGetReviews = (filters: GetReviewQueryModel) => {
  return useQuery({
    keepPreviousData: true,
    queryKey: reviewKeys.list(filters),
    queryFn: () => getReviewsAPI(filters),
  });
};

export const useGetFreqComments = () => {
  return useQuery({
    queryKey: reviewKeys.freqList(),
    queryFn: () => getFreqCommentsAPI(),
  });
};

export const useDeleteFreqComment = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, string>({
    mutationFn: (freqCommentId) => deleteFreqCommentAPI(freqCommentId),
    onSuccess: () => {
      queryClient.invalidateQueries(reviewKeys.freqLists());
    },
  });
};

export const useCreateFreqComment = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, CreateFreqCommentQueryModel>({
    mutationFn: (req) => createFreqCommentAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(reviewKeys.freqLists());
    },
  });
};

export const useUpdateFreqComment = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, UpdateFreqCommentQueryModel>({
    mutationFn: (req) => updateFreqCommentsAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(reviewKeys.freqLists());
    },
  });
};

export const useCreateCommentByReviewId = () => {
  return useMutation<unknown, APIError, CreateReviewQueryModel>({
    mutationFn: (req) => createCommentByReviewIdAPI(req),
  });
};

export const useUpdateCommentByReviewId = () => {
  return useMutation<unknown, APIError, UpdateReviewQueryModel>({
    mutationFn: (req) => updateCommentByReviewIdAPI(req),
  });
};

export const useDeleteCommentByReviewId = () => {
  return useMutation<unknown, APIError, string>({
    mutationFn: (req) => deleteCommentByReviewIdAPI(req),
  });
};

export const useReportReview = () => {
  return useMutation<unknown, APIError, DeleteReviewQueryModel>({
    mutationFn: (req) => reportReviewAPI(req),
  });
};
