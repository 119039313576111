import React from "react";
import { Link } from "react-router-dom";

import { Table, HasNoneData } from "components";
import { INQUIRY_TABLE_LIST } from "assets";
import SettingInquiryTableRow from "./tableRow/SettingInquiryTableRow";
import * as S from "./SettingInquiryTable.styled";

interface SettingInquiryTableProps {
  isDone: boolean;
  inquiries: any[];
}

export default function SettingInquiryTable({
  isDone,
  inquiries,
}: SettingInquiryTableProps) {
  return (
    <Table css={S.inquiryTable} title="문의 내역 리스트">
      <Table.Head columnList={INQUIRY_TABLE_LIST} />
      <Table.Body
        css={S.inquiryTableBody}
        isDone={isDone}
        data={inquiries}
        text="등록된 문의 내역이 없습니다"
        PropsTbodyHasNoneData={InquiryTBodyNoneData}
      >
        {inquiries.map((rowData) => (
          <SettingInquiryTableRow
            rowData={rowData}
            key={rowData.helpId}
            columnList={INQUIRY_TABLE_LIST}
          />
        ))}
      </Table.Body>
    </Table>
  );
}

const InquiryTBodyNoneData = () => {
  return (
    <S.InquiryTBodyNoneData>
      <tr>
        <HasNoneData
          css={S.inquiryNoHistory}
          asProp="td"
          text="아직 문의하신 내용이 없어요!"
        >
          <Link css={S.inquiryLink} to="create">
            1:1 문의하기
          </Link>
        </HasNoneData>
      </tr>
    </S.InquiryTBodyNoneData>
  );
};
