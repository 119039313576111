import React from "react";

import { Button } from "components";
import { useModal } from "hooks";
import * as S from "./MenuDialog.styled";

interface MenuDialogProps {
  rowData: any;
  editCompo: any;
  delCompo: any;
}

const MenuDialog = ({
  rowData,
  editCompo: EditCompo,
  delCompo: DelCompo,
}: MenuDialogProps) => {
  const {
    modalRef: editModalRef,
    handleModalOpen: handleEditModalOpen,
    handleModalClose: handleEditModalClose,
  } = useModal();

  const {
    modalRef: delModalRef,
    handleModalOpen: handleDelModalOpen,
    handleModalClose: handleDelModalClose,
  } = useModal();

  return (
    <>
      <Button
        css={S.deleteBtn}
        variant="warningMedium"
        onClick={handleDelModalOpen(
          <DelCompo
            ref={delModalRef}
            staffId={rowData.staffId}
            handleModalClose={handleDelModalClose}
          />,
        )}
      >
        삭제
      </Button>
      <Button
        css={S.editBtn}
        variant="secondarySmall"
        onClick={handleEditModalOpen(
          <EditCompo
            ref={editModalRef}
            data={rowData}
            handleModalClose={handleEditModalClose}
          />,
        )}
      >
        수정
      </Button>
    </>
  );
};

export default MenuDialog;
