import React from "react";
import { Link } from "react-router-dom";

import { Table, HasNoneData } from "components";
import { FAQ_TABLE_LIST } from "assets";
import { FAQ_NONE_DATA_TEXT } from "constants/index";
import FaQTableRow from "./row/FaQTableRow";
import * as S from "./FaQTable.styled";

interface FaQTableProps {
  isDone: boolean;
  faqs: any[];
}

const FaQTable = ({ isDone, faqs }: FaQTableProps) => {
  return (
    <Table css={S.root} title="FAQ 리스트">
      <Table.Head columnList={FAQ_TABLE_LIST} />
      <Table.Body
        css={S.tableBody}
        isDone={isDone}
        data={faqs}
        text="등록된 질문이 없습니다"
        PropsTbodyHasNoneData={FaQTBodyNoneData}
      >
        {faqs.map((rowData) => (
          <FaQTableRow
            key={rowData.faqId}
            rowData={rowData}
            columnList={FAQ_TABLE_LIST}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

export default FaQTable;

const FaQTBodyNoneData = () => {
  return (
    <S.FaQTBodyNoneData>
      <tr>
        <HasNoneData css={S.faQNoHistory} asProp="td" text={FAQ_NONE_DATA_TEXT}>
          <Link css={S.faQLink} to="/cs/inquiry/create">
            1:1 문의하기
          </Link>
        </HasNoneData>
      </tr>
    </S.FaQTBodyNoneData>
  );
};
