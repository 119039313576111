import React, { useRef, useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useImmer } from "use-immer";

import { LinkTimeExpiredModal } from "components";
import { useCheckRequired, useModal, useToast } from "hooks";
import { useResetPassword } from "services";
import {
  makeCryptoFunction,
  checkPasswordValidation,
  checkPasswordConfirmValidation,
  checkInputLength,
} from "utils";
import { PATH, TOAST_MSG, VALID_MESSAGE } from "constants/index";

const useResetPasswordForm = () => {
  const [searchParams] = useSearchParams({});
  const navigate = useNavigate();

  const inputRefs = useRef<any>({});

  const [form, setForm] = useImmer({
    password: { value: "", error: "" },
    passwordConfirm: { value: "", error: "" },
  });
  const [isPasswordEyeIcon, setIsPasswordEyeIcon] = useState(false);
  const [isPasswordConfirmEyeIcon, setIsPasswordConfirmEyeIcon] =
    useState(false);

  const { mutate: resetPasswordMutate } = useResetPassword();
  const { addToast } = useToast();
  const { modalRef, handleModalOpen, handleModalClose } = useModal();
  const [checkRequired] = useCheckRequired(inputRefs, form, setForm);

  const isSubmitDisabled = form.password.error || form.passwordConfirm.error;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (checkRequired()) return;

    setForm((draft) => {
      draft.password.error = "";
      draft.passwordConfirm.error = "";
    });

    const id = searchParams.get("id")!;
    const auth = searchParams.get("auth")!;
    const email = searchParams.get("email")!;

    const req = {
      body: {
        id,
        auth,
        email,
        password: makeCryptoFunction(form.password.value),
      },
    };
    resetPasswordMutate(req, {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.RESET_PASSWORD_DONE);
        navigate(PATH.login);
      },
      onError: (err) => {
        switch (err.response?.data.code) {
          case "CANNOT_USE_SAME_PASSWORD": {
            setForm((draft) => {
              draft.password.value = "";
              draft.password.error = VALID_MESSAGE.CHANGE_PW;
              draft.passwordConfirm.value = "";
              draft.passwordConfirm.error = VALID_MESSAGE.CHANGE_PW;
            });
            inputRefs.current[0].focus();
            break;
          }
          case "INVALID_AUTH_CODE":
            handleModalOpen(
              <LinkTimeExpiredModal
                ref={modalRef}
                handleModalClose={handleModalClose}
              />,
            )();
            break;
        }
      },
    });
  };

  const handleChangeForm = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      let error = "";

      switch (name) {
        case "password":
          if (!checkInputLength("PASSWORD", value)) return;
          if (form[name].error && !checkPasswordValidation(value)) {
            error = VALID_MESSAGE.PW;
          }
          break;

        case "passwordConfirm":
          if (!checkInputLength("PASSWORD", value)) return;
          if (
            form[name].error &&
            !checkPasswordConfirmValidation(value, form.password.value)
          ) {
            error = VALID_MESSAGE.PW_C;
          }
          break;
      }

      switch (name) {
        case "password":
          value.length !== 0
            ? setIsPasswordEyeIcon(true)
            : setIsPasswordEyeIcon(false);
          break;

        case "passwordConfirm":
          value.length !== 0
            ? setIsPasswordConfirmEyeIcon(true)
            : setIsPasswordConfirmEyeIcon(false);
          break;
      }

      setForm((draft: any) => {
        draft[name].value = value;
        draft[name].error = error;
      });
    },
    [form],
  );

  const handleBlurForm = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const {
        name,
        value,
        dataset: { required },
      } = event.target;
      let error = "";

      if (required && value.length === 0) {
        setForm((draft: any) => {
          draft[name].error = VALID_MESSAGE.REQUIRED;
        });

        return;
      }

      switch (name) {
        case "password": {
          if (!checkPasswordValidation(value)) {
            error = VALID_MESSAGE.PW;
          } else if (
            !checkPasswordConfirmValidation(value, form.passwordConfirm.value)
          ) {
            setForm((draft) => {
              draft.passwordConfirm.error = VALID_MESSAGE.PW_C;
            });
          } else {
            setForm((draft) => {
              draft.passwordConfirm.error = "";
            });
          }
          break;
        }

        case "passwordConfirm":
          !checkPasswordConfirmValidation(value, form.password.value) &&
            (error = VALID_MESSAGE.PW_C);
          break;
      }

      setForm((draft: any) => {
        draft[name].error = error;
      });
    },
    [form],
  );

  const handleCancel = () => {
    navigate(PATH.login);
  };

  return {
    inputRefs,
    form,
    isSubmitDisabled,
    isPasswordEyeIcon,
    isPasswordConfirmEyeIcon,
    handleSubmit,
    handleChangeForm,
    handleBlurForm,
    handleCancel,
  };
};

export default useResetPasswordForm;
