import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { mixinSettingArea } from "style/mixin";

export const InquiryWrapper = styled.article`
  ${({ theme }) => css`
    ${mixinSettingArea(theme)};
    height: fit-content;
    padding-bottom: 32px;
  `}
`;

export const AnswerWrapper = styled.article`
  ${({ theme }) => css`
    ${mixinSettingArea(theme)};
    grid-row: 3;
    margin-top: 12px;
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.color.gray_02};
    padding-bottom: 10px;

    & > h3 {
      ${theme.font.medium_18};
      color: ${theme.color.gray_09};
    }

    & > time {
      ${theme.font.regular_14};
      color: ${theme.color.gray_05};
    }
  `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-top: 20px;
`;

export const ContentWrapper = styled.dl`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 6px;

    &:nth-of-type(4) {
      border-bottom: 1px solid ${theme.color.gray_02};
      padding-bottom: 20px;
    }

    & > dt {
      ${theme.font.regular_14};
      color: ${theme.color.gray_07};
    }

    & > dd {
      ${theme.font.regular_14};
      color: ${theme.color.gray_10};
      line-height: 1.57;
      white-space: pre-line;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `}
`;

export const ImgWrapper = styled.div`
  display: flex;
  column-gap: 6px;
`;

export const ImgBtn = styled.button`
  ${({ theme }) => css`
    position: relative;
    width: 180px;
    height: 108px;
    border: 1px solid ${theme.color.gray_03};
    border-radius: 4px;
    overflow: hidden;

    ::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:hover {
      border: 1px solid ${theme.color.blue_01};

      ::after {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  `}
`;
