import React from "react";

import { Button, CloseButton } from "components";
import { useModal } from "hooks";
import BaseModal from "../base/BaseModal";
import * as S from "./ConfirmModal.styled";

interface ConfirmModalProps {
  className?: string;
  children?: React.ReactNode;
  btnType?: "active" | "alert";
  title: string;
  desc: string;
  activeBtnName: string;
  cancelBtnName?: string;
  activeFn?: () => void;
  closeFn?: () => void;
  cancelFn?: () => void;
}

const ConfirmModal = React.forwardRef(
  (
    {
      className,
      children,
      btnType = "active",
      title,
      desc,
      activeBtnName,
      cancelBtnName,
      activeFn,
      closeFn,
      cancelFn,
    }: ConfirmModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { handleModalClose } = useModal();

    return (
      <BaseModal ref={ref} css={S.baseModal} className={className}>
        <S.Header>
          <CloseButton
            css={S.closeButton}
            onClick={closeFn ?? handleModalClose}
          />
          <S.Title>{title}</S.Title>
          <S.Desc>{desc}</S.Desc>
        </S.Header>
        {children && children}
        <S.Footer>
          {cancelBtnName && (
            <Button
              variant="secondaryMedium"
              onClick={cancelFn ?? handleModalClose}
            >
              {cancelBtnName}
            </Button>
          )}
          <Button
            variant={btnType === "active" ? "primarySmall" : "warningMedium"}
            onClick={activeFn}
          >
            {activeBtnName}
          </Button>
        </S.Footer>
      </BaseModal>
    );
  },
);

ConfirmModal.displayName = "ConfirmModal";

export default ConfirmModal;
