import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.li`
  ${({ theme }) => css`
    height: 52px;
    background-color: ${theme.color.white};
  `}
`;

export const DateButton = styled.button`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &[aria-selected="true"] {
      &::before,
      &&:hover::before {
        background-color: ${theme.color.blue_01};
      }

      && > time {
        color: ${theme.color.white};
      }
    }

    &[aria-current="date"] > time {
      color: ${theme.color.blue_01};
    }

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }

    &:not([disabled]) {
      &:hover::before {
        background-color: ${theme.color.bg_hover};
      }
    }

    & > time {
      ${theme.font.medium_14};
      color: ${theme.color.gray_10};
      z-index: 2;
    }

    &[disabled] > time {
      color: ${theme.color.gray_02};
    }
  `}
`;
