import React from "react";

import { EstimatedTime, DateSelectInput, ValidMessage } from "components";
import { ACCEPTED_FORM } from "assets";
import * as S from "./ChangeForm.styled";

interface ChangeFormProps {
  id: any;
  inputRefs: any;
  data: any;
  tabIndex: number;
  handleChangeData: () => void;
  handleBlurData: () => void;
  handleSelectDate: (key: string) => void;
}

const ChangeForm = ({
  id,
  inputRefs,
  data,
  handleChangeData,
  handleBlurData,
  handleSelectDate,
}: ChangeFormProps) => {
  // 동작 확인해볼 것
  return (
    <div id={id} tabIndex={0}>
      {ACCEPTED_FORM.map((item) => (
        <S.InfoItem key={item.key} isRequired={item.isRequired}>
          <label htmlFor={`acceptForm-${item.key}`}>{item.label}</label>
          {item.type === "dateTime" ? (
            <DateSelectInput
              css={S.dateSelect}
              id={`acceptForm-${item.key}`}
              forwardRef={(el: any) => (inputRefs.current[item.key] = el)}
              name={item.key}
              value={data[item.key].value}
              data-required={item.isRequired ? "true" : ""}
              // placeholder={item.placeholder} // NOTE: 확인 후 삭제 요망
              validError={data[item.key].error}
              isDisabledBeforeToday={true}
              handleSelectDate={handleSelectDate(item.key)}
              onBlur={handleBlurData}
            >
              <DateSelectInput.Time
                css={S.plannedTime}
                id={`acceptForm-time-${item.key}`}
                onChange={handleChangeData}
                onBlur={handleBlurData}
              >
                <EstimatedTime.SelectHour
                  forwardRef={(el: any) => (inputRefs.current[item.key] = el)}
                  name={item.hourKey}
                  value={data[item.hourKey as string].value}
                />
                <EstimatedTime.SelectMin
                  css={S.plannedMin}
                  forwardRef={(el: any) =>
                    (inputRefs.current[item.minKey] = el)
                  }
                  name={item.minKey}
                  value={data[item.minKey].value}
                  halfDisable={
                    item.minKey === "plannedTimeMin" &&
                    data.plannedTimeHour.value === 24
                  }
                />
              </DateSelectInput.Time>
            </DateSelectInput>
          ) : item.type === "time" ? (
            <DateSelectInput.Time
              css={S.estimatedTime}
              id={`acceptForm-${item.key}`}
              onChange={handleChangeData}
              onBlur={handleBlurData}
            >
              <EstimatedTime.Hour
                forwardRef={(el: any) => (inputRefs.current[item.key] = el)}
                name={item.key}
                value={data[item.key].value}
                validError={data[item.key].error}
                data-required={item.isRequired ? "true" : ""}
                label="시간"
              />
              <EstimatedTime.SelectMin
                forwardRef={(el: any) => (inputRefs.current[item.minKey] = el)}
                name={item.minKey}
                value={data[item.minKey].value}
                zeroDisable={
                  item.minKey === "estimatedTimeMin" &&
                  +data.estimatedTimeHour.value === 0
                } // TODO Refactoring
              />
            </DateSelectInput.Time>
          ) : null}
          {data[item.key].error && (
            <ValidMessage css={S.validText}>
              {data[item.key].error}
            </ValidMessage>
          )}
        </S.InfoItem>
      ))}
    </div>
  );
};

export default ChangeForm;
