import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const FindPasswordDialog = styled.dialog`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 594px;
    height: 486px;
    border-radius: 3px;
    padding: 79px 0;
    background-color: ${theme.color.gray_00};
    z-index: 10;
  `}
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 28px;
  & > svg {
    width: 28px;
    height: 28px;
  }
`;

export const Container = styled.div`
  padding: 0 117px;
`;

export const Heading = styled.div`
  ${({ theme }) => css`
    margin-bottom: 28px;

    & h3 {
      ${theme.font.bold_24};
      margin-bottom: 12px;
      color: ${theme.color.gray_09};
    }

    & p {
      ${theme.font.regular_14};
      color: ${theme.color.gray_06};
    }
  `}
`;

export const Form = styled.form`
  position: relative;
`;

export const EmailInputWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin-bottom: 48px;

    & label {
      ${theme.font.medium_14};
      display: inline-block;
      margin-bottom: 6px;
      color: ${theme.color.gray_06};
    }
  `}
`;

export const EmailInput = styled.input<{ validError: boolean }>`
  ${({ theme, validError }) => css`
    ${theme.font.regular_16};
    width: 100%;
    height: 45px;
    border-radius: 4px;
    border: 1px solid ${validError ? theme.color.error_01 : theme.color.gray_02};
    padding: 0 12px;
    color: ${theme.color.gray_09};
    background-color: ${theme.color.white};

    &::placeholder {
      color: ${theme.color.gray_05};
    }

    &:focus {
      border: 1px solid ${theme.color.blue_01};
    }
  `}
`;

export const validMessage = css`
  bottom: -18px;
`;

export const authLinkButton = css`
  margin-bottom: 12px;
`;

export const GuideWord = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_05};
  `}
`;
