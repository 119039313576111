import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const RootStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 28px 0 0 0;
`;

export const ReservationInfoTitle = styled.h3`
  ${({ theme }) => css`
    ${theme.font.medium_16};
    margin-bottom: 14px;
  `}
`;
