import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import * as api from "apis";
import type { APIError, UpdateRepairshopQueryModel } from "types";

export const useGetReapirshop = () => {
  return useQuery({
    queryKey: ["repairshop"],
    queryFn: () => api.getRepairshopInfo(),
  });
};

export const useUpdateRepairshopInfo = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, UpdateRepairshopQueryModel>({
    mutationFn: (req) => api.putRepairshopInfo(req),
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
};
