import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import { useRecoilValue } from "recoil";

import {
  UnavailableTimeModal,
  ReservationReqDialog,
  DialogButton,
} from "components";
import { useModal, useScrollXFixedEl } from "hooks";
import { repairShopInfo } from "store";
import { WarningIconImg } from "assets";
import {
  PATH,
  REPAIR_SHOP_STATUS,
  CUSTOMER_CENTER_NUMBER,
} from "constants/index";
import type { RepairShopInfo } from "types";
import * as S from "./Header.styled";

const Header = () => {
  const headerRef = useRef(null);

  const repairShop = useRecoilValue<RepairShopInfo>(repairShopInfo);
  const { modalRef, handleModalOpen, handleModalClose } = useModal();
  useScrollXFixedEl(headerRef);

  const StatusMsg = ({ status }: { status: string }) => {
    return status === REPAIR_SHOP_STATUS.INACTIVE ? (
      <>
        정비소 비활성화 상태입니다. 문의를 원하시면 트럭닥터 고객센터 또는{" "}
        {CUSTOMER_CENTER_NUMBER}으로 연락바랍니다
        <WarningIconImg />
      </>
    ) : (
      <>오늘도 트럭닥터와 함께 좋은 하루 보내세요.</>
    );
  };

  return (
    <S.Root ref={headerRef}>
      <S.Container>
        <h1 className="a11y">트럭닥터 정비소</h1>
        <S.Content>
          <S.Message>
            <S.UserName>{repairShop.name}</S.UserName>님,
            <S.GreetingMessage
              isInactive={repairShop.status === REPAIR_SHOP_STATUS.INACTIVE}
            >
              <StatusMsg status={repairShop.status} />
            </S.GreetingMessage>
          </S.Message>
          <S.UtilButtonWrapper>
            <DialogButton
              css={S.headerDialogButton}
              popup={(
                isDialogOpen: boolean,
                dialogRef: HTMLDialogElement,
                handleDialogClose: () => void,
                handleDialogOpen: () => void,
              ) => {
                return (
                  <ReservationReqDialog
                    available={repairShop.available}
                    isDialogOpen={isDialogOpen}
                    dialogRef={dialogRef}
                    handleDialogOpen={handleDialogOpen}
                    handleDialogClose={handleDialogClose}
                  />
                );
              }}
            >
              <span>예약 요청 내역</span>
            </DialogButton>
            <S.UnavailableTimeWrapper>
              {/* NOTE: Button 컴포넌트의 해당 디자인 시스템 추가해서 적용하기 */}
              <button
                type="button"
                onClick={handleModalOpen(
                  <UnavailableTimeModal
                    ref={modalRef}
                    handleModalClose={handleModalClose}
                  />,
                )}
              >
                시간 설정
              </button>
            </S.UnavailableTimeWrapper>
            <NavLink css={S.goToAddReservationLink} to={PATH.addReservation}>
              예약 추가
            </NavLink>
          </S.UtilButtonWrapper>
        </S.Content>
      </S.Container>
    </S.Root>
  );
};

export default Header;
