import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.header`
  ${({ theme }) => css`
    position: fixed;
    left: 0;
    top: 0;
    min-width: 100%;
    padding-left: ${theme.size.NAV_WIDTH};
    z-index: ${theme.zIndex.HEADER_FOOTER};
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: ${theme.size.HEADER_HEIGHT};
    border-bottom: 1px solid ${theme.color.gray_02};
    padding: 0 ${theme.size.LAYOUT_PADDING_SIDE};
    background-color: ${theme.color.white};
    z-index: ${theme.zIndex.HEADER_FOOTER};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    min-width: ${theme.size.MAIN_MIN_WIDTH};
    max-width: ${theme.size.MAIN_MAX_WIDTH};
    height: ${theme.size.HEADER_HEIGHT};
    margin: 0 auto;
  `}
`;

export const Message = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: flex;
    margin-bottom: 16px;
    color: ${theme.color.gray_08};
  `}
`;

export const UserName = styled.span`
  ${({ theme }) => css`
    max-width: 215px;
    font-weight: 700;
    color: ${theme.color.blue_01};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

export const GreetingMessage = styled.span<{ isInactive: boolean }>`
  ${({ theme, isInactive }) => css`
    display: flex;
    column-gap: 4px;
    margin-left: 4px;
    color: ${isInactive ? theme.color.error_01 : theme.color.gray_08};
  `}
`;

export const UtilButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin-bottom: 8px;
`;

export const headerDialogButton = (theme: Theme) => css`
  display: flex;
  align-items: center;
  height: 34px;

  & > span {
    ${theme.font.medium_14};
    position: relative;
    padding: 7px 13px;
    border-radius: 4px;
    color: ${theme.color.blue_01};

    :hover {
      background-color: ${theme.color.blue_02};
    }
  }
`;

export const UnavailableTimeWrapper = styled.div`
  ${({ theme }) => css`
    & > button {
      ${theme.font.medium_14};
      display: flex;
      align-items: center;
      margin-right: 4px;
      padding: 0 13px;
      height: 34px;
      color: ${theme.color.gray_07};

      :hover {
        border-radius: 4px;
        background-color: ${theme.color.blue_02};
      }
    }
  `}
`;

export const goToAddReservationLink = (theme: Theme) => css`
  ${theme.button.primarySmall};
`;
