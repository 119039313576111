import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  display: flex;
`;
export const select = css`
  width: 100%;

  > button {
    width: 100%;
  }

  & > ul {
    max-height: 190px;
  }
`;

export const option = (theme: Theme, selected: boolean) => css`
  & > button {
    width: 100%;
    height: 100%;
    padding: 7px 10px 7px 12px;

    color: ${selected ? theme.color.blue_01 : theme.color.gray_09};
    background-color: ${selected ? theme.color.blue_02 : theme.color.white};

    &[disabled] {
      color: ${theme.color.blue_01};
    }

    & > svg {
      fill: ${selected ? theme.color.blue_01 : theme.color.gray_02};
    }

    & > span {
      display: inline-block;
      max-width: 140px;
      overflow-wrap: break-word;
      white-space: pre-wrap;
      text-align: left;
    }
  }
`;

export const ButtonWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 4px 6px;
  width: 100%;

  & > li {
    width: auto;
  }
`;

export const button = (theme: Theme) => css`
  display: flex;
  align-items: center;
  column-gap: 4px;
  width: 100%;
  height: 100%;
  padding: 6px 7px 6px 11px;

  &[data-status="brands"] > span {
    color: ${theme.color.blue_01};
  }

  & > span {
    display: inline-block;
    max-width: 170px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    text-align: left;
  }
`;

export const closeIcon = css`
  width: 16px;
  height: 16px;
`;
