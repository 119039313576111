import React from "react";
import { Link } from "react-router-dom";

import { Select } from "components";
import * as S from "./SettingInquiryHeader.styled";

interface SettingInquiryHeaderProps {
  INQUIRY_FILTER: {
    [key: string]: string;
  };
  selectedSortOption: string;
  handleSelectSortOption: (value: string) => void;
}

const SettingInquiryHeader = ({
  INQUIRY_FILTER,
  selectedSortOption,
  handleSelectSortOption,
}: SettingInquiryHeaderProps) => {
  return (
    <S.Root>
      <S.TitleWrapper>
        <h2>1:1 문의 내역</h2>
      </S.TitleWrapper>
      <S.SearchFilterWrapper>
        <Select
          css={S.sortSelect}
          id="inquiry-filter"
          labelTable={INQUIRY_FILTER}
          onSelect={handleSelectSortOption}
          selectedOption={selectedSortOption}
        >
          {Object.entries(INQUIRY_FILTER).map(([value, label]) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
        <Link css={S.linkButton} to="create">
          1:1 문의하기
        </Link>
      </S.SearchFilterWrapper>
    </S.Root>
  );
};

export default React.memo(SettingInquiryHeader);
