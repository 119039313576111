import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Title = styled.p<{ isRequired?: boolean }>`
  ${({ theme, isRequired }) => css`
    ${theme.font.regular_14};
    margin-bottom: 8px;
    color: ${theme.color.gray_07};

    span {
      color: ${theme.color.error_01};
    }

    &::after {
      content: ${isRequired ? "'*'" : "''"};
      margin-left: 2px;
      color: ${theme.color.error_01};
    }
  `}
`;

export const DescWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: 6px;

    & > p {
      ${theme.font.regular_14};
      color: ${theme.color.gray_05};
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.color.gray_03};
    border-top-width: 6px;
    border-radius: 4px;
    padding: 14px 22px;

    > div:last-child {
      border-top: 1px solid ${theme.color.gray_02};
      margin-top: 16px;
      padding-top: 16px;
    }
  `}
`;

export const ImageUploadBtn = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 108px;
    border: 1px solid ${theme.color.gray_03};
    border-radius: 4px;
    background-color: ${theme.color.background};
  `}
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border: 1px solid ${theme.color.blue_01};
    border-radius: 2px;
    background-color: ${theme.color.white};
  `}
`;

export const plusIcon = (theme: Theme) => css`
  width: 24px;
  height: 24px;
  fill: ${theme.color.blue_01};
`;

export const PreviewImgWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 180px);
  justify-content: space-between;
  column-gap: 4px;
  max-width: 732px;
`;

export const ImageBox = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 180px;
    height: 108px;
    border: 1px solid ${theme.color.gray_03};
    border-radius: 4px;
    overflow: hidden;

    img {
      object-fit: contain;
    }
  `}
`;
export const DeleteBtn = styled.button`
  ${({ theme }) => css`
    position: absolute;
    top: 4px;
    right: 4px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: ${theme.color.gray_10_opacity_50};

    & > svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      transform: translate(-50%, -50%);
    }
  `}
`;
