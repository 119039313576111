import type {
  GetCustomersQueryModel,
  GetCustomersServerModel,
  GetCustomerDetailServerModel,
  GetCustomerReservationQueryModel,
  GetCustomerReservationServerModel,
  CreateCustomerQueryModel,
  UpdateCustomerQueryModel,
  GetCustomerInfoByRegNumServerModel,
  GetCustomerNoShowCntServerModel,
} from "types";
import { ax } from "../axios";

export const getCustomersAPI = async (
  req: GetCustomersQueryModel,
): Promise<GetCustomersServerModel> => {
  const { data } = await ax.get("/customers", { params: req.query });

  return data;
};

export const getCustomerDetailAPI = async (
  customerId: string,
): Promise<GetCustomerDetailServerModel> => {
  const { data } = await ax.get(`/customers/${customerId}`);

  return data;
};

export const getCustomerDetailReservationsAPI = async (
  req: GetCustomerReservationQueryModel,
): Promise<GetCustomerReservationServerModel> => {
  const { data } = await ax.get(`/customers/${req.customerId}/reservations`, {
    params: req.query,
  });

  return data;
};

export const createCustomerAPI = (req: CreateCustomerQueryModel) => {
  return ax.post("/customers", req.body);
};

export const updateCustomerAPI = (req: UpdateCustomerQueryModel) => {
  return ax.put(`/customers/${req.customerId}`, req.body);
};

export const deleteCustomerAPI = (id: string) => {
  return ax.delete(`/customers/${id}`);
};

export const getCustomerInfoByRegNumAPI =
  async (): Promise<GetCustomerInfoByRegNumServerModel> => {
    const {
      data: { trucks },
    } = await ax.get("/customers/trucks");

    return trucks;
  };

export const getNoshowCntAPI = async (
  phone: string,
): Promise<GetCustomerNoShowCntServerModel> => {
  const params = {
    phone: phone.replaceAll("-", ""),
  };

  const { data } = await ax.get("/customers/phone", { params });

  return data;
};
