import React from "react";

import { OnCalendarIconImg, OffCalendarIconImg } from "assets";
import * as S from "./CalendarButton.styled";

interface CalendarButtonProps {
  className?: string;
  isDialogOpen?: boolean;
  restProps?: any;
  onClick?: any;
}

const CalendarButton = ({
  className,
  isDialogOpen,
  ...restProps
}: CalendarButtonProps) => {
  return (
    <S.Root
      type="button"
      className={className}
      aria-label="캘린더 열기"
      {...restProps}
    >
      {isDialogOpen ? (
        <OnCalendarIconImg css={S.onCalendarIcon} />
      ) : (
        <OffCalendarIconImg css={S.offCalendarIcon} />
      )}
    </S.Root>
  );
};

export default CalendarButton;
