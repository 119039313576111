import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.ul`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 28px;
    margin-bottom: 29px;
    border-bottom: 1px solid ${theme.color.gray_02};
    padding-bottom: 10px;
  `}
`;

export const Tab = styled.li`
  ${({ theme }) => css`
    & > button {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > span {
        ${theme.font.medium_16};
        display: inline-block;
        color: ${theme.color.gray_05};
      }

      &:hover > span {
        color: ${theme.color.blue_01};
      }
    }

    &[aria-selected="true"] span {
      color: ${theme.color.blue_01};
    }
  `}
`;

export const Line = styled.span`
  ${({ theme }) => css`
    display: inline-block;
    width: 100%;
    height: 4px;

    &[data-select="true"] {
      background: ${theme.color.blue_01};
      transform: translateY(10px);
    }
  `}
`;
