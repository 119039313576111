import React, { forwardRef } from "react";

import { VisuallyHidden } from "components";
import * as S from "./LoginInput.styled";

interface LoginInputProps {
  id: string;
  className?: string;
  name?: string;
  ref?: React.ForwardedRef<HTMLInputElement>;
  value?: string;
  label: string;
  placeholder?: string;
  validError?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const LoginInput = (
  { id, label, className, ...restProps }: LoginInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) => {
  const uuid = `input-${id}`;
  return (
    <>
      <VisuallyHidden className={className} as="label" htmlFor={uuid}>
        {label}
      </VisuallyHidden>
      <S.Input
        ref={ref}
        className={className}
        id={uuid}
        autoComplete="off"
        {...restProps}
      />
    </>
  );
};

export default forwardRef(LoginInput);
