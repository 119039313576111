import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Wrapper = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  cursor: default;

  &:hover > div {
    visibility: visible;
  }
`;

export const tooltipIcon = css`
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: calc(100% + 8px);
    left: -250px;
    border-radius: 3px;
    padding: 20px 16px;
    background-color: ${theme.color.gray_10};
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
    visibility: hidden;
    z-index: 100;

    &::before {
      content: "";
      position: absolute;
      top: -18px;
      right: 27px;
      display: block;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 10px solid ${theme.color.gray_10};
      border-left: 6px solid transparent;
      pointer-events: none;
    }

    & > p {
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: -0.01em;
      color: ${theme.color.white};
      white-space: nowrap;
    }
  `}
`;
