export const operationTimeListInit = () => {
  const dateObj = {
    월: "mon",
    화: "tue",
    수: "wed",
    목: "thu",
    금: "fri",
    토: "sat",
    일: "sun",
  };
  return Object.entries(dateObj).reduce((acc, [dayKo, date]) => {
    acc[date] = {
      dayKo,
      open: {
        value: "",
        error: "",
      },
      openHour: {
        value: "",
        error: "",
      },
      openMin: {
        value: "00",
        error: "",
      },
      close: {
        value: "",
        error: "",
      },
      closeHour: {
        value: "",
        error: "",
      },
      closeMin: {
        value: "00",
        error: "",
      },
      restStart: {
        value: "",
        error: "",
      },
      restStartHour: {
        value: "",
        error: "",
      },
      restStartMin: {
        value: "00",
        error: "",
      },
      restEnd: {
        value: "",
        error: "",
      },
      restEndHour: {
        value: "",
        error: "",
      },
      restEndMin: {
        value: "00",
        error: "",
      },
      nightStart: {
        value: "",
        error: "",
      },
      nightStartHour: {
        value: "",
        error: "",
      },
      nightStartMin: {
        value: "00",
        error: "",
      },
      nightEnd: {
        value: "",
        error: "",
      },
      nightEndHour: {
        value: "",
        error: "",
      },
      nightEndMin: {
        value: "00",
        error: "",
      },
      nightTimeSelected: false,
      breakTimeSelected: false,
      isWork: false,
    };

    return acc;
  }, {} as any);
};
