import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";

import { Button, HasNoneData, DetailModal } from "components";
import { useModal } from "hooks";
import { useGetFreqComments } from "services";
import { freqCommentState } from "store";
import { PlusIconImg } from "assets";
import FrequentlyUsedComment from "../comment/FrequentlyUsedComment";
import AddFrequentlyUseCommentModal from "../../addFrequently/AddFrequentlyUseCommentModal";
import * as S from "./FrequentlyUsedCommentsModal.styled";

const FrequentlyUsedCommentsModal = React.forwardRef(
  (_, ref: React.ForwardedRef<HTMLDialogElement>) => {
    const setFreqUseComments = useSetRecoilState(freqCommentState);
    const { data } = useGetFreqComments();

    const freqUseComments = data?.templates;

    const { modalRef: addModalRef, handleModalOpen: handleAddModalOpen } =
      useModal();

    useEffect(() => {
      if (freqUseComments) {
        setFreqUseComments(freqUseComments);
      }
    }, [freqUseComments]);

    return (
      <DetailModal ref={ref} css={S.detailModal} title="자주 쓰는 댓글 설정">
        <S.ContentTop>
          <S.ContentTopHeader>
            <span>댓글명</span>
            <span>내용</span>
            <span>선택</span>
          </S.ContentTopHeader>
          <S.AddButtonWrapper>
            <Button
              css={S.addButton}
              type="button"
              variant="secondarySmall"
              onClick={handleAddModalOpen(
                <AddFrequentlyUseCommentModal ref={addModalRef} />,
              )}
            >
              <PlusIconImg css={S.plusIcon} />
              자주 쓰는 댓글 추가
            </Button>
          </S.AddButtonWrapper>
          <S.CommentWrapper>
            {freqUseComments?.length === 0 ? (
              <HasNoneData
                css={S.hasNoneData}
                text="자주 쓰는 댓글이 없습니다"
              />
            ) : (
              freqUseComments?.map((v) => (
                <FrequentlyUsedComment key={v.templateId} data={v} />
              ))
            )}
          </S.CommentWrapper>
        </S.ContentTop>
      </DetailModal>
    );
  },
);

FrequentlyUsedCommentsModal.displayName = "FrequentlyUsedCommentsModal";

export default FrequentlyUsedCommentsModal;
