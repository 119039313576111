import React, { useRef, useCallback, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { isEmpty } from "lodash-es";

import { TruckSearchResultModal } from "components";
import { useCheckRequired, useToast, useModal } from "hooks";
import {
  useFetchCustomerNoShowCnt,
  useFinishReservation,
  useGetCustomerByRegNum,
  useUpdateReservationMemo,
} from "services";
import {
  deleteFirstZero,
  vinFormatter,
  numericOnly,
  checkRegNumValidation,
  checkVinValidation,
  deleteComma,
  getPhoneInfo,
  estimateHourFormatter,
  findSearchedCustomerInfo,
  checkDrivingDistance,
  checkPrice,
  findCustomerInfoByRegNum,
  checkVinExact,
  checkInputLength,
  checkExpectationTime,
  getCommaThousandUnitInputAfterChange,
  getCommaThousandUnitAfterDeleteMarker,
  checkPhoneValidation,
} from "utils";
import { TOAST_MSG, VALID_MESSAGE } from "constants/index";
import type { GetCustomerInfoByRegNumServerModel } from "types";

const INITIAL_STATE = {
  name: { value: "", error: "" },
  phoneLocal: { value: "", error: "" },
  phone: { value: "", error: "" },
  originRegNum: { value: "", error: "" },
  regNum: { value: "", error: "", step: 1 },
  regNumSearchText: { value: "", error: "" },
  modelName: { value: "", error: "" },
  modelYear: { value: "", error: "" },
  brandId: { value: "", error: "" },
  vin: { value: "", error: "" },
  mileage: { value: "", error: "" },
  axle: { value: "", error: "" },
  seat: { value: "", error: "" },
  userComment: { value: "", error: "" },
  estimatedTimeHour: { value: "", error: "" },
  estimatedTimeMin: { value: "", error: "" },
  staffIds: { value: [], error: "" },
  actualTimeHour: { value: "", error: "" },
  actualTimeMin: { value: "", error: "" },
  cost: {
    value: "",
    error: "",
    tip: "기사님께 보여지는 청구 금액입니다.",
  },
  shopComment: { value: "", error: "" },
  reservationNo: { value: "", error: "" },
  noshows: { value: "", error: "" },
  status: { value: "", error: "" },
  isSelf: { value: "", error: "" },
  memo: { value: "", error: "" },
  reservationId: { value: "", error: "" },
};

const useRepairing = (initData: any) => {
  const inputRefs = useRef<any>({});

  const [form, setForm] = useImmer(INITIAL_STATE);
  const [cursor, setCursor] = useImmer<{ mileage: number; cost: number }>({
    mileage: -1,
    cost: -1,
  });
  const [customerInfo, setCustomerInfo] =
    useState<GetCustomerInfoByRegNumServerModel>([]);

  const { mutate: finishReservationMutate } = useFinishReservation();
  const { mutate: updateReservationMemoMutate } = useUpdateReservationMemo();
  const { data: customerSearchInfos } = useGetCustomerByRegNum();
  const {
    isFetching,
    isSuccess,
    data: noshowInfo,
    refetch,
  } = useFetchCustomerNoShowCnt(`${form.phoneLocal.value}${form.phone.value}`, {
    enabled:
      form.phone.value.length >= 7 &&
      document.activeElement !== inputRefs.current["phone"],
  });

  const { addToast } = useToast();
  const { modalRef, handleModalOpen, handleModalClose } = useModal();
  const [checkRequired] = useCheckRequired(
    inputRefs,
    form,
    setForm,
    VALID_MESSAGE.REQUIRED_NO_ASTERISK,
  );

  const isSubmitDisabled =
    Object.values(form).filter((data) => data.error !== "").length > 0;

  const handleSubmit = (event: any) => {
    event.preventDefault();

    let error = false;
    if (form.actualTimeHour.value === "") {
      error = true;
      setForm((draft) => {
        draft.actualTimeHour.error = "필수 입력 정보입니다";
      });
    }
    if (!form.actualTimeMin.value) {
      error = true;
      setForm((draft) => {
        draft.actualTimeMin.error = "필수 입력 정보입니다";
      });
    }

    if (form.regNum.step === 2) {
      error = true;
      setForm((draft) => {
        draft.regNum.error = VALID_MESSAGE.REGNUM_SELECT;
      });
    }

    if (checkRequired() || error) return;

    const req = {
      reservationId: initData.reservationId,
      body: {
        name: form.name.value,
        phone: `${form.phoneLocal.value}${form.phone.value}`,
        staffIds: form.staffIds.value,
        ...(form.actualTimeHour.value !== "" &&
          form.actualTimeMin.value && {
            actualTime: `${form.actualTimeHour.value}:${form.actualTimeMin.value}`,
          }),
        estimatedTime: `${form.estimatedTimeHour.value}:${form.estimatedTimeMin.value}`,
        cost: deleteComma(form.cost.value),
        ...(form.seat.value && { seat: form.seat.value }),
        ...(form.axle.value && { axle: form.axle.value }),
        ...(form.shopComment.value.trim() && {
          shopComment: form.shopComment.value.trim(),
        }),
        ...(form.userComment.value.trim() && {
          userComment: form.userComment.value.trim(),
        }),
        ...(form.mileage.value && {
          mileage: deleteComma(form.mileage.value),
        }),
        selfInfo: {
          truckId: initData.truckId,
          vin: form.vin.value,
          regNum: form.regNum.value,
          modelName: form.modelName.value,
          ...(form.modelYear.value && {
            modelYear: form.modelYear.value,
          }),
          ...(form.mileage.value &&
            initData.isSelf === "y" && {
              mileage: deleteComma(form.mileage.value),
            }),
        },
      },
    };

    finishReservationMutate(req, {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.REPAIR_DONE);
      },
    });
  };

  const handleChangeForm = useCallback(
    (event: any, key: string) => {
      const name: keyof typeof form = event?.target?.name ?? key;
      let value = event?.target?.value ?? event;
      let error = "";

      switch (name) {
        case "name":
          value = value.replaceAll(" ", "");
          if (!checkInputLength("NAME", value)) return;
          break;

        case "modelName":
          if (!checkInputLength("MODEL_NAME", value)) return;
          break;

        case "regNum":
          value = value.replaceAll(" ", "").trim();
          if (!checkInputLength("REGNUM", value)) return;
          if (form[name].error && !checkRegNumValidation(value)) {
            error = VALID_MESSAGE.REGNUM;
          }
          break;

        case "phone":
          if (!checkInputLength("PHONE", value)) return;
          if (form[name].error && value.length < 7) {
            error = VALID_MESSAGE.PHONE_NUM;
          }
          break;

        case "vin":
          value = vinFormatter(value);
          if (!checkInputLength("VIN", value)) return;

          if (form[name as keyof typeof form].error) {
            if (!checkVinExact(value)) {
              error = VALID_MESSAGE.VIN;
            } else if (!checkVinValidation(value)) {
              error = VALID_MESSAGE.VIN_INCORRECT;
            }
          }
          break;

        case "axle":
          value = +value;
          break;

        case "mileage": {
          if (!checkDrivingDistance(value)) return;

          const mileageInputInfo = getCommaThousandUnitInputAfterChange(
            event,
            value,
          );
          if (!mileageInputInfo) return;

          value = mileageInputInfo.value;
          setCursor((draft) => {
            draft.mileage = mileageInputInfo.cursor;
          });

          break;
        }

        case "modelYear":
          value = numericOnly(value);
          if (!checkInputLength("MODEL_YEAR", value)) return;
          break;

        case "staffIds":
          setForm((draft: any) => {
            draft.staffIds.value = [
              ...new Set([...draft.staffIds.value, value]),
            ];
            draft.staffIds.error = "";
          });
          return;

        case "cost": {
          if (!checkPrice(value)) return;

          const priceInputInfo = getCommaThousandUnitInputAfterChange(
            event,
            value,
          );
          if (!priceInputInfo) return;

          value = priceInputInfo.value;
          setCursor((draft) => {
            draft.cost = priceInputInfo.cursor;
          });

          break;
        }

        case "actualTimeHour":
          if (!checkExpectationTime(value)) return;
          value = estimateHourFormatter(value);

          if (value === 0) {
            setForm((draft) => {
              draft.actualTimeMin.value = "30";
              draft.actualTimeMin.error = "";
            });
          }
          break;

        case "userComment":
        case "shopComment":
        case "memo":
          if (!checkInputLength("COMMON_TEXTAREA", value)) return;
      }

      setForm((draft) => {
        draft[name].value = value;
        draft[name].error = error;
      });
    },
    [form],
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const name = (e.target as HTMLInputElement).name;

    switch (name) {
      case "mileage":
      case "cost":
        {
          const numberInputInfo = getCommaThousandUnitAfterDeleteMarker(e);
          if (!numberInputInfo) return;

          setForm((draft) => {
            draft[name].value = numberInputInfo.value;
          });
          setCursor((draft) => {
            draft[name] = numberInputInfo.cursor;
          });
        }

        break;
    }
  };

  const handleBlurForm = useCallback(
    (event: any) => {
      const {
        value,
        name,
        dataset: { required },
      } = event.target;
      let error = "";

      if (required && form[name as keyof typeof form].value.length === 0) {
        setForm((draft: any) => {
          draft[name].error = VALID_MESSAGE.REQUIRED_NO_ASTERISK;
        });
        return;
      }

      switch (name) {
        case "phone":
          if (!checkPhoneValidation(value)) {
            error = VALID_MESSAGE.PHONE_NUM;
          } else {
            refetch();
          }
          break;

        case "vin":
          if (!checkVinExact(value)) {
            error = VALID_MESSAGE.VIN;
          } else if (!checkVinValidation(value)) {
            error = VALID_MESSAGE.VIN_INCORRECT;
          }
          break;
        case "modelName":
          if (!value.trim()) {
            error = VALID_MESSAGE.REQUIRED_NO_ASTERISK;
          }
          break;
      }

      setForm((draft: any) => {
        draft[name].error = error;
      });
    },
    [form],
  );

  const handleSaveMemo = useCallback(() => {
    const req = {
      reservationId: form.reservationId.value,
      body: {
        memo: form.memo.value.trim() || null,
      },
    };

    updateReservationMemoMutate(req, {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.SAVE_MEMO_DONE);
      },
    });
  }, [form]);

  const handleDeleteStaff = useCallback(
    (staffId: string) => () => {
      setForm((draft) => {
        draft.staffIds.value = draft.staffIds.value.filter(
          (id) => id !== staffId,
        );
      });
    },
    [],
  );

  const updateMileage = useCallback((mileage: any) => {
    setForm((draft) => {
      draft.mileage.value = mileage;
    });
  }, []);

  const checkUserInfo = () => {
    const userInfoLabelList = [
      "name",
      "phone",
      "modelName",
      "modelYear",
      "brandId",
    ];

    return !!userInfoLabelList.filter(
      (item) => form[item as keyof typeof form].value,
    ).length;
  };

  const processAfterRegNumSearch = () => {
    changeRegNumState(1)();

    if (form.regNum.error) {
      setForm((draft) => {
        draft.regNum.error = "";
      });
    }
  };

  const handleOverwriteInfo = () => {
    setForm((draft) => {
      draft.originRegNum.value = form.regNum.value;
      draft.regNum.value = form.regNum.value;
    });

    const searchedCustomerInfo = findCustomerInfoByRegNum(
      customerSearchInfos ?? [],
      form.regNum.value,
    );

    setCustomerInfo(searchedCustomerInfo);
    addToast(
      `${form.regNum.value} ${TOAST_MSG.SUCCESS.SEARCH_CUSTOMER_BY_REGNUM_DONE}`,
    );
    processAfterRegNumSearch();
  };

  const changeRegNum = (regNum: string) => {
    setForm((draft) => {
      draft.originRegNum.value = regNum;
      draft.regNum.value = regNum;
    });

    processAfterRegNumSearch();
  };

  const handleModalActive = () => {
    handleOverwriteInfo();
    handleModalClose();
  };

  const handleModalCancel = (regNum: string) => () => {
    changeRegNum(regNum);
    handleModalClose();
  };

  const handleSearchCustomer = useCallback(
    (e: any) => {
      e.preventDefault();

      const regNum =
        e.type === "click"
          ? form.regNum.value
          : form.regNumSearchText.value || form.regNum.value;

      if (!regNum) {
        setForm((draft) => {
          draft.regNum.error = VALID_MESSAGE.REQUIRED_NO_ASTERISK;
        });
        return;
      }

      if (!checkRegNumValidation(regNum)) {
        setForm((draft) => {
          draft.regNum.error = VALID_MESSAGE.REGNUM_EXAM;
        });
        return;
      }

      const searchedCustomerInfo = findCustomerInfoByRegNum(
        customerSearchInfos ?? [],
        regNum,
      );

      if (isEmpty(searchedCustomerInfo)) {
        changeRegNum(regNum);
        addToast(`${regNum} ${TOAST_MSG.FAIL.SEARCH_CUSTOMER_BY_REGNUM_ERROR}`);

        if (!checkUserInfo()) {
          setCustomerInfo(searchedCustomerInfo);
        }
      } else {
        if (checkUserInfo()) {
          handleModalOpen(
            <TruckSearchResultModal
              ref={modalRef}
              handleActive={handleModalActive}
              handleCancel={handleModalCancel(regNum)}
            />,
          )();
        } else {
          setCustomerInfo(searchedCustomerInfo);
          changeRegNum(regNum);
          addToast(
            `${regNum} ${TOAST_MSG.SUCCESS.SEARCH_CUSTOMER_BY_REGNUM_DONE}`,
          );
        }
      }
    },
    [form],
  );

  const handleSelectRegNum = useCallback(
    (regNum: string, selected = false) => {
      if (selected) {
        setForm((draft) => {
          draft["regNumSearchText"].value = regNum;
        });
      } else {
        setForm((draft) => {
          draft.regNum.value = regNum;
          draft.regNum.error = "";
        });
      }
    },
    [form],
  );

  const handleCancelRegNum = () => {
    setForm((draft) => {
      draft.regNum.value = form.originRegNum.value;
    });

    processAfterRegNumSearch();
  };

  const changeRegNumState = (step: number) => () => {
    setForm((draft) => {
      draft.regNum.step = step;
    });
  };

  const init = () => {
    const { areaCode, phone } = getPhoneInfo(initData.phone || "");

    setForm((draft: any) => {
      for (const key in initData) {
        if (draft[key]) {
          draft[key].value = initData[key] ?? "";
          draft[key].error = "";
        }
      }

      draft.phoneLocal.value = areaCode ?? "";
      draft.phone.value = phone;
      draft.originRegNum.value = initData.regNum;
      draft.estimatedTimeHour.value = deleteFirstZero(
        initData.estimatedTime.split(":")[0],
      );
      draft.estimatedTimeMin.value = initData.estimatedTime.split(":")[1];
    });
  };

  const handleSetCursor = (key: string) => {
    const target = inputRefs.current[key];

    target?.setSelectionRange(
      cursor[key as keyof typeof cursor],
      cursor[key as keyof typeof cursor],
    );
  };

  useEffect(() => {
    handleSetCursor("mileage");
  }, [inputRefs.current.mileage, cursor.mileage, form.mileage.value]);

  useEffect(() => {
    handleSetCursor("cost");
  }, [inputRefs.current.cost, cursor.cost, form.cost.value]);

  useEffect(() => {
    if (isEmpty(customerInfo)) return;

    const searchedCustomer = findSearchedCustomerInfo(
      customerInfo,
      form.regNum.value,
    );

    if (!searchedCustomer) return;

    const { areaCode, phone } = getPhoneInfo(searchedCustomer.phone || "");

    setForm((draft) => {
      draft.name.value = searchedCustomer.name || "";
      draft.name.error = "";
      draft.phoneLocal.value = areaCode ?? "";
      draft.phone.value = phone || "";
      draft.phone.error = "";
      draft.vin.value = searchedCustomer.vin || "";
      draft.vin.error = "";
      draft.modelName.value = searchedCustomer.modelName || "";
      draft.modelName.error = "";
      draft.brandId.value = searchedCustomer.brandId || "";
      draft.brandId.error = "";
      draft.modelYear.value = searchedCustomer.modelYear || "";
      draft.modelYear.error = "";
    });

    if (searchedCustomer.phone) {
      refetch();
    }
  }, [customerInfo]);

  useEffect(() => {
    init();
  }, [initData]);

  useEffect(() => {
    if (isSuccess && noshowInfo) {
      setForm((draft) => {
        draft.noshows.value = `${noshowInfo.noshows}`;
      });
    }
  }, [isFetching]);

  return {
    inputRefs,
    form,
    isSubmitDisabled,
    handleSubmit,
    handleChangeForm,
    handleBlurForm,
    handleKeyDown,
    handleSaveMemo,
    handleDeleteStaff,
    updateMileage,
    regNumAction: {
      changeRegNumState,
      handleSearchCustomer,
      handleSelectRegNum,
      handleCancelRegNum,
    },
  };
};

export default useRepairing;
