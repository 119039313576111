import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const detailModal = css`
  width: 532px;
`;

export const Form = styled.form`
  ${({ theme }) => css`
    padding-top: 24px;
    background-color: ${theme.color.gray_00};
  `}
`;

export const ContentWrapper = styled.div`
  padding: 24px 36px;
`;

export const NoticeWrapper = styled.dl`
  ${({ theme }) => css`
    margin-bottom: 28px;

    & > dt {
      ${theme.font.regular_14};
      margin-bottom: 6px;
      color: ${theme.color.gray_09};
    }

    & > dd {
      ${theme.font.regular_12};
      color: ${theme.color.gray_06};
    }

    & strong {
      color: ${theme.color.blue_01};
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    display: block;
    color: ${theme.color.gray_06};

    & > strong {
      color: ${theme.color.error_01};
    }
  `}
`;

export const passwordInput = css`
  &:last-of-type {
    margin-top: 6px;
  }
`;

export const OriginPasswordWrapper = styled.div`
  position: relative;
  margin-bottom: 40px;

  & > label {
    margin-bottom: 6px;
  }
`;

export const NewPasswordWrapper = styled.div`
  position: relative;

  & > label {
    margin-bottom: 9px;
  }
`;

export const GuideMessage = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_12};
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    margin: 2px 0 10px;
    color: ${theme.color.gray_06};
  `}
`;

export const validMessage = css`
  bottom: -22px;
`;
