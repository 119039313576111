import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const centerLayout = css`
  position: relative;
  padding-top: 0;

  @media screen and (max-height: 900px) {
    height: max-content;
  }
`;

export const reservationVehicleField = css`
  & > h4 {
    margin-bottom: 8px;
  }
`;

export const VehicleDesc = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_06};
  `}
`;
