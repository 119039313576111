import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

import { CalendarButton, MonthPicker, SelectedDate } from "components";
import * as S from "./MonthSelector.styled";

interface MonthSelectorProps {
  className?: any;
  monthYear: any;
  changeMonthYear: any;
  changePrevMonth: any;
  changeNextMonth: any;
  changeCurrentMonthYear: any;
}

const MonthSelector = ({
  className,
  monthYear,
  changeMonthYear,
  changePrevMonth,
  changeNextMonth,
  changeCurrentMonthYear,
}: MonthSelectorProps) => {
  const [searchParams] = useSearchParams();

  const isToday = useMemo(
    () => dayjs(monthYear.value).isSame(dayjs(), "month"),
    [monthYear.value],
  );

  return (
    <S.Root className={className}>
      <SelectedDate>
        <SelectedDate.Date>
          {dayjs(searchParams.get("date")).format("YYYY년 MM월 정비내역")}
        </SelectedDate.Date>
        <SelectedDate.PreviousButton onClick={changePrevMonth} />
        <SelectedDate.TodayButton
          isToday={isToday}
          onClick={changeCurrentMonthYear}
        >
          오늘
        </SelectedDate.TodayButton>
        <SelectedDate.NextButton onClick={changeNextMonth} />
      </SelectedDate>

      <MonthPicker
        cb={changeMonthYear}
        trigger={({ handleToggleDialogOpen, isDialogOpen }: any) => (
          <CalendarButton
            css={S.calendarBtn}
            aria-haspopup="true"
            aria-pressed={isDialogOpen}
            isDialogOpen={isDialogOpen}
            onClick={handleToggleDialogOpen}
          />
        )}
      />
    </S.Root>
  );
};

export default MonthSelector;
