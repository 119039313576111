import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { mixinNavElement, mixin_scrollbar } from "style/mixin";

export const Root = styled.nav`
  ${({ theme }) => css`
    position: relative;
    height: inherit;
    min-width: ${theme.size.NAV_WIDTH};
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    ${mixin_scrollbar(theme)};
    position: fixed;
    padding: 55px 0 24px;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    width: ${theme.size.NAV_WIDTH};
    height: 100%;
    font-size: 14px;
    background-color: ${theme.color.gray_10};
    z-index: ${theme.zIndex.NAV};
  `}
`;

export const NavWrapper = styled.div`
  height: 100%;
`;

export const LogoutButton = styled.button`
  ${({ theme }) => css`
    ${mixinNavElement};
    justify-content: center;
    padding-left: 0;
    min-height: 52px;
    font-size: 14px;

    :hover {
      background-color: ${theme.color.point_01_opacity_20};
    }
  `}
`;
