import { VALID_MESSAGE } from "constants/index";

const useCheckRequired = (
  ref: any,
  state: any,
  setState: any,
  message?: string,
) => {
  const checkRequired = () => {
    let result = false;
    let firstFailEl: any = "";

    for (const el of Object.values(ref.current).filter(Boolean) as any) {
      const isRequired = el.dataset.required;
      const name = el.name;

      if (isRequired && state[name].value.length === 0) {
        result = true;

        if (!firstFailEl) {
          firstFailEl = el;
        }
        setState((draft: any) => {
          draft[name].error = message ?? VALID_MESSAGE.REQUIRED;
        });
      }
    }
    firstFailEl && firstFailEl.focus();
    return result;
  };

  return [checkRequired];
};

export default useCheckRequired;
