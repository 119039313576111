import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { mixinSettingArea } from "style/mixin";

export const Root = styled.article`
  ${({ theme }) => css`
    ${mixinSettingArea(theme)};
    display: grid;
    grid-template-rows: auto 1fr;
  `}
`;

export const NoticeHeader = styled.header`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    & > h3 {
      ${theme.font.medium_18};
      color: ${theme.color.gray_09};
    }
  `}
`;
