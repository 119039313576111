import { useState, useEffect } from "react";

const POSITION_INIT_STATE = {
  prevMouseX: 0,
  prevMouseY: 0,
  translateX: 0,
  translateY: 0,
};
const usePan = (condition: any) => {
  const [isPanning, setPanning] = useState(false);
  const [position, setPosition] = useState(POSITION_INIT_STATE);

  const onMouseDown = (e: any) => {
    e.preventDefault();
    setPanning(true);
    setPosition({
      ...position,
      prevMouseX: e.clientX,
      prevMouseY: e.clientY,
    });
  };

  const mouseup = () => {
    setPanning(false);
  };

  const mousemove = (event: any) => {
    if (isPanning) {
      setPosition({
        translateX: position.translateX + event.clientX - position.prevMouseX,
        translateY: position.translateY + event.clientY - position.prevMouseY,
        prevMouseX: event.clientX,
        prevMouseY: event.clientY,
      });
    }
  };

  const resetPosition = () => {
    setPosition(POSITION_INIT_STATE);
  };

  useEffect(() => {
    condition && setPanning(false);
    window.addEventListener("mouseup", mouseup);
    window.addEventListener("mousemove", mousemove);
    return () => {
      window.removeEventListener("mouseup", mouseup);
      window.removeEventListener("mousemove", mousemove);
    };
  });

  return { position, onMouseDown, setPosition, resetPosition };
};

export default usePan;
