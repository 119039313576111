import React from "react";

import { usePagination } from "hooks";
import * as S from "./Pagination.styled";

interface PaginationProps {
  pageInfo: any;
}

interface PageListProps {
  pageNumList: any[];
  currentPage: number;
  handleClickPageNum: (pageNum: number) => () => void;
}

interface PageWrapperProps {
  children: React.ReactNode;
}

const Pagination = ({ pageInfo }: PaginationProps) => {
  const {
    currentPage,
    pageNumList,
    isDisabledPrev,
    isDisabledNext,
    handleClickPageNum,
    handleClickPrevButton,
    handleClickNextButton,
  } = usePagination(pageInfo);

  return (
    <Pagination.Wrapper>
      <S.Root>
        <S.Button
          type="button"
          onClick={handleClickPrevButton}
          disabled={isDisabledPrev}
        >
          {`< 이전`}
        </S.Button>
        <Pagination.PageList
          currentPage={currentPage}
          pageNumList={pageNumList}
          handleClickPageNum={handleClickPageNum}
        />
        <S.Button
          type="button"
          onClick={handleClickNextButton}
          disabled={isDisabledNext}
        >
          {`다음  >`}
        </S.Button>
      </S.Root>
    </Pagination.Wrapper>
  );
};

Pagination.PageList = function PageList({
  pageNumList,
  currentPage,
  handleClickPageNum,
}: PageListProps) {
  return (
    <S.PageNumWrapper>
      {pageNumList.map((pageNum) => (
        <S.PageNum key={pageNum} currentPage={+currentPage === pageNum}>
          <button type="button" onClick={handleClickPageNum(pageNum)}>
            {pageNum}
          </button>
        </S.PageNum>
      ))}
    </S.PageNumWrapper>
  );
};

Pagination.Wrapper = function Wrapper({ children }: PageWrapperProps) {
  return <S.PaginationWrapper>{children}</S.PaginationWrapper>;
};

export default Pagination;
