import React, { useEffect } from "react";
import { useResetRecoilState } from "recoil";
import { useQueryClient } from "@tanstack/react-query";

import CustomRouter from "router";
import { IESupport, Toast, Modal } from "components";
import { getHolidayInfo, repairShopInfo } from "store";
import { auth, browserInfo } from "utils";
import type { LoginServerModel } from "types";

const Root = () => {
  const queryClient = useQueryClient();
  const resetRepairShopInfo = useResetRecoilState(repairShopInfo);
  const resetHolidayInfo = useResetRecoilState(getHolidayInfo);

  useEffect(() => {
    auth.resolveUser().onAuthStateChanged((user: LoginServerModel | null) => {
      if (!user) {
        resetRepairShopInfo();
        resetHolidayInfo();
        queryClient.clear();
      }
    });
  }, []);

  return (
    <>
      {browserInfo() === "MS IE" ? (
        <IESupport />
      ) : (
        <CustomRouter>
          <Modal />
        </CustomRouter>
      )}
      <Toast />
    </>
  );
};

export default Root;
