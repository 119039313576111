import { css, Theme } from "@emotion/react";

import { calcTableBodyMaxHeight } from "utils";

export const root = (theme: Theme) => css`
  grid-template-columns: auto 199px;
  padding: 0 54px 0 39px;

  & > th:first-of-type,
  td:first-of-type {
    border-right: 1px solid ${theme.color.gray_03};
  }
`;

export const tableBody = css`
  min-height: 333.5px;
  max-height: calc(
    ${calcTableBodyMaxHeight([
      "22px",
      "16px",
      "22px",
      "16px",
      "28px",
      "24px",
      "4px",
    ])}
  );
`;
