import React from "react";

import MonthSelector from "../selector/MonthSelector";
import * as S from "./ReservationHeader.styled";

interface ReservationHeaderProps {
  monthYear: any;
  changeMonthYear: any;
  changePrevMonthYear: any;
  changeNextMonthYear: any;
  changeCurrentMonthYear: any;
}

const ReservationHeader = ({
  monthYear,
  changeMonthYear,
  changePrevMonthYear,
  changeNextMonthYear,
  changeCurrentMonthYear,
}: ReservationHeaderProps) => {
  return (
    <S.Root>
      <S.DateSelectorWrapper>
        <MonthSelector
          monthYear={monthYear}
          changeMonthYear={changeMonthYear}
          changePrevMonth={changePrevMonthYear}
          changeNextMonth={changeNextMonthYear}
          changeCurrentMonthYear={changeCurrentMonthYear}
        />
      </S.DateSelectorWrapper>
    </S.Root>
  );
};

export default ReservationHeader;
