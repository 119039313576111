import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.section`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Container = styled.article`
  ${({ theme }) => css`
    position: relative;
    width: 594px;
    height: 486px;
    margin-right: 126px;
    border-radius: 3px;
    padding: 80px 0;
    background-color: ${theme.color.gray_00};
    transform: translateY(10%);
  `}
`;

export const ContentWrapper = styled.div`
  padding: 0 117px;
`;

export const ContentTop = styled.div`
  ${({ theme }) => css`
    margin-bottom: 28px;

    & h2 {
      ${theme.font.bold_24};
      margin-bottom: 12px;
      color: ${theme.color.gray_09};
    }

    & p {
      ${theme.font.regular_14};
      color: ${theme.color.gray_06};
    }
  `}
`;

export const Form = styled.form`
  position: relative;
`;

export const SetNewPasswordWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin-bottom: 48px;

    & h3 {
      ${theme.font.medium_14};
      display: inline-block;
      margin-bottom: 6px;
      color: ${theme.color.gray_06};
    }
  `}
`;

export const passwordInput = (theme: Theme, validError: any) => css`
  & > input {
    ${theme.font.regular_16};
    border: ${validError
      ? `1px solid ${theme.color.error_01}`
      : `1px solid ${theme.color.gray_02}`};
    color: ${theme.color.gray_09};
    background-color: ${theme.color.white};
  }

  &::placeholder {
    color: ${theme.color.gray_05};
  }

  &:first-of-type {
    margin-bottom: 6px;
  }
`;

export const validMessage = css`
  bottom: -19px;
`;

export const passwordModalCloseButton = css`
  position: absolute;
  top: 24px;
  right: 24px;

  & > svg {
    width: 28px;
    height: 28px;
  }
`;
