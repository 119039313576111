import { PATH } from "constants/index";

export const CONFIGURATION_SITE_MAP = [
  { link: PATH.repairShop, label: "정비소 정보" },
  { link: PATH.staff, label: "직원 관리" },
  { link: PATH.changePassword, label: "비밀번호 변경" },
  { link: PATH.inquiry, label: "1:1 문의내역" },
];

export const CUSTOMER_SERVICE_SITE_MAP = [
  { link: PATH.notice, label: "공지사항" },
  // { link: PATH.event, label: "이벤트" },
  // { link: PATH.faq, label: "자주 묻는 질문(FAQ)" },
  { link: PATH.csInquiry, label: "1:1 문의내역" },
];
