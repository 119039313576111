import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const RootStyle = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid ${theme.color.gray_02};
  `}
`;

export const InfoList = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    border: 1px solid ${theme.color.gray_03};
    background-color: ${theme.color.white};

    & > dl:nth-of-type(1) {
      grid-column: 1;
      grid-row: 1;
    }

    & > dl:nth-of-type(2) {
      grid-column: 2;
      grid-row: 1;
    }

    & > dl:nth-of-type(3) {
      grid-column: 1;
      grid-row: 2;
    }

    & > dl:nth-of-type(4) {
      grid-column: 2;
      grid-row: 2;
    }

    & > dl:nth-of-type(5) {
      position: relative;
      top: -1px;
      grid-column: 3;
      grid-row: 2;
      height: calc(100% + 1px);
      border-top: 1px solid ${theme.color.gray_03};

      & > dd {
        border-right: none;
      }
    }

    & > dl:nth-of-type(6) {
      grid-column: 1;
      grid-row: 3;
    }

    & > dl:nth-of-type(7) {
      grid-column: 2;
      grid-row: 3;
    }

    & > dl:nth-of-type(8) {
      grid-column: 3;
      grid-row: 3;

      & > dd {
        border-right: none;
      }
    }

    & > dl:nth-of-type(9) {
      grid-column: 1;
      grid-row: 4;

      & > dt {
        border-bottom: none;
      }

      & > dd {
        border-right: none;
        border-bottom: none;
      }
    }

    & > dl:nth-of-type(10) {
      position: relative;
      top: -1px;
      left: -1px;
      grid-column: 3;
      grid-row: 4;
      width: calc(100% + 2px);
      height: calc(100% + 1px);
      border-top: 1px solid ${theme.color.gray_03};

      & > dt {
        border-left: 1px solid ${theme.color.gray_03};
        border-bottom: none;
      }

      & > dd {
        border-right: 1px solid ${theme.color.gray_03};
        border-bottom: none;
      }
    }
  `}
`;

export const ItemBox = styled.dl<{ noShowCount: string }>`
  ${({ theme, noShowCount }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;

    & > dt {
      ${theme.font.medium_14};
      flex: 0 0 33.1753%;
      display: flex;
      align-items: center;
      height: 100%;
      border-bottom: 1px solid ${theme.color.gray_03};
      border-right: 1px solid ${theme.color.gray_03};
      padding: 0 20px;
      color: ${theme.color.gray_06};
      background-color: ${theme.color.gray_00};
    }

    & > dd {
      ${theme.font.medium_14};
      flex: 0 0 66.8247%;
      display: flex;
      align-items: center;
      height: 100%;
      border-bottom: 1px solid ${theme.color.gray_03};
      border-right: 1px solid ${theme.color.gray_03};
      padding: 0 20px;
      color: ${theme.color.gray_07};
      background-color: ${theme.color.white};

      &#noshows {
        color: ${noShowCount === "0건"
          ? `${theme.color.gray_07}`
          : `${theme.color.error_01}`};
      }
    }
  `}
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 4px;
  width: 100%;
`;

export const addButton = (theme: Theme) => css`
  ${theme.font.medium_14};
  min-width: auto;
  height: 34px;
  border-radius: 3px;
  padding: 0 14px;
  color: ${theme.color.white};
  background-color: ${theme.color.blue_01};
`;

export const deleteButton = (theme: Theme) => css`
  ${addButton(theme)}
  border: 1px solid ${theme.color.gray_03};
  color: ${theme.color.gray_07};
  background-color: ${theme.color.white};
`;
