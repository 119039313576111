import { css, Theme } from "@emotion/react";

import { calcTableBodyMaxHeight, calcTableWidth } from "utils";

export const root = (theme: Theme) => css`
  grid-template-columns: ${calcTableWidth([
    80, 135, 125, 170, 84, 223, 80, 80,
  ])};

  th:nth-of-type(2),
  th:nth-of-type(6),
  th:nth-of-type(8) {
    border-right: 1px solid ${theme.color.gray_03};
  }
`;

export const tableBody = css`
  min-height: 371.5px;
  height: 100%;
  max-height: calc(${calcTableBodyMaxHeight(["84px", "8px", "2px"])} + 7px);
`;

export const cell = (theme: Theme) => css`
  display: flex;
  justify-content: space-between;
  :nth-of-type(2),
  :nth-of-type(6),
  :nth-of-type(8) {
    border-right: 1px solid ${theme.color.gray_03};
  }
`;
