import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  display: flex;
`;

export const ratingStar = (theme: Theme, percent: string) => css`
  & .cover {
    transform: ${`translateX(${percent})`};
    fill: ${theme.color.blue_01};
  }
`;
