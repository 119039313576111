import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const detailModal = css`
  width: 534px;
`;

export const Content = styled.div`
  padding: 24px 35px;
`;

export const Form = styled.form`
  ${({ theme }) => css`
    border-radius: 4px;
    background-color: ${theme.color.gray_00};
  `}
`;

export const LabelInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 85px 1fr;

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;

export const Label = styled.label`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_07};

    &::after {
      content: "*";
      display: inline-block;
      margin-left: 2px;
      color: ${theme.color.error_01};
    }
  `}
`;

export const maxLengthInput = css`
  width: 270px;
`;

export const textarea = css`
  width: 338px;
  height: 204px;
  padding-left: 12px;

  & > span {
    right: 16px;
    bottom: 9px;
  }
`;

export const validText = css`
  position: static;
  grid-column: 2 / -1;
  margin-top: 6px;
`;
