import React from "react";
import dayjs from "dayjs";

import { Textarea, ValidMessage, Checkbox } from "components";
import { formatMaintenanceDateTime } from "utils";
import * as S from "./CancelForm.styled";

const CANCEL_COMMENT_ID = "accepted-cancel-comment";
const VALID_ERORR_ID = "error-msg-reservation-cacnelComment";

interface CancelFormProps {
  id: string;
  inputRefs: any;
  reservationData: any;
  tabIndex?: number;
  data: any;
  handleChangeData: () => void;
  handleBlurData: () => void;
}

const CancelForm = ({
  id,
  inputRefs,
  reservationData,
  data,
  handleChangeData,
  handleBlurData,
}: CancelFormProps) => {
  const isReservationDateBeforeToday =
    dayjs(new Date()).diff(dayjs(reservationData.planned), "minute") > 0;

  return (
    <div id={id} tabIndex={0}>
      <S.InfoItem isRequired={true}>
        <span>정비 일시</span>
        <S.DateInput
          value={formatMaintenanceDateTime(reservationData.planned)}
          readOnly
        />
      </S.InfoItem>
      <S.InfoItem isRequired={true}>
        <label htmlFor={CANCEL_COMMENT_ID}>예약 취소 사유</label>
        <div>
          <Textarea
            css={S.textarea}
            ref={(el) => (inputRefs.current["cancelComment"] = el)}
            id={CANCEL_COMMENT_ID}
            name="cancelComment"
            maxLength={200}
            value={data.cancelComment.value}
            placeholder="취소사유를 입력하세요"
            validError={data.cancelComment.error}
            data-required="true"
            aria-invalid="true"
            aria-errormessage={VALID_ERORR_ID}
            onChange={handleChangeData}
            onBlur={handleBlurData}
          />
          {isReservationDateBeforeToday && (
            <Checkbox
              css={S.checkbox}
              label="노쇼"
              name="noshow"
              value="y"
              selected={data.noshow.value === "y"}
              handleClick={handleChangeData}
            />
          )}
          {data.cancelComment.error && (
            <ValidMessage css={S.validText} id={VALID_ERORR_ID}>
              {data.cancelComment.error}
            </ValidMessage>
          )}
        </div>
      </S.InfoItem>
    </div>
  );
};

export default CancelForm;
