import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { mixin_scrollbar } from "style/mixin";

export const Root = styled.table`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    border: 1px solid ${theme.color.gray_02};
    border-radius: 3px 3px 0 0;
    background-color: ${theme.color.white};
  `}
`;

export const Trow = styled.tr<{ click?: boolean }>`
  ${({ click, theme }) => css`
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 14px;
    border-bottom: 1px solid ${theme.color.gray_03};
    padding-left: 20px;
    cursor: ${click ? "pointer" : "default"};

    &:nth-of-type(20) {
      border-bottom: 0;
    }
  `}
`;

export const Thead = styled.thead`
  ${({ theme }) => css`
    & > ${Trow} {
      height: 40px;
      border-bottom: 1px solid ${theme.color.gray_03};
      background-color: ${theme.color.gray_00};
    }
  `}
`;

export const Tbody = styled.tbody`
  ${({ theme }) => css`
    ${mixin_scrollbar(theme)};
    position: relative;
    display: flex;
    /* flex: 1;  */ /* NOTE : 전체 디자인 시스템에 어떤 영향을 미칠지 몰라 주석으로 대체합니다. 한지용 : 2023-04-10 */
    flex-direction: column;
    background-color: ${theme.color.white};

    & > ${Trow} {
      min-height: 49px;
      &:hover {
        background-color: ${theme.color.background};
      }
    }
  `}
`;

export const mixinCell = (theme: Theme) => css`
  ${theme.font.medium_14};
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

export const Th = styled.th`
  ${({ theme }) => css`
    ${mixinCell(theme)};
    color: ${theme.color.gray_06};
  `}
`;

export const Td = styled.td`
  ${({ theme }) => css`
    ${mixinCell(theme)};
    color: ${theme.color.gray_08};

    & mark {
      color: ${theme.color.blue_01};
    }

    & > span {
      ${theme.font.medium_14};
      display: inline-block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `}
`;

export const DetailOpenButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:focus-visible {
    position: absolute;
    z-index: 1;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
`;

export const DetailRow = styled.tr`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    border-bottom: 1px solid ${theme.color.gray_02};
    background-color: ${theme.color.gray_00};

    :last-child:nth-of-type(10),
    :last-child:nth-of-type(11) {
      border-bottom: 0;
    }
  `}
`;

export const DetailInfoWrapper = styled.td`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
