import { useEffect } from "react";

const useSetMetaTags = (title: string) => {
  const setMetaTags = () => {
    if (!document.head) return;

    const titleElement = document.head.querySelector("title");
    const ogTitleElement = document.head.querySelector(
      'meta[property="og:title"]',
    );
    const ogSiteNameElement = document.head.querySelector(
      'meta[property="og:site_name"]',
    );

    if (titleElement !== null) {
      titleElement.textContent = title;
    }

    if (ogTitleElement !== null) {
      ogTitleElement.setAttribute("content", title);
    }

    if (ogSiteNameElement !== null) {
      ogSiteNameElement.setAttribute("content", title);
    }
  };

  const resetMetaTags = () => {
    if (!document.head) return;

    const titleElement = document.head.querySelector("title");
    const ogTitleElement = document.head.querySelector(
      'meta[property="og:title"]',
    );
    const ogSiteNameElement = document.head.querySelector(
      'meta[property="og:site_name"]',
    );

    if (titleElement !== null) {
      titleElement.textContent = "";
    }

    if (ogTitleElement !== null) {
      ogTitleElement.setAttribute("content", "");
    }

    if (ogSiteNameElement !== null) {
      ogSiteNameElement.setAttribute("content", "");
    }
  };

  useEffect(() => {
    setMetaTags();

    return () => resetMetaTags();
  }, [title]);
};

export default useSetMetaTags;
