export const color = {
  white: "#FFFFFF",
  background: "#F4F7FA",
  gray_00: "#F9FAFB",
  gray_01: "#F0F2F5",
  gray_02: "#E4E9F1",
  gray_03: "#D4DBE7",
  gray_03_20: "rgba(212, 219, 231, 0.2)",
  gray_04: "#BEC8D6",
  gray_05: "#9DA8B7",
  gray_06: "#737D8C",
  gray_07: "#4E5968",
  gray_08: "#353D46",
  gray_09: "#20252B",
  gray_10: "#0C1014",
  gray_10_85: "rgba(12, 16, 20, 0.85)",
  blue_01: "#388CFF",
  blue_02: "#EAF3FF",
  blue_03: "#0E6AE9",
  error_01: "#F54242",
  error_02: "#FEE3E3",
  sunday: "#B24242",
  saturday: "#0173B2",

  bg_select: "#ECF4FF",
  bg_hover: "rgba(68, 147, 249, 0.1)",
  bg_alpha_2: "rgba(255,255,255, 0.2)",
  bg_secondray_alpha: "rgba(15, 24, 34, 0.85)",
  gray_10_opacity_50: "rgba(12, 16, 20, 0.5)",
  point_01_opacity_10: "rgba(68, 147, 249, 0.1)",
  point_01_opacity_20: "rgba(68, 147, 249, 0.2)",
  text_gray_opacity_40: "rgba(188, 199, 204, 0.4)",
  bg_step_complete_repair: "rgba(65, 77, 107, 0.2)",
  brand_primary_opacity_40: "rgba(68, 147, 249, 0.4)",
  bg_step_proceeding_repair: "rgba(26, 123, 255, 0.2)",
  bg_step_complete_reservation: "rgba(58, 186, 255, 0.2)",

  disabled: "#EAEEf0",
  button_border_gray_light: "#D6DDE0",
} as const;

export type ColorType = typeof color;
