import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const InfoItem = styled.div<{ isRequired?: boolean }>`
  ${({ isRequired, theme }) => css`
    ${theme.font.regular_14};
    display: grid;
    grid-template-columns: 132px 1fr;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    & > span,
    & > label {
      color: ${theme.color.gray_09};

      &::after {
        content: ${isRequired ? "'*'" : null};
        display: inline-block;
        margin-left: 3px;
        font-size: 14px;
        color: ${theme.color.error_01};
      }
    }
  `}
`;

export const textarea = css`
  min-height: 279px;
  padding-left: 11px;

  & > textarea {
    padding-right: 11px;
  }
`;

export const validText = css`
  position: static;
  grid-column: 2 / -1;
  margin-top: 6px;
`;
