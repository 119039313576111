import type {
  CreateBreakTimeQueryModel,
  GetBreaktimesQueryModel,
  GetBreaktimesServerModel,
  GetRequestedReservationsServerModel,
} from "types";
import { ax } from "../axios";

export const getRqstdReservationsAPI = async () => {
  const { data } = await ax.get<GetRequestedReservationsServerModel>(
    "/reservations/requested",
  );

  return data;
};

export const getBreaktimesAPI = async (req: GetBreaktimesQueryModel) => {
  const { data } = await ax.get<GetBreaktimesServerModel>(
    "/schedules/breaktimes",
    { params: req.query },
  );

  return data;
};

export const createBreaktimesAPI = (req: CreateBreakTimeQueryModel) => {
  return ax.post("/schedules/breaktimes", req.body);
};
