import React from "react";

import {
  Button,
  CloseButton,
  LoginPasswordInput,
  ValidMessage,
} from "components";
import useResetPasswordForm from "./hooks/useResetPasswordForm";
import * as S from "./Password.styled";

const VALID_ERORR_ID = "error-msg-mechanic-new-pw";

const Password = () => {
  const {
    inputRefs,
    form,
    isSubmitDisabled,
    isPasswordEyeIcon,
    isPasswordConfirmEyeIcon,
    handleSubmit,
    handleChangeForm,
    handleBlurForm,
    handleCancel,
  } = useResetPasswordForm();

  return (
    <S.Root>
      <S.Container>
        <CloseButton css={S.passwordModalCloseButton} onClick={handleCancel} />
        <S.ContentWrapper>
          <S.ContentTop>
            <h2>비밀번호 재설정</h2>
            <p>
              안정성을 위해 영문(대/소문자), 숫자, 특수문자(!*#& 등)를
              <br />
              활용한 8-16자의 비밀번호를 입력해주세요.
            </p>
          </S.ContentTop>
          <S.Form onSubmit={handleSubmit}>
            <S.SetNewPasswordWrapper>
              <h3>새 비밀번호 설정</h3>
              <LoginPasswordInput
                css={(theme) => S.passwordInput(theme, form.password.error)}
                id="reset-password-dialog-pw"
                name="password"
                ref={(el) => (inputRefs.current[0] = el)}
                value={form.password.value}
                label="새 비밀번호"
                aria-invalid="true"
                aria-errormessage={VALID_ERORR_ID}
                placeholder="새 비밀번호를 입력하세요"
                validError={form.password.error}
                data-required="true"
                isPasswordEyeIcon={isPasswordEyeIcon}
                onChange={handleChangeForm}
                onBlur={handleBlurForm}
              />
              <LoginPasswordInput
                css={(theme) =>
                  S.passwordInput(theme, form.passwordConfirm.error)
                }
                id="reset-password-dialog-pw-confirm"
                name="passwordConfirm"
                ref={(el) => (inputRefs.current[1] = el)}
                value={form.passwordConfirm.value}
                label="새 비밀번호 확인"
                aria-invalid="true"
                aria-errormessage={VALID_ERORR_ID}
                placeholder="확인을 위해 새 비밀번호를 한번 더 입력하세요"
                validError={form.passwordConfirm.error}
                data-required="true"
                isPasswordConfirmEyeIcon={isPasswordConfirmEyeIcon}
                onChange={handleChangeForm}
                onBlur={handleBlurForm}
              />
              {(form.password.error || form.passwordConfirm.error) && (
                <ValidMessage css={S.validMessage} id={VALID_ERORR_ID}>
                  {form.password.error || form.passwordConfirm.error}
                </ValidMessage>
              )}
            </S.SetNewPasswordWrapper>
            <Button
              type="submit"
              variant="primaryLarge"
              disabled={!!isSubmitDisabled}
            >
              완료
            </Button>
          </S.Form>
        </S.ContentWrapper>
      </S.Container>
    </S.Root>
  );
};

export default Password;
