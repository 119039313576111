import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { calcSmallTableWidth, calcTableBodyMaxHeight } from "utils";

export const root = (theme: Theme) => css`
  grid-template-columns: ${calcSmallTableWidth([140, 135, 110, 110, 100])};

  th:nth-of-type(2),
  th:nth-of-type(5) {
    border-right: 1px solid ${theme.color.gray_03};
  }
`;

export const tableBody = css`
  min-height: 289.5px;
  max-height: calc(
    ${calcTableBodyMaxHeight([
        "22px",
        "16px",
        "74px",
        "8px",
        "28px",
        "24px",
        "4px",
      ])} - 8px - 4px
  );

  && > tr:hover div:last-of-type {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }
`;

export const cell = (theme: Theme) => css`
  display: flex;
  justify-content: space-between;

  :nth-of-type(2),
  :nth-of-type(5) {
    border-right: 1px solid ${theme.color.gray_03};
  }
`;

export const JobType = styled.span`
  ${({ theme }) => css`
    &[data-status="y"] {
      color: ${theme.color.blue_01};
    }
  `}
`;

export const MenuBtnWrapper = styled.div`
  position: relative;
  display: none;
  margin-right: 14px;
`;

export const staffNoneData = (theme: Theme) => css`
  & > svg {
    width: 54px;
    height: 54px;
  }

  & > span {
    ${theme.font.medium_16};
    margin-bottom: 16px;
  }
`;

export const staffAddButton = (theme: Theme) => css`
  ${theme.button.secondarySmall}
  height: 36px;
  padding: 0px 14px;
`;

export const StaffTBodyNoneData = styled.tbody`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  & > tr > td > svg {
    width: 54px;
    height: 54px;
  }
`;

export const staffNoHistory = (theme: Theme) => css`
  position: static;
  transform: none;

  & > span {
    ${theme.font.medium_16};
    margin-bottom: 16px;
    color: ${theme.color.gray_05};
  }
`;
