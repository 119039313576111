import React from "react";

import { Pagination } from "components";
import { useFilterParams } from "hooks";
import { useGetNotices } from "services";
import type { GetNoticesQueryModel } from "types";
import NoticeTable from "./Container/table/NoticeTable";
import * as S from "./Notice.styled";

const Notice = () => {
  const { filters } = useFilterParams<GetNoticesQueryModel["query"]>(["page"], {
    page: "1",
    pageSize: "20",
  });

  const { data, isSuccess } = useGetNotices({
    query: filters,
  });

  return (
    <S.Root>
      <S.NoticeHeader>
        <h3>공지사항</h3>
      </S.NoticeHeader>
      <NoticeTable isDone={isSuccess} notices={data?.notices ?? []} />
      {isSuccess && <Pagination pageInfo={data?.pageInfo} />}
    </S.Root>
  );
};

export default Notice;
