import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  position: absolute;
  top: 65px;
  z-index: 1;
`;

export const daumPostCode = (theme: Theme) => css`
  width: 400px !important;
  height: 360px !important;
  border: 1px solid ${theme.color.gray_03};
  border-radius: 3px;
`;
