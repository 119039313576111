import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  position: relative;
`;

export const DialogRoot = styled.dialog`
  ${({ theme }) => css`
    position: absolute;
    top: calc(29px + 4px);
    width: 298px;
    border: 1px solid ${theme.color.gray_03};
    border-radius: 3px;
    padding: 30px 12px 12px;
    background-color: ${theme.color.white};
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
    z-index: 1000;

    &::before {
      top: -21px;
      border-bottom-color: ${theme.color.gray_03};
      z-index: -1;
    }

    &::after {
      top: -19px;
      border-bottom-color: ${theme.color.white};
    }
  `}
`;

export const Header = styled.header`
  margin-bottom: 21px;
`;

export const Navigation = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 48px;
    margin-bottom: 3px;

    & > button {
      width: 24px;
      height: 24px;
    }

    time {
      ${theme.font.bold_18};
      color: ${theme.color.gray_09};
      width: 40px;
      display: block;
      text-align: center;
    }
  `}
`;

export const Year = styled.time`
  ${({ theme }) => css`
    display: block;
    text-align: center;
    ${theme.font.bold_14};
    color: ${theme.color.gray_04};
    white-space: nowrap;
  `}
`;

export const mixinFillColor = (theme: Theme) => css`
  fill: ${theme.color.gray_06};
`;

export const leftIcon = (theme: Theme) => css`
  ${mixinFillColor(theme)}
`;

export const rightIcon = (theme: Theme) => css`
  ${mixinFillColor(theme)}
`;

export const Weeks = styled.ul`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

export const Dates = styled.ul`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

export const WeekLi = styled.li`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    display: flex;
    justify-content: center;
    height: 17px;
    color: ${theme.color.gray_09};

    &:nth-of-type(7n + 1) {
      color: ${theme.color.sunday};
    }

    &:nth-of-type(7n + 7) {
      color: ${theme.color.saturday};
    }
  `}
`;

export const largerViewBtn = css`
  width: 100%;
  margin: 16px 0 8px;
`;
