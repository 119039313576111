import React, { useCallback } from "react";

import { ConfirmModal } from "components";
import { useDeleteStaff } from "services";
interface StaffDelModalProps {
  staffId: string;
  handleModalClose: () => void;
}

const StaffDelModal = React.forwardRef(
  (
    { staffId, handleModalClose }: StaffDelModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { mutate: deleteStaff } = useDeleteStaff();

    const handleDeleteStaff = useCallback(() => {
      deleteStaff(staffId, {
        onSuccess: () => {
          handleModalClose();
        },
      });
    }, [staffId]);

    return (
      <ConfirmModal
        ref={ref}
        title="직원 삭제"
        desc={`삭제된 정보는 복원이나 복구 할 수 없습니다.\n정보를 정말 삭제하시겠습니까?`}
        btnType="alert"
        activeBtnName="삭제"
        cancelBtnName="취소"
        activeFn={handleDeleteStaff}
      />
    );
  },
);

StaffDelModal.displayName = "StaffDelModal";

export default StaffDelModal;
