import { atom } from "recoil";

import type { FreqCommentTemplate, Review } from "types";

const freqCommentState = atom<FreqCommentTemplate[]>({
  key: "freqCommentState",
  default: [],
});

const openCommentState = atom({
  key: "openCommentState",
  default: "",
});

const reviewState = atom<Review[]>({
  key: "reviewState",
  default: [],
});

export { freqCommentState, openCommentState, reviewState };
