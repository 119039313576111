import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { calcTableBodyMaxHeight } from "utils";

export const root = (theme: Theme) => css`
  grid-template-columns: auto 184px;
  padding: 0 20px;

  & > th:first-of-type,
  td:first-of-type {
    border-right: 1px solid ${theme.color.gray_03};
  }
`;

export const tableBody = css`
  min-height: 333.5px;
  max-height: calc(
    ${calcTableBodyMaxHeight([
      "22px",
      "16px",
      "22px",
      "16px",
      "28px",
      "24px",
      "4px",
      "10px",
    ])}
  );
`;

export const noticeNoneData = css`
  & > svg {
    width: 54px;
    height: 54px;
  }
`;

export const NoticeTBodyNoneData = styled.tbody`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  & > tr > td > svg {
    width: 54px;
    height: 54px;
  }
`;

export const noticeNoHistory = css`
  position: static;
  transform: none;
`;
