import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const InfoItem = styled.div<{ isRequired: boolean }>`
  ${({ isRequired, theme }) => css`
    ${theme.font.regular_14};
    display: grid;
    grid-template-columns: 132px 1fr;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    & > span,
    & > label {
      color: ${theme.color.gray_09};

      &::after {
        content: ${isRequired ? "'*'" : null};
        display: inline-block;
        margin-left: 3px;
        font-size: 14px;
        color: ${theme.color.error_01};
      }
    }
  `}
`;

export const DateInput = styled.input`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    width: 100%;
    max-width: 184px;
    height: 34px;
    border: 1px solid ${theme.color.gray_03};
    border-radius: 4px;
    padding: 6px 12px;

    &[readonly] {
      color: ${theme.color.gray_08};
      background-color: ${theme.color.gray_01};
    }
  `}
`;

export const timeInput = css`
  & > div:first-of-type {
    margin-right: 14px;
  }
`;

export const estimatedTimeInput = css`
  & > div:first-of-type {
    margin-right: 14px;
  }

  & > div:last-of-type > input {
    text-align: left;
    width: 78px;
  }
`;
