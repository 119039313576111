import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { isEmpty } from "lodash-es";

import { useGetHolidays } from "services";
import { getHolidayInfo } from "store";

const useHoliday = () => {
  const [holidays, setHolidays] = useRecoilState(getHolidayInfo);
  const { refetch } = useGetHolidays({
    enabled: false,
    onSuccess: (res) => {
      setHolidays(res);
    },
  });

  useEffect(() => {
    if (isEmpty(holidays)) {
      refetch();
    }
  }, []);
};

export default useHoliday;
