import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { calcTableBodyMaxHeight, calcTableWidth } from "utils";

export const root = (theme: Theme) => css`
  grid-template-columns: ${calcTableWidth([107, 90, 135, 125, 170, 85, 180])};

  th:nth-of-type(1),
  th:nth-of-type(3),
  th:nth-of-type(7) {
    border-right: 1px solid ${theme.color.gray_03};
  }

  &[data-status="accepted"],
  &[data-status="repairing"],
  &[data-status="completed"],
  &[data-status="canceled"] {
    grid-template-columns: ${calcTableWidth([
      107, 90, 135, 125, 170, 85, 180, 101,
    ])};

    th:nth-of-type(8),
    td:nth-of-type(8) {
      border-right: 1px solid ${theme.color.gray_03};
    }
  }
`;

export const TBodyNoneData = styled.tbody`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const tableBody = css`
  min-height: 285.5px;
  max-height: calc(
    ${calcTableBodyMaxHeight([
        "30px",
        "32px",
        "36px",
        "30px",
        "34px",
        "16px",
        "2px",
      ])} + 5px
  );
`;

export const cell = (theme: Theme) => css`
  &:nth-of-type(1),
  &:nth-of-type(3),
  &:nth-of-type(7) {
    border-right: 1px solid ${theme.color.gray_03};
  }
`;

export const ReservationMethod = styled.span`
  ${({ theme }) => css`
    && {
      ${theme.font.medium_13};
      width: 70px;
      height: 26px;
      border-radius: 4px;
      padding: 5px 0;
      color: ${theme.color.gray_05};
      background-color: ${theme.color.gray_02};
      text-align: center;

      &[data-status="자체 예약"] {
        color: ${theme.color.blue_01};
        background-color: ${theme.color.blue_02};
      }
    }
  `}
`;

export const noHistory = (theme: Theme) => css`
  position: static;
  transform: none;

  & > span {
    ${theme.font.medium_16};
    margin-bottom: 26px;
    line-height: 1.84;
    text-align: center;
    color: ${theme.color.gray_05};
  }
`;

export const dialogBtn = (theme: Theme) => css`
  ${theme.font.regular_16};
`;

export const configurationLink = (theme: Theme) => css`
  ${theme.button.ghostPrimary};
  ${theme.font.regular_16};
  cursor: pointer;
`;

export const detailLink = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
