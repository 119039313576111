import React from "react";
import { useRecoilValue } from "recoil";

import { Portal } from "components";
import { modalCompoState } from "store";
import * as S from "./Modal.styled";

const Modal = () => {
  const ModalComponent = useRecoilValue(modalCompoState);

  return (
    <Portal container="#modal-root" mounted={ModalComponent.length > 0}>
      {ModalComponent?.map((compo, idx) => (
        <S.Dim key={idx}>{compo}</S.Dim>
      ))}
    </Portal>
  );
};

export default Modal;
