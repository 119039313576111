import { useRef, useState, useEffect } from "react";

const useFileDragDrop = (onDrop: any) => {
  const dropRef = useRef<any>(null);

  const [isDragging, setIsDragging] = useState(false);

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  };

  const handleDragOut = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.webp)$/i;

    setIsDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];

      if (!allowedExtensions.exec(file.name)) {
        alert("jpg(jpeg), png, webp 파일만 업로드 가능합니다.");
        return;
      }
      onDrop(file);
      e.dataTransfer.clearData();
    }
  };

  useEffect(() => {
    const dropEl = dropRef.current;
    if (dropEl) {
      dropEl.addEventListener("dragenter", handleDragIn);
      dropEl.addEventListener("dragleave", handleDragOut);
      dropEl.addEventListener("dragover", handleDrag);
      dropEl.addEventListener("drop", handleDrop);
    }

    return () => {
      dropEl?.removeEventListener("dragenter", handleDragIn);
      dropEl?.removeEventListener("dragleave", handleDragOut);
      dropEl?.removeEventListener("dragover", handleDrag);
      dropEl?.removeEventListener("drop", handleDrop);
    };
  });

  return { dropRef, isDragging };
};

export default useFileDragDrop;
