import React from "react";

import { MobileInput, Input, Radio, DetailModal } from "components";
import { ADD_STAFF_FORM } from "assets";
import useAddStaff from "../../hooks/useAddStaff";
import * as S from "./StaffAddModal.styled";

interface StaffAddModalProps {
  handleModalClose: () => void;
}

const StaffAddModal = React.forwardRef(
  (
    { handleModalClose }: StaffAddModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const {
      inputRefs,
      form,
      isSubmitDisabled,
      handleSubmit,
      handleChangeForm,
      handleBlurForm,
    } = useAddStaff(handleModalClose);

    return (
      <DetailModal
        ref={ref}
        title="직원 추가"
        cancelBtnName="취소"
        posBtnName="등록"
        isPosDisabled={!!isSubmitDisabled}
        posFnType="submit"
        posFn={handleSubmit}
      >
        <S.Container>
          {ADD_STAFF_FORM.map((item) => (
            <S.InputWrapper key={item.label} isRequired={item.isRequired}>
              <label htmlFor={`add-staff-${item.key}`}>{item.label}</label>
              {item.type === "input" ? (
                <Input
                  css={S.input}
                  id={`add-staff-${item.key}`}
                  ref={(el) => (inputRefs.current[item.key] = el)}
                  data-required={item.isRequired ? "true" : ""}
                  name={item.key}
                  value={form[item.key as keyof typeof form].value}
                  placeholder={item.placeholder}
                  validError={form[item.key as keyof typeof form].error}
                  onChange={handleChangeForm}
                  onBlur={handleBlurForm}
                />
              ) : item.type === "phone" ? (
                <MobileInput
                  ref={inputRefs}
                  isRequired={item.isRequired}
                  type="mobile"
                  data={form}
                  phoneKey={item.key}
                  phoneLocalKey={`${item.key as keyof typeof form}Local`}
                  placeholder={item.placeholder ?? ""}
                  readOnly={item.readOnly}
                  handleChange={handleChangeForm}
                  handleBlur={handleBlurForm}
                />
              ) : (
                <S.RadioWrapper>
                  {"radioItems" in item &&
                    (item?.radioItems as any[]).map((radio, idx) => (
                      <Radio
                        css={S.radioItem}
                        key={radio.label}
                        id={`${item.key}-${idx}`}
                        name={item.key}
                        value={radio.value}
                        checked={
                          radio.value ===
                          form[item.key as keyof typeof form].value
                        }
                        onChange={handleChangeForm}
                      >
                        {radio.label}
                      </Radio>
                    ))}
                </S.RadioWrapper>
              )}
              {form[item.key as keyof typeof form].error && (
                <S.ValidText>
                  {form[item.key as keyof typeof form].error}
                </S.ValidText>
              )}
            </S.InputWrapper>
          ))}
        </S.Container>
      </DetailModal>
    );
  },
);

StaffAddModal.displayName = "StaffAddModal";

export default StaffAddModal;
