import { css, Theme } from "@emotion/react";

export const mixinInput = css`
  width: 100%;
  input {
    width: 100%;
    height: 30px;
    border: 1px solid;
    font-size: 20px;

    // 구글 자동완성 css 해제
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    }
    outline: none;
  }
`;

export const mixinSettingArea = (theme: Theme) => css`
  grid-row: 2;
  grid-column: 2;
  border: 1px solid ${theme.color.gray_03};
  padding: 28px 39px 24px;
  background-color: ${theme.color.white};
`;

export const mixinMessage = css`
  height: 20px;
`;

export const mixinNavElement = css`
  display: flex;
  align-items: center;
  height: 52px;
`;

export const mixinInput34 = (theme: Theme) => css`
  display: flex;
  align-items: center;
  width: 100%;
  height: 34px;
  padding: 0 12px;
  border-radius: 3px;
  border: solid 1px ${theme.color.gray_03};
`;
export const mixinInput32 = (theme: Theme) => css`
  ${mixinInput34(theme)}
  height: 32px;
`;

export const mixinSettingHeader = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -8px 0 6px;
  h2 {
    font-size: 18px;
    font-weight: 500;
  }
`;

export const mixin_scrollbar = (theme: Theme) => css`
  position: relative;
  z-index: 10;
  overflow: hidden;
  @supports not (overflow: overlay) {
    overflow: auto;
  }

  ::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.color.gray_04};
    margin-right: 2px;
    border-radius: 50px;
  }
  @supports not (overflow: overlay) {
    scrollbar-width: thin;
    background-color: ${theme.color.gray_04} transparent;
  }

  :hover {
    overflow-y: overlay;
  }
`;
