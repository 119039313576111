export default class Generator {
  private key;

  constructor(key: any, initial = "") {
    this.key = key;
    !this.load() && this.save(initial);
  }

  save(data: any) {
    if (data === null || data === undefined) {
      return;
    }

    return localStorage.setItem(this.key, JSON.stringify(data));
  }

  load() {
    return JSON.parse(localStorage.getItem(this.key) as any);
  }

  clear() {
    localStorage.removeItem(this.key);
  }
}

export const userStorage = new Generator("user");
