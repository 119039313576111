import React from "react";

import { CenterLayout, DiagnosisSolutionContainer } from "components";
import { useSetMetaTags } from "hooks";
import * as S from "./DiagnosisSolution.styled";

const DiagnosisSolution = () => {
  useSetMetaTags("정비관리 | 차량 파손 진단 솔루션");

  return (
    <CenterLayout css={S.centerLayout}>
      <h2 className="a11y">차량 파손 진단 솔루션</h2>
      <DiagnosisSolutionContainer />
    </CenterLayout>
  );
};

export default DiagnosisSolution;
