import React, { useRef, useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useImmer } from "use-immer";
import { isEmpty } from "lodash-es";

import { TruckSearchResultModal } from "components";
import { useCheckRequired, useToast, useModal } from "hooks";
import {
  useCancelReservation,
  useChangeReservation,
  useFetchCustomerNoShowCnt,
  useGetCustomerByRegNum,
  useStartReservation,
  useUpdateReservationMemo,
} from "services";
import {
  estimateHourFormatter,
  deleteFirstZero,
  vinFormatter,
  numericOnly,
  checkRegNumValidation,
  checkVinValidation,
  deleteComma,
  getPhoneInfo,
  findSearchedCustomerInfo,
  checkExpectationTime,
  checkDrivingDistance,
  checkVinExact,
  findCustomerInfoByRegNum,
  formatPlannedDateTime,
  formatUtcISODateTime,
  checkInputLength,
  checkPhoneValidation,
  getCommaThousandUnitInputAfterChange,
  getCommaThousandUnitAfterDeleteMarker,
} from "utils";
import {
  VALID_MESSAGE,
  TOAST_MSG,
  APPROVED_MAINTENANCE,
} from "constants/index";
import type { GetCustomerInfoByRegNumServerModel, ValueOf } from "types";

const INITIAL_STATE = {
  name: { value: "", error: "" },
  phoneLocal: { value: "010", error: "" },
  phone: { value: "", error: "" },
  originRegNum: { value: "", error: "" },
  regNum: { value: "", error: "", step: 1 },
  regNumSearchText: { value: "", error: "" },
  modelName: { value: "", error: "" },
  modelYear: { value: "", error: "" },
  brandId: { value: "", error: "" },
  vin: { value: "", error: "" },
  mileage: { value: "", error: "" },
  axle: { value: "", error: "" },
  seat: { value: "", error: "" },
  userComment: { value: "", error: "" },
  approvedMaintenance: { value: APPROVED_MAINTENANCE.APPROVED, error: "" },
  planned: { value: "", error: "" },
  plannedDate: { value: "", error: "" },
  plannedTimeHour: { value: "", error: "" },
  plannedTimeMin: { value: "", error: "" },
  estimatedTime: { value: "", error: "" },
  estimatedTimeHour: { value: "", error: "" },
  estimatedTimeMin: { value: "", error: "" },
  cancelComment: { value: "", error: "" },
  reservationId: { value: "", error: "" },
  noshow: { value: "n", error: "" },
  noshows: { value: "", error: "" },
  reservationNo: { value: "", error: "" },
  status: { value: "", error: "" },
  isSelf: { value: "", error: "" },
  memo: { value: "", error: "" },
};

const useAccepted = (initData: any) => {
  const inputRefs = useRef({}) as any;
  const isInited = useRef(false);

  const [form, setForm] = useImmer(INITIAL_STATE);
  const [cursor, setCursor] = useState<number | null>(null);
  const [customerInfo, setCustomerInfo] =
    useState<GetCustomerInfoByRegNumServerModel>([]);
  const [checkRequired] = useCheckRequired(
    inputRefs,
    form,
    setForm,
    VALID_MESSAGE.REQUIRED_NO_ASTERISK,
  );

  const { mutate: changeReservationMutate } = useChangeReservation();
  const { mutate: cancelReservationMutate } = useCancelReservation();
  const { mutate: startReservationMutate } = useStartReservation();
  const { mutate: updateReservationMemoMutate } = useUpdateReservationMemo();
  const { data: customerSearchInfos } = useGetCustomerByRegNum();
  const {
    isFetching,
    isSuccess,
    data: noshowInfo,
    refetch,
  } = useFetchCustomerNoShowCnt(`${form.phoneLocal.value}${form.phone.value}`, {
    enabled:
      form.phone.value.length >= 7 &&
      document.activeElement !== inputRefs.current["phone"],
  });
  const { addToast } = useToast();
  const { modalRef, handleModalOpen, handleModalClose } = useModal();

  const isSubmitDisabled =
    Object.values(form).filter((data) => data.error !== "").length > 0;

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();

      const type = form.approvedMaintenance.value as ValueOf<
        typeof APPROVED_MAINTENANCE
      >;

      if (checkRequired()) return;
      if (
        form.approvedMaintenance.value === "approved" &&
        !checkVinValidation(form.vin.value)
      ) {
        setForm((draft) => {
          draft.vin.error = VALID_MESSAGE.VIN_INCORRECT;
        });
        inputRefs.current["vin"].focus();

        return;
      }

      if (form.regNum.step === 2) {
        setForm((draft) => {
          draft.regNum.error = VALID_MESSAGE.REGNUM_SELECT;
        });
        return;
      }

      setForm((draft: any) => {
        for (const key of Object.keys(form)) {
          draft[key].error = "";
        }
      });

      const formatPlannedDate = formatPlannedDateTime(
        form.plannedDate.value,
        form.plannedTimeHour.value,
        form.plannedTimeMin.value,
      );

      switch (type) {
        case "accepted":
          {
            const req = {
              reservationId: initData.reservationId,
              body: {
                estimatedTime: `${form.estimatedTimeHour.value}:${form.estimatedTimeMin.value}`,
              },
            };

            changeReservationMutate(req, {
              onSuccess: () => {
                addToast(TOAST_MSG.SUCCESS.UPDATE_RESERVATION_DONE);
              },
              onError: (err) => {
                switch (err.response?.data.code) {
                  case "INVALID_PLANNED":
                    addToast(TOAST_MSG.FAIL.NOT_CORRECT_DATE);
                }
              },
            });
          }
          break;
        case "approved":
          {
            const req = {
              reservationId: initData.reservationId,
              body: {
                name: form.name.value,
                phone: `${form.phoneLocal.value}${form.phone.value}`,
                ...(form.seat.value && { seat: form.seat.value }),
                ...(form.axle.value && { axle: form.axle.value }),
                ...(form.userComment.value && {
                  userComment: form.userComment.value,
                }),
                ...(form.mileage.value && {
                  mileage: deleteComma(form.mileage.value),
                }),
                selfInfo: {
                  truckId: initData.truckId,
                  vin: form.vin.value,
                  regNum: form.regNum.value,
                  modelName: form.modelName.value.trim(),
                  ...(form.modelYear.value && {
                    modelYear: form.modelYear.value,
                  }),
                  ...(form.mileage.value &&
                    initData.isSelf === "y" && {
                      mileage: deleteComma(form.mileage.value),
                    }),
                },
              },
            };

            startReservationMutate(req, {
              onSuccess: () => {
                addToast(TOAST_MSG.SUCCESS.START_REPAIR);
              },
            });
          }
          break;

        case "change":
          {
            const req = {
              reservationId: initData.reservationId,
              body: {
                planned: formatUtcISODateTime(
                  formatPlannedDate,
                  "YYYY-MM-DD HH:mm",
                ),
                estimatedTime: `${form.estimatedTimeHour.value}:${form.estimatedTimeMin.value}`,
              },
            };

            changeReservationMutate(req, {
              onSuccess: () => {
                addToast(TOAST_MSG.SUCCESS.UPDATE_RESERVATION_DONE);
              },
              onError: (err) => {
                switch (err.response?.data.code) {
                  case "INVALID_PLANNED":
                    addToast(TOAST_MSG.FAIL.NOT_CORRECT_DATE);
                }
              },
            });
          }
          break;

        case "cancel":
          {
            const req = {
              reservationId: initData.reservationId,
              body: {
                cancelComment: form.cancelComment.value.trim(),
                noshow: form.noshow.value,
              },
            };

            cancelReservationMutate(req, {
              onSuccess: () => {
                addToast(TOAST_MSG.SUCCESS.CANCEL_RESERVATION_DONE);
              },
            });
          }
          break;
      }
    },
    [form],
  );

  const handleChangeForm = useCallback(
    (event: any, key: string) => {
      const name = event?.target?.name ?? key;
      let value = event?.target?.value ?? event;
      let error = "";

      switch (name) {
        case "name":
          value = value.replaceAll(" ", "");
          if (!checkInputLength("NAME", value)) return;
          break;

        case "modelName":
          if (!checkInputLength("MODEL_NAME", value)) return;
          break;

        case "regNum":
          value = value.trim();
          if (!checkInputLength("REGNUM", value)) return;

          if (
            form[name as keyof typeof form].error &&
            !checkRegNumValidation(value)
          ) {
            error = VALID_MESSAGE.REGNUM;
          }
          break;

        case "phone":
          if (!checkInputLength("PHONE", value)) return;
          if (
            form[name as keyof typeof form].error &&
            !checkPhoneValidation(value)
          ) {
            error = VALID_MESSAGE.PHONE_NUM;
          }
          break;

        case "vin":
          value = vinFormatter(value);
          if (!checkInputLength("VIN", value)) return;

          if (form[name as keyof typeof form].error) {
            if (!checkVinExact(value)) {
              error = VALID_MESSAGE.VIN;
            } else if (!checkVinValidation(value)) {
              error = VALID_MESSAGE.VIN_INCORRECT;
            }
          }
          break;

        case "axle":
          value = +value;
          break;

        case "mileage": {
          if (!checkDrivingDistance(value)) return;

          const mileageInputInfo = getCommaThousandUnitInputAfterChange(
            event,
            value,
          );
          if (!mileageInputInfo) return;

          value = mileageInputInfo.value;
          setCursor(mileageInputInfo.cursor);

          break;
        }

        case "modelYear":
          value = numericOnly(value);
          if (!checkInputLength("MODEL_YEAR", value)) return;
          break;

        case "plannedDate":
          value = dayjs(value).format("YYYY.MM.DD");
          break;

        case "estimatedTimeHour":
          if (!checkExpectationTime(value)) return;
          value = estimateHourFormatter(value);
          if (value === 0) {
            setForm((draft) => {
              draft.estimatedTimeMin.value = "30";
            });
          }
          break;

        case "noshow":
          value = event.target.checked ? "y" : "n";
          break;

        case "approvedMaintenance": {
          setForm((draft: any) => {
            for (const key of Object.keys(form)) {
              draft[key].error = "";
            }
          });
          break;
        }

        case "userComment":
        case "cancelComment":
        case "memo":
          if (!checkInputLength("COMMON_TEXTAREA", value)) return;
      }

      setForm((draft) => {
        draft[name as keyof typeof form].value = value;
        draft[name as keyof typeof form].error = error;
      });
    },
    [form],
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch ((e.target as HTMLInputElement).name) {
      case "mileage":
        {
          const mileageInfo = getCommaThousandUnitAfterDeleteMarker(e);
          if (!mileageInfo) return;

          setCursor(mileageInfo.cursor);
          setForm((draft) => {
            draft.mileage.value = mileageInfo.value;
          });
        }
        break;
    }
  };

  const handleBlurForm = useCallback(
    (event: any) => {
      const {
        value,
        name,
        dataset: { required },
      } = event.target;
      let error = "";

      if (required && form[name as keyof typeof form].value.length === 0) {
        setForm((draft: any) => {
          draft[name].error = VALID_MESSAGE.REQUIRED_NO_ASTERISK;
        });
        return;
      }

      switch (name) {
        case "phone":
          if (!checkPhoneValidation(value)) {
            error = VALID_MESSAGE.PHONE_NUM;
          } else {
            refetch();
          }
          break;

        case "vin":
          if (!checkVinExact(value)) {
            error = VALID_MESSAGE.VIN;
          } else if (!checkVinValidation(value)) {
            error = VALID_MESSAGE.VIN_INCORRECT;
          }
          break;
        case "modelName":
          if (!value.trim()) {
            error = VALID_MESSAGE.REQUIRED_NO_ASTERISK;
          }
          break;
      }

      setForm((draft: any) => {
        draft[name].error = error;
      });
    },
    [form],
  );

  const handleSaveMemo = useCallback(() => {
    const req = {
      reservationId: form.reservationId.value,
      body: {
        memo: form.memo.value.trim() || null,
      },
    };

    updateReservationMemoMutate(req, {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.SAVE_MEMO_DONE);
      },
    });
  }, [form]);

  const handleSelectDate = useCallback(
    (name: string) => (date: any) => {
      handleChangeForm(date, name);
    },
    [],
  );

  const updateMileage = useCallback((mileage: any) => {
    setForm((draft) => {
      draft.mileage.value = mileage;
    });
  }, []);

  const checkUserInfo = () => {
    const userInfoLabelList = [
      "name",
      "phone",
      "modelName",
      "modelYear",
      "brandId",
    ];

    return !!userInfoLabelList.filter(
      (item) => form[item as keyof typeof form].value,
    ).length;
  };

  const processAfterRegNumSearch = () => {
    changeRegNumState(1)();

    if (form.regNum.error) {
      setForm((draft) => {
        draft.regNum.error = "";
      });
    }
  };

  const handleOverwriteInfo = () => {
    setForm((draft) => {
      draft.originRegNum.value = form.regNum.value;
      draft.regNum.value = form.regNum.value;
    });

    const searchedCustomerInfo = findCustomerInfoByRegNum(
      customerSearchInfos ?? [],
      form.regNum.value,
    );

    setCustomerInfo(searchedCustomerInfo);
    addToast(
      `${form.regNum.value} ${TOAST_MSG.SUCCESS.SEARCH_CUSTOMER_BY_REGNUM_DONE}`,
    );
    processAfterRegNumSearch();
  };

  const changeRegNum = (regNum: string) => {
    setForm((draft) => {
      draft.originRegNum.value = regNum;
      draft.regNum.value = regNum;
    });

    processAfterRegNumSearch();
  };

  const handleModalActive = () => {
    handleOverwriteInfo();
    handleModalClose();
  };

  const handleModalCancel = (regNum: string) => () => {
    changeRegNum(regNum);
    handleModalClose();
  };

  const handleSearchCustomer = useCallback(
    (e: any) => {
      e.preventDefault();

      const regNum =
        e.type === "click"
          ? form.regNum.value
          : form.regNumSearchText.value || form.regNum.value;

      if (!regNum) {
        setForm((draft) => {
          draft.regNum.error = VALID_MESSAGE.REQUIRED_NO_ASTERISK;
        });
        return;
      }

      if (!checkRegNumValidation(regNum)) {
        setForm((draft) => {
          draft.regNum.error = VALID_MESSAGE.REGNUM_EXAM;
        });
        return;
      }

      const searchedCustomerInfo = findCustomerInfoByRegNum(
        customerSearchInfos ?? [],
        regNum,
      );

      if (isEmpty(searchedCustomerInfo)) {
        changeRegNum(regNum);
        addToast(`${regNum} ${TOAST_MSG.FAIL.SEARCH_CUSTOMER_BY_REGNUM_ERROR}`);

        if (!checkUserInfo()) {
          setCustomerInfo(searchedCustomerInfo);
        }
      } else {
        if (checkUserInfo()) {
          handleModalOpen(
            <TruckSearchResultModal
              ref={modalRef}
              handleActive={handleModalActive}
              handleCancel={handleModalCancel(regNum)}
            />,
          )();
        } else {
          setCustomerInfo(searchedCustomerInfo);
          changeRegNum(regNum);
          addToast(
            `${regNum} ${TOAST_MSG.SUCCESS.SEARCH_CUSTOMER_BY_REGNUM_DONE}`,
          );
        }
      }
    },
    [form],
  );

  const handleSelectRegNum = useCallback(
    (regNum: string, selected = false) => {
      if (selected) {
        setForm((draft) => {
          draft["regNumSearchText"].value = regNum;
        });
      } else {
        setForm((draft) => {
          draft.regNum.value = regNum;
          draft.regNum.error = "";
        });
      }
    },
    [form],
  );

  const handleCancelRegNum = () => {
    setForm((draft) => {
      draft.regNum.value = form.originRegNum.value;
    });

    processAfterRegNumSearch();
  };

  const changeRegNumState = (step: number) => () => {
    setForm((draft) => {
      draft.regNum.step = step;
    });
  };

  const init = () => {
    const { areaCode, phone } = getPhoneInfo(initData.phone || "");

    setForm((draft: any) => {
      for (const key in initData) {
        if (draft[key]) {
          draft[key].value = initData[key] ?? "";
          draft[key].error = "";
        }
      }

      draft.vin.value = initData.vin
        ? checkVinValidation(initData.vin)
          ? initData.vin
          : ""
        : "";
      draft.phoneLocal.value = areaCode ?? "";
      draft.phone.value = phone;
      draft.originRegNum.value = initData.regNum;
      draft.plannedDate.value =
        dayjs(initData.planned).format("YYYY.MM.DD") ?? "";
      draft.plannedTimeHour.value =
        Number(dayjs(initData.planned).format("HH:mm").split(":")[0]) ?? "";
      draft.plannedTimeMin.value =
        dayjs(initData.planned).format("HH:mm").split(":")[1] ?? "";
      draft.estimatedTimeHour.value =
        deleteFirstZero(initData.estimatedTime?.split(":")[0]) ?? "";
      draft.estimatedTimeMin.value =
        initData.estimatedTime?.split(":")[1] ?? "";
      draft.approvedMaintenance.value = APPROVED_MAINTENANCE.APPROVED;
    });
  };

  useEffect(() => {
    const mileageInput = inputRefs.current.mileage;

    mileageInput?.setSelectionRange(cursor, cursor);
  }, [inputRefs.mileage, cursor, form.mileage.value]);

  useEffect(() => {
    init();
    isInited.current = true;
  }, [initData]);

  useEffect(() => {
    if (isEmpty(customerInfo)) return;

    const searchedCustomer = findSearchedCustomerInfo(
      customerInfo,
      form.regNum.value,
    );

    if (!searchedCustomer) return;

    const { areaCode, phone } = getPhoneInfo(searchedCustomer.phone || "");

    setForm((draft) => {
      draft.name.value = searchedCustomer.name || "";
      draft.name.error = "";
      draft.phoneLocal.value = areaCode ?? "";
      draft.phone.value = phone || "";
      draft.phone.error = "";
      draft.vin.value = searchedCustomer.vin || "";
      draft.vin.error = "";
      draft.modelName.value = searchedCustomer.modelName || "";
      draft.modelName.error = "";
      draft.brandId.value = searchedCustomer.brandId || "";
      draft.brandId.error = "";
      draft.modelYear.value = searchedCustomer.modelYear || "";
      draft.modelYear.error = "";
    });

    if (searchedCustomer.phone) {
      refetch();
    }
  }, [customerInfo]);

  useEffect(() => {
    const type = form.approvedMaintenance.value;
    const callbackTable: any = {
      [APPROVED_MAINTENANCE.CANCEL]: () => {
        addToast(TOAST_MSG.SUCCESS.SELECT_CANCEL_RESERVATION);
        setForm((draft) => {
          draft.cancelComment.value = "";
          draft.cancelComment.error = "";
          draft.estimatedTimeHour.value = "";
          draft.estimatedTimeHour.error = "";
          draft.estimatedTimeMin.value = "";
          draft.estimatedTimeMin.error = "";
        });
      },
      [APPROVED_MAINTENANCE.CHANGE]: () => {
        setForm((draft: any) => {
          draft.planned.value = dayjs(initData.planned).format(
            "YYYY-MM-DD HH:mm",
          );
          draft.plannedDate.value =
            dayjs(initData.planned).format("YYYY.MM.DD") ?? "";
          draft.plannedTimeHour.value =
            +dayjs(initData.planned).format("HH:mm").split(":")[0] ?? "";
          draft.plannedTimeMin.value =
            dayjs(initData.planned).format("HH:mm").split(":")[1] ?? "";
          draft.estimatedTimeHour.value =
            deleteFirstZero(initData.estimatedTime?.split(":")[0]) ?? "";
          draft.estimatedTimeMin.value =
            initData.estimatedTime?.split(":")[1] ?? "";
        });
      },
    };
    typeof callbackTable[type] === "function" && callbackTable[type]();
  }, [form.approvedMaintenance.value, initData]);

  useEffect(() => {
    if (isSuccess && noshowInfo) {
      setForm((draft) => {
        draft.noshows.value = `${noshowInfo.noshows}`;
      });
    }
  }, [isFetching]);

  return {
    inputRefs,
    form,
    isSubmitDisabled,
    handleSubmit,
    handleChangeForm,
    handleBlurForm,
    handleSelectDate,
    handleSaveMemo,
    handleKeyDown,
    updateMileage,
    regNumAction: {
      changeRegNumState,
      handleSearchCustomer,
      handleSelectRegNum,
      handleCancelRegNum,
    },
  };
};

export default useAccepted;
