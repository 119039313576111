import React from "react";

import { Table } from "components";

interface EventTableRowProps {
  rowData: any;
  columnList: any;
}

const EventTableRow = ({ rowData, columnList }: EventTableRowProps) => {
  return (
    <Table.Row id={rowData.id}>
      {columnList.map((columnData: any) => (
        <Table.Cell key={columnData.key}>
          <span>{rowData[columnData.key]}</span>
        </Table.Cell>
      ))}
    </Table.Row>
  );
};

export default EventTableRow;
