import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.form`
  ${({ theme }) => css`
    position: relative;

    & > input {
      ${theme.font.regular_14};
      width: 100%;
      height: 36px;
      border: 1px solid ${theme.color.gray_03};
      border-radius: 3px;
      padding: 0 39px 0 11px;
      color: ${theme.color.gray_09};

      &::placeholder {
        color: ${theme.color.gray_06};
      }

      &:focus {
        border-color: ${theme.color.blue_01};
      }
    }
  `}
`;

export const searchIcon = (theme: Theme) => css`
  position: absolute;
  top: 9px;
  right: 12px;
  width: 20px;
  height: 20px;
  fill: ${theme.color.gray_05};
`;

export const ResetBtn = styled.button`
  position: absolute;
  top: 9px;
  right: 12px;
  width: 20px;
  height: 20px;

  & > svg {
    pointer-events: none;
  }
`;
