import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const DialogRoot = styled.dialog`
  ${({ theme }) => css`
    position: absolute;
    top: 26px;
    left: 6px;
    width: 261px;
    border: 1px solid ${theme.color.gray_03};
    border-radius: 3px;
    background-color: ${theme.color.white};
    box-shadow: ${theme.boxshadow.shadow2_2_8};
    z-index: 10;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 16px;
      display: block;
      width: 0;
      height: 0;
    }

    &::before {
      top: -21px;
      border-bottom-color: ${theme.color.gray_03};
      z-index: -1;
    }

    &::after {
      top: -19px;
      border-bottom-color: ${theme.color.white};
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    padding: 24px 24px 10px;

    & > label {
      display: inline-block;
      margin-bottom: 6px;
      color: ${theme.color.gray_07};
    }
  `}
`;

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 6px;
    width: 100%;
    height: 100%;

    & > input {
      width: 100%;
      height: 34px;
      padding: 0 12px;
      border: 1px solid ${theme.color.gray_03};
      border-radius: 3px;
      color: ${theme.color.gray_10};

      &::placeholder {
        color: ${theme.color.gray_05};
      }
    }
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    border-top: 1px solid ${theme.color.gray_03};
    padding: 12px 0;
  `}
`;

const buttonMixin = css`
  width: 54px;
`;

export const cancelButton = css`
  ${buttonMixin};
  margin-right: 4px;
`;

export const submitButton = css`
  ${buttonMixin};
`;
