export const dummyNoticeList = Array(10)
  .fill(undefined)
  .map((_, index) => ({
    id: index,
    title: `정비예약을 했는데 오류가 나서 화면에 뜨지 않습니다. 어떡하죠?`,
    content: `질문들이 너무 많아서 간략히 설명드리겠습니다.

    \n C언어에서는 생각보다 warning이 자주 나옵니다. 변수를 선언한 후 사용하지 않았다면 그것도 경고로 뜨는 등경고는 무시하고 진행해도 상관이 없습니다. 경고를 잡을려고 하시면 현재는 전혀 실력이 좋아지실 수 없습니다.(경고의 의미를 다 이해하셔야 하기 때문에..)

경고는 무시하시고 진행해 주세요.

문자열 입력은 사실상 C언어에서 중요한 맥락은 아닙니다. 과제의 취지는 여러분들한테 if 사용법 등을 익히라고내준것입니다.

scanf_s가 나타난 이유가 문자열 입력등에서 scanf의 취약점을 보완하기 위해 나온 것이고, 이러한 이유때문에 문자열 입력시에 scanf_s는 좀 더 명확하게 요구하는 것들이 있고. 이 내용의 구체적인 설명은 현재수업으로는 여러분이 이해하기가 힘듭니다.

이번 과제는 간략히 if 사용법을 학습한다 생각하고 진행해 주세요.`,
    created: `2022-01-05 10:00`,
  }));

export const dummyEventList = Array(20)
  .fill(undefined)
  .map((_, index) => ({
    id: index,
    title: `DTK 서비스센터를 위한 트럭닥터 런칭 이벤트`,
    content: `안녕하세요 고객님, 트럭닥터를 사랑해주시는 고객님께 항상 진심으로 감사드립니다. \n
아래와 같이 트럭닥터의 ‘ 광고성 푸시 수신동의’관리 기준 변경에 대해 안내 드립니다.
    
변경내용
기존: 고객님이 보유하고 계신 기기별로 광고성 푸시 수신동의 관리
변경: 고객님이 가장 최근에 직접 설정하신 ‘광고성 푸시 수신 동의’ 설정에 의해 전체 기기를 통합 관리
*관리 기준이 변경됨에 따라, 여러 기기를 이용하시는 고객님의 경우 가장 최근에 설정한 기기의 '광고성 푸시 수신동의 설정 상태'를 기준으로 전체 기기에 해당 설정이 자동 적용됩니다.`,
    period: "2021.01.06 - 2021.11.06",
    updatedDate: "2021.01.06. 10:00",
  }));

const dummyFaQList1 = Array(3)
  .fill(undefined)
  .map((_, index) => ({
    id: index,
    title: `회원정보 수정은 어떻게 하나요?`,
    category: "회원정보",
    content: `회원정보에서는 이름(개명),아이디(이메일), 휴대폰번호, 비밀번호, 주소록, 배송지를 수정할 수 있습니다.

회원정보 수정하기
[환경설정_정비소 정보]에서 변경 가능합니다. (단, 아이디 변경은 불가합니다.)

회원정보 수정에 문제가 생긴 경우 고객센터 1577–0000으로 유선 문의 주시거나 1:1 문의를 남겨주세요! :)`,
  }));

const dummyFaQList2 = Array(2)
  .fill(undefined)
  .map((_, index) => ({
    id: index + 3,
    title: `고객센터 운영시간은 어떻게 되나요?`,
    category: "고객센터",
    content: `고객센터는 평일 오전 9시 ~ 오후 9시까지 운영을 하고 있습니다.

    추가적으로 카카오톡 고객센터와 CS 센터 전화 상담을 진행하고 있으니 공지사항을 확인 바랍니다 :)`,
  }));

export const dummyFaQList = [...dummyFaQList1, ...dummyFaQList2];

export const FAQ_SORT_OPTION_TABLE = {
  name: "가나다순",
  recent: "최신순",
  likes: "조회순",
};
