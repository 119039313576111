import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const Input = styled.input<{ validError?: boolean }>`
  ${({ theme, validError }) => css`
    ${theme.font.regular_14};
    width: 100%;
    height: 34px;
    padding: 0 33px 0 12px;
    border-radius: 3px;
    border: ${validError
      ? `1px solid ${theme.color.error_01}`
      : `1px solid ${theme.color.gray_03}`};
    color: ${theme.color.gray_09};

    &::placeholder {
      color: ${theme.color.gray_05};
    }

    &:focus {
      border: 1px solid ${theme.color.blue_01};
    }

    &:disabled {
      color: ${theme.color.gray_04};
    }
  `}
`;

export const ToggleButton = styled.button`
  position: absolute;
  right: 9px;
  display: flex;
  align-items: center;
  padding: 3px;
`;

export const eyeIcon = (theme: Theme, active: number) => css`
  fill: ${active ? theme.color.blue_01 : theme.color.gray_04};
`;
