import React from "react";

import { LAYOUT_TYPE } from "constants/index";
import Navbar from "./navbar/Navbar";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import HomeFooter from "./footer/HomeFooter";
import * as S from "./Layout.styled";

interface LayoutProps {
  children: React.ReactNode;
  type: string;
}

const Layout = ({ children, type }: LayoutProps) => {
  return (
    <>
      {type === LAYOUT_TYPE.PUBLIC ? (
        <>
          <S.PublicMain>{children}</S.PublicMain>
          <HomeFooter />
        </>
      ) : (
        <>
          <Navbar />
          <Header />
          <S.PrivateMain>{children}</S.PrivateMain>
          <Footer />
        </>
      )}
    </>
  );
};

export default Layout;
