import React from "react";

import { CheckboxActiveIconImg, CheckboxInActiveIconImg } from "assets";
import * as S from "./TimeCheckBox.styled";

interface TimeCheckProps {
  className?: string;
  children: React.ReactNode;
  value?: string;
  id: string;
  checked?: boolean;
  onChange?: any;
}

const TimeCheckBox = ({
  className,
  children,
  id,
  ...restProps
}: TimeCheckProps) => {
  const { checked } = restProps as any;

  return (
    <S.Root className={className} checked={checked}>
      <input type="checkbox" id={id} {...restProps} />
      <label htmlFor={id}>
        {checked ? <CheckboxActiveIconImg /> : <CheckboxInActiveIconImg />}
        <span>{children}</span>
      </label>
    </S.Root>
  );
};

export default TimeCheckBox;
