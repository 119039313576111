import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import { PreparingServiceModal } from "components";
import { useModal } from "hooks";
import type { SiteMap } from "types";
import * as S from "./Sidebar.styled";

interface SidebarProps {
  siteMap: SiteMap[];
}

const Sidebar = ({ siteMap }: SidebarProps) => {
  const location = useLocation();

  const { modalRef, handleModalOpen } = useModal();
  const isCurrentPage = (path: string) => location.pathname.includes(path);

  return (
    <S.Root>
      {siteMap.map((page) =>
        page.isPreparing ? (
          <S.PreparingButton
            key={page.link}
            onClick={handleModalOpen(<PreparingServiceModal ref={modalRef} />)}
          >
            {page.label}
          </S.PreparingButton>
        ) : (
          <NavLink
            css={S.link}
            key={page.link}
            aria-current={isCurrentPage(page.link) ? "page" : "false"}
            to={page.link}
          >
            {page.label}
          </NavLink>
        ),
      )}
    </S.Root>
  );
};
export default Sidebar;
