import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const CarouselRoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SlideButton = styled.button<{ disabled: boolean }>`
  ${({ theme, disabled }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 36px;
      height: 36px;

      path {
        fill: ${disabled
          ? theme.color.point_01_opacity_20
          : theme.color.blue_01};
      }
    }
  `}
`;

export const CarouselWrapper = styled.div<{ carouselWidth: number }>`
  ${({ carouselWidth }) => css`
    width: ${carouselWidth || 428}px;
    overflow: hidden;
  `}
`;

export const Carousel = styled.div<{
  currentSlide: number;
  carouselHeight: number;
  columnGap?: number;
}>`
  ${({ currentSlide, carouselHeight, columnGap }) => css`
    display: flex;
    column-gap: ${columnGap || 0}px;
    min-height: ${carouselHeight || 584}px;
    transition: all 0.5s ease-in-out;
    transform: translateX(-${currentSlide}00%);
  `}
`;
