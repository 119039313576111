import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import {
  DiagnosisSolutionPreview,
  DiagnosisSolutionResult,
  LoadingSpinner,
} from "components";
import { useCarousel } from "hooks";
import { useDiagnosisSolution, useGetReservationsDetail } from "services";
import * as S from "./DiagnosisSolutionContainer.styled";

const DiagnosisSolutionContainer = () => {
  // TODO: URL로 입력시 data가 없을 때 이동해야할 프로세스 개발 필요
  const [searchParams] = useSearchParams();

  const { data } = useGetReservationsDetail(searchParams.get("reservationId")!);
  const { data: dianosisSolution, mutate: diagnosisSolutionMutate } =
    useDiagnosisSolution();

  const { currentSlide, handleNextSlide, handlePrevSlide } = useCarousel(
    data?.presignedExternals?.length!,
  );

  useEffect(() => {
    if (!data || !data.presignedExternals) return;

    const req = {
      body: {
        imageUrl: data.presignedExternals[currentSlide],
      },
    };
    diagnosisSolutionMutate(req);
  }, [currentSlide, data]);

  if (!data) return <LoadingSpinner />;

  return (
    <section>
      <S.Header>
        <h3>차량 파손 진단</h3>
      </S.Header>
      <S.ContentWrapper>
        <S.LeftContainer>
          <DiagnosisSolutionPreview
            convertedData={dianosisSolution?.file}
            originFiles={data?.presignedExternals}
            currentSlide={currentSlide}
            handleNextSlide={handleNextSlide}
            handlePrevSlide={handlePrevSlide}
          />
        </S.LeftContainer>
        <S.RightContainer>
          <DiagnosisSolutionResult data={dianosisSolution?.damage} />
        </S.RightContainer>
      </S.ContentWrapper>
    </section>
  );
};

export default DiagnosisSolutionContainer;
