export const MOBILE_CODES = {
  "010": "010",
  "011": "011",
  "016": "016",
  "017": "017",
  "019": "019",
};

export const TELEPHONE_AREA_CODES = {
  ...MOBILE_CODES,
  "02": "02",
  "031": "031",
  "032": "032",
  "033": "033",
  "041": "041",
  "042": "042",
  "043": "043",
  "044": "044",
  "051": "051",
  "052": "052",
  "053": "053",
  "054": "054",
  "055": "055",
  "061": "061",
  "062": "062",
  "063": "063",
  "064": "064",
};

export const DIRECT_INPUT_TELEPHONE_AREA_CODES = {
  "": "직접입력",
  ...TELEPHONE_AREA_CODES,
};

export const CONTACT_INFO = {
  mobile: MOBILE_CODES,
  mobileArea: TELEPHONE_AREA_CODES,
  mobileAreaDirect: DIRECT_INPUT_TELEPHONE_AREA_CODES,
};
