import emotionReset from "emotion-reset";
import { css } from "@emotion/react";
import { theme } from "./themes/theme";

const globalStyle = css`
  ${emotionReset}
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 62.5%; // 10px
  }

  #root {
    display: flex;
    width: 100%;
    font-family: "Spoqa Han Sans Neo", "sans-serif";
    background-color: ${theme.color.blue_01};
    color: ${theme.color.gray_10};
  }

  body {
    overflow: auto;
    overflow: overlay;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  img {
    max-width: 100%;
    height: auto;
  }

  input,
  button,
  textarea {
    border: 0;
    padding: 0;
    outline: none;
    background-color: transparent;
    font-family: "Spoqa Han Sans Neo", "sans-serif";
    color: ${theme.color.gray_10};
  }

  input:read-only,
  textarea:read-only {
    cursor: default;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s;
    -webkit-text-fill-color: inherit !important;
  }

  input {
    ::placeholder {
      color: ${theme.color.gray_10};
    }
    :focus {
      border: 1px solid ${theme.color.blue_01};
    }
    background-color: ${theme.color.white};
  }

  textarea {
    ::placeholder {
      color: ${theme.color.gray_10};
    }

    background-color: ${theme.color.white};
  }

  textarea {
    resize: none;

    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: ${theme.color.gray_04};
    }
    ::-webkit-scrollbar-track {
      margin: 8px;
    }
  }

  button {
    white-space: nowrap;
    cursor: pointer;

    &[disabled] {
      cursor: not-allowed;
    }
  }

  input[type="radio"] + label {
    cursor: pointer;
  }

  dialog {
    font-family: "Spoqa Han Sans Neo", "sans-serif";
    border: 0;
  }

  textarea {
    font-family: "Spoqa Han Sans Neo", "sans-serif";
    outline: none;
  }

  input[type="checkbox"]:focus-visible + label {
    outline: 1px solid ${theme.color.blue_01};
    z-index: 1;
  }

  [href]:focus-visible,
  [tabindex]:focus-visible,
  button:focus-visible {
    outline: none;
    box-shadow: 0 0 0 3px rgba(68, 147, 249, 0.65);
  }

  mark {
    background-color: inherit;
  }

  .a11y {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
`;

export default globalStyle;
