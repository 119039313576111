import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const root = css`
  min-width: 566px;
`;

export const Container = styled.div`
  padding: 24px 35px;
  overflow-y: overlay;
`;

export const InputWrapper = styled.div<{ isRequired?: boolean }>`
  ${({ isRequired, theme }) => css`
    display: grid;
    grid-template-columns: 126px 270px;
    align-items: center;

    & > label {
      ${theme.font.regular_14};
      color: ${theme.color.gray_07};

      &::after {
        content: ${isRequired ? "' *'" : null};
        margin-left: 3px;
        color: ${theme.color.error_01};
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }

    &:first-of-type {
      margin-bottom: 4px;
    }
  `}
`;

export const input = css`
  height: 34px;
`;

export const Label = styled.label<{ isRequired: boolean }>`
  ${({ isRequired, theme }) => css`
    color: ${theme.color.gray_07};

    &::after {
      ${theme.font.regular_14};
      content: ${isRequired ? "'*'" : null};
      display: inline-block;
      margin-left: 3px;
      color: ${theme.color.error_01};
    }
  `}
`;

export const RadioWrapper = styled.div`
  display: flex;

  & > div:first-of-type {
    margin-right: 24px;
  }
`;

export const radioItem = (theme: Theme) => css`
  input[type="radio"]:checked + label {
    color: ${theme.color.blue_01};
  }
`;

export const ValidText = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_12};
    grid-column: 2 / -2;
    margin: 6px 0 4px;
    color: ${theme.color.error_01};
  `}
`;
