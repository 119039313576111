import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.aside`
  ${({ theme }) => css`
    position: sticky;
    grid-row: 2;
    grid-column: 1;
    top: 89px;
    display: flex;
    flex-direction: column;
    height: max-content;
    border: 1px solid ${theme.color.gray_03};
    background-color: ${theme.color.white};
    overflow: hidden;
  `}
`;

export const mixinTab = (theme: Theme) => css`
  ${theme.font.medium_14};
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  padding-left: 24px;
  color: ${theme.color.gray_05};

  :not(:last-child) {
    border-bottom: 1px solid ${theme.color.gray_02};
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 48px;
    z-index: 5;
  }

  &:hover::before {
    background-color: ${theme.color.point_01_opacity_10};
  }
`;

export const link = (theme: Theme) => css`
  ${mixinTab(theme)}

  &[aria-current="page"] {
    color: ${theme.color.blue_01};

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 6px;
      height: 100%;
      border-radius: 0 2px 2px 0;
      background-color: ${theme.color.blue_01};
    }
  }
`;

export const PreparingButton = styled.button`
  ${({ theme }) => css`
    ${mixinTab(theme)};
  `}
`;
