import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div``;

export const InfoItem = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: grid;
    grid-template-columns: 132px 1fr;
    color: ${theme.color.gray_09};

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  `}
`;

export const MemoInfoItem = styled(InfoItem)`
  && {
    margin-bottom: 0;
  }
`;

export const Label = styled.label<{ as?: string; isRequired?: boolean }>`
  ${({ isRequired, theme }) => css`
    color: ${theme.color.gray_07};

    &::after {
      content: ${isRequired ? "'*'" : null};
      display: inline-block;
      margin-left: 3px;
      color: ${theme.color.error_01};
    }
  `}
`;

export const InputWrapper = styled.div`
  width: 100%;
`;

export const SuffixWrapper = styled.div`
  display: flex;
`;

export const MobileWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
`;

export const noshow = css`
  align-self: flex-end;
`;

export const mixinEl = css`
  width: 270px;
`;

export const suffixInput = css`
  ${mixinEl};
`;

export const select = css`
  ${mixinEl};
`;

export const textarea = css`
  min-height: 93px;
`;

export const memoTextarea = css`
  height: 123px;
`;

export const Status = styled.span`
  ${({ theme }) => css`
    ${theme.font.bold_14};
    color: ${theme.color.blue_01};

    &[data-status="canceled"] {
      color: ${theme.color.error_01};
    }
  `}
`;

export const SuffixText = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: flex;
    align-items: flex-end;
    margin-left: 6px;
    color: ${theme.color.gray_10};
  `}
`;

export const RadioWrapper = styled.div`
  display: flex;
`;

export const radioButton = (keyname: string) => css`
  width: ${keyname === "axle" ? "50px" : "68px"};
  height: 34px;

  &:not(:last-of-type) {
    margin-right: 6px;
  }
`;

export const validText = css`
  position: static;
  margin-top: 6px;
`;

export const updateDialogButton = (theme: Theme) => css`
  ${theme.button.ghostPrimary};
  margin-left: 6px;
`;

export const ButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const editRegNumBtn = (theme: Theme) => css`
  margin-left: 6px;
  color: ${theme.color.blue_01};
`;

export const updateRegNumBtn = (theme: Theme) => css`
  margin: 0 6px;
  color: ${theme.color.white};
`;

export const dropdownInput = css`
  width: 186px;
  height: 34px;
`;
