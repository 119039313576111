import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Input = styled.input<{ validError?: boolean }>`
  ${({ theme, validError }) => css`
    ${theme.font.regular_16};
    width: 100%;
    height: 45px;
    border: 1px solid
      ${validError ? theme.color.error_01 : theme.color.gray_03_20};
    border-radius: 3px;
    padding: 0 35px 0 12px;
    color: ${theme.color.white};
    background-color: ${theme.color.bg_alpha_2};

    &::placeholder {
      color: ${theme.color.gray_04};
    }

    &:focus {
      border: 1px solid ${theme.color.blue_01};
    }

    &:disabled {
      color: ${theme.color.gray_04};
    }
  `}
`;
