import React from "react";

import { Button, DropdownInput } from "components";
import { CheckIconImg } from "assets";
import { VALID_MESSAGE } from "constants/index";
import * as S from "./CustomerInqueryForm.styled";

const REGNUM_INPUT_ID = "basicInfo-input-regNum";
const VALID_ERORR_ID = "error-msg-reservation-regNum";
const VALID_DONE_ID = "done-msg-reservation-regNum";

interface CustomerInqueryProps {
  inputRefs: any;
  data: any;
  isDisabledRegNum: boolean;
  handleSearchCustomer: any;
  handleChangeData: any;
  handleBlurData?: any;
  handleSelect: any;
}

const CustomerInqueryForm = ({
  inputRefs,
  data,
  isDisabledRegNum,
  handleSearchCustomer,
  handleChangeData,
  handleBlurData,
  handleSelect,
}: CustomerInqueryProps) => {
  return (
    <S.Root>
      <S.Label htmlFor={REGNUM_INPUT_ID} isRequired>
        차량 번호
      </S.Label>
      <S.InputContainer>
        <S.InputWrapper>
          <DropdownInput
            css={S.dropdownInput}
            id={REGNUM_INPUT_ID}
            ref={(el) => (inputRefs.current["regNum"] = el)}
            refEl={inputRefs.current["regNum"]}
            name="regNum"
            value={data.regNum.value}
            searchValue={data.regNumSearchText.value}
            data-required="true"
            aria-invalid="true"
            aria-errormessage={VALID_ERORR_ID}
            validError={data.regNum.error}
            placeholder="차량번호를 입력하세요"
            onChange={handleChangeData}
            onBlur={handleBlurData}
            handleSelect={handleSelect}
            handleSearch={handleSearchCustomer}
          />
          <Button
            css={S.regNumButton}
            type="button"
            variant="primaryMedium"
            disabled={isDisabledRegNum}
            onClick={handleSearchCustomer}
          >
            완료
          </Button>
        </S.InputWrapper>
        {data.regNum.step === 2 ? (
          <S.ValidText id={VALID_DONE_ID} data-status="done">
            <CheckIconImg />
            <span>{VALID_MESSAGE.COMPLETED}</span>
          </S.ValidText>
        ) : (
          <S.ValidText id={VALID_ERORR_ID} data-status="notDone">
            {data.regNum.error === VALID_MESSAGE.REGNUM_SELECT && (
              <CheckIconImg />
            )}
            {data.regNum.error}
          </S.ValidText>
        )}
      </S.InputContainer>
    </S.Root>
  );
};

export default CustomerInqueryForm;
