import React, { useRef, useEffect } from "react";

import { VisuallyHidden } from "components";

interface IntersectionAreaProps {
  onImpression: any;
}

const IntersectionArea = ({ onImpression }: IntersectionAreaProps) => {
  const triggerRef = useRef(null);

  useEffect(() => {
    const fetchMoreObserver = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          onImpression();
        }
      },
    );
    if (triggerRef.current) {
      fetchMoreObserver.observe(triggerRef.current);
    }

    return () => {
      fetchMoreObserver.disconnect();
    };
  });

  return (
    <div ref={triggerRef}>
      <VisuallyHidden>더 보기</VisuallyHidden>
    </div>
  );
};

export default IntersectionArea;
