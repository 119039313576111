import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  position: relative;
`;

export const DialogRoot = styled.dialog`
  ${({ theme }) => css`
    position: absolute;
    top: calc(29px + 5px);
    width: 298px;
    border: 1px solid ${theme.color.gray_03};
    border-radius: 3px;
    padding: 24px 16px;
    background-color: ${theme.color.white};
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
    z-index: 1000;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 8px;
      display: block;
    }

    &::before {
      top: -21px;
      border-bottom-color: ${theme.color.gray_03};
      z-index: -1;
    }

    &::after {
      top: -19px;
      border-bottom-color: ${theme.color.white};
    }
  `}
`;

export const Header = styled.header`
  margin-bottom: 20px;
`;

export const Navigation = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 25px;

    & > button {
      width: 24px;
      height: 24px;
    }

    time {
      ${theme.font.bold_18};
      color: ${theme.color.gray_10};
      width: 40px;
      display: block;
      text-align: center;
    }
  `}
`;

export const mixinFillColor = (theme: Theme) => css`
  fill: ${theme.color.gray_04};
`;

export const leftIcon = (theme: Theme) => css`
  ${mixinFillColor(theme)};
`;

export const rightIcon = (theme: Theme) => css`
  ${mixinFillColor(theme)};
`;

export const Months = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 4px;
`;
