import { ax } from "../axios";

import type {
  GetDiagnosisSolutionQueryModel,
  GetDiagnosisSolutionServerModel,
} from "types";

export const getDiagnosisSolutionAPI = async (
  req: GetDiagnosisSolutionQueryModel,
) => {
  const { data } = await ax.post<GetDiagnosisSolutionServerModel>(
    "/reservations/estimate",
    req.body,
  );

  return data;
};
