import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";

import {
  createBreaktimesAPI,
  getBreaktimesAPI,
  getRqstdReservationsAPI,
} from "apis";
import { formatDate } from "utils";
import type {
  APIError,
  CreateBreakTimeQueryModel,
  GetBreaktimesQueryModel,
  GetRequestedReservationsClientModel,
} from "types";

const breaktimeKeys = {
  all: ["breaktime"] as const,
  lists: () => [...breaktimeKeys.all, "list"] as const,
  list: (filters: GetBreaktimesQueryModel) =>
    [...breaktimeKeys.lists(), { filters }] as const,
};

export const useGetRequestedReservations =
  (): UseQueryResult<GetRequestedReservationsClientModel> => {
    return useQuery({
      queryKey: ["requestedReservation"],
      queryFn: () => getRqstdReservationsAPI(),
      select: (res) => [
        ...res.reservations.map((reservation) => ({
          ...reservation,
          date: formatDate(reservation.date, "YYYY.MM.DD HH:mm"),
          requested: formatDate(reservation.requested, "YYYY.MM.DD HH:mm"),
        })),
      ],
    });
  };

export const useGetBreaktimes = (
  req: GetBreaktimesQueryModel,
): UseQueryResult<string[]> => {
  return useQuery({
    queryKey: breaktimeKeys.list(req),
    queryFn: () => getBreaktimesAPI(req),
    enabled: false,
    select: (res) => [
      ...res.breaktimes.map((breaktime) => formatDate(breaktime, "HH:mm")),
    ],
  });
};

export const useCreateBreaktimes = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, CreateBreakTimeQueryModel>({
    mutationFn: (req) => createBreaktimesAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(breaktimeKeys.lists());
    },
  });
};
