import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    & > svg {
      fill: ${theme.color.white};
    }
  `}
`;

export const ImgWrapper = styled.div`
  ${({ theme }) => css`
    min-width: 46px;
    min-height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${theme.color.white};
    border-radius: 100px;

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 100px;
    }
  `}
`;
