import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";

import { useFilterParams } from "hooks";
import { useGetReviews } from "services";
import { reviewState } from "store";
import type { GetReviewQueryModel } from "types";

const useLoadReview = () => {
  const [searchParams] = useSearchParams();
  const answered = searchParams.get("answered");

  const ITEM_COUNT = 10;
  const [nextCursor, setNextCursor] = useState<string | undefined>(undefined);

  const [reviews, setReviews] = useRecoilState(reviewState);

  const { filters } = useFilterParams<GetReviewQueryModel["query"]>(
    answered !== "total" ? ["answered", "sort"] : ["sort"],
  );

  const { data, isSuccess } = useGetReviews({
    query: {
      ...filters,
      nextCursor,
      itemCount: ITEM_COUNT,
    } as GetReviewQueryModel["query"],
  });

  const loadMoreReviews = useCallback(() => {
    if (isSuccess && data?.reviews.length === ITEM_COUNT) {
      setNextCursor(data.nextCursor);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (!nextCursor) {
      setReviews(data?.reviews ?? []);
    } else {
      setReviews([...reviews, ...(data?.reviews ?? [])]);
    }
  }, [data?.reviews]);

  useEffect(() => {
    setNextCursor(undefined);
  }, [searchParams]);

  return {
    totalCnt: data?.totalCnt,
    totalAvg: data?.totalAvg,
    reviews,
    isSuccess,
    loadMoreReviews,
  };
};

export default useLoadReview;
