import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const detailModal = css`
  width: 534px;
`;

export const Content = styled.div`
  padding: 24px 35px;
`;

export const LabelInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 105px 1fr;

  &:not(:last-of-type) {
    margin-bottom: 6px;
  }
`;

export const Label = styled.label`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_07};

    &::after {
      content: "*";
      display: inline-block;
      margin-left: 2px;
      color: ${theme.color.error_01};
    }
  `}
`;

export const maxLengthInput = css`
  width: 270px;
`;

export const textarea = css`
  min-height: 146px;
  padding-left: 12px;

  & > span {
    right: 16px;
    bottom: 9px;
  }
`;

export const cancelButton = css`
  min-width: 90px;
  margin-right: 10px;
`;

export const registerButton = css`
  min-width: 90px;
`;

export const validText = css`
  position: static;
  grid-column: 2 / -1;
  margin-top: 6px;
`;
