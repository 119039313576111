// NOTE : import { ... }  from "assets"를 적용하면 새로고침 때 에러가 발생합니다.
import {
  ScheduleIconImg,
  CustomerIconImg,
  ReviewIconImg,
  SettingIconImg,
} from "assets/icon";
import { PATH } from "constants/index";

const NAV_LIST = [
  {
    navigatePath: PATH.reservation,
    includePath: [
      PATH.root,
      PATH.reservation,
      PATH.reservationDetail,
      PATH.reservationSchedule,
      PATH.diagnosisSolution,
    ],
    Icon: ScheduleIconImg,
    content: "정비관리",
  },
  {
    navigatePath: PATH.customer,
    includePath: [PATH.customer, PATH.customerDetail],
    Icon: CustomerIconImg,
    content: "고객관리",
  },
  {
    navigatePath: PATH.review,
    includePath: [PATH.review],
    Icon: ReviewIconImg,
    content: "리뷰관리",
  },
  {
    navigatePath: PATH.repairShop,
    includePath: [
      PATH.configuration,
      PATH.repairShop,
      PATH.staff,
      PATH.changePassword,
      PATH.inquiry,
      PATH.inquiryCreate,
      PATH.inquiryDetail,
    ],
    Icon: SettingIconImg,
    content: "환경설정",
  },
];

export { NAV_LIST };
