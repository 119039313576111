import { css, Theme } from "@emotion/react";

const menuBtn = (theme: Theme) => css`
  width: 42px;
  height: 28px;
  padding: 4px 8px;
  color: ${theme.color.gray_05};
  background-color: transparent;
`;

export const deleteBtn = (theme: Theme) => css`
  ${menuBtn(theme)};

  &:hover {
    color: ${theme.color.error_01};
    background-color: ${theme.color.error_02};
  }
`;

export const editBtn = (theme: Theme) => css`
  ${menuBtn(theme)};
  border: 0;

  &:hover {
    color: ${theme.color.blue_01};
  }
`;
