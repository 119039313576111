import React from "react";

import { TooltipIconImg } from "assets";
import * as S from "./Tooltip.styled";

interface TooltipProps {
  className?: string;
  text: string;
}

const Tooltip = ({ className, text }: TooltipProps) => {
  const newLineMessage = text?.replace("\n", "<br />");

  return (
    <S.Wrapper className={className} aria-describedby="tip">
      <TooltipIconImg css={S.tooltipIcon} />
      <S.Content>
        <p
          id="tip"
          role="tooltip"
          dangerouslySetInnerHTML={{ __html: newLineMessage }}
        ></p>
      </S.Content>
    </S.Wrapper>
  );
};

export default Tooltip;
