import { useState, useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";

import { useInput, useToast } from "hooks";
import { useReportReview } from "services";
import { reviewState } from "store";
import {
  INPUT_MAX_LENGTH,
  TOAST_MSG,
  REVIEW_SORT_OPTION_TABLE,
} from "constants/index";
import type { Review } from "types";

interface UseReportReveiwProps {
  reviewId: string;
  handleClose: () => void;
}

type SelectedCategoryType =
  | "advertising"
  | "obscenity"
  | "termAbuse"
  | "prsinExpsr"
  | "directInput";

const useReportReveiwForm = ({
  reviewId,
  handleClose,
}: UseReportReveiwProps) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [validError, setValidError] = useState(false);
  const [reviews, setReviews] = useRecoilState(reviewState);

  const { mutate: reportReviewMutate } = useReportReview();

  const [reason, handleChangeReason, setReason] = useInput(
    "",
    (v: any) => v.length > INPUT_MAX_LENGTH.REPORT_REVIEW_CONTENT,
  );
  const { addToast } = useToast();

  const isSubmitDisabled = reason === "" || !reason.trim();

  const handleSelectCategory = useCallback((option: any) => {
    setSelectedCategory(option);
  }, []);

  const initReportForm = useCallback(() => {
    setSelectedCategory("");
    setReason("");
  }, []);

  const handleSubmit = () => {
    const req = {
      reviewId,
      body: {
        reportReason: reason.trim(),
      },
    };

    reportReviewMutate(req, {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.REPORT_REVIEW_DONE);

        const targetReview = reviews.find(
          (reveiw) => reveiw.reservationId === req.reviewId,
        ) as Review;

        const updatedReview = { ...targetReview, reported: true };

        const updatedReviewList = reviews.map((review) =>
          review.reservationId === req.reviewId ? updatedReview : review,
        );

        setReviews(updatedReviewList);
        handleClose();
      },
      onError: (err) => {
        switch (err.response?.data.code) {
          case "INVALID_REPORT_REASON":
            addToast(TOAST_MSG.FAIL.INVALID_REPORT_REASON);
            break;
          case "ALREADY_REPORTED":
            addToast(TOAST_MSG.FAIL.REPORTED_REVIEW);
            break;
        }
      },
    });
  };

  const handleOnBlurReason = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    if (!event.target.value.trim()) {
      setValidError(true);
    }
  };

  useEffect(() => {
    const reasonValue =
      selectedCategory === "directInput"
        ? ""
        : REVIEW_SORT_OPTION_TABLE[selectedCategory as SelectedCategoryType] ??
          "";

    setReason(reasonValue);
  }, [selectedCategory]);

  useEffect(() => {
    if (reason) {
      setValidError(false);
    }
  }, [reason]);

  return {
    selectedCategory,
    reason,
    isSubmitDisabled,
    validError,
    handleSelectCategory,
    handleChangeReason,
    handleOnBlurReason,
    handleSubmit,
    initReportForm,
  };
};

export default useReportReveiwForm;
