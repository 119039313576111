import React from "react";

import {
  ReservationForm,
  Button,
  ReservationInfo,
  RepairingForm,
} from "components";
import { useRepairing } from "hooks";
import { IS_SELF_TABLE } from "constants/index";
import * as S from "./Repairing.styled";

interface RepairngProps {
  reservationData: any;
}

type IsSelfTalbeType = "y" | "n";

const Repairng = ({ reservationData }: RepairngProps) => {
  const {
    inputRefs,
    form,
    isSubmitDisabled,
    handleSubmit,
    handleChangeForm,
    handleBlurForm,
    handleKeyDown,
    handleSaveMemo,
    handleDeleteStaff,
    updateMileage,
    regNumAction,
  } = useRepairing(reservationData);

  return (
    <ReservationForm
      title="예약 상세"
      requestDate={reservationData.requested}
      handleSubmit={handleSubmit}
    >
      <ReservationForm.LeftContent>
        <ReservationInfo
          inputRefs={inputRefs}
          data={form}
          reservationData={reservationData}
          accepted={reservationData.accepted}
          status={reservationData.status}
          isSelf={IS_SELF_TABLE[reservationData.isSelf as IsSelfTalbeType]}
          handleChangeData={handleChangeForm}
          handleBlurData={handleBlurForm}
          handleSaveMemo={handleSaveMemo}
          handleKeyDown={handleKeyDown}
          updateMileage={updateMileage}
          regNumAction={regNumAction}
        />
      </ReservationForm.LeftContent>
      <ReservationForm.RightContent>
        <ReservationForm.Field title="정비 정보">
          <RepairingForm
            inputRefs={inputRefs}
            reservationData={reservationData}
            data={form}
            handleChangeData={handleChangeForm}
            handleBlurData={handleBlurForm}
            handleKeyDown={handleKeyDown}
            handleDeleteStaff={handleDeleteStaff}
          />
          <Button
            css={S.submitButton}
            type="submit"
            disabled={isSubmitDisabled}
          >
            정비 완료
          </Button>
        </ReservationForm.Field>
      </ReservationForm.RightContent>
    </ReservationForm>
  );
};

export default Repairng;
