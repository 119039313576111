/* eslint-disable no-useless-escape */
import { deleteHyphen, deleteComma } from "utils";
import { INPUT_MAX_LENGTH, INPUT_MAX_VALUE } from "constants/index";

export const checkPhoneNumValidation = (data: string) => {
  const regExp = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;

  return regExp.test(data);
};

export const checkEmailValidation = (data: string) => {
  const regExp = RegExp(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
  );
  return regExp.test(data);
};

// NOTE: 비밀번호 정규식 주석처리
export const checkPasswordValidation = (password: string) => {
  //  8~16자의 영문 대문자, 숫자, 특수문자 중 최소 2종류
  const checkSpeacial = /(?=.*[!\@\]\#\$\%\^\&\*\(\)\-\_=\+\{\}\;\:\,\<\.\>])/; //  특수 문자 확인하는 정규식
  const checkNumber = /(?=.*[0-9])/; //  숫자 체크
  const checkBigLetter = /(?=.*[A-Z])/; //  대문자 체크
  const checkSmallLetter = /(?=.*[a-z])/;
  const checkLength = /^.{8,16}$/; //  길이 체크

  const checkList = [
    checkSpeacial.test(password),
    checkNumber.test(password),
    checkBigLetter.test(password),
    checkSmallLetter.test(password),
  ];
  // 4개 중 2개가 true 이면 true
  const boolenCheckThreeOfTwo = checkList.filter(Boolean).length >= 2;

  return checkLength.test(password) && boolenCheckThreeOfTwo;
};

export const checkPasswordConfirmValidation = (
  password: string,
  data: string,
) => {
  return password === data;
};

export const checkNull = (detectList: any[]) => {
  return detectList?.some(
    (data) => data === "" || data?.length === 0 || data === false,
  );
};

export const checkFirstNum = (value: string) => {
  if (value === "0") {
    return value;
  }

  if (typeof value === "string" && value[0] === "0") {
    value = value.slice(1);
  }
  return value;
};

export const checkRegNumValidation = (regNum: string) => {
  const regExp = RegExp(/^[0-9]{2,3}[가-힣]{1}[\s]{0,1}[0-9]{4}$/);
  const regExp2 = RegExp(/^[가-힣]{2}[0-9]{1,2}[가-힣]{1}[\s]{0,1}[0-9]{4}$/);

  return regExp.test(regNum) || regExp2.test(regNum);
};

export const checkVinValidation = (vin: string) => {
  const regExp = RegExp(/^[A-HJ-NPR-Z0-9]{17}$/);

  return vin.length === 0 || regExp.test(vin);
};

export const checkNameValidation = (name: string) => {
  const regex = /^[가-힣a-zA-Z]+$/;

  return regex.test(name);
};

export const checkLandLinePhone = (phone: string) => {
  const regex = RegExp(
    /^(010|02|031|032|033|041|042|043|044|051|052|053|054|055|061|062|063|064)\d{7,8}$/g,
  );

  return regex.test(deleteHyphen(phone));
};

export const checkPhoneValidation = (phone: string) =>
  phone.length >= 7 && phone.length <= 8;

export const checkCellPhoneValidation = (phone: string) =>
  phone.length >= 8 && phone.length <= 12;

export const checkDrivingDistance = (drivingDistance: string) =>
  +deleteComma(drivingDistance) <= INPUT_MAX_VALUE.DRIVING_DISTANCE;

export const checkVinExact = (vin: string) =>
  vin.length === INPUT_MAX_LENGTH.VIN;
export const checkPrice = (price: string) =>
  +deleteComma(price) <= INPUT_MAX_VALUE.PRICE;
export const checkExpectationTime = (time: number) =>
  time <= INPUT_MAX_VALUE.EXPECTATION_TIME;
export const checkActualTime = (time: number) =>
  time <= INPUT_MAX_VALUE.ACTUAL_TIME;

export const checkInputLength = (
  type: keyof typeof INPUT_MAX_LENGTH,
  value: string,
) => value.length <= INPUT_MAX_LENGTH[type];
