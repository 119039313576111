import React from "react";

import { VisuallyHidden, Select, SearchInput, Button } from "components";
import { useModal } from "hooks";
import { CUSTOMER_SORT_OPTION_TABLE, PlusIconImg } from "assets";
import CustomerAddModal from "../modal/customerAdd/CustomerAddModal";
import * as S from "./CustomerHeader.styled";

interface CustomerHeaderProps {
  totalCount: number;
  selectedSortOption: string;
  handleSelectSortOption: (option: string) => void;
}

const CustomerHeader = ({
  totalCount,
  selectedSortOption,
  handleSelectSortOption,
}: CustomerHeaderProps) => {
  const { modalRef, handleModalOpen, handleModalClose } = useModal();

  return (
    <S.Root>
      <S.TitleWrapper>
        <h2>고객관리</h2>
        <VisuallyHidden>고객 리스트 총 갯수</VisuallyHidden>
        <span>({totalCount?.toLocaleString()})</span>
      </S.TitleWrapper>
      <S.SearchFilterWrapper>
        <Select
          css={S.sortSelect}
          id="customer-list-sort"
          selectedOption={selectedSortOption}
          labelTable={CUSTOMER_SORT_OPTION_TABLE}
          onSelect={handleSelectSortOption}
        >
          {Object.entries(CUSTOMER_SORT_OPTION_TABLE).map(([value, label]) => (
            <Select.Option key={label} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
        <SearchInput
          css={S.searchInput}
          id="customer"
          placeholder="고객명 또는 차량 번호를 입력해주세요."
        />
        <Button
          icon={<PlusIconImg css={S.plusIcon} />}
          onClick={handleModalOpen(
            <CustomerAddModal
              ref={modalRef}
              handleModalClose={handleModalClose}
            />,
          )}
        >
          고객 추가
        </Button>
      </S.SearchFilterWrapper>
    </S.Root>
  );
};

export default CustomerHeader;
