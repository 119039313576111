import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const DialogRoot = styled.dialog`
  ${({ theme }) => css`
    position: absolute;
    top: 24px;
    width: 268px;
    border: 1px solid ${theme.color.gray_03};
    border-radius: 3px;
    background-color: ${theme.color.white};
    z-index: 10;
    box-shadow: ${theme.boxshadow.shadow2_2_8};

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 75px;
      display: block;
      width: 0;
      height: 0;
    }

    &::before {
      top: -21px;
      border-bottom-color: ${theme.color.gray_02};
      z-index: -1;
    }

    &::after {
      top: -19px;
      border-bottom-color: ${theme.color.white};
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    padding: 24px 24px 10px;

    & > label {
      display: inline-block;
      margin-bottom: 6px;
      color: ${theme.color.gray_07};
    }
  `}
`;

export const mixinEl = css`
  height: 34px;
`;

export const hourInput = css`
  &:not(:last-of-type) {
    margin-right: 9px;
  }

  & > input {
    ${mixinEl};
  }
`;

export const minSelector = css`
  & > div {
    width: 90px;

    & > button {
      ${mixinEl};
    }
  }
`;

export const ErrorText = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_12};
    margin-top: 4px;
    color: ${theme.color.error_01};
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    border-top: 1px solid ${theme.color.gray_03};
    padding: 12px 0;
  `}
`;

export const buttonMixin = css`
  width: 54px;
`;

export const cancelButton = css`
  ${buttonMixin};
  margin-right: 4px;
`;

export const submitButton = css`
  ${buttonMixin};
`;
