import React, { useCallback } from "react";
import { useRecoilValue } from "recoil";

import { Table } from "components";
import { useGetFaqDetail } from "services";
import { detailInfoRowOpenState } from "store";
import * as S from "./FaQTableRow.styled";

interface FaQTableRowProps {
  rowData: any;
  columnList: any[];
}

const FaQTableRow = ({ rowData, columnList }: FaQTableRowProps) => {
  const detailInfoRowClicked = useRecoilValue(detailInfoRowOpenState);

  const { refetch, data, isSuccess } = useGetFaqDetail(rowData.faqId, {
    enabled: detailInfoRowClicked === rowData.faqId,
  });

  const handleShowDetailTab = useCallback(() => {
    refetch();
  }, [rowData]);

  return (
    <>
      <Table.ClickRow id={rowData.faqId} handleClick={handleShowDetailTab}>
        {columnList.map((columnData) => (
          <Table.Cell key={columnData.key}>
            <span>{rowData[columnData.key]}</span>
          </Table.Cell>
        ))}
      </Table.ClickRow>

      {isSuccess && (
        <Table.DetailRow css={S.tableDetailRow} id={rowData.faqId}>
          <S.TitleWrapper>
            <S.TitleContent>{data?.title}</S.TitleContent>
          </S.TitleWrapper>
          <S.ContentWrapper>
            <S.QuestionContent>{data?.content}</S.QuestionContent>
          </S.ContentWrapper>
        </Table.DetailRow>
      )}
    </>
  );
};

export default FaQTableRow;
