import React from "react";
import * as S from "./HomeFooter.styled";

const HomeFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <S.Root>
      <span>© {currentYear} Coconut Silo.</span>
    </S.Root>
  );
};

export default HomeFooter;
