import React from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

import { DatePicker, SelectedDate, CalendarButton } from "components";
import useDateSelector from "../hooks/useDateSelector";
import * as S from "./DateSelector.styled";

interface DateSelectorProps {
  className?: string;
}

const DateSelector = ({ className }: DateSelectorProps) => {
  const [searchParams] = useSearchParams();

  const date = searchParams.get("date")
    ? dayjs(searchParams.get("date"))
    : dayjs();

  const {
    handlePreviousDate,
    getIsToday,
    handleChangeTodayDate,
    handleNextDate,
    selectDateCallback,
  } = useDateSelector(date);

  return (
    <S.Root className={className}>
      <SelectedDate>
        <SelectedDate.Date>
          {date.format("MM월 DD일 정비내역")}
        </SelectedDate.Date>
        <SelectedDate.PreviousButton onClick={handlePreviousDate} />
        <SelectedDate.TodayButton
          isToday={getIsToday()}
          onClick={handleChangeTodayDate}
        >
          오늘
        </SelectedDate.TodayButton>
        <SelectedDate.NextButton onClick={handleNextDate} />
      </SelectedDate>
      <DatePicker
        cb={selectDateCallback}
        calendarLargerView
        trigger={({ handleToggleDialogOpen, isDialogOpen }: any) => (
          <CalendarButton
            css={S.calendarButton}
            aria-haspopup="true"
            aria-pressed={isDialogOpen}
            isDialogOpen={isDialogOpen}
            onClick={handleToggleDialogOpen}
          />
        )}
      />
    </S.Root>
  );
};

export default DateSelector;
