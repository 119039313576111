import React from "react";
import dayjs from "dayjs";
import { isEmpty } from "lodash-es";

import { CenterLayout, Pagination, Button } from "components";
import { useFilterParams, useSetMetaTags } from "hooks";
import { useGetReservationExcel, useGetReservationsDaily } from "services";
import { formatUtcISODateTime } from "utils";
import { RESERVATION_TABINFO } from "assets";
import type { GetReservationsDailyQueryModel, ReservationStatus } from "types";
import ReservationTable from "./container/table/ReservationTable";
import TabList from "./container/tabList/TabList";
import DateSelector from "./detail/container/selector/DateSelector";
import useTab from "./hooks/useTab";
import * as S from "./Reservation.styled";

const ReservationMain = () => {
  const { filters } = useFilterParams<GetReservationsDailyQueryModel["query"]>(
    ["date", "status", "page", "tab"],
    {
      date: formatUtcISODateTime(dayjs().format("YYYY-MM-DD"), "YYYY-MM-DD"),
      tab: "planned",
    },
  );

  const { refetch } = useGetReservationExcel({ query: filters });
  const { isSuccess, data: reservationInfo } = useGetReservationsDaily({
    query: filters,
  });

  const { tab, handleChangeTab } = useTab<ReservationStatus>("planned");

  const handleClickExcelDownload = () => {
    refetch();
  };

  useSetMetaTags("정비관리 | 정비소");

  return (
    <CenterLayout>
      <h2 className="a11y">예약 스케줄</h2>
      <DateSelector />
      <TabList
        tabInfo={RESERVATION_TABINFO}
        currentTab={tab}
        handleChangeTab={handleChangeTab}
      />
      <S.ReservationInfo id={RESERVATION_TABINFO[tab].panel}>
        <S.TotalCnt>
          <span>상세 내역</span>
        </S.TotalCnt>
        {tab === "accepted" && (
          <Button
            css={S.downloadBtn}
            type="button"
            variant="secondarySmall"
            disabled={isEmpty(reservationInfo?.reservations)}
            onClick={handleClickExcelDownload}
          >
            엑셀 다운로드
          </Button>
        )}
      </S.ReservationInfo>
      <ReservationTable
        isDone={isSuccess}
        reservationArr={reservationInfo?.reservations}
        tabName={tab}
      />
      <Pagination pageInfo={reservationInfo?.pageInfo ?? {}} />
    </CenterLayout>
  );
};

export default ReservationMain;
