import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  ${({ theme }) => css`
    height: fit-content;
    border: 1px solid ${theme.color.gray_03};
  `}
`;

export const WeekWrapper = styled.ul`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 1px;
    border-bottom: 1px solid ${theme.color.gray_03};
    background-color: ${theme.color.gray_03};
  `}
`;

export const WeekLi = styled.li`
  ${({ theme }) => css`
    ${theme.font.medium_16};
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 40px;
    padding: 13px 15px;
    color: ${theme.color.gray_07};
    text-align: end;
    background-color: ${theme.color.gray_00};

    &:first-of-type {
      color: ${theme.color.sunday};
    }

    &:last-of-type {
      color: ${theme.color.saturday};
    }
  `}
`;

export const DateWrapper = styled.ul`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 1px;
    background-color: ${theme.color.gray_03};
  `}
`;
