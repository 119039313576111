import React from "react";
import { Outlet } from "react-router-dom";

import { CenterLayout } from "components";
import { useSetMetaTags } from "hooks";
import { CUSTOMER_CENTER_NUMBER } from "constants/index";
import type { SiteMap } from "types";
import Sidebar from "../sidebar/Sidebar";
import * as S from "./SideLayout.styled";

interface SideLayoutProps {
  siteMap: SiteMap[];
  title: string;
  isCsCenter: boolean;
}

const SideLayout = ({ siteMap, title, isCsCenter }: SideLayoutProps) => {
  useSetMetaTags(`${title} | 정비소`);

  return (
    <CenterLayout css={S.centerLayout}>
      <S.PageHeader>
        {isCsCenter && <SideLayout.CsCenterHeader />}
        {!isCsCenter && <SideLayout.InquiryHeader />}
      </S.PageHeader>
      <h2 className="a11y">{title}</h2>
      <Sidebar siteMap={siteMap} />

      <Outlet />
    </CenterLayout>
  );
};

export default SideLayout;

SideLayout.CsCenterHeader = function CsCenterHeader() {
  return (
    <>
      <S.PageTitle>트럭닥터 고객센터</S.PageTitle>
      <S.CSInfoBox>
        고객센터 {CUSTOMER_CENTER_NUMBER}
        <span>(이용시간: 월~금 09:00~ 18:00 )</span>
      </S.CSInfoBox>
    </>
  );
};

SideLayout.InquiryHeader = function InquiryHeader() {
  return <S.PageTitle>환경설정</S.PageTitle>;
};
