import React, { useCallback, useState } from "react";

import { VisuallyHidden } from "components";
import { useSetMetaTags } from "hooks";
import LoginForm from "./container/form/LoginForm";
import FindPasswordDialog from "./container/dialog/FindPasswordDialog";
import * as S from "./Login.styled";

const Login = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleFindPasswordDialog = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useSetMetaTags("로그인 | 정비소");

  return (
    <S.Root>
      <S.Container>
        {isOpen && (
          <FindPasswordDialog
            handleFindPasswordDialog={handleFindPasswordDialog}
          />
        )}
        <VisuallyHidden>로그인</VisuallyHidden>
        <S.ContentWrapper>
          <S.TitleWrapper>
            <h3>트럭닥터 시작하기</h3>
            <span>전화 없는 정비예약 관리, 트럭닥터</span>
          </S.TitleWrapper>
          <LoginForm handleFindPasswordDialog={handleFindPasswordDialog} />
        </S.ContentWrapper>
      </S.Container>
    </S.Root>
  );
};
export default Login;
