import React from "react";
import { useSetRecoilState, useRecoilState } from "recoil";

import { Button } from "components";
import { useToast } from "hooks";
import { useDeleteCommentByReviewId } from "services";
import { openCommentState, reviewState } from "store";
import { CommentIconImg } from "assets";
import { TOAST_MSG } from "constants/index";
import type { Review } from "types";
import * as S from "./ReplyComment.styled";

interface ReplyCommentProps {
  reviewId: string;
  comment: string;
}

const ReplyComment = ({ reviewId, comment }: ReplyCommentProps) => {
  const setOpenCommentState = useSetRecoilState(openCommentState);
  const [reviews, setReviews] = useRecoilState(reviewState);

  const { mutate: deleteCommentByReviewIdMutate } =
    useDeleteCommentByReviewId();

  const { addToast } = useToast();

  const handleDelete = () => {
    deleteCommentByReviewIdMutate(reviewId, {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.DELETE_REVIEW_CMNT_DONE);

        const targetReview = reviews.find(
          (reveiw) => reveiw.reservationId === reviewId,
        ) as Review;

        const deleteReview = { ...targetReview, reply: "" };

        const deleteReviewList = reviews.map((review) =>
          review.reservationId === reviewId ? deleteReview : review,
        );

        setReviews(deleteReviewList);
      },
    });
    setOpenCommentState("");
  };

  const handleUpdate = () => {
    setOpenCommentState(reviewId);
  };

  return (
    <S.Root>
      <S.Comment>
        <CommentIconImg css={S.commentIcon} />
        <p>{comment}</p>
      </S.Comment>

      <S.ButtonWrapper>
        <Button
          css={S.deleteButton}
          variant="secondarySmall"
          onClick={handleDelete}
        >
          삭제
        </Button>
        <Button variant="primarySmall" onClick={handleUpdate}>
          수정
        </Button>
      </S.ButtonWrapper>
    </S.Root>
  );
};

export default ReplyComment;
