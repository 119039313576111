import React, { forwardRef } from "react";

import { Radio } from "components";
import * as S from "./RadioButton.styled";

interface RadioButtonProps {
  className?: string;
  children: React.ReactNode;
  role?: string;
  id: string;
  name: string;
  value: string | number;
  checked: boolean;
  onChange: any;
  restProps?: any;
}

const RadioButton = (
  { children, id, value, onChange, ...restProps }: RadioButtonProps,
  ref: any,
) => {
  return (
    <Radio
      css={S.root}
      ref={ref}
      id={id}
      value={value}
      onChange={onChange}
      {...restProps}
    >
      {children}
    </Radio>
  );
};

export default forwardRef(RadioButton);
