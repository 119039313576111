import { boxshadow } from "./boxshadow";
import { button } from "./button";
import { color } from "./color";
import { size } from "./size";
import { font } from "./font";
import { zIndex } from "./zIndex";

export const theme = {
  boxshadow,
  button,
  color,
  size,
  font,
  zIndex,
};
