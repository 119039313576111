import React from "react";

import { LeftIconImg, RightIconImg } from "assets";
import * as S from "./Carousel.styled";

interface CarouselProps {
  currentSlide: number;
  totalNumberOfSlides: number;
  children: React.ReactNode;
  carouselWidth: number;
  carouselHeight: number;
  columnGap?: number;
  handleNextSlide: () => void;
  handlePrevSlide: () => void;
}

const Carousel = ({
  currentSlide,
  totalNumberOfSlides,
  children,
  columnGap,
  carouselWidth,
  carouselHeight,
  handleNextSlide,
  handlePrevSlide,
}: CarouselProps) => {
  return (
    <S.CarouselRoot>
      <S.SlideButton
        type="button"
        aria-label="이전 이미지"
        disabled={currentSlide === 0}
        onClick={handlePrevSlide}
      >
        <LeftIconImg />
      </S.SlideButton>
      <S.CarouselWrapper carouselWidth={carouselWidth}>
        <S.Carousel
          currentSlide={currentSlide}
          carouselHeight={carouselHeight}
          columnGap={columnGap}
        >
          {children}
        </S.Carousel>
      </S.CarouselWrapper>
      <S.SlideButton
        type="button"
        aria-label="다음 이미지"
        disabled={currentSlide === totalNumberOfSlides}
        onClick={handleNextSlide}
      >
        <RightIconImg />
      </S.SlideButton>
    </S.CarouselRoot>
  );
};

export default Carousel;
