import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 1px solid ${theme.color.gray_02};
    padding: 0;
  `}
`;

export const onCalendarIcon = (theme: Theme) => css`
  pointer-events: none;

  path:nth-of-type(2) {
    fill: ${theme.color.blue_01} !important;
  }
`;

export const offCalendarIcon = (theme: Theme) => css`
  pointer-events: none;

  path:nth-of-type(2) {
    fill: ${theme.color.blue_01} !important;
  }
`;
