import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { mixinSettingArea } from "style/mixin";

export const Root = styled.article`
  ${({ theme }) => css`
    ${mixinSettingArea(theme)};
  `}
`;

export const Wrapper = styled.div`
  margin-bottom: 24px;
`;

export const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_02};
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    ${theme.font.medium_18};
    color: ${theme.color.gray_09};
  `}
`;

export const CreatedBox = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: flex;
    align-items: flex-start;
    color: ${theme.color.gray_04};
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  padding: 16px 0 24px 0;
`;

export const NoticeTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    width: 100%;

    & > label {
      color: ${theme.color.gray_07};
    }

    & > label,
    & > h3 {
      ${theme.font.regular_14};
    }
  `}
`;

export const NoticeContent = styled.div`
  ${({ theme }) => css`
    & > label {
      ${theme.font.regular_14};
      color: ${theme.color.gray_07};
    }
  `}
`;

export const Contents = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    width: 100%;
    padding: 6px 0;
    color: ${theme.color.gray_09};

    & > div.se-image-container {
      margin: 12px 0;

      & > figure > img {
        border-radius: 12px;
      }
    }
  `}
`;
