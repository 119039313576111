import React from "react";

import { DetailModal } from "components";
import { ExclamationIconImg } from "assets";
import * as S from "./PreparingServiceModal.styled";

const PreparingServiceModal = React.forwardRef(
  (_, ref: React.ForwardedRef<HTMLDialogElement>) => {
    return (
      <DetailModal css={S.baseModal} ref={ref}>
        <S.Header>
          <span className="a11y">서비스 준비중입니다.</span>
        </S.Header>
        <S.Content>
          <ExclamationIconImg css={S.exclamationIcon} />
          <S.TextWrapper>
            <dt aria-hidden="true">서비스 준비중이에요!</dt>
            <dd>
              보다 나은 서비스 제공을 위하여 페이지 준비중에 있어요 문의를
              원하시면 아래 대표 전화번호로 연락해주세요 :)
            </dd>
          </S.TextWrapper>
          <S.TelText>TEL . 02.564.0309</S.TelText>
        </S.Content>
      </DetailModal>
    );
  },
);

PreparingServiceModal.displayName = "PreparingServiceModal";

export default PreparingServiceModal;
