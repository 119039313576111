import type {
  GetStaffsServerModel,
  CreateStaffQueryModel,
  UpdateStaffQueryModel,
  GetStaffsQueryModel,
} from "types";
import { ax } from "../axios";

export const getStaffsAPI = async (
  req: GetStaffsQueryModel,
): Promise<GetStaffsServerModel> => {
  const { data } = await ax.get("/staffs", { params: req.query });

  return data;
};

export const createStaffAPI = (req: CreateStaffQueryModel) => {
  return ax.post("/staffs", req.body);
};

export const updateStaffAPI = (req: UpdateStaffQueryModel) => {
  return ax.put(`/staffs/${req.staffId}`, req.body);
};

export const deleteStaffAPI = (staffId: string) => {
  return ax.delete(`/staffs/${staffId}`);
};
