import React from "react";
import { useRecoilValue } from "recoil";

import { repairShopInfo } from "store";
import { ShorteningIconImg, DefaultProfileImg } from "assets";
import type { RepairShopInfo } from "types";
import * as S from "./Profile.styled";

const Profile = () => {
  const repairShop = useRecoilValue<RepairShopInfo>(repairShopInfo);

  return (
    <S.Root>
      <S.ImgWrapper>
        <img
          src={repairShop.thumbnail || DefaultProfileImg}
          alt="프로필 이미지"
        />
      </S.ImgWrapper>
      <ShorteningIconImg />
    </S.Root>
  );
};

export default Profile;
