import React, { useEffect, useState } from "react";

import { Carousel, LoadingSpinner } from "components";
import type { GetDiagnosisSolutionServerModel } from "types";
import * as S from "./DiagnosisSolutionPreview.styled";

interface DiagnosisSolutionPreviewProps {
  convertedData?: GetDiagnosisSolutionServerModel["file"];
  originFiles: (string | null)[] | null;
  currentSlide: number;
  handleNextSlide: () => void;
  handlePrevSlide: () => void;
}

const DiagnosisSolutionPreview = ({
  convertedData,
  originFiles,
  currentSlide,
  handleNextSlide,
  handlePrevSlide,
}: DiagnosisSolutionPreviewProps) => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  const base64Image = `data:image/jpeg;base64,${convertedData}`;

  const calculateImageSize = (): void => {
    const image = new Image();
    image.src = base64Image;
    image.onload = () => {
      setSize({ width: image.width, height: image.height });
    };
  };

  const isWidthMoreThanHeight = size.width > size.height;

  useEffect(() => {
    calculateImageSize();
  }, [convertedData]);

  return (
    <S.DiagnosisSolutionPreviewWrapper>
      <S.Title>{`차량 외부 사진(${currentSlide + 1}/${
        originFiles?.length
      }): 상세 사진`}</S.Title>
      {originFiles && convertedData ? (
        <Carousel
          currentSlide={currentSlide}
          totalNumberOfSlides={originFiles.length - 1}
          carouselWidth={isWidthMoreThanHeight ? 426 : 240}
          carouselHeight={!isWidthMoreThanHeight ? 584 : 240}
          columnGap={2}
          handlePrevSlide={handlePrevSlide}
          handleNextSlide={handleNextSlide}
        >
          {originFiles.map((originFile, index) => (
            <S.PreviewWrapper key={index}>
              <S.Label isOrigin>이미지 원본</S.Label>
              <S.Preview
                width={size.width}
                height={size.height}
                isWidthMoreThanHeight={isWidthMoreThanHeight}
                src={originFile!}
              />
              <S.Label isOrigin={false}>파손 진단 결과</S.Label>
              <S.Preview
                width={size.width}
                height={size.height}
                isWidthMoreThanHeight={isWidthMoreThanHeight}
                src={base64Image}
              />
            </S.PreviewWrapper>
          ))}
        </Carousel>
      ) : (
        <LoadingSpinner />
      )}
    </S.DiagnosisSolutionPreviewWrapper>
  );
};

export default DiagnosisSolutionPreview;
