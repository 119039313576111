import React from "react";

import {
  Select,
  MobileInput,
  Input,
  ValidMessage,
  NoshowCnt,
} from "components";
import { useGetBrands } from "services";
import { SELF_RESERVATION_BASIC_INFO } from "assets";
import * as S from "./BasicInfo.styled";

interface BasicInfoProps {
  inputRefs: any;
  data: any;
  noshowCnt: number;
  handleKeyDown: any;
  handleChangeData: any;
  handleBlurData: any;
}

const BasicInfo = ({
  inputRefs,
  data,
  noshowCnt,
  handleKeyDown,
  handleChangeData,
  handleBlurData,
}: BasicInfoProps) => {
  const { data: brands } = useGetBrands();

  return (
    <S.Root>
      <S.OtherInfoWrapper>
        {SELF_RESERVATION_BASIC_INFO.map((item) => (
          <div key={item.key}>
            <S.Label
              htmlFor={`basicInfo-input-${item.key}`}
              isRequired={item.isRequired}
            >
              {item.label}
            </S.Label>
            <S.InfoWrapper>
              {item.type === "text" ? (
                <S.InputWrapper>
                  <Input
                    css={S.input}
                    id={`basicInfo-input-${item.key}`}
                    ref={(el) =>
                      item.key !== "regNum" &&
                      (inputRefs.current[item.key] = el)
                    }
                    name={item.key}
                    value={data[item.key].value}
                    data-required={item.isRequired ? "true" : ""}
                    validError={
                      item.key === "regNum" ? false : data[item.key].error
                    }
                    placeholder={item.placeholder}
                    onChange={handleChangeData}
                    onBlur={handleBlurData}
                    readOnly={item.readOnly}
                    onKeyDown={handleKeyDown}
                  />
                  {item.suffixText && (
                    <S.SuffixText>{item.suffixText}</S.SuffixText>
                  )}
                </S.InputWrapper>
              ) : item.type === "phone" ? (
                <S.InputWrapper>
                  <MobileInput
                    ref={inputRefs}
                    isRequired={item.isRequired}
                    data={data}
                    phoneKey={item.key}
                    phoneLocalKey={`${item.key}Local`}
                    placeholder={item.placeholder}
                    readOnly={item.readOnly}
                    handleChange={handleChangeData}
                    handleBlur={handleBlurData}
                  />
                  <NoshowCnt css={S.noshow} noshowCnt={noshowCnt} />
                </S.InputWrapper>
              ) : (
                <Select
                  css={S.select}
                  name={item.key}
                  forwardRef={(el: any) => (inputRefs.current[item.key] = el)}
                  labelTable={item.key === "brandId" ? brands : item.labelTable}
                  required={item.isRequired}
                  placeholder={item.placeholder}
                  selectedOption={data[item.key].value}
                  validError={data[item.key].error}
                  onSelect={handleChangeData}
                  onBlur={handleBlurData}
                >
                  {Object.entries(
                    item.key === "brandId"
                      ? brands ?? {}
                      : item.labelTable ?? {},
                  ).map(([value, label]: [string, any]) => (
                    <Select.Option key={value} value={value}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              )}
              {item.key !== "regNum" && data[item.key].error && (
                <ValidMessage css={S.validText}>
                  {data[item.key].error}
                </ValidMessage>
              )}
            </S.InfoWrapper>
          </div>
        ))}
      </S.OtherInfoWrapper>
    </S.Root>
  );
};

export default BasicInfo;
