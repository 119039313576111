import React, { forwardRef } from "react";

import * as S from "./Button.styled";

export type ButtonVariant =
  | "primarySmall"
  | "primaryMedium"
  | "primaryLarge"
  | "secondarySmall"
  | "secondaryMedium"
  | "secondaryLarge"
  | "warningMedium"
  | "ghostPrimary"
  | "ghostSecondary";

interface ButtonProps {
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  variant?: ButtonVariant;
  icon?: React.ReactNode;
  type?: any;
  onClick?: any;
  restProps?: any;
}

const Button = forwardRef(
  (
    { children, icon, variant = "primarySmall", ...restProps }: ButtonProps,
    ref?: any,
  ) => {
    return (
      <S.Root
        type="button"
        ref={ref}
        hasIcon={!!icon}
        variant={variant}
        {...restProps}
      >
        {icon && icon}
        {children}
      </S.Root>
    );
  },
);

Button.displayName = "Button";

export default Button;
