import React from "react";

import * as S from "./CenterLayout.styled";

interface CenterLayoutProps {
  className?: string;
  children: React.ReactNode;
}

const CenterLayout = ({ children, className }: CenterLayoutProps) => {
  return <S.Root className={className}>{children}</S.Root>;
};

export default CenterLayout;
