export const NOT_ALLOWED_TIME_LIST = [
  { key: "00:00", label: "00:00 ~ 00:30" },
  { key: "00:30", label: "00:30 ~ 01:00" },
  { key: "01:00", label: "01:00 ~ 01:30" },
  { key: "01:30", label: "01:30 ~ 02:00" },
  { key: "02:00", label: "02:00 ~ 02:30" },
  { key: "02:30", label: "02:30 ~ 03:00" },
  { key: "03:00", label: "03:00 ~ 03:30" },
  { key: "03:30", label: "03:30 ~ 04:00" },
  { key: "04:00", label: "04:00 ~ 04:30" },
  { key: "04:30", label: "04:30 ~ 05:00" },
  { key: "05:00", label: "05:00 ~ 05:30" },
  { key: "05:30", label: "05:30 ~ 06:00" },
  { key: "06:00", label: "06:00 ~ 06:30" },
  { key: "06:30", label: "06:30 ~ 07:00" },
  { key: "07:00", label: "07:00 ~ 07:30" },
  { key: "07:30", label: "07:30 ~ 08:00" },
  { key: "08:00", label: "08:00 ~ 08:30" },
  { key: "08:30", label: "08:30 ~ 09:00" },
  { key: "09:00", label: "09:00 ~ 09:30" },
  { key: "09:30", label: "09:30 ~ 10:00" },
  { key: "10:00", label: "10:00 ~ 10:30" },
  { key: "10:30", label: "10:30 ~ 11:00" },
  { key: "11:00", label: "11:00 ~ 11:30" },
  { key: "11:30", label: "11:30 ~ 12:00" },
  { key: "12:00", label: "12:00 ~ 12:30" },
  { key: "12:30", label: "12:30 ~ 13:00" },
  { key: "13:00", label: "13:00 ~ 13:30" },
  { key: "13:30", label: "13:30 ~ 14:00" },
  { key: "14:00", label: "14:00 ~ 14:30" },
  { key: "14:30", label: "14:30 ~ 15:00" },
  { key: "15:00", label: "15:00 ~ 15:30" },
  { key: "15:30", label: "15:30 ~ 16:00" },
  { key: "16:00", label: "16:00 ~ 16:30" },
  { key: "16:30", label: "16:30 ~ 17:00" },
  { key: "17:00", label: "17:00 ~ 17:30" },
  { key: "17:30", label: "17:30 ~ 18:00" },
  { key: "18:00", label: "18:00 ~ 18:30" },
  { key: "18:30", label: "18:30 ~ 19:00" },
  { key: "19:00", label: "19:00 ~ 19:30" },
  { key: "19:30", label: "19:30 ~ 20:00" },
  { key: "20:00", label: "20:00 ~ 20:30" },
  { key: "20:30", label: "20:30 ~ 21:00" },
  { key: "21:00", label: "21:00 ~ 21:30" },
  { key: "21:30", label: "21:30 ~ 22:00" },
  { key: "22:00", label: "22:00 ~ 22:30" },
  { key: "22:30", label: "22:30 ~ 23:00" },
  { key: "23:00", label: "23:00 ~ 23:30" },
  { key: "23:30", label: "23:30 ~ 00:00" },
];

export const NOT_ALLOWED_TIME_TABLE = {
  "00:00": "00:00 ~ 00:30",
  "00:30": "00:30 ~ 01:00",
  "01:00": "01:00 ~ 01:30",
  "01:30": "01:30 ~ 02:00",
  "02:00": "02:00 ~ 02:30",
  "02:30": "02:30 ~ 03:00",
  "03:00": "03:00 ~ 03:30",
  "03:30": "03:30 ~ 04:00",
  "04:00": "04:00 ~ 04:30",
  "04:30": "04:30 ~ 05:00",
  "05:00": "05:00 ~ 05:30",
  "05:30": "05:30 ~ 06:00",
  "06:00": "06:00 ~ 06:30",
  "06:30": "06:30 ~ 07:00",
  "07:00": "07:00 ~ 07:30",
  "07:30": "07:30 ~ 08:00",
  "08:00": "08:00 ~ 08:30",
  "08:30": "08:30 ~ 09:00",
  "09:00": "09:00 ~ 09:30",
  "09:30": "09:30 ~ 10:00",
  "10:00": "10:00 ~ 10:30",
  "10:30": "10:30 ~ 11:00",
  "11:00": "11:00 ~ 11:30",
  "11:30": "11:30 ~ 12:00",
  "12:00": "12:00 ~ 12:30",
  "12:30": "12:30 ~ 13:00",
  "13:00": "13:00 ~ 13:30",
  "13:30": "13:30 ~ 14:00",
  "14:00": "14:00 ~ 14:30",
  "14:30": "14:30 ~ 15:00",
  "15:00": "15:00 ~ 15:30",
  "15:30": "15:30 ~ 16:00",
  "16:00": "16:00 ~ 16:30",
  "16:30": "16:30 ~ 17:00",
  "17:00": "17:00 ~ 17:30",
  "17:30": "17:30 ~ 18:00",
  "18:00": "18:00 ~ 18:30",
  "18:30": "18:30 ~ 19:00",
  "19:00": "19:00 ~ 19:30",
  "19:30": "19:30 ~ 20:00",
  "20:00": "20:00 ~ 20:30",
  "20:30": "20:30 ~ 21:00",
  "21:00": "21:00 ~ 21:30",
  "21:30": "21:30 ~ 22:00",
  "22:00": "22:00 ~ 22:30",
  "22:30": "22:30 ~ 23:00",
  "23:00": "23:00 ~ 23:30",
  "23:30": "23:30 ~ 00:00",
};
