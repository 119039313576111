export const REPAIR_SHOP_STATUS = Object.freeze({
  ACTIVE: "active",
  INACTIVE: "inactive",
  PREPARING: "preparing",
});

export const APPROVED_MAINTENANCE = Object.freeze({
  ACCEPTED: "accepted",
  APPROVED: "approved",
  CHANGE: "change",
  CANCEL: "cancel",
});
