import React from "react";

import {
  Button,
  ValidMessage,
  LoginInput,
  KeepLoginButton,
  LoginPasswordInput,
} from "components";
import useLoginForm from "../hooks/useLoginForm";
import * as S from "./LoginForm.styled";

const VALID_ERORR_ID = "error-msg-shop-login";

interface LoginFormProps {
  handleFindPasswordDialog: () => void;
}

const LoginForm = ({ handleFindPasswordDialog }: LoginFormProps) => {
  const {
    inputRefs,
    form,
    isKeepLogin,
    isSubmitDisabled,
    isPasswordEyeIcon,
    handleChangeFormValue,
    handleBlurFormValue,
    handleToggleKeepLogin,
    handleSubmit,
  } = useLoginForm();

  return (
    <S.Root onSubmit={handleSubmit}>
      <S.InputWrapper>
        <LoginInput
          css={S.loginInput}
          id="email"
          name="email"
          ref={(el) => (inputRefs.current[0] = el)}
          value={form.email.value}
          label="이메일"
          aria-invalid="true"
          aria-errormessage={VALID_ERORR_ID}
          placeholder="이메일 주소를 입력하세요"
          validError={form.email.error}
          data-required="true"
          onChange={handleChangeFormValue}
          onBlur={handleBlurFormValue}
        />
        <LoginPasswordInput
          id="password"
          name="password"
          ref={(el) => (inputRefs.current[1] = el)}
          value={form.password.value}
          label="패스워드"
          aria-invalid="true"
          aria-errormessage={VALID_ERORR_ID}
          placeholder="비밀번호를 입력하세요"
          isPasswordEyeIcon={isPasswordEyeIcon}
          validError={form.password.error}
          data-required="true"
          onChange={handleChangeFormValue}
          onBlur={handleBlurFormValue}
        />
        {(form.email.error || form.password.error) && (
          <ValidMessage css={S.validMessage} id={VALID_ERORR_ID}>
            {form.email.error || form.password.error}
          </ValidMessage>
        )}
      </S.InputWrapper>
      <Button
        css={S.loginButton}
        type="submit"
        variant="primaryLarge"
        disabled={!!isSubmitDisabled}
      >
        로그인
      </Button>
      <S.SubContentWrapper>
        <KeepLoginButton
          selected={isKeepLogin}
          onClick={handleToggleKeepLogin}
        />

        <Button variant="ghostSecondary" onClick={handleFindPasswordDialog}>
          비밀번호 찾기
        </Button>
      </S.SubContentWrapper>
    </S.Root>
  );
};

export default LoginForm;
