import { useMutation } from "@tanstack/react-query";

import { getDiagnosisSolutionAPI } from "apis";
import type {
  APIError,
  GetDiagnosisSolutionQueryModel,
  GetDiagnosisSolutionServerModel,
} from "types";

export const useDiagnosisSolution = () => {
  return useMutation<
    GetDiagnosisSolutionServerModel,
    APIError,
    GetDiagnosisSolutionQueryModel
  >({
    mutationFn: (req) => getDiagnosisSolutionAPI(req),
  });
};
