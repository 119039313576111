import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import type { AxiosError } from "axios";

import { getFaqDetailAPI, getFaqsAPI } from "apis";
import type { GetFaqsQueryModel, GetFaqDetailServerModel } from "types";

export const faqKeys = {
  all: ["faq"] as const,
  lists: () => [...faqKeys.all, "list"],
  list: (filters: GetFaqsQueryModel) => [...faqKeys.lists(), { filters }],
  details: () => [...faqKeys.all, "detail"],
  detail: (faqId: string) => [...faqKeys.details(), faqId],
};

export const useGetFaqs = (filters: GetFaqsQueryModel) => {
  return useQuery({
    queryKey: faqKeys.list(filters),
    queryFn: () => getFaqsAPI(filters),
  });
};

export const useGetFaqDetail = (
  req: string,
  options?: UseQueryOptions<
    GetFaqDetailServerModel,
    AxiosError,
    GetFaqDetailServerModel,
    ReturnType<typeof faqKeys.detail>
  >,
) => {
  return useQuery({
    ...options,
    queryKey: faqKeys.detail(req),
    queryFn: () => getFaqDetailAPI(req),
  });
};
