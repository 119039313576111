import React from "react";

import { Textarea } from "components";
import { SELF_RESERVATION_ADDITION_INFO } from "assets";
import { INPUT_MAX_LENGTH } from "constants/index";
import * as S from "./AdditionInfo.styled";

interface AdditionInfoProps {
  inputRefs?: any;
  data: any;
  handleChangeData: any;
  handleBlurData?: any;
}

const AdditionInfo = ({ data, handleChangeData }: AdditionInfoProps) => {
  return (
    <S.Root>
      {SELF_RESERVATION_ADDITION_INFO.map((item) => (
        <div key={item.key}>
          <S.Label
            htmlFor={`additionInfo-input-${item.key}`}
            isRequired={item.isRequired}
          >
            {item.label}
          </S.Label>
          <Textarea
            id={`additionInfo-input-${item.key}`}
            name={item.key}
            value={data[item.key].value}
            maxLength={INPUT_MAX_LENGTH.USER_COMMENT}
            placeholder={item.placeholder}
            onChange={handleChangeData}
          />
        </div>
      ))}
    </S.Root>
  );
};

export default AdditionInfo;
