import React from "react";

import { Button } from "components";
import { LeftIconImg, RightIconImg } from "assets";
import * as S from "./SelectedDate.styled";

interface SelectedDateProps {
  className?: string;
  children: React.ReactNode;
}

interface ButtonProps {
  onClick: () => void;
}

interface TodayButtonProps {
  children: React.ReactNode;
  isToday: boolean;
  onClick: () => void;
}

const SelectedDate = ({ className, children }: SelectedDateProps) => {
  return <S.Root className={className}>{children}</S.Root>;
};

SelectedDate.Date = function Date({ className, children }: SelectedDateProps) {
  return <S.DateText className={className}>{children}</S.DateText>;
};

SelectedDate.PreviousButton = function PreviousButton({
  onClick,
}: ButtonProps) {
  return (
    <S.DateBtn type="button" aria-label="이전 날짜 선택" onClick={onClick}>
      <LeftIconImg />
    </S.DateBtn>
  );
};

SelectedDate.NextButton = function NextButton({ onClick }: ButtonProps) {
  return (
    <S.DateBtn type="button" aria-label="다음 날짜 선택" onClick={onClick}>
      <RightIconImg />
    </S.DateBtn>
  );
};

SelectedDate.TodayButton = function TodayButton({
  children,
  isToday,
  onClick,
}: TodayButtonProps) {
  return (
    <Button
      css={(theme) => S.todayBtn(theme, isToday)}
      variant={isToday ? "primarySmall" : "secondarySmall"}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default SelectedDate;
