import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const CustomerDetailHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-bottom: 18px;

    & > h2 {
      ${theme.font.bold_18};
    }
  `}
`;
