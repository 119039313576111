import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const tableDetailRow = (theme: Theme) => css`
  height: max-Content;
  background-color: ${theme.color.gray_00};
  > td {
    padding: 0;
  }
`;

const mixinPaddingSide = css`
  padding: 24px 30px 12px;
`;

const mixinContent = css`
  line-height: 1.64;
  letter-spacing: -0.14px;
`;

export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    ${mixinPaddingSide};
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid ${theme.color.gray_02};
  `}
`;

export const TitleContent = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_16};
    color: ${theme.color.gray_09};
  `}
`;

export const ContentWrapper = styled.div`
  padding: 24px 30px 36px;
`;

export const QuestionContent = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    ${mixinContent}
    color: ${theme.color.gray_09};
    white-space: pre-line;
  `}
`;
