import React from "react";

import { Button, CloseButton } from "components";
import { useModal } from "hooks";
import BaseModal from "../base/BaseModal";
import * as S from "./DetailModal.styled";

interface DetailModalProps {
  className?: string;
  children: React.ReactNode;
  isPosDisabled?: boolean;
  cancelBtnName?: string;
  desc?: string;
  title?: string;
  posFnType?: "submit" | "button";
  posBtnName?: string;
  tooltip?: React.ReactNode;
  cancelFn?: () => void;
  closeFn?: () => void;
  posFn?: (e?: React.FormEvent<HTMLFormElement>) => void;
}

const DetailModal = React.forwardRef(
  (
    {
      className,
      children,
      isPosDisabled,
      cancelBtnName,
      posBtnName,
      tooltip,
      title,
      desc,
      posFnType = "button",
      cancelFn,
      closeFn,
      posFn,
    }: DetailModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { handleModalClose } = useModal();

    return (
      <BaseModal css={S.baseModal} className={className} ref={ref}>
        <S.Header>
          <CloseButton
            css={S.closeButton}
            onClick={closeFn ?? handleModalClose}
          />
          {title && <S.Title>{title}</S.Title>}
          {tooltip && tooltip}
          {desc && <S.Desc>{desc}</S.Desc>}
        </S.Header>
        <form onSubmit={posFn && posFn}>
          <S.Content>{children}</S.Content>
          {(cancelBtnName || posBtnName) && (
            <S.BottomWrapper>
              {cancelBtnName && (
                <Button
                  variant="secondaryMedium"
                  onClick={cancelFn ?? handleModalClose}
                >
                  {cancelBtnName}
                </Button>
              )}
              {posBtnName && (
                <Button
                  disabled={isPosDisabled}
                  type={posFnType}
                  variant="primaryMedium"
                  onClick={posFn && posFn}
                >
                  {posBtnName}
                </Button>
              )}
            </S.BottomWrapper>
          )}
        </form>
      </BaseModal>
    );
  },
);

DetailModal.displayName = "DetailModal";

export default DetailModal;
