import dayjs from "dayjs";

import { DIRECT_INPUT_TELEPHONE_AREA_CODES } from "assets";
import type { DateTemplate } from "types";
import { checkFirstNum } from "./validation";

// NOTE: toLocaleString을 사용하면 hook-form의 setValueAs에서 적용되지 않음
export const comma = (input: string | number): string =>
  numericOnly(input).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

export const numericOnly = (value: string | number): string => {
  typeof value === "number"
    ? (value = `${value}`)
    : (value = checkFirstNum(value));

  const regex = /[^0-9]/g;
  return value.replaceAll(regex, "");
};

export const numericOnlyWithComma = (value: string | number): string => {
  value = convertNumberToString(value);
  value = numericOnly(value);

  const parts = value.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return parts.join(".");
};

export const numberInputWithComma = (currentValue: any, prevValue?: any) => {
  let newCurrentValue = convertNumberToString(currentValue);

  const isDeleteValue = newCurrentValue?.length < prevValue?.length;
  const deletedZero = isDeleteValue && newCurrentValue[0] === "0" ? "0" : "";
  newCurrentValue = numericOnly(deletedZero + newCurrentValue);
  const parts = newCurrentValue.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return parts.join(".");
};

const convertNumberToString = (value: string | number): string => {
  if (typeof value === "number") {
    value = `${value}`;
  }
  return value;
};

export const numToStringPhoneNum = (phoneNumber: string): string => {
  // 입력 받는 값을 숫자만 받고 이외의 값은 빈문자열로 바꿈
  // 앞에 글자가 국번
  // 국번 종류에 따라 - 가 다르게 붙음

  const isNumber = /[^0-9]/g;
  phoneNumber = phoneNumber?.replace(isNumber, "");
  phoneNumber = phoneNumber
    ?.replace(
      /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
      "$1-$2-$3",
    )
    ?.replace("--", "-");

  return phoneNumber;
};

export const dateWithDot = (value: string | number): string => {
  value = numericOnly(value);
  value = value
    .toString()
    .replace(/([0-9]{4})([0-9]+)?([0-9]{2})$/, "$1.$2.$3")
    .replace("..", ".");

  return value;
};

export const timeWithColon = (value: string | number): string => {
  value = numericOnly(value);
  value = value
    .toString()
    .replace(/([0-9]{2})([0-9]{2})$/, "$1:$2")
    .replace("::", ":");

  if (value.length > 5) {
    value = value.slice(0, 5);
    value = value.replace(":", "");
    value = value.slice(0, 2) + ":" + value.slice(2);
  }

  return value;
};

export const twelveTimeFormatter = (time: string | number): string => {
  return dayjs("1/1/1 " + time)
    .format("a hh")
    .toUpperCase();
};

export const twelveTimeHalfFormatter = (time: string | number): string => {
  return dayjs("1/1/1 " + time)
    .add(30, "m")
    .format("hh:mm a")
    .toUpperCase();
};

export const twoLengthTime = (time: string | number): string | number => {
  const stringTime = `${time}`;
  if (stringTime.length < 2) {
    time = 0 + stringTime;
  }
  return time;
};

export const addFirstZero = (value: string): string => {
  return `${+value < 10 ? "0" + value : value}`;
};

export const deleteFirstZero = (value: string): string => {
  if (!value) return "";

  return value[0] === "0" ? value.slice(1) : value;
};

export const deleteKeyNullInValue = (object: any) => {
  // key 값은 있고, value 값이 없으면, key 값을 제거해주는 함수
  const res = {} as any;
  for (const [key, value] of Object.entries(object)) {
    if (
      value !== "" &&
      value !== null &&
      value !== undefined &&
      JSON.stringify(value) !== "[]"
    ) {
      res[key] = value;
    }
  }
  return res;
};
export const exchangeValueNullToString = (object: any) => {
  // value 값: null -> "" 로 변경
  for (const [key, value] of Object.entries(object)) {
    if (value === null) {
      object[key] = "";
    }
  }
  return object;
};
export const reservationNumFormatter = (num: string): string => {
  if (!num) return "";
  return num.replace(/(\d{4})(\d{4})(\d{4})/, "$1-$2-$3");
};
export const dateFormatter = (date: string): string => {
  if (typeof date === "number") date = `${date}`;

  return dayjs(date.replaceAll(".", "-")).format("YYYY-MM-DD");
};
export const deleteComma = (value: number | string): string => {
  return `${value}`.replaceAll(",", "");
};

export const vinFormatter = (vin: string): string =>
  vin.replace(/[^0-9a-zA-Z]/, "").toUpperCase();

export const estimateHourFormatter = (value: string | number): number | "" => {
  const strValue = `${value}`;

  return strValue
    ? Math.min(+strValue.replaceAll(/[^0-9]/g, "").slice(0, 3), 999)
    : "";
};

export const hourFormatter = (value: string | number): number | "" => {
  const strValue = `${value}`;

  return strValue
    ? Math.min(+strValue.replaceAll(/[^0-9]/g, "").slice(0, 2), 24)
    : "";
};

export const deleteHyphen = (value: string): string =>
  value.replaceAll("-", "");

export const formatYYYYMMDDHHmm = (date: string | number): string =>
  dayjs(date).utc().format("YYYY.MM.DD HH:mm");

export const formatYYYYMMDD = (date: string | number): string =>
  dayjs(date).utc().format("YYYY.MM.DD");

export const formatPlannedDateTime = (
  date: string,
  hour: string,
  min: string,
): string => `${dateFormatter(date)} ${addFirstZero(hour)}:${min}`;

export const formatMaintenanceDateTime = (dateTime: string | number): string =>
  `${dayjs(dateTime).format("YYYY.MM.DD. HH:mm")}`;

export const formatRepairingDateTime = (dateTime: string | number): string =>
  `${dayjs(dateTime).format("YYYY.MM.DD (ddd) A HH:mm")}`;

export const formatKstYYYYMMDD = (
  date: string | number | undefined,
): string => {
  if (!date) return "";
  return dayjs(date).format("YYYY.MM.DD");
};

export const formatActualTimes = (time: string): string => {
  const hours = time.split(":")[0];
  const minutes = time.split(":")[1];

  return `${hours}시간 ${minutes}분`;
};

export const formatReservationStatus = (
  status: "canceled" | "completed",
): string => {
  switch (status) {
    case "canceled":
      return "취소(노쇼)";
    case "completed":
      return "정비완료";
  }
};

export function formatCurrency(number: number | string) {
  const comma = (str: number | string): string => {
    str = `${str}`;
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
  };

  const unComma = (str: number | string): string => {
    str = `${str}`;
    return str.replace(/[^\d]+/g, "");
  };

  return number && comma(unComma(number));
}

export const getPhoneInfo = (
  value: string,
): {
  areaCode: string;
  phone: string;
} => {
  const areaCode = value.match(
    /^0(1[016789]{1}|2|3\d{1}|4\d{1}|5\d{1}|6\d{1}|7\d{1}|8\d{1}|9\d{1})/,
  )?.[0];
  const mobileAreaCode =
    DIRECT_INPUT_TELEPHONE_AREA_CODES[
      areaCode as keyof typeof DIRECT_INPUT_TELEPHONE_AREA_CODES
    ];

  return {
    areaCode: mobileAreaCode ?? "",
    phone: mobileAreaCode?.length ? value.slice(mobileAreaCode.length) : value,
  };
};

export const formatDate = (
  date: dayjs.Dayjs | string | number | Date,
  template: DateTemplate = "DD/MM/YY, HH:mm",
): string => dayjs(date).format(template);

export const formatUtcISODateTime = (
  localDate: string,
  template: DateTemplate = "HH:mm",
): string => {
  const date = dayjs.utc(dayjs(localDate, template));

  return date.toISOString();
};

export const formatKstISODateTime = (
  localDate: string,
  template: DateTemplate = "YYYY-MM-DDTHH:mm:ss.sss[Z]",
): string => dayjs(localDate).subtract(9, "hour").format(template);

export const getBrandModel = (brandName: string, modelName: string): string =>
  `${brandName ? `${brandName} /` : ""}  ${modelName ? modelName : ""}`;

export const getDateFormat = (date: string): "" | "YYYY-MM-DD" | "YYYY-MM" => {
  const yyyymmRegex = /([12]\d{3}-(0[1-9]|1[0-2]))/;
  const yyyymmddRegex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

  if (yyyymmddRegex.test(date)) return "YYYY-MM-DD";
  if (yyyymmRegex.test(date)) return "YYYY-MM";
  return "";
};
