import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.section`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: ${theme.size.MAIN_MIN_WIDTH};
    width: ${`calc(100vw - ${theme.size.NAV_WIDTH} - ${theme.size.LAYOUT_PADDING_SIDE} * 2)`};
    max-width: ${theme.size.MAIN_MAX_WIDTH};
    margin: 0 auto;
    padding: ${theme.size.MAIN_PADDING_TOP} 0 ${theme.size.MAIN_PADDING_BOTTOM};
  `}
`;
