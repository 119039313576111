import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { CenterLayout } from "components";
import { useFilterParams } from "hooks";
import { useGetCustomerDetail, useGetCustomerReservation } from "services";
import type { GetCustomerReservationQueryModel } from "types";
import CustomersInfo from "./container/info/CustomersInfo";
import CustomersReservationInfo from "./container/reservationInfo/CustomersReservationInfo";
import * as S from "./CustomerDetail.styled";

const CustomerDetail = () => {
  const [searchParams] = useSearchParams();
  const userId = useMemo(() => searchParams.get("customerId"), [searchParams]);

  const { filters } = useFilterParams<
    GetCustomerReservationQueryModel["query"]
  >(["page"]);
  const { isSuccess, data: customerInfo } = useGetCustomerDetail(userId!);
  const { data: customerReservationInfo } = useGetCustomerReservation({
    customerId: userId!,
    query: filters,
  });

  return (
    <CenterLayout>
      <S.CustomerDetailHeader>
        <h2>고객 상세</h2>
      </S.CustomerDetailHeader>
      <CustomersInfo customerInfo={customerInfo} />
      <CustomersReservationInfo
        isDone={isSuccess}
        reservationData={customerReservationInfo}
      />
    </CenterLayout>
  );
};

export default CustomerDetail;
