import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Wrapper = styled.div`
  display: flex;
  column-gap: 6px;
  width: 270px;
`;

export const mobileInput = css`
  height: 34px;
`;

export const mobileSelect = css`
  & > ul {
    max-height: 190px;
  }

  & > button {
    width: 96px;
    padding: 0 11px;
  }
`;
