import { useState, useEffect, useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { createPageList } from "utils";

const usePagination = ({
  totalPages,
  maxPageNum = 5,
  currentPage: initCurrentPage,
}: {
  totalPages: any;
  maxPageNum: number;
  currentPage: any;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1,
  );
  const [pageNumList, setPageNumList] = useState<any>([]);

  const isDisabledPrev = useMemo(() => {
    return currentPage - maxPageNum <= 0;
  }, [currentPage]);

  const isDisabledNext = useMemo(() => {
    return (
      Math.floor((currentPage + maxPageNum - 1) / maxPageNum) >
      Math.floor(totalPages / maxPageNum)
    );
  }, [currentPage, totalPages]);

  const handleSetPageQuery = (page: number) => {
    setCurrentPage(page);
  };

  const handleClickPageNum = useCallback(
    (pageNum: number) => () => {
      handleSetPageQuery(pageNum);
    },
    [searchParams],
  );

  const handleClickPrevButton = useCallback(() => {
    const prevPageNum = Math.max(
      Math.floor((currentPage - maxPageNum - 1) / maxPageNum) * maxPageNum + 1,
      1,
    );
    handleSetPageQuery(prevPageNum);
  }, [currentPage]);

  const handleClickNextButton = useCallback(() => {
    const nextPageNum = Math.min(
      Math.floor((currentPage + maxPageNum - 1) / maxPageNum) * maxPageNum + 1,
      totalPages,
    );
    handleSetPageQuery(nextPageNum);
  }, [currentPage]);

  useEffect(() => {
    const newPageList = createPageList(currentPage, maxPageNum, totalPages);
    if (JSON.stringify(pageNumList) !== JSON.stringify(newPageList)) {
      setPageNumList(newPageList);
    }
  }, [currentPage, totalPages]);

  useEffect(() => {
    if (
      (currentPage > 1 && currentPage !== +searchParams.get("page")!) ||
      (currentPage === 1 && +searchParams.get("page")! > 1)
    ) {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        page: `${currentPage}`,
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (initCurrentPage && initCurrentPage !== currentPage) {
      setCurrentPage(initCurrentPage);
    }
  }, [initCurrentPage]);

  return {
    currentPage,
    pageNumList,
    isDisabledPrev,
    isDisabledNext,
    handleClickPageNum,
    handleClickPrevButton,
    handleClickNextButton,
  };
};

export default usePagination;
