import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import dayjs from "dayjs";

import { VisuallyHidden, StarRating, Button } from "components";
import { useModal } from "hooks";
import { openCommentState } from "store";
import { reservationNumFormatter } from "utils";
import { CommentIconImg, NewBadgeIcon } from "assets";
import { Review } from "types";
import ReplyCommentForm from "./reply/form/ReplyCommentForm";
import ReplyComment from "./reply/comment/ReplyComment";
import ReportReviewModal from "./report/ReportReviewModal";
import * as S from "./ReviewComment.styled";

interface ReviewCommentProps {
  isLastEl: any;
  reviewData: Review;
}

const ReviewComment = ({ isLastEl, reviewData }: ReviewCommentProps) => {
  const navigate = useNavigate();

  const replyButtonRef = useRef<HTMLButtonElement>(null);
  const ref = useRef(null);

  const [openComment, setOpenCommentState] = useRecoilState(openCommentState);
  const [hasLastElBorder, setHasLastElBorder] = useState(true);
  const { modalRef, handleModalOpen, handleModalClose } = useModal();

  const options = {
    root: null,
    rootMargin: "0px 0px 0px 0px",
    threshold: 0.5,
    trackVisibility: true,
    delay: 100,
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio === 1) {
        setHasLastElBorder(true);
      } else {
        setHasLastElBorder(false);
      }
    });
  }, options);

  const openReplyCommentForm = () => {
    setOpenCommentState(reviewData.reservationId);
  };

  const closeReplyCommentForm = () => {
    setOpenCommentState("");
    setTimeout(
      () => replyButtonRef.current && replyButtonRef.current.focus(),
      0,
    );
  };

  const handleDetailReservationPage = () =>
    navigate(`/reservation/detail?reservationId=${reviewData.reservationId}`);

  useEffect(() => {
    isLastEl && observer.observe(ref.current!);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <S.Root ref={isLastEl ? ref : null} hasLastElBorder={hasLastElBorder}>
      <S.Container>
        <S.CommentInfoWrapper>
          <S.CommentInfoTop>
            <S.CommentInfoTopLeft isWithdrawal={!reviewData.nickname}>
              <StarRating css={S.starRating} rate={reviewData.rating} />
              <span>
                <VisuallyHidden as="label">리뷰 작성자</VisuallyHidden>
                {reviewData.nickname ?? "[알 수 없음]"}
              </span>
              {!reviewData.reply && <NewBadgeIcon />}
            </S.CommentInfoTopLeft>
            <S.CommentInfoTopRight isReported={reviewData.reported}>
              <time>
                {dayjs(reviewData.reviewCreated).format("YYYY.MM.DD. HH:mm")}
              </time>
              {!reviewData.reported && (
                <button
                  type="button"
                  onClick={handleModalOpen(
                    <ReportReviewModal
                      ref={modalRef}
                      reviewId={reviewData.reservationId}
                      handleModalClose={handleModalClose}
                    />,
                  )}
                >
                  신고
                </button>
              )}
            </S.CommentInfoTopRight>
          </S.CommentInfoTop>
          <S.CommentMetaDataWrapper>
            <S.MaintenanceNumber onClick={handleDetailReservationPage}>
              <dt>정비번호:</dt>
              <dd>{reservationNumFormatter(reviewData.reservationNo)}</dd>
            </S.MaintenanceNumber>
            <S.DateTimeVisit>
              <dt>방문일시</dt>
              <dd>
                <time>{dayjs(reviewData.planned).format("YYYY.MM.DD")}</time>
              </dd>
            </S.DateTimeVisit>
          </S.CommentMetaDataWrapper>
          <S.CommentInfoDesc>{reviewData.content}</S.CommentInfoDesc>
        </S.CommentInfoWrapper>
        <S.ReplyWrapper>
          {openComment === reviewData.reservationId ? (
            <ReplyCommentForm
              reviewId={reviewData.reservationId}
              comment={reviewData.reply}
              closeReplyCommentForm={closeReplyCommentForm}
            />
          ) : reviewData.reply ? (
            <ReplyComment
              reviewId={reviewData.reservationId}
              comment={reviewData.reply}
            />
          ) : (
            <Button
              css={S.replyButton}
              ref={replyButtonRef}
              variant="secondarySmall"
              icon={<CommentIconImg />}
              onClick={openReplyCommentForm}
            >
              댓글 입력
            </Button>
          )}
        </S.ReplyWrapper>
      </S.Container>
    </S.Root>
  );
};

export default ReviewComment;
