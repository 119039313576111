import React from "react";

import * as S from "./VisuallyHidden.styled";

interface VisuallyHiddenProps {
  as?: any;
  className?: string;
  htmlFor?: string;
  children: React.ReactNode;
}

const VisuallyHidden = ({ children, as }: VisuallyHiddenProps) => {
  return <S.Root as={as}>{children}</S.Root>;
};

export default VisuallyHidden;
