export const size = {
  HEADER_HEIGHT: "54px",
  FOOTER_HEIGHT: "60px",
  NAV_WIDTH: "68px",
  MAIN_MIN_WIDTH: "1272px",
  MAIN_MAX_WIDTH: "1550px",
  MAIN_PADDING_TOP: "38px",
  MAIN_PADDING_BOTTOM: "32px",
  SMALL_MIN_WIDTH: "946px",
  LAYOUT_PADDING_SIDE: "50px",
  LAYOUT_PADDING_UP_DOWN: "40px",
  SCROLLBAR_LOWER: "10px",
  SCHEDULER_TIME_CELL_WIDTH: "78px",
  FOLD_NAV_SCHEDULER_TIME_CELL_WIDTH: "92px",
  PAGINATION_HEIGHT: "68px",
} as const;

export type SizeType = typeof size;
