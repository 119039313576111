import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const baseModal = css`
  width: 494px;
`;

export const Header = styled.div`
  position: relative;
  padding: 24px 28px;
`;

export const closeButton = css`
  position: absolute;
  right: 24px;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    ${theme.font.bold_16};
    margin-bottom: 24px;
    color: ${theme.color.gray_09};
  `}
`;

export const Desc = styled.pre`
  ${({ theme }) => css`
    ${theme.font.regular_16};
    color: ${theme.color.gray_10};
    white-space: break-spaces;
  `}
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    display: flex;
    justify-content: center;
    column-gap: 6px;
    border-top: 1px solid ${theme.color.gray_03};
    padding: 12px 0;
  `}
`;
