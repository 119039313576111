export const REVIEW_SORT_OPTION_TABLE = {
  recent: "최신순",
  desc: "평점 높은순",
  asc: "평점 낮은순",
};

export const REVIEW_SORT_OPTION_ANSWER_STATUS_TABLE = {
  total: "전체보기",
  false: "미답변",
  true: "답변완료",
};
