import React, { useEffect } from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { useRecoilState } from "recoil";

import { Layout, ReqPasswordChangedModal } from "components";
import { useModal } from "hooks";
import { useGetBrands, useGetPresignedUrl } from "services";
import { repairShopInfo } from "store";
import { auth, isExceededPwRtnPeriod } from "utils";
import { LAYOUT_TYPE, PASSWORD_RETENTION_PERIOD, PATH } from "constants/index";
import type { RepairShopInfo } from "types";

const AuthRoute = () => {
  const { pathname, search } = useLocation();

  const [user, setUser] = useRecoilState<RepairShopInfo>(repairShopInfo);
  const { refetch: refetchBrands } = useGetBrands();
  const { refetch: refetchThumbnail } = useGetPresignedUrl(user.thumbnailKey, {
    onSuccess: (res) => {
      setUser({
        ...user,
        ...(res && { thumbnail: res }),
      });
    },
    enabled: false,
  });
  const { modalRef, handleModalOpen, handleModalClose } = useModal();

  useEffect(() => {
    refetchBrands();

    if (user.thumbnailKey) {
      refetchThumbnail();
    }
  }, [user.thumbnailKey]);

  useEffect(() => {
    if (
      auth.isAuth &&
      isExceededPwRtnPeriod(user.pwUpdated, PASSWORD_RETENTION_PERIOD)
    ) {
      handleModalOpen(
        <ReqPasswordChangedModal
          ref={modalRef}
          handleModalClose={handleModalClose}
        />,
      )();
    }
  }, []);

  return auth.isAuth ? (
    <Layout type={LAYOUT_TYPE.PRIVATE}>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to={PATH.login} state={{ from: pathname + search }} />
  );
};

export default AuthRoute;
