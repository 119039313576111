import React from "react";

import { Pagination } from "components";
import EventTable from "./Container/table/EventTable";
import * as S from "./Event.styled";

const Event = () => {
  return (
    <S.Root>
      <S.EventHeader>
        <h3>이벤트</h3>
      </S.EventHeader>
      <EventTable />
      <Pagination pageInfo={{}} />
    </S.Root>
  );
};

export default Event;
