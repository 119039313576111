import React from "react";

import { Textarea, ValidMessage } from "components";
import { formatMaintenanceDateTime } from "utils";
import * as S from "./CancelForm.styled";

const CANCEL_COMMENT_ID = "accepted-cancel-comment";
const VALID_ERORR_ID = "error-msg-reservation-cacnelComment";

interface CancelFormProps {
  id: string;
  inputRefs: any;
  reservationData: any;
  tabIndex?: number;
  data: any;
  handleChangeData: () => void;
  handleBlurData: () => void;
}

const CancelForm = ({
  id,
  inputRefs,
  reservationData,
  data,
  handleChangeData,
  handleBlurData,
}: CancelFormProps) => {
  return (
    <div id={id} tabIndex={0}>
      <S.InfoItem>
        <span>예약 일시</span>
        <time>{formatMaintenanceDateTime(reservationData.planned)}</time>
      </S.InfoItem>
      <S.InfoItem isRequired={true}>
        <label htmlFor={CANCEL_COMMENT_ID}>예약 불가 사유</label>
        <Textarea
          css={S.textarea}
          ref={(el) => (inputRefs.current["cancelComment"] = el)}
          id={CANCEL_COMMENT_ID}
          name="cancelComment"
          maxLength={200}
          value={data.cancelComment.value}
          placeholder="예약 불가 사유를 입력해주세요"
          validError={data.cancelComment.error}
          data-required="true"
          aria-invalid="true"
          aria-errormessage={VALID_ERORR_ID}
          onChange={handleChangeData}
          onBlur={handleBlurData}
        />
        {data.cancelComment.error && (
          <ValidMessage css={S.validText} id={VALID_ERORR_ID}>
            {data.cancelComment.error}
          </ValidMessage>
        )}
      </S.InfoItem>
    </div>
  );
};

export default CancelForm;
