import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.li`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: grid;
    grid-template-columns: 112px 1fr 156px;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid ${theme.color.gray_02};
    padding: 16px 0;
    color: ${theme.color.gray_10};
    background-color: ${theme.color.white};

    &:last-of-type&:nth-of-type(n + 5) {
      border-bottom: 0;
    }

    & > span {
      :nth-of-type(1) {
        align-self: center;
        padding: 0 16px;
        text-align: center;
        word-break: keep-all;
        overflow-wrap: break-word;
      }
      :nth-of-type(2) {
        word-break: break-word;
      }
    }

    & > div {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 0.01em;
      color: ${theme.color.gray_09};
      padding-left: 24px;
    }
  `}
`;

export const deleteButton = css`
  margin-right: 4px;
`;

export const ContentWrapper = styled.span`
  white-space: pre-wrap;
`;
