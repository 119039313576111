import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { mixin_scrollbar } from "style/mixin";

const checkboxListMixins = (theme: Theme) => css`
  ${mixin_scrollbar(theme)};
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 35px;
  row-gap: 1px;
  width: 100%;
  border: 1px solid ${theme.color.gray_03};
  border-radius: 3px;
  background-color: ${theme.color.white};

  & > li {
    border-bottom: 1px solid ${theme.color.gray_03};
  }

  ::-webkit-scrollbar {
    width: 14px;
    border: 0;
    border-left: solid 1px ${theme.color.gray_02};
    background-color: ${theme.color.white};
  }
`;

export const detailModal = (theme: Theme) => css`
  border-color: ${theme.color.gray_03};

  & > div > h2 {
    color: ${theme.color.gray_07};
  }
`;

export const tooltip = css`
  width: 21px;
  height: 21px;
  margin-left: 2px;

  & > div {
    left: -19px;

    &::before {
      left: 22px;
    }
  }
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 24px 14px;
    background-color: ${theme.color.gray_00};

    & > div {
      padding: 0 20px;
    }
    & > div:first-of-type {
      border-right: 1px solid ${theme.color.gray_03};
    }
  `}
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: flex;
    column-gap: 3px;
    margin-bottom: 6px;
    color: ${theme.color.gray_07};

    & > span {
      color: ${theme.color.error_01};
    }
  `}
`;

export const NonReservedTimeCheckList = styled.ul`
  ${({ theme }) => css`
    ${checkboxListMixins(theme)};
    height: 288px;

    & > li {
      :nth-of-type(n + 8):last-child {
        border-bottom: none;
      }
    }
  `}
`;

export const allCheckedToggleButton = (
  theme: Theme,
  isAllChecked: boolean,
) => css`
  position: relative;
  top: 5px;
  border: 1px solid ${isAllChecked ? theme.color.gray_03 : theme.color.blue_01};
  color: ${isAllChecked ? theme.color.gray_07 : theme.color.blue_01};
  background-color: ${isAllChecked ? null : theme.color.blue_02};
`;

export const SettingTimeWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;

    & span {
      ${theme.font.regular_14};
      margin-top: 6px;
      padding-right: 25px;
      color: ${theme.color.gray_05};
    }

    & > div {
      margin-bottom: 13px;
    }
  `}
`;

export const SettingTimeCheckList = styled.ul`
  ${({ theme }) => css`
    ${checkboxListMixins(theme)};
    height: 345px;

    & > li {
      :nth-of-type(n + 10):last-child {
        border-bottom: none;
      }
    }
  `}
`;

export const buttonWrapper = (theme: Theme) => css`
  background-color: ${theme.color.gray_00};
`;

export const saveButton = (theme: Theme) => css`
  ${theme.font.medium_14};
  width: 90px;
  height: 36px;
  color: ${theme.color.white};
  background-color: ${theme.color.blue_01};
`;
