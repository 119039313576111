import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  margin-bottom: 10px;
`;

export const Label = styled.label<{ isRequired: boolean }>`
  ${({ isRequired, theme }) => css`
    ${theme.font.regular_14};
    display: inline-block;
    width: 136px;
    margin-bottom: 6px;
    color: ${theme.color.gray_07};

    &::after {
      content: ${isRequired ? "'*'" : null};
      display: inline-block;
      margin-left: 3px;
      color: ${theme.color.error_01};
    }
  `}
`;

export const validText = css`
  position: static;
`;

const inputMixin = css`
  width: 270px;
  height: 34px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

export const InputWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const noshow = css`
  align-self: flex-end;
`;

export const input = css`
  ${inputMixin};
`;

export const SuffixText = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    align-self: flex-end;
    color: ${theme.color.gray_10};
  `}
`;

export const select = (theme: Theme) => css`
  ${inputMixin};

  & > ul {
    max-height: 190px;
    margin-top: 5px;

    & > li > button {
      ${theme.font.regular_14};
      color: ${theme.color.gray_09};
    }
  }
`;

export const OtherInfoWrapper = styled.div`
  display: grid;
  row-gap: 10px;

  & > div {
    display: flex;
    width: 100%;
  }
`;
