import React from "react";
import DaumPostcode from "react-daum-postcode";

import { useDialog } from "hooks/index";
import * as S from "./DaumPostDialog.styled";

interface DaumPostDialogProps {
  trigger: any;
  onSelect: any;
}

const DaumPostDialog = ({ trigger, onSelect }: DaumPostDialogProps) => {
  const { isDialogOpen, dialogRef, handleToggleDialogOpen } = useDialog();

  const handleLocationInput = (data: any) => {
    const districtCode = data.bcode.slice(0, 2);
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    const geoCoder = new (window as any).kakao.maps.services.Geocoder();

    const callback = function (result: any, status: any) {
      if (status === (window as any).kakao.maps.services.Status.OK) {
        const { x, y } = result[0];
        const address = {
          zipcode: data.zonecode,
          addr: fullAddress,
          lng: x,
          lat: y,
          districtCode,
        };
        onSelect(address);
      }
    };

    geoCoder.addressSearch(fullAddress, callback);
  };

  return (
    <>
      {typeof trigger === "function" &&
        trigger({ handleToggleDialogOpen, isDialogOpen })}
      {isDialogOpen && (
        <S.Root ref={dialogRef}>
          <DaumPostcode
            css={S.daumPostCode}
            shorthand={false}
            onComplete={handleLocationInput}
          />
        </S.Root>
      )}
    </>
  );
};

export default DaumPostDialog;
