import React, { useState } from "react";

import {
  MaxLengthInput,
  Select,
  Checkbox,
  Button,
  Textarea,
  MobileInput,
} from "components";
import { ADD_INQUIRY_FORM, INQUIRY_TABLE } from "assets";
import {
  INPUT_MAX_LENGTH,
  PERSONAL_INFORMATION_POLICY_URL,
} from "constants/index";
import InquiryImageUploader from "../container/imageUploader/InquiryImageUploader";
import useAddInquiry from "../container/hooks/useAddInquiry";
import * as S from "./SettingInquiryCreate.styled";

const SettingInquiryCreate = () => {
  const {
    form,
    inputRefs,
    attachList,
    isSubmitDisabled,
    handleSubmit,
    handleBlurForm,
    handleChangeForm,
    handleDeleteImage,
  } = useAddInquiry();
  const [isAgree, setIsAgree] = useState(false);

  const handleSetIsAgree = () => {
    setIsAgree((prev) => !prev);
  };

  const renderContent = (item: any) => {
    switch (item.type) {
      case "select":
        return (
          <Select
            css={S.select}
            id={`add-inquiry-${item.key}`}
            forwardRef={(el: any) => (inputRefs.current[item.key] = el)}
            name={item.key}
            selectedOption={form[item.key as keyof typeof form].value as string}
            labelTable={INQUIRY_TABLE}
            required={item.isRequired}
            placeholder={item.placeholder}
            validError={form[item.key as keyof typeof form].error}
            onSelect={handleChangeForm}
            onBlur={handleBlurForm}
          >
            {Object.entries(INQUIRY_TABLE).map(([value, label]) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        );

      case "phone":
        return (
          <MobileInput
            css={S.mobileInput}
            ref={inputRefs}
            type="mobile"
            isRequired={item.isRequired}
            data={form}
            phoneKey={item.key}
            phoneLocalKey={`${item.key}Local`}
            placeholder={item.placeholder}
            readOnly={item.readOnly}
            handleChange={handleChangeForm}
            handleBlur={handleBlurForm}
          />
        );

      case "input":
        return (
          <MaxLengthInput
            css={S.input(item.key)}
            name={item.key}
            onBlur={handleBlurForm}
            onChange={handleChangeForm}
            disabled={item?.isDisabled}
            value={(form[item.key as keyof typeof form].value as string) || ""}
            id={`add-inquiry-${item.key}`}
            placeholder={item.placeholder}
            validError={form[item.key as keyof typeof form].error}
            data-required={item.isRequired ? "true" : ""}
            ref={(el) => (inputRefs.current[item.key] = el)}
          />
        );

      case "maxInput":
        return (
          <MaxLengthInput
            name={item.key}
            onBlur={handleBlurForm}
            onChange={handleChangeForm}
            disabled={item?.isDisabled}
            value={(form[item.key as keyof typeof form].value as string) || ""}
            id={`add-inquiry-${item.key}`}
            placeholder={item.placeholder}
            validError={form[item.key as keyof typeof form].error}
            data-required={item.isRequired ? "true" : ""}
            maxLength={item.maxLength}
            ref={(el) => (inputRefs.current[item.key] = el)}
          />
        );

      case "textarea":
        return (
          <Textarea
            css={S.textarea}
            id={`add-inquiry-${item.key}`}
            ref={(el) => (inputRefs.current[item.key] = el)}
            data-required={item.isRequired ? "true" : ""}
            name={item.key}
            maxLength={INPUT_MAX_LENGTH.INQUIRY_CONTENT}
            value={(form[item.key as keyof typeof form].value as string) || ""}
            placeholder={item.placeholder}
            validError={form[item.key as keyof typeof form].error}
            onChange={handleChangeForm}
            onBlur={handleBlurForm}
          />
        );
      case "file":
        return (
          <InquiryImageUploader
            itemKey={item.key}
            inputRefs={inputRefs}
            attachList={attachList}
            handleChangeForm={handleChangeForm}
            handleDeleteImage={handleDeleteImage}
          />
        );
    }
  };

  return (
    <S.Root>
      <S.Header>1:1 문의 등록</S.Header>
      <S.Container>
        {ADD_INQUIRY_FORM.map((item) => (
          <S.InputWrapper key={item.label}>
            <dt>
              <S.Label htmlFor={`add-inquiry-${item.key}`}>
                {item.label} {item.isRequired && <span>*</span>}
              </S.Label>
            </dt>
            <S.LabelWrapper>{renderContent(item)}</S.LabelWrapper>
            {form[item.key as keyof typeof form].error && (
              <S.ValidText>
                {form[item.key as keyof typeof form].error}
              </S.ValidText>
            )}
          </S.InputWrapper>
        ))}
      </S.Container>
      <S.SubmitWrapper>
        <div>
          <Checkbox
            key="isAgree"
            label="(필수) 개인정보 수집, 이용동의"
            selected={isAgree}
            handleClick={handleSetIsAgree}
          />
          <a
            href={PERSONAL_INFORMATION_POLICY_URL}
            target="_blank"
            rel="noreferrer"
          >
            자세히보기
          </a>
        </div>
        <Button
          css={S.button}
          type="submit"
          disabled={
            isSubmitDisabled ||
            !isAgree ||
            !form.category.value ||
            !form.title.value ||
            !form.content.value ||
            !form.name.value ||
            !form.phone.value ||
            !form.email.value
          }
          onClick={handleSubmit}
        >
          완료
        </Button>
      </S.SubmitWrapper>
    </S.Root>
  );
};

export default SettingInquiryCreate;
