import React, { forwardRef } from "react";

import * as S from "./Radio.styled";

interface RadioProps {
  className?: string;
  children?: React.ReactNode;
  id: string;
  name: string;
  value: string | number | boolean;
  checked: boolean;
  onChange: any;
  onClick?: any;
  restProps?: any;
}

const Radio = (
  {
    className,
    children,
    id,
    name,
    value,
    checked,
    onChange,
    ...restProps
  }: RadioProps,
  ref: any,
) => {
  const uid = `radio-${id}`;

  const handleEnterSelectRadio = (event: any) => {
    if (event.keyCode === 13) {
      onChange(value, name);
    }
  };

  return (
    <S.Root className={className} {...restProps}>
      <input
        ref={ref}
        type="radio"
        id={uid}
        name={name}
        tabIndex={-1}
        value={`${value}`}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={uid} tabIndex={0} onKeyDown={handleEnterSelectRadio}>
        {children}
      </label>
    </S.Root>
  );
};

export default forwardRef(Radio);
