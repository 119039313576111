import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const RootStyle = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    border-bottom: 1px solid ${theme.color.gray_02};
    padding-bottom: 20px;
  `}
`;

export const UploadLabel = styled.label<{ disabled: boolean }>`
  ${({ disabled }) => css`
    width: 142px;
    padding-right: 10px;
    pointer-events: ${disabled ? "none" : ""};
    user-select: none;
    cursor: pointer;

    input {
      display: none;
    }
  `}
`;

export const UploadButton = styled.div<{ disabled: boolean }>`
  ${({ disabled, theme }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    column-gap: 2px;
    height: 32px;
    border: 1px solid;
    border-color: ${theme.color.gray_03};
    border-radius: 4px;
    padding: 6px 12px 6px 7px;
    background-color: ${disabled ? theme.color.gray_02 : theme.color.white};
    cursor: ${disabled ? "not-allowed" : "pointer"};

    > span {
      ${theme.font.medium_14};
      color: ${disabled ? theme.color.gray_05 : theme.color.gray_07};
    }

    > svg {
      width: 16px;
      height: 16px;

      > path {
        fill: ${disabled ? theme.color.gray_05 : theme.color.blue_01};
      }
    }
  `}
`;

export const FileWrapper = styled.div<{ isAttaches: boolean }>`
  ${({ isAttaches, theme }) => css`
    display: flex;
    column-gap: 6px;
    margin-top: 6px;
    border-bottom: ${isAttaches ? 0 : `1px solid ${theme.color.gray_02}`};
    border: ${isAttaches ? `1px solid ${theme.color.gray_02}` : 0};
    border-radius: ${isAttaches ? "4px" : "0"};
    padding: ${isAttaches ? "11px" : "0"};
  `}
`;

export const File = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 180px;
    height: 108px;
    border: 1px solid ${theme.color.gray_03};
    border-radius: 4px;

    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  `}
`;

export const DeleteBtn = styled.button`
  position: absolute;
  top: 2px;
  right: 4px;
  width: 29px;
  height: 29px;
  border-radius: 4px;
  background: rgba(12, 16, 20, 0.5);

  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
