import React from "react";

import {
  ChromeIconImg,
  FirefoxIconImg,
  EdgeIconImg,
  SafariIconImg,
  ExclamationIconImg,
} from "assets";
import * as S from "./IESupport.styled";

const IESupport = () => {
  const title = "지원되지 않는 브라우저 입니다.";
  const desc =
    "지원하지 않는 웹 브라우저를 사용하고 있습니다. \n 다음 옵션 중 하나를 선택하여 다시 시도해주세요!";
  const browsers = {
    "Google Chrome": <ChromeIconImg />,
    "Mozilla Firefox": <FirefoxIconImg />,
    "Microsoft Edge": <EdgeIconImg />,
    Safari: <SafariIconImg />,
  };

  return (
    <S.Root>
      <h1 className="a11y">IE not supported</h1>
      <S.ContentWrapper>
        <ExclamationIconImg css={S.exclamationIcon} />
        <S.ContentItem>
          <S.Title>{title}</S.Title>
          <S.Desc>{desc}</S.Desc>
        </S.ContentItem>
      </S.ContentWrapper>
      <S.BrowserWrapper>
        {Object.entries(browsers).map(([browser, icon]) => (
          <S.BrowserItem key={browser}>
            {icon}
            <span>{browser}</span>
          </S.BrowserItem>
        ))}
      </S.BrowserWrapper>
    </S.Root>
  );
};

export default IESupport;
