import React from "react";

import {
  Select,
  MileageDialog,
  ReservationForm,
  MobileInput,
  Button,
  Input,
  Textarea,
  RadioButton,
  ValidMessage,
  DialogButton,
  DropdownInput,
  NoshowCnt,
} from "components";
import { useGetBrands } from "services";
import {
  numToStringPhoneNum,
  numericOnlyWithComma,
  checkVinValidation,
  reservationNumFormatter,
  getNullData,
} from "utils";
import {
  RESERVATION_TABINFO,
  RESERVATION_DEFAULT_INFO,
  RESERVATION_CUSTOMER_INFO,
  RESERVATION_REQUEST_INFO,
  SEAT_TABLE,
} from "assets";
import * as S from "./ReservationInfo.styled";
import { AttachedFile } from "../index";

interface ReservationInfoProps {
  inputRefs?: any;
  data: any;
  reservationData?: any;
  status: any;
  isSelf: any;
  accepted?: any;
  isChangeable?: boolean;
  handleChangeData?: any;
  handleBlurData?: any;
  handleSaveMemo?: () => void;
  handleKeyDown?: any;
  updateMileage?: any;
  regNumAction?: any;
}

const ReservationInfo = ({
  inputRefs,
  data,
  reservationData,
  status,
  isSelf,
  isChangeable = true,
  handleChangeData,
  handleBlurData,
  handleSaveMemo,
  handleKeyDown,
  updateMileage,
  regNumAction,
}: ReservationInfoProps) => {
  const { data: brands } = useGetBrands();

  const content =
    isSelf && isChangeable ? (
      <ReservationInfo.InputForm
        inputRefs={inputRefs}
        data={data}
        reservationData={reservationData}
        brandListTable={brands}
        handleChangeData={handleChangeData}
        handleBlurData={handleBlurData}
        handleSaveMemo={handleSaveMemo}
        handleKeyDown={handleKeyDown}
        regNumAction={regNumAction}
      />
    ) : (
      <ReservationInfo.ReadForm
        data={data}
        reservationData={reservationData}
        status={status}
        brandListTable={brands}
        updateMileage={updateMileage}
        handleChangeData={handleChangeData}
        handleSaveMemo={handleSaveMemo}
      />
    );

  return (
    <S.Root>
      {/* <div as={!isSelf && "dl"}>{content}</div>  // NOTE(한지용): div에 as 속성이 없습니다. */}
      <div>{content}</div>
    </S.Root>
  );
};

ReservationInfo.InputForm = function ReservInputForm({
  inputRefs,
  data,
  brandListTable,
  reservationData,
  handleChangeData,
  handleBlurData,
  handleSaveMemo,
  handleKeyDown,
  regNumAction,
}: any) {
  const getTextValue = (key: any) => {
    switch (key) {
      case "reservationNo":
        return reservationNumFormatter(data[key].value);

      case "isSelf":
        return data[key].value === "y" ? "자체 예약" : "앱 예약";

      case "status":
        return (
          <S.Status data-status={data[key].value}>
            {
              RESERVATION_TABINFO[
                data[key].value as keyof typeof RESERVATION_TABINFO
              ]?.label
            }
          </S.Status>
        );

      default:
        return data[key].value;
    }
  };

  return (
    <>
      <ReservationForm.Field title="예약 요청" type="default">
        {RESERVATION_DEFAULT_INFO.map((item) => (
          <S.InfoItem key={item.key}>
            <S.Label as="dt">{item.label}</S.Label>
            <dd>
              <span>{getTextValue(item.key) ?? "-"}</span>
            </dd>
          </S.InfoItem>
        ))}
      </ReservationForm.Field>
      <ReservationForm.Field title="고객 정보">
        {RESERVATION_CUSTOMER_INFO.map((item) => (
          <S.InfoItem key={item.key}>
            <S.Label
              htmlFor={`accepted-modal-${item.key}`}
              isRequired={item.isRequired}
            >
              {item.label}
            </S.Label>
            <S.InputWrapper>
              {item.type === "text" ? (
                <S.SuffixWrapper>
                  <Input
                    css={S.suffixInput}
                    id={`accepted-modal-${item.key}`}
                    ref={(el) => (inputRefs.current[item.key] = el)}
                    readOnly={item.isReadOnly}
                    name={item.key}
                    value={data[item.key].value}
                    validError={data[item.key].error}
                    data-required={item.isRequired ? "true" : ""}
                    placeholder={item.placeholder}
                    suffixText={item?.suffixText}
                    onChange={handleChangeData}
                    onBlur={handleBlurData}
                    onKeyDown={handleKeyDown}
                  />
                  {item.suffixText && (
                    <S.SuffixText>{item.suffixText}</S.SuffixText>
                  )}
                </S.SuffixWrapper>
              ) : item.type === "phone" ? (
                <S.MobileWrapper>
                  <MobileInput
                    ref={inputRefs}
                    isRequired={item.isRequired}
                    data={data}
                    phoneKey={item.key}
                    phoneLocalKey={`${item.key}Local`}
                    placeholder={item.placeholder}
                    readOnly={item.isReadOnly}
                    handleChange={handleChangeData}
                    handleBlur={handleBlurData}
                  />
                  <NoshowCnt css={S.noshow} noshowCnt={data.noshows.value} />
                </S.MobileWrapper>
              ) : item.type === "regNum" ? (
                <S.SuffixWrapper>
                  {data[item.key].step === 1 && (
                    <Input
                      css={S.suffixInput}
                      id={`accepted-modal-${item.key}`}
                      ref={(el) => (inputRefs.current[item.key] = el)}
                      name={item.key}
                      value={data[item.key].value}
                      validError={data[item.key].error}
                      placeholder={item.placeholder}
                      suffixText={item?.suffixText}
                      onChange={handleChangeData}
                      onBlur={handleBlurData}
                      readOnly={item.isReadOnly}
                    />
                  )}
                  {data[item.key].step === 2 && (
                    <DropdownInput
                      css={S.dropdownInput}
                      id={`accepted-modal-${item.key}`}
                      ref={(el) => (inputRefs.current["regNum"] = el)}
                      refEl={inputRefs.current["regNum"]}
                      name="regNum"
                      value={data.regNum.value}
                      searchValue={data.regNumSearchText.value}
                      data-required="true"
                      aria-invalid="true"
                      aria-errormessage="error-msg-reservation-regNum"
                      validError={data.regNum.error}
                      placeholder="차량번호를 입력하세요"
                      onChange={handleChangeData}
                      onBlur={handleBlurData}
                      handleSelect={regNumAction.handleSelectRegNum}
                      handleSearch={regNumAction.handleSearchCustomer}
                    />
                  )}
                  {data[item.key].step === 1 && (
                    <Button
                      css={S.editRegNumBtn}
                      variant="secondarySmall"
                      onClick={regNumAction.changeRegNumState(2)}
                    >
                      수정
                    </Button>
                  )}
                  {data[item.key].step === 2 && (
                    <>
                      <Button
                        css={S.updateRegNumBtn}
                        onClick={regNumAction.handleSearchCustomer}
                      >
                        완료
                      </Button>
                      <Button
                        variant="secondarySmall"
                        onClick={regNumAction.handleCancelRegNum}
                      >
                        취소
                      </Button>
                    </>
                  )}
                </S.SuffixWrapper>
              ) : item.type === "select" ? (
                <Select
                  css={S.select}
                  forwardRef={(el: any) => (inputRefs.current[item.key] = el)}
                  name={item.key}
                  labelTable={
                    item.key === "brandId" ? brandListTable : item.labelTable
                  }
                  required={item.isRequired as boolean}
                  placeholder={item.placeholder}
                  selectedOption={data[item.key].value}
                  onSelect={handleChangeData}
                  onBlur={handleBlurData}
                >
                  {Object.entries(
                    item.key === "brandId"
                      ? brandListTable ?? {}
                      : item.labelTable ?? {},
                  ).map(([value, label]) => (
                    <Select.Option key={value} value={value}>
                      {label as string}
                    </Select.Option>
                  ))}
                </Select>
              ) : item.type === "textarea" ? (
                <Textarea
                  css={S.textarea}
                  id={`accepted-modal-${item.key}`}
                  ref={(el) => (inputRefs.current[item.key] = el)}
                  name={item.key}
                  value={data[item.key].value}
                  placeholder={item.placeholder}
                  onChange={handleChangeData}
                  onBlur={handleBlurData}
                />
              ) : (
                <S.RadioWrapper>
                  {"radioItems" in item &&
                    (item.radioItems as any[]).map(
                      (
                        radio: any, // NOTE(한지용) : RESERVATION_CUSTOMER_INFO 속성에 radioItems가 없습니다.
                      ) => (
                        <RadioButton
                          css={S.radioButton(item.key)}
                          key={radio.value}
                          id={`radio-repairInfo-${radio.value}`}
                          name={item.key}
                          value={radio.value}
                          checked={data[item.key].value === radio.value}
                          onChange={handleChangeData}
                        >
                          {radio.label}
                        </RadioButton>
                      ),
                    )}
                </S.RadioWrapper>
              )}
              {data[item.key].error && (
                <ValidMessage css={S.validText}>
                  {data[item.key].error}
                </ValidMessage>
              )}
            </S.InputWrapper>
          </S.InfoItem>
        ))}
      </ReservationForm.Field>
      <ReservationForm.Field title="요청 정보">
        {RESERVATION_REQUEST_INFO.map((item) => (
          <S.InfoItem key={item.key}>
            <S.Label
              htmlFor={`accepted-modal-${item.key}`}
              isRequired={
                item.key === "vin" &&
                data?.approvedMaintenance?.value !== "approved"
                // ? false
                // : item.isRequired // NOTE(한지용) RESERVATION_REQUEST_INFO에 isRequired 속성이 없습니다.
              }
            >
              {item.label}
            </S.Label>
            <S.InputWrapper>
              {item.type === "textarea" ? (
                <Textarea
                  css={S.textarea}
                  id={`accepted-modal-${item.key}`}
                  ref={(el) => (inputRefs.current[item.key] = el)}
                  name={item.key}
                  value={data[item.key].value}
                  placeholder={item.placeholder}
                  onChange={handleChangeData}
                  onBlur={handleBlurData}
                  maxLength={200}
                />
              ) : item.type === "radio" ? (
                <S.RadioWrapper>
                  {item.radioItems?.map((radio) => (
                    <RadioButton
                      css={S.radioButton(item.key)}
                      key={radio.value}
                      id={`radio-repairInfo-${radio.value}`}
                      name={item.key}
                      value={radio.value}
                      checked={data[item.key].value === radio.value}
                      onChange={handleChangeData}
                    >
                      {radio.label}
                    </RadioButton>
                  ))}
                </S.RadioWrapper>
              ) : (
                <span>{getTextValue(item.key) ?? "-"}</span>
              )}
              {data[item.key].error && (
                <ValidMessage css={S.validText}>
                  {data[item.key].error}
                </ValidMessage>
              )}
            </S.InputWrapper>
          </S.InfoItem>
        ))}
        {reservationData.hasAttachedFiles && (
          <AttachedFile data={reservationData} />
        )}
      </ReservationForm.Field>
      <ReservationForm.Field
        title="추가 정보"
        hasBtn
        handleSaveInfo={handleSaveMemo}
      >
        <S.MemoInfoItem>
          <S.Label as="dt">메모</S.Label>
          <dd>
            <Textarea
              css={S.memoTextarea}
              id="accepted-modal-memo"
              name="memo"
              value={data["memo"].value}
              maxLength={200}
              onChange={handleChangeData}
            />
          </dd>
        </S.MemoInfoItem>
      </ReservationForm.Field>
    </>
  );
};

interface ReadFormProps {
  data: any;
  reservationData: any;
  status: any;
  brandListTable: any;
  updateMileage: () => void;
  handleChangeData: () => void;
  handleSaveMemo: any;
}

ReservationInfo.ReadForm = function ReservReadForm({
  data,
  reservationData,
  status,
  brandListTable = [],
  updateMileage,
  handleChangeData,
  handleSaveMemo,
}: ReadFormProps) {
  const getTextValue = (key: any) => {
    switch (key) {
      case "name":
        return reservationData[key] ? data[key].value : getNullData(key);

      case "brandId":
        return brandListTable[reservationData[key]];

      case "mileage":
        return data[key].value
          ? `${numericOnlyWithComma(data[key].value)} km`
          : "-";

      case "axle":
        return reservationData[key] ? `${reservationData[key]} 축` : "-";

      case "seat":
        return SEAT_TABLE[reservationData[key] as keyof typeof SEAT_TABLE];

      case "vin":
        return reservationData[key]
          ? checkVinValidation(reservationData[key])
            ? reservationData[key]
            : "-"
          : "-";

      case "phone":
        return reservationData[key] ? (
          <S.MobileWrapper>
            <span>{numToStringPhoneNum(reservationData[key])}</span>
            <NoshowCnt noshowCnt={data.noshows.value} />
          </S.MobileWrapper>
        ) : (
          getNullData(key)
        );

      case "reservationNo":
        return reservationNumFormatter(reservationData[key]);

      case "isSelf":
        return reservationData[key] === "y" ? "자체 예약" : "앱 예약";

      case "status":
        return (
          <S.Status data-status={reservationData[key]}>
            {
              RESERVATION_TABINFO[
                reservationData[key] as keyof typeof RESERVATION_TABINFO
              ]?.label
            }
          </S.Status>
        );

      default:
        return reservationData[key] || "-";
    }
  };
  const updateableMileageStatus = ["accepted", "repairing"];
  const updateableMemoStatus = ["planned", "accepted", "repairing"];

  return (
    <>
      <ReservationForm.Field title="예약 요청" type="default">
        {RESERVATION_DEFAULT_INFO.map((item) => (
          <S.InfoItem key={item.key}>
            <S.Label as="dt">{item.label}</S.Label>
            <dd>
              <span>{getTextValue(item.key) ?? "-"}</span>
            </dd>
          </S.InfoItem>
        ))}
      </ReservationForm.Field>
      <ReservationForm.Field title="고객 정보">
        {RESERVATION_CUSTOMER_INFO.map((item) => (
          <S.InfoItem key={item.key}>
            <S.Label as="dt">{item.label}</S.Label>
            <dd>
              {item.key === "mileage" &&
              updateableMileageStatus.includes(status) ? (
                <div>
                  <span>{getTextValue(item.key) ?? "-"}</span>
                  {(data.approvedMaintenance?.value === "approved" ||
                    data.status?.value === "repairing") && (
                    <S.ButtonWrapper>
                      <DialogButton
                        css={S.updateDialogButton}
                        popup={(
                          isDialogOpen: any,
                          dialogRef: any,
                          handleDialogClose: any,
                        ) => (
                          <MileageDialog
                            isDialogOpen={isDialogOpen}
                            dialogRef={dialogRef}
                            mileage={data[item.key].value}
                            updateMileage={updateMileage}
                            handleDialogClose={handleDialogClose}
                          />
                        )}
                      >
                        수정
                      </DialogButton>
                    </S.ButtonWrapper>
                  )}
                </div>
              ) : (
                <span>{getTextValue(item.key) ?? "-"}</span>
              )}
            </dd>
          </S.InfoItem>
        ))}
      </ReservationForm.Field>
      <ReservationForm.Field title="요청 정보">
        {RESERVATION_REQUEST_INFO.map((item) => (
          <S.InfoItem key={item.key}>
            <S.Label as="dt">{item.label}</S.Label>
            <dd>
              {item.key === "userComment" ? (
                <Textarea
                  css={S.textarea}
                  id={`accepted-modal-${item.key}`}
                  name={item.key}
                  value={data[item.key].value}
                  readOnly={true}
                />
              ) : (
                <span>{getTextValue(item.key) ?? "-"}</span>
              )}
            </dd>
          </S.InfoItem>
        ))}
        {reservationData.hasAttachedFiles && (
          <AttachedFile data={reservationData} />
        )}
      </ReservationForm.Field>
      <ReservationForm.Field
        title="추가 정보"
        hasBtn={updateableMemoStatus.includes(status)}
        handleSaveInfo={handleSaveMemo}
      >
        <S.MemoInfoItem>
          <S.Label as="dt">메모</S.Label>
          <dd>
            <Textarea
              css={S.memoTextarea}
              id="accepted-modal-memo"
              name="memo"
              value={data["memo"].value}
              maxLength={200}
              onChange={handleChangeData}
              readOnly={!updateableMemoStatus.includes(status)}
            />
          </dd>
        </S.MemoInfoItem>
      </ReservationForm.Field>
    </>
  );
};

export default ReservationInfo;
