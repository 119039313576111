import React from "react";

import { Select, MobileInput, Input, DetailModal } from "components";
import { useGetBrands } from "services";
import { ADD_CUSTOMER_FORM } from "assets";
import useAddCustomer from "../../../hooks/useAddCustomer";
import * as S from "./CustomerAddModal.styled";

interface CustomerAddModalProps {
  handleModalClose: () => void;
}

const CustomerAddModal = React.forwardRef(
  (
    { handleModalClose }: CustomerAddModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { data: brands } = useGetBrands();

    const {
      inputRefs,
      form,
      isSubmitDisabled,
      handleSubmit,
      handleChangeForm,
      handleBlurForm,
    } = useAddCustomer(handleModalClose);

    return (
      <DetailModal
        ref={ref}
        title="고객 추가"
        isPosDisabled={!!isSubmitDisabled}
        cancelBtnName="취소"
        posBtnName="등록"
        posFnType="submit"
        posFn={handleSubmit}
      >
        <S.Container>
          {ADD_CUSTOMER_FORM.map((item) => (
            <S.InputWrapper key={item.label} isRequired={item.isRequired}>
              <dt>
                <label htmlFor={`add-customer-${item.key}`}>{item.label}</label>
              </dt>
              <dd>
                {item.type === "input" ? (
                  <Input
                    css={S.input}
                    id={`add-customer-${item.key}`}
                    ref={(el) => (inputRefs.current[item.key] = el)}
                    data-required={item.isRequired ? "true" : ""}
                    name={item.key}
                    value={form[item.key as keyof typeof form].value}
                    placeholder={item.placeholder}
                    validError={form[item.key as keyof typeof form].error}
                    onChange={handleChangeForm}
                    onBlur={handleBlurForm}
                  />
                ) : item.type === "phone" ? (
                  <MobileInput
                    ref={inputRefs}
                    isRequired={item.isRequired}
                    data={form}
                    phoneKey={item.key}
                    phoneLocalKey={`${item.key}Local`}
                    placeholder={item.placeholder}
                    readOnly={item.isReadOnly}
                    handleChange={handleChangeForm}
                    handleBlur={handleBlurForm}
                  />
                ) : (
                  <Select
                    css={S.DropDown}
                    id={`add-customer-${item.key}`}
                    forwardRef={(el: any) => (inputRefs.current[item.key] = el)}
                    name={item.key}
                    selectedOption={form[item.key as keyof typeof form].value}
                    labelTable={brands}
                    required={item.isRequired}
                    placeholder={item.placeholder}
                    validError={form[item.key as keyof typeof form].error}
                    onSelect={handleChangeForm}
                    onBlur={handleBlurForm}
                  >
                    {Object.entries(brands ?? {}).map(([value, label]) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </dd>
              {form[item.key as keyof typeof form].error && (
                <S.ValidText>
                  {form[item.key as keyof typeof form].error}
                </S.ValidText>
              )}
            </S.InputWrapper>
          ))}
        </S.Container>
      </DetailModal>
    );
  },
);

CustomerAddModal.displayName = "CustomerAddModal";

export default CustomerAddModal;
