import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";
import "react-image-crop/dist/ReactCrop.css";

export const detailModal = (theme: Theme) => css`
  & > form > div {
    border-bottom: 0;
    background-color: ${theme.color.white};
  }
`;

export const Content = styled.div`
  padding: 20px 36px 0;
`;

export const ValidMsg = styled.dl`
  ${({ theme }) => css`
    dt {
      ${theme.font.regular_14};
      margin-bottom: 6px;
      color: ${theme.color.gray_10};
    }

    dd {
      ${theme.font.regular_12};
      color: ${theme.color.gray_06};
    }
  `}
`;

export const UploadContainer = styled.div`
  ${({ theme }) => css`
    height: 368px;
    margin-top: 20px;
    border: 1px solid ${theme.color.gray_03};
    border-radius: 4px;
    padding: 16px;
  `}
`;

export const UploadBox = styled.div<{ isDragging: boolean }>`
  ${({ isDragging, theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: ${theme.color.gray_00};
    background-image: ${isDragging
      ? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%234493f9' stroke-width='3' stroke-dasharray='5' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e")`
      : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23bec8d6' stroke-width='3' stroke-dasharray='5' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e")`};

    & > * {
      pointer-events: ${isDragging ? "none" : "auto"};
    }

    & > svg {
      margin-bottom: 8px;
    }

    & > p {
      ${theme.font.medium_14};
      margin-bottom: 8px;
      color: ${theme.color.blue_01};
      text-align: center;

      & > span {
        ${theme.font.regular_14};
        display: block;
        margin-top: 4px;
        color: ${theme.color.gray_05};
      }
    }
  `}
`;

export const Uploader = styled.label`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 34px;
    border-radius: 4px;
    border: 1px solid ${theme.color.blue_01};
    padding: 0 15px;
    color: ${theme.color.blue_01};
    background-color: ${theme.color.white};
    cursor: pointer;

    & > input {
      display: none;
    }
  `}
`;

export const ImgChangerWrapper = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.color.gray_03};
    border-radius: 4px;
    overflow: hidden;
  `}
`;

export const ImageChanger = styled.label`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 42px;
    border-bottom: 1px solid ${theme.color.gray_03};
    border-radius: 4px 4px 0 0;
    color: ${theme.color.blue_01};
    cursor: pointer;

    & > input {
      display: none;
    }
  `}
`;

export const bottomButtonWrapper = (theme: Theme) => css`
  border-top: 0;
  background-color: ${theme.color.white};
`;

export const completeButton = css`
  width: 90px;
`;

export const CropContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 400px;
  height: max-content;
  max-height: 400px;
  overflow-y: overlay;

  &::-webkit-scrollbar {
    display: none;
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const reactCrop = (theme: Theme) => css`
  display: block;
  width: 100%;

  .ReactCrop__crop-selection {
    border: 8px solid ${theme.color.blue_01};
  }
  .ReactCrop__child-wrapper {
    display: flex;
    align-items: center;
    min-height: 400px;
    background-color: ${theme.color.gray_09};
  }
`;

export const Image = styled.img<{ ratio: number }>`
  ${({ ratio }) => css`
    transform-origin: left top;
    transform: scale(${ratio});
  `}
`;
