import { AtomEffect, DefaultValue, WrappedValue, atom } from "recoil";

import type { RepairShopInfo } from "types";

interface StorageEffectProps<T> {
  setSelf: (
    param:
      | T
      | DefaultValue
      | Promise<T | DefaultValue>
      | WrappedValue<T>
      | ((param: T | DefaultValue) => T | DefaultValue | WrappedValue<T>),
  ) => void;
  onSet: (
    param: (newValue: T, oldValue: T | DefaultValue, isReset: boolean) => void,
  ) => void;
}

const localStorageEffect =
  <T>(key: string): AtomEffect<T> =>
  ({ setSelf, onSet }: StorageEffectProps<T>) => {
    const savedValue = localStorage.getItem(key);

    if (savedValue !== null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: any, _: any, isReset: boolean) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

const repairShopInfo = atom<RepairShopInfo>({
  key: "user",
  default: {
    available: "n",
    name: "",
    pwUpdated: "",
    status: "",
    thumbnail: "",
    thumbnailKey: "",
  },
  effects: [localStorageEffect<RepairShopInfo>("user")],
});

const getHolidayInfo = atom<string[]>({
  key: "holidays",
  default: [],
  effects: [localStorageEffect<string[]>("holidays")],
});

export { repairShopInfo, getHolidayInfo };
