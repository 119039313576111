import React, { createContext, useContext, useMemo } from "react";

import { Select, Input } from "components";
import { MIN_OPTION_TABLE } from "constants/index";
import * as S from "./EstimatedTime.styled";

const EstimatedContext = createContext({});

interface EstimatedTimeProps {
  forwardRef?: any;
  className?: string;
  children: React.ReactNode;
  id?: string;
  disabled?: boolean;
  onChange?: any;
  onBlur?: any;
  required?: boolean;
}

interface EstimatedHourProps {
  id?: string;
  className?: string;
  label?: string;
  name?: string;
  forwardRef?: any;
  value?: string;
  validError?: any;
  required?: any;
  readOnly?: boolean;
  onChange?: any;
}

interface EstimatedHourSelectProps {
  id?: string;
  placeholder?: string;
  forwardRef?: any;
  name?: string;
  className?: string;
  label?: string;
  value: string;
  validError?: any;
  required?: any;
}

interface EstimatedExpandHourProps {
  className?: string;
  id?: string;
  placeholder?: string;
  name?: string;
  validError?: any;
  label: string;
  isItemDisabled?: boolean;
  value: string;
  filterTime?: any;
}

interface MinProps {
  className?: string;
  label: string;
  value?: string;
  required?: any;
  readOnly?: boolean;
}

interface SelectMinProps {
  className?: string;
  placeholder?: string;
  forwardRef?: any;
  halfDisable?: boolean;
  zeroDisable?: boolean;
  required?: boolean;
  isItemDisabled?: boolean;
  name?: string;
  id?: string;
  value: string;
  label?: string;
  validError?: any;
  onSelect?: any;
}

// TODO Refactoring
const EstimatedTime = ({
  className,
  children,
  id,
  disabled,
  onChange,
  onBlur,
}: EstimatedTimeProps) => {
  const uuid = `estimatedTime-${id}`;

  return (
    <EstimatedContext.Provider value={{ id: uuid, disabled, onChange, onBlur }}>
      <S.Root className={className}>{children}</S.Root>
    </EstimatedContext.Provider>
  );
};

EstimatedTime.Hour = function EstimatedHourInput({
  className,
  label = "시",
  forwardRef,
  required,
  ...restProps
}: EstimatedHourProps) {
  const { id, onChange, onBlur } = useContext(EstimatedContext) as any;

  const uid = `${id}-hour`;

  return (
    <S.Wrapper className={className} labelLength={label.length}>
      <Input
        css={S.timeInput}
        id={uid}
        ref={forwardRef}
        placeholder="-"
        data-required={required ? "true" : ""}
        onChange={onChange}
        onBlur={onBlur}
        {...restProps}
      />
      <S.SuffixText htmlFor={uid}>{label}</S.SuffixText>
    </S.Wrapper>
  );
};

EstimatedTime.SelectHour = function EstimatedHourSelect({
  className,
  value,
  required,
  label = "시",
  ...restProps
}: EstimatedHourSelectProps) {
  const { disabled, onChange, onBlur } = useContext(EstimatedContext) as any;

  const hours = useMemo(
    () =>
      [...Array(24)].reduce(
        (acc, _, idx) => ({
          ...acc,
          [+idx]: `${+idx < 10 ? `0${idx}` : idx + ""}`,
        }),
        {},
      ),

    [],
  );

  return (
    <S.Wrapper className={className}>
      <Select
        css={S.hourSelect()}
        labelTable={hours}
        selectedOption={value}
        placeholder="선택"
        required={required}
        data-required={required ? "true" : ""}
        disabled={disabled}
        onSelect={onChange}
        {...restProps}
      >
        {Object.entries(hours).map(([value, label]: [any, any]) => (
          <Select.Option key={label as any} value={+value} onBlur={onBlur}>
            {label}
          </Select.Option>
        ))}
      </Select>
      <S.SuffixText as="span">{label}</S.SuffixText>
    </S.Wrapper>
  );
};

EstimatedTime.SelectExpndHour = function EstimatedExpndHourSelect({
  className,
  value,
  label = "시",
  filterTime,
  isItemDisabled,
  ...restProps
}: EstimatedExpandHourProps) {
  const { disabled, onChange, onBlur } = useContext(EstimatedContext) as any;

  const hours = useMemo(
    () =>
      [...Array(25)].reduce(
        (acc, _, idx) => ({
          ...acc,
          [+idx]: `${+idx < 10 ? `0${idx}` : idx + ""}`,
        }),
        {},
      ),
    [],
  );

  const filteredHours = Object.entries(hours).filter(([value]) =>
    filterTime(value),
  );

  return (
    <S.Wrapper className={className}>
      <Select
        css={S.hourSelect(!filteredHours.length)}
        labelTable={hours}
        selectedOption={value}
        placeholder="-"
        disabled={disabled || isItemDisabled}
        onSelect={onChange}
        {...restProps}
      >
        {filteredHours?.map(([value, label]: [string, any]) => (
          <Select.Option key={label} value={+value} onBlur={onBlur}>
            {label}
          </Select.Option>
        ))}
      </Select>
      <S.SuffixText as="span">{label}</S.SuffixText>
    </S.Wrapper>
  );
};

EstimatedTime.Min = function EstimatedMinInput({
  className,
  label = "분",
  required,
  ...restProps
}: MinProps) {
  const { id, onChange, onBlur } = useContext(EstimatedContext) as any;

  const uid = `${id}-min`;

  return (
    <S.Wrapper className={className} labelLength={label.length}>
      <Input
        css={S.timeInput}
        id={uid}
        placeholder="-"
        data-required={required ? "true" : ""}
        onChange={onChange}
        onBlur={onBlur}
        {...restProps}
      />
      <S.SuffixText htmlFor={uid}>{label}</S.SuffixText>
    </S.Wrapper>
  );
};

EstimatedTime.SelectMin = function EstimatedMinSelect({
  className,
  value,
  isItemDisabled,
  halfDisable,
  zeroDisable,
  label = "분",
  ...restProps
}: SelectMinProps) {
  const { disabled, onChange, onBlur } = useContext(EstimatedContext) as any;

  return (
    <S.Wrapper className={className}>
      <Select
        css={S.minSelect}
        labelTable={MIN_OPTION_TABLE}
        selectedOption={value}
        placeholder="선택"
        disabled={disabled || isItemDisabled}
        onSelect={onChange}
        {...restProps}
      >
        {Object.entries(MIN_OPTION_TABLE)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map(
            ([value, label], idx) =>
              !((idx === 0 && zeroDisable) || (idx === 1 && halfDisable)) && (
                <Select.Option key={label} value={value} onBlur={onBlur}>
                  {label}
                </Select.Option>
              ),
          )}
      </Select>

      <S.SuffixText as="span">{label}</S.SuffixText>
    </S.Wrapper>
  );
};

export default EstimatedTime;
