import React from "react";

import { Radio, Select, Button } from "components";
import { useModal } from "hooks";
import { CommentIconImg } from "assets";
import FrequentlyUsedCommentsModal from "../modal/FrequentlyUsedCommentsModal";
import useFrequentlyUsedCommentDropDown from "../../hooks/useFrequentlyUsedCommentDropDown";
import * as S from "./FrequentlyUsedCommentSelector.styled";

interface FrequentlyUsedCommentSelectorProps {
  radioItems: any;
  selectedType?: any;
  onChange: (option: string) => void;
}

interface RadioItemListType {
  [key: string]: string;
}

const FrequentlyUsedCommentSelector = ({
  radioItems,
  onChange,
}: FrequentlyUsedCommentSelectorProps) => {
  const radioItemList: RadioItemListType = {
    default: "자주 쓰는 댓글 선택",
  };
  radioItems.forEach((item: any) => {
    radioItemList[item.templateId] = item.title;
  });

  const {
    isSelected,
    selectedOption,
    handleSelectOption,
    handleOnClickDropDown,
    hanldeRadioItemClick,
  } = useFrequentlyUsedCommentDropDown({ onChange: onChange });
  const { modalRef, handleModalOpen } = useModal();

  return (
    <S.Root>
      <S.RadioWrapper>
        <li>
          <Radio
            id="default"
            name="commentType"
            value=""
            checked={!isSelected}
            onChange={onChange}
            onClick={hanldeRadioItemClick}
          >
            직접입력
          </Radio>
        </li>
        <li>
          <Radio
            id="dropdown"
            name="commentType"
            value=""
            checked={isSelected}
            onChange={onChange}
            onClick={hanldeRadioItemClick}
          />
          <Select
            css={S.selectReview}
            placeholder={"자주 쓰는 댓글 선택"}
            labelTable={radioItemList}
            selectedDropdown={isSelected}
            selectedOption={selectedOption}
            onSelect={handleSelectOption}
            onClickCallback={handleOnClickDropDown}
          >
            {Object.entries(radioItemList).map(([value, label]) => (
              <Select.Option id={value} key={label} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </li>
      </S.RadioWrapper>
      <Button
        css={S.settingButton}
        icon={<CommentIconImg />}
        variant="secondarySmall"
        onClick={handleModalOpen(
          <FrequentlyUsedCommentsModal ref={modalRef} />,
        )}
      >
        댓글 설정
      </Button>
    </S.Root>
  );
};

export default FrequentlyUsedCommentSelector;
