import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.strong`
  ${({ theme }) => css`
    ${theme.font.regular_12};
    position: absolute;
    left: 0;
    display: block;
    white-space: nowrap;
    color: ${theme.color.error_01};
  `}
`;
