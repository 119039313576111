import { useRef, useCallback, useEffect } from "react";
import { useImmer } from "use-immer";

import { useCheckRequired, useToast } from "hooks";
import { useUpdateStaff } from "services";
import {
  checkInputLength,
  checkPhoneValidation,
  deleteHyphen,
  getPhoneInfo,
} from "utils";
import { TOAST_MSG, VALID_MESSAGE } from "constants/index";

const INIT_STATE = {
  name: { value: "", error: "" },
  phoneLocal: { value: "010", error: "" },
  phone: { value: "", error: "" },
  position: { value: "", error: "" },
  staffId: { value: "", error: "" },
  isMechanic: { value: "", error: "" },
  alimtalkYn: { value: "", error: "" },
  repairCount: { value: "", error: "" },
  repairCountText: { value: "", error: "" },
};

const useEditStaff = (initData: any, handleModalClose: () => void) => {
  const inputRefs = useRef<any>({});

  const [form, setForm] = useImmer(INIT_STATE);
  const { mutate: updateStaff } = useUpdateStaff();

  const isSubmitDisabled =
    Object.values(form).filter((data) => data.error !== "").length > 0;

  const { addToast } = useToast();
  const [checkRequired] = useCheckRequired(
    inputRefs,
    form,
    setForm,
    VALID_MESSAGE.REQUIRED_NO_ASTERISK,
  );

  const handleSubmit = useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      if (checkRequired()) return;

      const body = {
        staffId: form.staffId.value,
        name: form.name.value.trim(),
        position: form.position.value.trim(),
        phone: `${form.phoneLocal.value}${form.phone.value}`,
        isMechanic: form.isMechanic.value,
        alimtalkYn: form.alimtalkYn.value,
        repairCount: form.repairCount.value,
      };

      const req = {
        staffId: form.staffId.value,
        body,
      };

      updateStaff(req, {
        onSuccess: () => {
          addToast(TOAST_MSG.SUCCESS.UPDATE_STAFF_DONE);
          handleModalClose();
        },
        onError: (err) => {
          if (err.response?.data.code === "CANNOT_UPDATE_STAFF") {
            addToast(TOAST_MSG.FAIL.UPDATE_STAFF_FAIL);
          }
        },
      });
    },
    [form],
  );

  const handleCancel = useCallback(() => {
    setForm(initData);
  }, []);

  const handleChangeForm = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
      const name = event?.target?.name ?? key;
      let value = event?.target?.value ?? event;
      let error = "";

      switch (name) {
        case "name":
          value = value.replaceAll(" ", "");
          if (!checkInputLength("NAME", value)) return;
          if (form[name].error) {
            error = VALID_MESSAGE.FAIL;
          }
          break;
        case "position": {
          if (!checkInputLength("STAFF_POSITION", value)) return;
          if (form[name].error) {
            error = VALID_MESSAGE.FAIL;
          }
          break;
        }

        case "phone":
          if (!checkInputLength("PHONE", value)) return;
          if (form[name].error && !checkPhoneValidation(value)) {
            error = VALID_MESSAGE.PHONE_NUM;
          }
          break;
      }

      setForm((draft: any) => {
        draft[name].value = value;
        draft[name].error = error;
      });
    },
    [form],
  );

  const handleBlurForm = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const {
        value,
        name,
        dataset: { required },
      } = event.target;
      let error = "";

      if (required && form[name as keyof typeof form].value.length === 0) {
        setForm((draft: any) => {
          draft[name].error = VALID_MESSAGE.REQUIRED_NO_ASTERISK;
        });
        return;
      }

      switch (name) {
        case "phone":
          if (!checkPhoneValidation(value)) {
            error = VALID_MESSAGE.PHONE_NUM;
          }
          break;
        case "position":
          if (!value.trim()) {
            error = VALID_MESSAGE.REQUIRED_NO_ASTERISK;
          }
          break;
      }

      setForm((draft: any) => {
        draft[name].error = error;
      });
    },
    [form],
  );

  const init = () => {
    const { areaCode, phone } = getPhoneInfo(
      deleteHyphen(initData.phone) || "",
    );

    setForm((draft: any) => {
      for (const key in initData) {
        if (draft[key]) {
          draft[key].value = initData[key] ?? "";
          draft[key].error = "";
        }
        draft.phoneLocal.value = areaCode ?? "";
        draft.phone.value = phone;
      }
    });
  };

  useEffect(() => {
    init();
  }, [initData]);

  return {
    inputRefs,
    form,
    isSubmitDisabled,
    handleSubmit,
    handleCancel,
    handleChangeForm,
    handleBlurForm,
  };
};

export default useEditStaff;
