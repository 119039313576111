export const STAFF_SORT_OPTION_TABLE = {
  name: "가나다순",
  recent: "최근 활동순",
  repair: "정비 횟수 순",
};

export const STAFF_TABLE_DATA = {
  columnList: [
    { label: "직원명", key: "name" },
    { label: "연락처", key: "phone" },
    { label: "직무", key: "isMechanic" },
    { label: "직급", key: "position" },
    { label: "정비 건수", key: "repairCount" },
    { label: "알림톡", key: "alimtalkYn" },
  ],
  detailInfoList: [
    {
      label: "이름",
      key: "name",
      type: "input",
      placeholder: "이름을 입력하세요",
      isRequired: true,
      isReadOnly: false,
    },
    {
      label: "연락처",
      key: "phone",
      type: "phone",
      placeholder: "예)01012345678",
      isRequired: true,
      isReadOnly: false,
    },
    {
      label: "직무",
      key: "isMechanic",
      type: "radio",
      radioItems: [
        { label: "정비 인력", value: "y" },
        { label: "비정비 인력", value: "n" },
      ],
      isRequired: true,
    },
    {
      label: "직급",
      key: "position",
      type: "input",
      placeholder: "직급을 입력하세요",
      isRequired: true,
    },
    {
      label: "알림톡 수신여부",
      key: "alimtalkYn",
      type: "radio",
      radioItems: [
        { label: "수신", value: "y" },
        { label: "비수신", value: "n" },
      ],
      isRequired: true,
    },
  ],
} as const;

export const CONVERT_VALUE_TABLE = {
  isMechanic: { y: "정비 인력", n: "비정비 인력" },
  alimtalkYn: { y: "수신", n: "미수신" },
};

export const ADD_STAFF_FORM = [
  {
    label: "이름",
    key: "name",
    type: "input",
    placeholder: "이름을 입력하세요",
    isRequired: true,
    readOnly: false,
  },
  {
    label: "연락처",
    key: "phone",
    type: "phone",
    placeholder: "- 없이 숫자만 입력하세요",
    isRequired: true,
  },
  {
    label: "직무",
    key: "isMechanic",
    type: "radio",
    radioItems: [
      { label: "정비 인력", value: "y" },
      { label: "비정비 인력", value: "n" },
    ],
    isRequired: true,
  },
  {
    label: "직급",
    key: "position",
    type: "input",
    placeholder: "직급을 입력하세요",
    isRequired: true,
  },

  {
    label: "알림톡 수신여부",
    key: "alimtalkYn",
    type: "radio",
    radioItems: [
      { label: "수신", value: "y" },
      { label: "비수신", value: "n" },
    ],
    isRequired: true,
  },
];

export const TIME_SELECT = {
  meridiem: [
    { id: "ante", label: "오전", name: "meridiem", value: 0 },
    { id: "post", label: "오후", name: "meridiem", value: 1 },
  ],
  hour: [...Array(12)].reduce((acc, _, idx) => {
    const label = idx + 1 < 10 ? `0${idx + 1}` : idx + 1 + "";
    return [
      ...acc,
      { id: `hour-${label}`, label, name: "hour", value: +label },
    ];
  }, []),
  min: [...Array(60)].reduce((acc, _, idx) => {
    const label = idx + 1 < 10 ? `0${idx}` : idx + "";
    return [...acc, { id: `min-${label}`, label, name: "min", value: +label }];
  }, []),
};

export const REPAIR_PREFIX_KEY = "raw/repairshop/";
