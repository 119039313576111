import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { getFilterParams } from "utils";
import type { StringKeyOf } from "types";

const useFilterParams = <T>(params: StringKeyOf<T>[], init?: Partial<T>) => {
  const [searchParams] = useSearchParams();

  const filters = useMemo(
    () => getFilterParams(searchParams, params, init),
    [searchParams],
  );

  return { filters };
};

export default useFilterParams;
