import { useState, useCallback } from "react";

import { numericOnlyWithComma } from "utils";

const useNumericInputComma = (initValue = "", limit: any) => {
  const [value, setValue] = useState(initValue);

  const handleChangeValue = useCallback((event: any) => {
    const v = event.target.value;

    if (typeof limit === "function" && !limit(v)) return;
    setValue(numericOnlyWithComma(v));
  }, []);

  return [value, handleChangeValue, setValue] as const;
};

export default useNumericInputComma;
