import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.li`
  ${({ theme }) => css`
    height: 34px;
    background-color: ${theme.color.white};

    &:nth-of-type(7n + 1) > button:not([disabled]) > time {
      color: ${theme.color.sunday};
    }

    &:nth-of-type(7n + 7) > button:not([disabled]) > time {
      color: ${theme.color.saturday};
    }

    &[data-status="true"] button:not([disabled]) > time {
      color: ${theme.color.sunday};
    }
  `}
`;

export const DateButton = styled.button`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 34px;

    &[aria-selected="true"] {
      &::before,
      &&:hover::before {
        background-color: ${theme.color.blue_01};
      }

      && > time {
        ${theme.font.bold_13};
        color: ${theme.color.white};
      }
    }

    &[aria-current="date"] > time {
      color: ${theme.color.blue_01};
    }

    &::before {
      content: "";
      position: absolute;
      top: 3px;
      left: 5px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }

    &:not([disabled]) {
      &:hover::before {
        background-color: ${theme.color.blue_02};
      }
    }

    & > time {
      ${theme.font.regular_13};
      color: ${theme.color.gray_09};
      z-index: 2;
    }

    &[disabled] > time {
      color: ${theme.color.gray_04};
    }
  `}
`;
