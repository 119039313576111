import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  display: flex;
  margin-bottom: 36px;
`;

export const calendarButton = (theme: Theme) => css`
  ${theme.button.secondarySmall};
  height: 30px;
  padding: 0;
`;
