import React from "react";

import { CheckIconImg } from "assets";
import * as S from "./Checkbox.styled";

interface CheckboxProps {
  className?: string;
  name?: string;
  value?: string;
  label: string;
  selected: boolean;
  handleClick: any;
  restProps?: any;
}

const Checkbox = ({
  className,
  label,
  selected,
  handleClick,
  ...restProps
}: CheckboxProps) => {
  const handlePushEnter = (event: any) => {
    const { key } = event;
    if (key === "Enter") handleClick();
  };

  return (
    <S.Root
      className={className}
      selected={selected}
      onKeyDown={handlePushEnter}
    >
      <input
        type="checkbox"
        checked={selected}
        onChange={handleClick}
        {...restProps}
      />
      <div tabIndex={0}>
        <CheckIconImg />
      </div>
      <span>{label}</span>
    </S.Root>
  );
};

export default Checkbox;
