import React, { useEffect, useState, useCallback } from "react";
import { isEmpty } from "lodash-es";

import { SelectAddList, Button } from "components";
import { useToast } from "hooks";
import { useGetMechanics, useUpdateReservationDetail } from "services";
import { TOAST_MSG } from "constants/index";
import * as S from "./MechanicDialog.styled";

interface MechanicDialogProps {
  className?: string;
  isDialogOpen?: boolean;
  dialogRef: React.ForwardedRef<HTMLDialogElement>;
  handleDialogClose: () => void;
  reservationId: string;
  mechanics: any[];
}

const MechanicDialog = ({
  className,
  isDialogOpen,
  dialogRef,
  handleDialogClose,
  reservationId,
  mechanics,
}: MechanicDialogProps) => {
  const [mechanicList, setMechanicList] = useState(() =>
    mechanics.map((item) => item.staffId),
  );
  const selectedMechanics = mechanics.reduce((acc, { staffId, staffName }) => {
    return { ...acc, [staffId]: staffName };
  }, {});

  const { mutate: updateReservationDetailMutate } =
    useUpdateReservationDetail();
  const { data: mechanicInfo } = useGetMechanics({
    enabled: isDialogOpen,
  });
  const { addToast } = useToast();

  const handleAddMechanic = useCallback((mechanicId: string) => {
    setMechanicList((prev) => [...new Set([...prev, mechanicId])]);
  }, []);

  const handleDeleteMechanic = useCallback(
    (mechanicId: string) => (event: React.ChangeEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      const newMechanics = mechanicList.filter((id) => id !== mechanicId);
      setMechanicList(newMechanics);
    },
    [mechanicList],
  );

  const updateMechanics = () => {
    const req = {
      reservationId,
      body: { staffIds: mechanicList },
    };

    updateReservationDetailMutate(req, {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.UPDATE_RESERVATION_STAFF_DONE);
      },
    });
  };

  const handleUpdate = useCallback(() => {
    updateMechanics();
    handleDialogClose();
  }, [updateMechanics]);

  useEffect(() => {
    if (!isDialogOpen) {
      setMechanicList(() => mechanics.map((item) => item.staffId));
    }
  }, [isDialogOpen]);

  useEffect(() => {
    const mechanicIds = mechanics.map((item) => item.staffId);
    setMechanicList(mechanicIds);
  }, [mechanics]);

  const isSubmitDisabled = mechanicList.length === 0;

  const isDisplayOption = (value: string) => {
    return Object.keys(mechanicInfo ?? {}).includes(value);
  };

  return (
    <>
      {isDialogOpen && (
        <S.DialogRoot className={className} open={isDialogOpen} ref={dialogRef}>
          <S.Content>
            <span>담당 정비사</span>
            <SelectAddList
              css={S.mechanicSelectAddList(isEmpty(mechanicInfo))}
              labelTable={{ ...mechanicInfo, ...selectedMechanics }}
              selectedItems={mechanicList}
              displayCase={isDisplayOption}
            >
              <SelectAddList.Select
                placeholder="선택"
                onSelect={handleAddMechanic}
              />
              <SelectAddList.ButtonWrapper
                css={S.button}
                handleDeleteAddList={handleDeleteMechanic}
              />
            </SelectAddList>
          </S.Content>
          <S.ButtonWrapper>
            <Button
              css={S.cancelButton}
              variant="secondarySmall"
              onClick={handleDialogClose}
            >
              취소
            </Button>
            <Button
              css={S.submitButton}
              onClick={handleUpdate}
              disabled={isSubmitDisabled}
            >
              완료
            </Button>
          </S.ButtonWrapper>
        </S.DialogRoot>
      )}
    </>
  );
};

export default MechanicDialog;
