import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { bgImg } from "assets";

export const PublicMain = styled.main`
  width: 100%;
  padding-bottom: 63px;
  background: center / cover no-repeat url(${bgImg});
  min-height: 100vh;
`;

export const PrivateMain = styled.main`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    width: 100%;
    min-width: max-content;
    height: max-content;
    min-height: 100vh;
    padding: ${theme.size.HEADER_HEIGHT} ${theme.size.LAYOUT_PADDING_SIDE}
      ${theme.size.FOOTER_HEIGHT};
    background-color: ${theme.color.background};
  `}
`;
