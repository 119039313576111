import React from "react";
import dayjs from "dayjs";

import { Button } from "components";
import * as S from "./ReservationForm.styled";

interface ReservationFormProps {
  children: React.ReactNode;
  title: string;
  requestDate?: string;
  handleSubmit?: (e: React.SyntheticEvent<HTMLFormElement>) => void;
}

const ReservationForm = ({
  children,
  title,
  requestDate,
  handleSubmit,
}: ReservationFormProps) => {
  return (
    <section>
      <S.Header>
        <h3>{title}</h3>
        {requestDate && (
          <time>{`예약 접수 일시 ${dayjs(requestDate).format(
            "YYYY.MM.DD. HH:mm",
          )}`}</time>
        )}
      </S.Header>

      <S.Form onSubmit={handleSubmit}>{children}</S.Form>
    </section>
  );
};

ReservationForm.LeftContent = function LeftContent({ children }: any) {
  return <S.LeftContainer>{children}</S.LeftContainer>;
};

ReservationForm.RightContent = function RightContent({ children }: any) {
  return <S.RightContainer>{children}</S.RightContainer>;
};

ReservationForm.Field = function ContentField({
  className,
  children,
  title,
  type = "content",
  hasBtn = false,
  handleSaveInfo,
}: any) {
  return (
    <S.FieldWrapper data-status={type} className={className}>
      {type === "default" ? (
        <h4 className="a11y">{title}</h4>
      ) : (
        <h4>{title}</h4>
      )}
      {children}
      {hasBtn && (
        <Button
          css={S.btn}
          type="button"
          variant="primaryMedium"
          onClick={handleSaveInfo}
        >
          저장
        </Button>
      )}
    </S.FieldWrapper>
  );
};

export default ReservationForm;
