import dayjs, { OpUnitType } from "dayjs";

export const getMeridiemStr = (hour: string) => {
  return +hour <= 12 ? "오전" : "오후";
};

export const getMeridiemHour = (hour: string) => {
  const h = +hour > 12 ? +hour % 12 : +hour;
  return getTwoDigitTime(h);
};

export const getTwoDigitTime = (time: number) =>
  time < 10 ? `0${time}` : time;

export const getTransformationTime = (...time: number[]) => {
  const [meridiem, hour, min] = time;

  const h = calcHour(meridiem, hour);
  const m = getTwoDigitTime(min);
  return `${h}:${m}`;
};

export const calcHour = (meridiem: any, hour: any) => {
  const h = +hour % 12;
  const calcedHour = meridiem ? (h === 0 ? 0 : h + 12) : +hour;
  return getTwoDigitTime(calcedHour);
};

export const getHourSepartorStr = (time: any) =>
  time ? +time.split(":")[0] : "";

export const getMinSepartorStr = (time: any) =>
  time ? time.split(":")[1] : "00";

export const getHourValue = (data: any) => (data === "" ? data : +data);

export const filterAvailableNightStartTime =
  (exceptHour: number) => (value: string) => {
    return !(+value < exceptHour);
  };

export const filterAvailableBreakStartTime =
  (exceptStartHour: number, exceptEndHour: number, isIncludeEndHour = true) =>
  (value: string) =>
    +value >= exceptStartHour &&
    (isIncludeEndHour ? +value <= exceptEndHour : +value < exceptEndHour);

export const filterAvailableBreakEndTime =
  (
    exceptStartHour: number,
    exceptEndHour: number,
    isIncludeStartHour: boolean,
    isIncludeEndHour: boolean,
  ) =>
  (value: string) =>
    (isIncludeStartHour
      ? +value >= exceptStartHour
      : +value > exceptStartHour) &&
    (isIncludeEndHour ? +value <= exceptEndHour : +value < exceptEndHour);

export const filterAvailableEndTime =
  (exceptHour: number, exceptMin: string) => (value: string) =>
    +value >= exceptHour && (+value !== exceptHour || exceptMin !== "30");

export const filterAvailableNightEndTime =
  (exceptHour: number, isIncludeStartHour: boolean) => (value: string) =>
    isIncludeStartHour ? +value >= exceptHour : +value > exceptHour;

export const isBetweenDateTime = (
  compareTime: string,
  startTime: string,
  endTime: string,
  format = "HH:mm",
  optionUnitType: OpUnitType = "minute",
  includeType: "[]" | "[)" | "(]" | "()" = "[]",
) =>
  dayjs(compareTime, format).isBetween(
    dayjs(startTime, format),
    dayjs(endTime, format),
    optionUnitType,
    includeType,
  );
