import React, { useEffect } from "react";

const useOnClickOutside = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  handler: (e?: any) => void,
  exceptEl?: HTMLElement | null,
) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const el = ref?.current;
      const isIncludeEl = !el || el.contains(event.target as HTMLElement);
      const isIncludeExceptEl =
        exceptEl && exceptEl?.contains(event.target as HTMLElement);

      if (isIncludeEl || isIncludeExceptEl) return;
      handler();
    };
    window.addEventListener("mousedown", listener);
    return () => {
      window.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
