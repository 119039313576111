import React, { useState, useCallback, useEffect, useRef } from "react";

import { Button } from "components";
import {
  numericOnlyWithComma,
  checkDrivingDistance,
  getCommaThousandUnitInputAfterChange,
  getCommaThousandUnitAfterDeleteMarker,
} from "utils";
import * as S from "./MileageDialog.styled";

const MILEAGE_ID = "mileage";

interface MileageDialogProps {
  className?: string;
  isDialogOpen: boolean;
  dialogRef: any;
  handleDialogClose: () => void;
  mileage: number;
  updateMileage: (mileage: any) => void;
}

const MileageDialog = ({
  className,
  isDialogOpen,
  dialogRef,
  handleDialogClose,
  mileage,
  updateMileage,
}: MileageDialogProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [mileageValue, setMileageValue] = useState<string>("");
  const [cursor, setCursor] = useState<number | null>(null);

  const handleChangeMileage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!checkDrivingDistance(e.target.value)) return;

    const mileageInputInfo = getCommaThousandUnitInputAfterChange(e, value);
    if (!mileageInputInfo) return;

    setCursor(mileageInputInfo.cursor);
    setMileageValue(mileageInputInfo.value);
  };

  const handleUpdate = useCallback(() => {
    updateMileage(mileageValue);
    handleDialogClose();
  }, [mileageValue]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const mileageInfo = getCommaThousandUnitAfterDeleteMarker(e);
    if (!mileageInfo) return;

    setMileageValue(mileageInfo.value);
    setCursor(mileageInfo.cursor);
  };

  useEffect(() => {
    setMileageValue(numericOnlyWithComma(mileage));
  }, [isDialogOpen]);

  useEffect(() => {
    const input = inputRef.current;

    input?.setSelectionRange(cursor, cursor);
  }, [inputRef, cursor, mileageValue]);

  const isSubmitDisabled = mileageValue === "";

  return (
    <>
      {isDialogOpen && (
        <S.DialogRoot className={className} open={isDialogOpen} ref={dialogRef}>
          <S.Content>
            <label htmlFor={MILEAGE_ID}>누적 주행거리</label>
            <S.InputWrapper>
              <input
                ref={inputRef}
                id={MILEAGE_ID}
                value={mileageValue}
                placeholder="주행거리를 입력하세요"
                onKeyDown={handleKeyDown}
                onChange={handleChangeMileage}
              />
              <span>Km</span>
            </S.InputWrapper>
          </S.Content>
          <S.ButtonWrapper>
            <Button
              css={S.cancelButton}
              variant="secondarySmall"
              onClick={handleDialogClose}
            >
              취소
            </Button>
            <Button
              css={S.submitButton}
              disabled={isSubmitDisabled}
              onClick={handleUpdate}
            >
              완료
            </Button>
          </S.ButtonWrapper>
        </S.DialogRoot>
      )}
    </>
  );
};

export default MileageDialog;
