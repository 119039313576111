import type {
  LoginQueryModel,
  RequestResetPasswordQueryModel,
  ResetPasswordQueryModel,
  ChangePasswordQueryModel,
  LoginServerModel,
  DeleteRefreshTokenQueryModel,
  DelayPwRetentionPeriodServerModel,
} from "types";
import { ax } from "../axios";

export const loginAPI = async (req: LoginQueryModel) => {
  const { data } = await ax.patch<LoginServerModel>("/auth/login", req.body);

  const res = {
    ...data,
    thumbnailKey: data.thumbnail,
  };

  return res;
};
export const logoutAPI = (refreshToken: string) => {
  return ax.patch("/auth/logout", {
    refreshToken,
  });
};

export const sendAuthLinkAPI = (req: RequestResetPasswordQueryModel) => {
  return ax.post("/auth/password/reset", req.body);
};

export const resetPasswordAPI = (req: ResetPasswordQueryModel) => {
  return ax.put("/auth/password/reset", req.body);
};

export const delayPwRetentionPeriodAPI = async () => {
  const { data } = await ax.patch<DelayPwRetentionPeriodServerModel>(
    "/auth/password/delay",
  );

  return data;
};

export const changePasswordAPI = (req: ChangePasswordQueryModel) => {
  return ax.patch("/auth/password", req.body);
};

export const deleteRefreshTokenAPI = (req: DeleteRefreshTokenQueryModel) => {
  return ax.delete("/auth/token", { params: req.body });
};
