import type {
  GetFaqsQueryModel,
  GetFaqDetailServerModel,
  GetFaqsServerModel,
} from "types";
import { ax } from "../axios";

export const getFaqsAPI = async (
  req: GetFaqsQueryModel,
): Promise<GetFaqsServerModel> => {
  const params = {
    page: req.query.page,
    category: req.query.category,
  };

  const { data } = await ax.get("/faqs", { params });

  return data;
};

export const getFaqDetailAPI = async (
  faqId: string,
): Promise<GetFaqDetailServerModel> => {
  const { data } = await ax.get(`/faqs/${faqId}`);

  return data;
};
