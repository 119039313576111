export const TOAST_MSG = Object.freeze({
  SUCCESS: {
    ADD_REVIEW_CMNT_DONE: "댓글 입력이 완료 되었습니다",
    DELETE_REVIEW_CMNT_DONE: "댓글이 삭제 되었습니다",
    UPDATE_REVIEW_CMNT_DONE: "댓글이 수정 되었습니다",
    ADD_FREQ_CMNT_DONE: "자주 쓰는 댓글이 등록되었습니다",
    DELETE_FREQ_CMNT_DONE: "자주 쓰는 댓글이 삭제되었습니다",
    UPDATE_FREQ_CMNT_DONE: "자주 쓰는 댓글이 수정되었습니다",
    REPORT_REVIEW_DONE: "리뷰가 신고되었습니다",
    ADD_RESERVATION_DONE: "예약이 추가되었습니다",
    REPAIR_DONE: "정비가 완료되었습니다",
    START_REPAIR: "정비가 시작되었습니다",
    RESERVATION_DONE: "예약이 완료되었습니다",
    UPDATE_RESERVATION_DONE: "예약 변경이 완료되었습니다",
    SELECT_CANCEL_RESERVATION:
      "예약 취소를 선택하셨습니다 \n 사유 입력 후 완료 버튼을 누르면 해당 예약은 취소됩니다",
    CANCEL_RESERVATION_DONE: "예약이 취소되었습니다",
    ADD_CUSTOMER_DONE: "고객이 추가되었습니다",
    UPDATE_CUSTOMER_DONE: "고객 정보가 수정되었습니다",
    DELETE_CUSTOMER_DONE: "고객 정보가 삭제되었습니다",
    SEND_AUTH_LINK_DONE: "전송이 완료되었습니다!",
    RESET_PASSWORD_DONE:
      "비밀번호 재설정이 완료되었습니다 \n 로그인 페이지로 돌아갑니다",
    CHANGE_PASSWORD_DONE:
      "비밀번호가 변경되었습니다 \n 보안을 위해 전 기기에서 로그아웃됩니다",
    SET_BREAKETIME_DONE: "예약 불가 일시 설정이 완료되었습니다",
    SEARCH_CUSTOMER_BY_REGNUM_DONE:
      "고객 정비 완료 기록이 확인되어 \n 고객 정보가 자동 입력됩니다",
    UPDATE_RESERVATION_STAFF_DONE: "담당 정비사 수정이 완료되었습니다",
    UPDATE_RESERVATION_COST_DONE: "담당 정비 금액 수정이 완료되었습니다",
    ADD_STAFF_DONE: "직원이 추가되었습니다",
    UPDATE_STAFF_DONE: "직원 정보가 수정완료 되었습니다.",
    DELETE_STAFF_DONE: "직원이 삭제되었습니다.",
    UPDATE_SHOP_INFO_DONE: "정비소 정보가 수정되었습니다",
    ADD_INQUIRY_DONE: "1:1 문의 등록이 완료되었습니다",
    SAVE_MEMO_DONE: "메모를 저장하였습니다.",
  },
  FAIL: {
    SEARCH_CUSTOMER_BY_REGNUM_ERROR:
      "고객 정비 완료된 기록이 없습니다 \n 고객정보를 입력해주세요:)",
    UPDATE_STAFF_FAIL: "정비이력이 있는 경우 \n직무 변경이 불가합니다",
    DELETE_STAFF_FAIL: "담당 정비 예약이 존재하는 직원은 삭제할 수 없습니다",
    REPORTED_REVIEW: "이미 신고된 리뷰입니다",
    INVALID_REPORT_REASON: "신고 사유 형식이 유효하지 않습니다.",
    NOT_CORRECT_DATE:
      "정비 일시가 올바르지 않습니다 \n 예약시간을 변경해주세요 :)",
    ADD_THUMBNAIL_IMG_ERROR: "대표 이미지를 추가해주세요 :)",
    ADD_INQUIRY_IMG_WARNING: "이미지는 최대 3개까지 추가할 수 있습니다 :)",
    UPDATE_OPERATION_TIME: "운영 시작 및 종료 시간을 모두 입력해주세요",
    SETUP_ETC_OPERATION_TIME: "운영시간을 설정 후 다시 시도해주세요!",
    RE_SETUP_ETC_OPERATION_TIME: "운영시간을 재설정 후 다시 시도해주세요!",
    NONE_CHECK_CAR_INFO: "차량 조회를 완료해주세요!",
    UPLOAD_FILE_SIZE: "10MB 이하의 이미지만 첨부할 수 있습니다",
  },
});
