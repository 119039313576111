import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.footer`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 35px;
    padding-right: 50px;
    color: ${theme.color.white};
  `}
`;
