import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.section`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Container = styled.article`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 594px;
    width: 594px;
    height: 486px;
    margin-right: 126px;
    border-radius: 3px;
    background-color: ${theme.color.bg_secondray_alpha};
    transform: translateY(10%);
  `}
`;

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 0 117px;
`;

export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: 28px;

    & h3 {
      ${theme.font.bold_24};
      margin-bottom: 2px;
      color: ${theme.color.white};
    }

    & span {
      ${theme.font.medium_14};
      color: ${theme.color.blue_01};
    }
  `}
`;
