import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 35px;
  height: 20px;
`;

export const Button = styled.button`
  ${({ disabled, theme }) => css`
    ${theme.font.medium_14};
    display: flex;
    align-items: center;
    height: 100%;
    color: ${theme.color.gray_04};
    opacity: ${disabled && "40%"};

    svg {
      width: 20px;
      height: 20px;
      fill: ${theme.color.gray_04};
    }
  `}
`;

export const PageNumWrapper = styled.ul`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const PageNum = styled.li<{ currentPage: boolean }>`
  ${({ currentPage, theme }) => css`
    & > button {
      ${theme.font.bold_14};
      width: 20px;
      height: 20px;
      border-radius: 30px;
      color: ${currentPage ? theme.color.white : theme.color.gray_05};
      background-color: ${currentPage && theme.color.blue_01};

      &:hover {
        background-color: ${!currentPage && theme.color.blue_02};
      }
    }
  `}
`;

export const PaginationWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 62px;
    border: 1px solid ${theme.color.gray_02};
    border-top: 0;
    border-radius: 0 0 3px 3px;
    background-color: ${theme.color.white};
  `}
`;
