import React from "react";

import { Pagination } from "components";
import type { GetCustomerReservationClientModel } from "types";
import CustomersReservationTable from "../reservationTable/CustomersReservationTable";
import * as S from "./CustomersReservationInfo.styled";

interface CustomersReservationInfoProps {
  reservationData?: GetCustomerReservationClientModel;
  isDone: boolean;
}

const CustomersReservationInfo = ({
  isDone,
  reservationData,
}: CustomersReservationInfoProps) => {
  return (
    <S.RootStyle>
      <S.ReservationInfoTitle>
        상세 내역 {reservationData?.pageInfo?.totalData}
      </S.ReservationInfoTitle>
      <CustomersReservationTable
        reservationDataInfo={reservationData?.reservations}
        isDone={isDone}
      />
      {(reservationData?.reservations?.length ?? 0) > 0 && (
        <Pagination pageInfo={reservationData?.pageInfo} />
      )}
    </S.RootStyle>
  );
};

export default CustomersReservationInfo;
