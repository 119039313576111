import React, { useCallback } from "react";

// NOTE: index.js에서 불러오면 default error가 발생합니다.
import { Input, DatePicker, CalendarButton } from "components";
import EstimatedTime from "../estimatedtime/EstimatedTime";
import * as S from "./DateSelectInput.styled";

interface DateSelectInputProps {
  className?: string;
  children?: React.ReactNode;
  forwardRef?: any;
  isDisabledBeforeToday: boolean;
  isReverse?: boolean;
  placeholder?: string;
  id?: string;
  name?: string;
  required?: boolean;
  value: string;
  validError?: any;
  restProps?: any;
  onBlur?: any;
  handleSelectDate: any;
}

const DateSelectInput = ({
  className,
  children,
  handleSelectDate,
  isDisabledBeforeToday,
  isReverse = false,
  required,
  forwardRef,
  value,
  validError,
  ...restProps
}: DateSelectInputProps) => {
  const onSelectCallback = useCallback((date: any) => {
    handleSelectDate(date);
  }, []);

  return (
    <S.Root className={className}>
      <S.DateWrapper>
        <Input
          css={S.dateInput}
          readOnly
          data-required={required ? "true" : ""}
          ref={forwardRef}
          value={value}
          validError={validError}
          {...restProps}
        />
        <DatePicker
          css={S.calendar(isReverse)}
          cb={onSelectCallback}
          isDisabledBeforeToday={isDisabledBeforeToday}
          isReverse={isReverse}
          initValue={value}
          trigger={({
            handleToggleDialogOpen,
            isDialogOpen,
          }: {
            handleToggleDialogOpen: any;
            isDialogOpen: boolean;
          }) => (
            <CalendarButton
              css={S.calendarBtn}
              aria-haspopup="true"
              aria-pressed={isDialogOpen}
              isDialogOpen={isDialogOpen}
              onClick={handleToggleDialogOpen}
            />
          )}
        />
      </S.DateWrapper>
      {children}
    </S.Root>
  );
};

DateSelectInput.Time = EstimatedTime;

export default DateSelectInput;
