import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.form`
  position: relative;
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 48px;
`;

export const loginInput = css`
  margin-bottom: 8px;
`;

export const validMessage = css`
  bottom: -22px;
`;

export const loginButton = css`
  margin-bottom: 12px;
`;

export const SubContentWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    & span {
      color: ${theme.color.gray_04};
    }
  `}
`;
