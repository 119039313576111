import type {
  GetNoticesQueryModel,
  GetNoticesServerModel,
  GetNoticeDetailServerModel,
} from "types";
import { ax } from "../axios";

export const getNoticesAPI = async (
  req: GetNoticesQueryModel,
): Promise<GetNoticesServerModel> => {
  const params = { page: req.query.page };

  const { data } = await ax.get("/notices", { params });

  return data;
};

export const getNoticeDetailAPI = async (
  id: string,
): Promise<GetNoticeDetailServerModel> => {
  const { data } = await ax.get(`/notices/${id}`);

  return data;
};
