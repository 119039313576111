import React from "react";

import * as S from "./ExternalLink.styled";

interface ExternalLinkProps {
  className?: string;
  variant: "secondarySmall" | "ghostPrimary" | "ghostSecondary";
  href: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
}

const ExternalLink = ({
  className,
  variant,
  href,
  icon,
  children,
}: ExternalLinkProps) => {
  return (
    <S.ExternalLink
      className={className}
      variant={variant}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {icon && icon}
      {children}
    </S.ExternalLink>
  );
};

export default ExternalLink;
