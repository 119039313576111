import React from "react";

import { ReservationForm, Button } from "components";
import { ReservationInfo, PlannedForm } from "components/reservation";
import { usePlanned } from "hooks";
import { PLANNED_BUTTON_TABLE } from "assets";
import * as S from "./Planned.styled";

interface PlannedProps {
  reservationData: any;
}

const planned = ({ reservationData }: PlannedProps) => {
  const {
    inputRefs,
    form,
    isSubmitDisabled,
    handleSubmit,
    handleChangeForm,
    handleBlurForm,
    handleSelectDate,
    handleSaveMemo,
  } = usePlanned(reservationData);
  return (
    <ReservationForm
      title="예약 상세"
      requestDate={reservationData.requested}
      handleSubmit={handleSubmit}
    >
      <ReservationForm.LeftContent>
        <ReservationInfo
          inputRefs={inputRefs}
          data={form}
          reservationData={reservationData}
          accepted={reservationData.accepted}
          status={reservationData.status}
          isSelf={false}
          handleChangeData={handleChangeForm}
          handleBlurData={handleBlurForm}
          handleSaveMemo={handleSaveMemo}
        />
      </ReservationForm.LeftContent>
      <ReservationForm.RightContent>
        <ReservationForm.Field title="예약 승인">
          <PlannedForm
            inputRefs={inputRefs}
            data={form}
            reservationData={reservationData}
            handleChangeData={handleChangeForm}
            handleBlurData={handleBlurForm}
            handleSelectDate={handleSelectDate}
          />
          <Button
            css={S.submitButton}
            type="submit"
            disabled={isSubmitDisabled}
          >
            {PLANNED_BUTTON_TABLE[form.approvedMaintenance.value]}
          </Button>
        </ReservationForm.Field>
      </ReservationForm.RightContent>
    </ReservationForm>
  );
};

export default planned;
