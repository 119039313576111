import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const DialogRoot = styled.dialog`
  ${({ theme }) => css`
    position: absolute;
    top: -170px;
    left: -250px;
    width: 100%;
    border: 1px solid ${theme.color.gray_02};
    background-color: ${theme.color.white};
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
    z-index: 10;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    padding: 24px 24px 12px;

    & > span {
      display: inline-block;
      margin-bottom: 6px;
      color: ${theme.color.gray_07};
    }
  `}
`;

export const mechanicSelectAddList = (isNoneData: boolean) => css`
  flex-direction: column;
  row-gap: 4px;

  div > ul {
    border: ${isNoneData && 0};
  }
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    border-top: 1px solid ${theme.color.gray_03};
    padding: 12px 0;
  `}
`;

export const button = css`
  & > li > button > span {
    max-width: 146px;
  }
`;

export const buttonMixin = css`
  width: 54px;
`;

export const cancelButton = css`
  ${buttonMixin};
  margin-right: 4px;
`;

export const submitButton = css`
  ${buttonMixin};
`;
