import React from "react";

import { ReservationForm, Button } from "components";
import { ReservationInfo, AcceptedForm } from "components/reservation";
import { useAccepted } from "hooks";
import { ACCEPTED_BUTTON_TABLE } from "assets";
import { IS_SELF_TABLE } from "constants/index";
import * as S from "./Accepted.styled";

interface AcceptedProps {
  reservationData: any;
}

type IsSelfTalbeType = "y" | "n";

const Accepted = ({ reservationData }: AcceptedProps) => {
  const {
    inputRefs,
    form,
    isSubmitDisabled,
    handleSubmit,
    handleChangeForm,
    handleBlurForm,
    handleSelectDate,
    handleSaveMemo,
    handleKeyDown,
    updateMileage,
    regNumAction,
  } = useAccepted(reservationData);

  return (
    <ReservationForm
      title="예약 상세"
      requestDate={reservationData.requested}
      handleSubmit={handleSubmit}
    >
      <ReservationForm.LeftContent>
        <ReservationInfo
          inputRefs={inputRefs}
          data={form}
          reservationData={reservationData}
          accepted={reservationData.accepted}
          status={reservationData.status}
          isSelf={IS_SELF_TABLE[reservationData.isSelf as IsSelfTalbeType]}
          isChangeable={form.approvedMaintenance.value === "approved"}
          handleChangeData={handleChangeForm}
          handleBlurData={handleBlurForm}
          handleSaveMemo={handleSaveMemo}
          handleKeyDown={handleKeyDown}
          updateMileage={updateMileage}
          regNumAction={regNumAction}
        />
      </ReservationForm.LeftContent>
      <ReservationForm.RightContent>
        <ReservationForm.Field title="정비 승인">
          <AcceptedForm
            inputRefs={inputRefs}
            data={form}
            reservationData={reservationData}
            handleChangeData={handleChangeForm}
            handleBlurData={handleBlurForm}
            handleSelectDate={handleSelectDate}
          />
          <Button
            css={S.submitButton}
            type="submit"
            disabled={isSubmitDisabled}
          >
            {ACCEPTED_BUTTON_TABLE[form.approvedMaintenance.value]}
          </Button>
        </ReservationForm.Field>
      </ReservationForm.RightContent>
    </ReservationForm>
  );
};

export default Accepted;
