import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { mixin_scrollbar } from "style/mixin";

export const Root = styled.div`
  position: relative;
  width: 270px;
  height: max-content;

  & > input {
    width: 100%;
  }

  & > input[aria-expanded="true"] + ul {
    display: block;
  }
`;

export const DropdownWrapper = styled.ul`
  ${({ theme }) => css`
    ${mixin_scrollbar(theme)}
    position: absolute;
    display: none;
    width: 100%;
    height: 270px;
    margin-top: 4px;
    border: 1px solid ${theme.color.gray_02};
    border-radius: 4px;
    background-color: ${theme.color.white};
    overflow-y: overlay;
    z-index: 10;

    @supports not (overflow: overlay) {
      overflow: auto;
    }

    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: ${theme.color.gray_02};
    }
    ::-webkit-scrollbar-track {
      margin: 6px;
    }
  `}
`;

export const DropdownItem = styled.li`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.color.gray_02};
  `}
`;

export const DropdownItemBtn = styled.button`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    width: 100%;
    padding: 12px;

    &[data-focus="true"] {
      background-color: ${theme.color.background};
    }

    :hover {
      background-color: ${theme.color.background};
    }
  `}
`;

export const RegNumInfo = styled.div`
  ${({ theme }) => css`
    & > span {
      ${theme.font.regular_14};
      color: ${theme.color.gray_09};
    }
  `}
`;

export const CustomerInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    column-gap: 5px;
    width: 100%;

    & > span {
      ${theme.font.regular_12};
      color: ${theme.color.gray_06};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
    }

    & > span:nth-of-type(2) {
      min-width: 100px;
    }
  `}
`;

export const noData = (theme: Theme) => css`
  width: 100%;
  padding: 0 24px;

  & > span {
    ${theme.font.medium_14};
    margin-bottom: 13px;
    color: ${theme.color.gray_05};
    text-align: center;
    white-space: pre;
  }

  & > pre {
    ${theme.font.regular_13};
    color: ${theme.color.gray_05};
    text-align: center;
  }
`;
