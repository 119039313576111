import React from "react";

import {
  EstimatedTime,
  EstimatedTimeDialog,
  SelectAddList,
  Input,
  Textarea,
  DialogButton,
} from "components";
import { useGetMechanics } from "services";
import { timeFormatter, formatRepairingDateTime } from "utils";
import { REPAIRING_FORM } from "assets";
import { INPUT_MAX_LENGTH } from "constants/index";
import * as S from "./RepairingForm.styled";

interface RepairingFormProps {
  inputRefs: any;
  reservationData: any;
  data: any;
  handleChangeData: any;
  handleBlurData: any;
  handleDeleteStaff: any;
  handleKeyDown?: any;
}

const RepairingForm = ({
  inputRefs,
  reservationData,
  data,
  handleChangeData,
  handleBlurData,
  handleDeleteStaff,
  handleKeyDown,
}: RepairingFormProps) => {
  const { data: mechanics } = useGetMechanics();

  const estimatedTime = `${data.estimatedTimeHour.value}: ${data.estimatedTimeMin.value}`;

  return (
    <S.Root>
      <S.InfoItem>
        <S.Label as="span">정비 일시</S.Label>
        <time>{formatRepairingDateTime(reservationData.accepted)}</time>
      </S.InfoItem>
      <S.InfoItem>
        <S.Label as="span">예상 정비시간</S.Label>
        <S.TimeWrapper>
          <span>{timeFormatter(estimatedTime, "h시간 mm분 이내")}</span>
          <S.ButtonWrapper>
            <DialogButton
              css={S.updateDialogButton}
              popup={(
                isDialogOpen: any,
                dialogRef: any,
                handleDialogClose: any,
              ) => {
                return (
                  <EstimatedTimeDialog
                    isDialogOpen={isDialogOpen}
                    dialogRef={dialogRef}
                    initHour={data.estimatedTimeHour.value}
                    initMnute={data.estimatedTimeMin.value}
                    reservationId={reservationData.reservationId}
                    handleDialogClose={handleDialogClose}
                  />
                );
              }}
            >
              수정
            </DialogButton>
          </S.ButtonWrapper>
        </S.TimeWrapper>
      </S.InfoItem>
      {REPAIRING_FORM.map((item) => {
        const error = data[item.key].error;
        const minError = data[item.minKey as string]?.error;
        const tip = data[item.key].tip;

        return (
          <S.InfoItem key={item.key}>
            <S.Label
              htmlFor={`repairing-modal-${item.key}`}
              isRequired={item.isRequired as boolean}
            >
              {item.label}
            </S.Label>
            <S.InputWrapper>
              {item.type === "selectArray" ? (
                <SelectAddList
                  css={S.staffAddList}
                  labelTable={mechanics}
                  selectedItems={data[item.key].value}
                >
                  <SelectAddList.Select
                    css={S.select}
                    id={item.key}
                    forwardRef={(el: any) => (inputRefs.current[item.key] = el)}
                    name={item.key}
                    required={item.isRequired}
                    validError={data[item.key].error}
                    placeholder={item.placeholder}
                    onSelect={handleChangeData}
                    onBlur={handleBlurData}
                  />
                  {data[item.key].value.length > 0 && (
                    <SelectAddList.ButtonWrapper
                      css={S.staffItems}
                      handleDeleteAddList={handleDeleteStaff}
                    />
                  )}
                </SelectAddList>
              ) : item.type === "time" ? (
                <EstimatedTime
                  id={`acceptForm-${item.key}`}
                  onChange={handleChangeData}
                  onBlur={handleBlurData}
                >
                  <EstimatedTime.Hour
                    css={S.hourInput}
                    forwardRef={(el: any) => (inputRefs.current[item.key] = el)}
                    name={item.key}
                    value={data[item.key].value}
                    validError={
                      data[item.key].error || data[item.minKey as string].error
                    }
                    label="시간"
                    required={item.isRequired}
                  />
                  <EstimatedTime.SelectMin
                    css={S.minSelect}
                    forwardRef={(el: any) => (inputRefs.current[item.key] = el)}
                    name={item.minKey}
                    value={data[item.minKey as string].value}
                    validError={
                      data[item.minKey as string].error || data[item.key].error
                    }
                    label="분"
                    zeroDisable={
                      item.minKey === "actualTimeMin" &&
                      +data.actualTimeHour.value === 0
                    }
                  />
                </EstimatedTime>
              ) : item.type === "text" ? (
                <S.SuffixWrapper>
                  <Input
                    css={S.input}
                    id={`accepted-modal-${item.key}`}
                    ref={(el) => (inputRefs.current[item.key] = el)}
                    name={item.key}
                    value={data[item.key].value}
                    validError={data[item.key].error}
                    data-required={item.isRequired ? "true" : ""}
                    placeholder={item.placeholder}
                    suffixText={item?.suffixText}
                    onChange={handleChangeData}
                    onBlur={handleBlurData}
                    onKeyDown={handleKeyDown}
                  />
                  {item.suffixText && (
                    <S.SuffixText>{item.suffixText}</S.SuffixText>
                  )}
                </S.SuffixWrapper>
              ) : (
                <Textarea
                  css={S.textarea}
                  id={`accepted-modal-${item.key}`}
                  name={item.key}
                  value={data[item.key].value}
                  placeholder={item.placeholder}
                  maxLength={INPUT_MAX_LENGTH.SHOP_COMMENT}
                  onChange={handleChangeData}
                />
              )}
              {item.type === "time" ? (
                (error || minError) && (
                  <S.ValidText>{error || minError}</S.ValidText>
                )
              ) : (error || tip) && error ? (
                <S.ValidText>{error}</S.ValidText>
              ) : tip ? (
                <S.TipText>{tip}</S.TipText>
              ) : null}
            </S.InputWrapper>
          </S.InfoItem>
        );
      })}
    </S.Root>
  );
};

export default RepairingForm;
