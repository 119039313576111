import React from "react";

import { VisuallyHidden, Button, PasswordInput } from "components";
import { useChangePasswordForm } from "hooks";
import * as S from "./ChangePasswordForm.styled";

const OLD_PW_INPUT_ID = "setting-old-pw-input";
const NEW_PW_INPUT_ID = "setting-new-pw-input";
const NEW_PW_C_INPUT_ID = "setting-new-pw-confirm-input";
const VALID_ERROR_ID_OLD_PW = "error-msg-setting-old-password";
const VALID_ERROR_ID_NEW_PW = "error-msg-setting-new-password";

const ChangePasswordForm = () => {
  const {
    inputRefs,
    form,
    isSubmitDisabled,
    handleSubmit,
    handleChangeForm,
    handleBlurForm,
  } = useChangePasswordForm();

  return (
    <S.Wrapper onSubmit={handleSubmit}>
      <S.OriginPasswordWrapper>
        <S.Label htmlFor={OLD_PW_INPUT_ID}>기존 비밀번호 입력</S.Label>
        <PasswordInput
          css={S.passwordInput}
          id={OLD_PW_INPUT_ID}
          ref={(el) => (inputRefs.current["originPassword"] = el)}
          name="originPassword"
          value={form.originPassword.value}
          aria-invalid="true"
          aria-errormessage={VALID_ERROR_ID_OLD_PW}
          validError={form.originPassword.error}
          placeholder="비밀번호를 입력하세요"
          data-required="true"
          onChange={handleChangeForm}
          onBlur={handleBlurForm}
        />
        {form.originPassword.error && (
          <S.ValidMessage id={VALID_ERROR_ID_OLD_PW}>
            {form.originPassword.error}
          </S.ValidMessage>
        )}
      </S.OriginPasswordWrapper>
      <S.NewPasswordWrapper>
        <S.Label htmlFor={NEW_PW_INPUT_ID}>새 비밀번호 입력</S.Label>
        <S.GuideMessage>
          안정성을 위해 영문(대/소문자), 숫자, 특수문자(!*#& 등)를 활용한
          8-16자의 비밀번호를 입력해주세요.
          <br />
          보안을 위해 비밀번호 변경 시 전 기기에서 로그아웃됩니다.
        </S.GuideMessage>
        <PasswordInput
          css={S.passwordInput}
          id={NEW_PW_INPUT_ID}
          ref={(el) => (inputRefs.current["newPassword"] = el)}
          name="newPassword"
          value={form.newPassword.value}
          aria-invalid="true"
          aria-errormessage={VALID_ERROR_ID_NEW_PW}
          validError={form.newPassword.error}
          placeholder="새 비밀번호를 입력하세요"
          data-required="true"
          onChange={handleChangeForm}
          onBlur={handleBlurForm}
        />
        <VisuallyHidden as="label" htmlFor={NEW_PW_C_INPUT_ID}>
          새 비밀번호 입력 확인
        </VisuallyHidden>
        <PasswordInput
          css={S.passwordInput}
          id={NEW_PW_C_INPUT_ID}
          ref={(el) => (inputRefs.current["newPasswordConfirm"] = el)}
          name="newPasswordConfirm"
          value={form.newPasswordConfirm.value}
          aria-invalid="true"
          aria-errormessage={VALID_ERROR_ID_NEW_PW}
          validError={form.newPasswordConfirm.error}
          placeholder="확인을 위해 새 비밀번호를 한번 더 입력하세요"
          data-required="true"
          onChange={handleChangeForm}
          onBlur={handleBlurForm}
        />
        {(form.newPassword.error || form.newPasswordConfirm.error) && (
          <S.ValidMessage id={VALID_ERROR_ID_NEW_PW}>
            {form.newPassword.error || form.newPasswordConfirm.error}
          </S.ValidMessage>
        )}
      </S.NewPasswordWrapper>
      <div>
        <Button
          css={S.submitButton}
          type="submit"
          disabled={!!isSubmitDisabled}
        >
          완료
        </Button>
      </div>
    </S.Wrapper>
  );
};

export default ChangePasswordForm;
