import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Wrapper = styled.form`
  padding: 20px 0;
`;

export const OriginPasswordWrapper = styled.div`
  position: relative;
  margin-bottom: 28px;

  & > label {
    margin-bottom: 6px;
  }
`;

export const Label = styled.label`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: block;
    color: ${theme.color.gray_07};

    &::after {
      content: "*";
      margin-left: 2px;
      color: ${theme.color.error_01};
    }
  `}
`;

export const passwordInput = css`
  max-width: 462px;

  &:last-of-type {
    margin-top: 6px;
  }
`;

export const NewPasswordWrapper = styled.div`
  position: relative;
  margin-bottom: 28px;

  & > label {
    margin-bottom: 9px;
  }
`;

export const GuideMessage = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_12};
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    margin-bottom: 18px;
    color: ${theme.color.gray_06};
  `}
`;

export const ValidMessage = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_12};
    margin-top: 4px;
    color: ${theme.color.error_01};
  `}
`;

export const submitButton = css`
  min-width: 100px;
`;
