import styled from "@emotion/styled";
import { css } from "@emotion/react";

const Badge = styled.label`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 26px;
  border-radius: 4px;
`;

export const YesBadge = styled(Badge)`
  ${({ theme }) => css`
    color: ${theme.color.blue_01};
    background-color: ${theme.color.blue_02};
  `}
`;

export const NoBadge = styled(Badge)`
  ${({ theme }) => css`
    color: ${theme.color.gray_05};
    background-color: ${theme.color.gray_02};
  `}
`;

export const RootStyle = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;
