import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { mixin_scrollbar } from "style/mixin";

export const ReviewContainer = styled.div`
  ${({ theme }) => css`
    ${mixin_scrollbar(theme)};
    position: relative;
    width: 100%;
    height: calc(100vh - 54px - 38px - 89px - 32px - 68px);
    border: 1px solid ${theme.color.gray_03};
    border-radius: 3px;
    background-color: ${theme.color.white};
  `}
`;
