import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.li<{ hasLastElBorder: boolean }>`
  ${({ hasLastElBorder, theme }) => css`
    width: 100%;
    border-bottom: 1px solid
      ${hasLastElBorder ? theme.color.gray_03 : "transparent"};
    padding: 23px 0 12px;
    background-color: ${theme.color.white};

    &:not(:first-of-type) {
      border-top: 0;
    }
  `}
`;

export const Container = styled.div`
  padding: 0 28px;
`;

export const CommentInfoWrapper = styled.div`
  margin-bottom: 16px;
`;

export const starRating = css`
  margin-right: 6px;
`;

export const CommentInfoTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const CommentInfoTopLeft = styled.div<{ isWithdrawal: boolean }>`
  ${({ isWithdrawal, theme }) => css`
    display: flex;
    align-items: center;

    & > span {
      ${theme.font.medium_14};
      margin-right: 3px;
      letter-spacing: -0.02em;
      color: ${isWithdrawal ? theme.color.gray_05 : theme.color.gray_09};
    }
  `}
`;

export const CommentInfoTopRight = styled.div<{ isReported: any }>`
  ${({ isReported, theme }) => css`
    display: flex;
    align-items: center;

    & > time,
    & > button {
      ${theme.font.regular_14};
      color: ${theme.color.gray_05};
    }

    & > button {
      color: ${theme.color.gray_06};
    }

    & > time {
      position: relative;
      display: flex;
      align-items: center;
      margin-right: ${isReported ? 0 : "17px"};

      &::after {
        content: "";
        position: absolute;
        right: -9px;
        display: ${isReported && "none"};
        width: 1px;
        height: 15px;
        background-color: ${theme.color.gray_05};
        transform: translateY(5%);
      }
    }
  `}
`;

export const CommentMetaDataWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const mixinMetaData = (theme: Theme) => css`
  display: flex;

  & > dt,
  & > dd {
    ${theme.font.regular_14};
    letter-spacing: -0.01em;
    color: ${theme.color.gray_05};
  }

  & > dt {
    margin-right: 4px;
  }
`;

export const MaintenanceNumber = styled.dl`
  ${({ theme }) => css`
    ${mixinMetaData(theme)}
    position: relative;
    align-items: center;
    margin-right: 13px;
    border-bottom: 1px solid ${theme.color.gray_05};
    cursor: pointer;

    & > dt,
    & > dd {
      color: ${theme.color.gray_06};
    }

    :hover {
      border-bottom: 1px solid ${theme.color.gray_08};

      dt,
      dd {
        color: ${theme.color.gray_08};
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 1px;
      right: -8px;
      width: 1px;
      height: 85%;
      background-color: ${theme.color.gray_05};
    }
  `}
`;

export const DateTimeVisit = styled.dl`
  ${({ theme }) => css`
    ${mixinMetaData(theme)}
  `}
`;

export const CommentInfoDesc = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.gray_09};
`;

export const ReplyWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    border-top: 1px solid ${theme.color.gray_02};

    & > p {
      margin-top: 10px;
      font-size: 14px;
      letter-spacing: -0.01em;
      line-height: 1.5;
      color: ${theme.color.gray_06};
    }
  `}
`;

export const replyButton = (theme: Theme) => css`
  ${theme.font.regular_14};
  margin-top: 8px;
  padding: 0 12px;
`;
