import React from "react";

import * as S from "./ValidMessge.styled";

interface ValidMessageProps {
  className?: string;
  children: React.ReactNode;
  id?: string;
  restProps?: any;
}

const ValidMessage = ({
  id,
  className,
  children,
  ...restProps
}: ValidMessageProps) => {
  return (
    <S.Root id={id} className={className} aria-live="assertive" {...restProps}>
      {children}
    </S.Root>
  );
};

export default ValidMessage;
