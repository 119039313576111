import dayjs from "dayjs";

import { RESERVATION_STATUS } from "constants/index";
import type { MonthlyReservation } from "types";
import { deleteFirstZero } from "./formatter";
import { deleteObjectKey } from "./object";

export const isExceededPwRtnPeriod = (date: any, retentionPeriod: any) => {
  const now = dayjs();
  return now.diff(date, "day") >= retentionPeriod;
};

export const isSameDate = (date: any, otherDate: any, flag = "day") => {
  const formattedDate = dayjs(date).format("YYYY-MM-DD");

  return dayjs(formattedDate).isSame(otherDate, flag as any);
};

export const timeFormatter = (time: string, format: any) => {
  if (!time) return;

  const hour = +deleteFirstZero(time.split(":")[0]);
  const min = +time.split(":")[1];
  const newFormat =
    hour === 0
      ? format.toLowerCase().replace(/h\S+/g, "")
      : min === 0
      ? format.toLowerCase().replace(/mm\S+/g, "")
      : format;

  return newFormat.replaceAll("h", hour).replaceAll("mm", min);
};

export const getMonthDates = (date: any) => {
  return Number(dayjs(date).endOf("month").format("DD"));
};

export const getTimeOnTableHoursHidden = (date: any) => {
  return dayjs(date).format("YYYY.MM.DD").toString();
};

export const getTimeOnTableIncludeHours = (date: any) => {
  return dayjs(date).format("YYYY.MM.DD. HH:mm").toString();
};

export const getMonthlyDate = (
  reservations: MonthlyReservation[],
  dates: number,
) => {
  const reservationTable = reservations.reduce((acc, cur) => {
    const date = +cur.date.slice(-2);
    const dateReservationStatusData = deleteObjectKey(cur, "date");
    const sortedDateReservationStatus: [string, number][] = [];

    RESERVATION_STATUS.forEach((status) => {
      const count = +dateReservationStatusData?.[status];
      if (count > 0) {
        sortedDateReservationStatus.push([status, count]);
      }
    });

    return { ...acc, [date]: sortedDateReservationStatus };
  }, {} as { [key: number]: [string, number][] });

  return [...Array(dates)].reduce((acc, _, idx) => {
    const date = idx + 1;
    const result = reservationTable[date] ?? [];

    return [...acc, result];
  }, []);
};
