import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

const useDateSelector = (selectedDate: dayjs.Dayjs) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const setDate = (date: any) => {
    setSearchParams({
      ...Object.fromEntries(
        [...searchParams].filter((item) => item[0] !== "page"),
      ),
      date,
    });
  };

  const handlePreviousDate = useCallback(() => {
    const prevDate = dayjs(selectedDate)
      .subtract(1, "day")
      .format("YYYY-MM-DD");
    setDate(prevDate);
  }, [selectedDate]);
  //NOTE : callback dependency array 확인 필요

  const handleNextDate = useCallback(() => {
    const nextDate = dayjs(selectedDate).add(1, "day").format("YYYY-MM-DD");
    setDate(nextDate);
  }, [selectedDate]);

  const getIsToday = useCallback(
    () => dayjs(selectedDate).isSame(dayjs(), "day"),
    [selectedDate],
  );

  const handleChangeTodayDate = () => {
    const isToday = getIsToday();

    if (isToday) return;

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      date: dayjs().format("YYYY-MM-DD"),
    });
  };

  const selectDateCallback = useCallback((date: any) => {
    setDate(date);
  }, []);

  return {
    handlePreviousDate,
    getIsToday,
    handleChangeTodayDate,
    handleNextDate,
    selectDateCallback,
  };
};

export default useDateSelector;
