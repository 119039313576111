import { useState, useCallback } from "react";

const useSelect = (initValue = "") => {
  const [value, setValue] = useState(initValue);

  const handleChangeValue = useCallback(
    () => (v: string) => {
      setValue(v);
    },
    [],
  );

  return [value, handleChangeValue, setValue] as const;
};

export default useSelect;
