export const CUSTOMER_CENTER_NUMBER = "02-566-0309";

export const HOMEPAGE_URL = "https://coconutsilo.com/";

export const PERSONAL_INFORMATION_POLICY_URL =
  "https://truckdoctor.co.kr/repairshop/privacy";

export const TERMS_OF_USE_URL = "https://truckdoctor.co.kr/repairshop/terms";

export const WEEKS = ["일", "월", "화", "수", "목", "금", "토"]; // 일주일 배열

export const FAQ_NONE_DATA_TEXT =
  "아직 등록된 질문이 없어요!\n1:1문의에 질문을 남겨주시면 빠르게 답변드릴게요:)";

export const PATH = {
  root: "/",
  login: "/login",
  password: "/password",
  expired: "/expired",
  notFound: "/notFound",
  schedule: "/schedule",
  addReservation: "/addReservation",
  reservation: "/reservation",
  reservationDetail: "/reservation/detail",
  reservationSchedule: "/reservation/schedule",
  customer: "/customer",
  customerDetail: "/customer/detail",
  review: "/review",
  configuration: "/configuration",
  repairShop: "/configuration/repairshop",
  staff: "/configuration/staff",
  changePassword: "/configuration/changePassword",
  inquiry: "/configuration/inquiry",
  inquiryCreate: "/configuration/inquiry/create",
  inquiryDetail: "/configuration/inquiry/detail",
  cs: "/cs",
  notice: "/cs/notice",
  event: "/cs/event",
  faq: "/cs/faq",
  csInquiry: "/cs/inquiry",
};

export const LAYOUT_TYPE = Object.freeze({
  PRIVATE: "PRIVATE",
  PUBLIC: "PUBLIC",
});

export const VALID_MESSAGE = Object.freeze({
  COMPLETED: "차량조회 완료.",
  REQUIRED: "필수 입력 정보입니다.",
  REQUIRED_NO_ASTERISK: "필수 입력 정보입니다.",
  EMAIL: "올바른 형식의 이메일 주소를 입력하세요.",
  LOGIN_PW: "*입력하신 정보를 다시 확인해주세요.",
  PW: "*영문,숫자,특수문자를 활용한 8-16자의 비밀번호를 입력하세요.",
  PW_C: "비밀번호가 일치하지 않습니다.",
  FAIL: "*입력하신 정보를 다시 확인해 주세요.",
  FAIL_NO_ASTERISK: "입력하신 정보를 다시 확인해주세요.",
  CHANGE_PW: "*기존과 동일한 비밀번호입니다. 다른 비밀번호를 입력하세요.",
  SEND_AUTH_LINK:
    "가입이력이 없는 이메일입니다. 입력하신 정보를 다시 확인해주세요.",
  DATA: "*입력하신 정보를 다시 확인해 주세요.",
  DATA_NO_ASTERISK: "입력하신 정보를 다시 확인해 주세요.",
  REGNUM: "올바른 형식으로 입력해주세요",
  REGNUM_EXAM: `형식에 맞게 입력해주세요.\n(예) 서울 OO가OOOO/ OO나OOOO/ OOO가OOOO)`,
  REGNUM_SELECT: "차량 조회를 완료해주세요.",
  PHONE_NUM: "7-8자리 숫자를 입력해주세요",
  TELEPHONE_NUM: "8-12자리 숫자를 입력해주세요",
  PHONE_FORMAT: "전화번호 형식에 맞춰 입력해주세요",
  VIN: "17자의 차대번호를 입력해주세요",
  VIN_INCORRECT: "올바르지 않은 차대번호 입니다. 다시 한번 확인해주세요",
  VIN_DUPLICATE: "중복된 차대번호 입니다. 다시 한번 확인해주세요",
  OPERATION_TIME: "운영 시작 및 종료 시간을 입력하세요.",
});

export const VALID_TARGET_TYPE = Object.freeze({
  EMAIL: "email",
  PW: "password",
  PW_C: "passwordConfirm",
  PHONE: "phoneNum",
  TIMEOUT: "timeout",
  ACCOUNT: "account",
});

export const ERROR_CODE_TYPE = Object.freeze({
  PASSWORD_MISMATCHED: {
    CODE: "PASSWORD_MISMATCHED",
    MSG: "입력하신 정보를 다시 확인해 주세요.",
  },
  CANNOT_USE_SAME_PASSWORD: {
    CODE: "CANNOT_USE_SAME_PASSWORD",
    MSG: "기존과 동일한 비밀번호입니다. 다른 비밀번호를 입력하세요.",
  },
});

export const MIN_OPTION_TABLE = {
  "00": "00",
  30: "30",
};
export const INPUT_MAX_LENGTH = Object.freeze({
  FREQ_CMNT_LABEL: 10,
  FREQ_CMNT_CONTENT: 300,
  REPORT_REVIEW_CONTENT: 30,
  MAINTENANCE_OPINION: 200,
  REASONS_NOT_AVAILABLE_CONTENT: 200,
  USER_COMMENT: 200,
  SHOP_COMMENT: 500,
  REVIEW_COMMENT: 300,
  EMAIL: 250,
  PASSWORD: 16,
  PHONE: 8,
  CELL_PHONE: 12,
  NAME: 20,
  MODEL_NAME: 50,
  REGNUM: 10,
  VIN: 17,
  MODEL_YEAR: 4,
  COMMON_TEXTAREA: 200,
  COMMENT_TITLE: 10,
  COMMENT_CONTENT: 300,
  REPAIR_SHOP_NAME: 50,
  ADDR_DETAIL: 250,
  OPERATE_TIME_MEMO: 50,
  INTRODUCTION: 300,
  STAFF_POSITION: 10,
  INQUIRY_TITLE: 30,
  INQUIRY_CONTENT: 500,
  INQUIRY_WRITER: 20,
  SEARCH_INPUT: 20,
});

export const INPUT_MAX_VALUE = Object.freeze({
  DRIVING_DISTANCE: 999999999,
  PRICE: 1000000000,
  EXPECTATION_TIME: 838,
  ACTUAL_TIME: 838,
});

export const TOAST_MSG = Object.freeze({
  SUCCESS: {
    ADD_REVIEW_CMNT_DONE: "댓글 입력이 완료 되었습니다",
    DELETE_REVIEW_CMNT_DONE: "댓글이 삭제 되었습니다",
    UPDATE_REVIEW_CMNT_DONE: "댓글이 수정 되었습니다",
    ADD_FREQ_CMNT_DONE: "자주 쓰는 댓글이 등록되었습니다",
    DELETE_FREQ_CMNT_DONE: "자주 쓰는 댓글이 삭제되었습니다",
    UPDATE_FREQ_CMNT_DONE: "자주 쓰는 댓글이 수정되었습니다",
    REPORT_REVIEW_DONE: "리뷰가 신고되었습니다",
    ADD_RESERVATION_DONE: "예약이 추가되었습니다",
    REPAIR_DONE: "정비가 완료되었습니다",
    START_REPAIR: "정비가 시작되었습니다",
    RESERVATION_DONE: "예약이 완료되었습니다",
    UPDATE_RESERVATION_DONE: "예약 변경이 완료되었습니다",
    SELECT_CANCEL_RESERVATION:
      "예약 취소를 선택하셨습니다 \n 사유 입력 후 완료 버튼을 누르면 해당 예약은 취소됩니다",
    CANCEL_RESERVATION_DONE: "예약이 취소되었습니다",
    ADD_CUSTOMER_DONE: "고객이 추가되었습니다",
    UPDATE_CUSTOMER_DONE: "고객 정보가 수정되었습니다",
    DELETE_CUSTOMER_DONE: "고객 정보가 삭제되었습니다",
    SEND_AUTH_LINK_DONE: "전송이 완료되었습니다!",
    RESET_PASSWORD_DONE:
      "비밀번호 재설정이 완료되었습니다 \n 로그인 페이지로 돌아갑니다",
    CHANGE_PASSWORD_DONE:
      "비밀번호가 변경되었습니다 \n 보안을 위해 전 기기에서 로그아웃됩니다",
    SET_BREAKETIME_DONE: "예약 불가 일시 설정이 완료되었습니다",
    SEARCH_CUSTOMER_BY_REGNUM_DONE:
      "고객 정비 완료 기록이 확인되어 \n 고객 정보가 자동 입력됩니다",
    UPDATE_RESERVATION_STAFF_DONE: "담당 정비사 수정이 완료되었습니다",
    UPDATE_RESERVATION_COST_DONE: "담당 정비 금액 수정이 완료되었습니다",
    ADD_STAFF_DONE: "직원이 추가되었습니다",
    UPDATE_STAFF_DONE: "직원 정보가 수정완료 되었습니다.",
    DELETE_STAFF_DONE: "직원이 삭제되었습니다.",
    UPDATE_SHOP_INFO_DONE: "정비소 정보가 수정되었습니다",
    ADD_INQUIRY_DONE: "1:1 문의 등록이 완료되었습니다",
    SAVE_MEMO_DONE: "메모를 저장하였습니다.",
  },
  FAIL: {
    SEARCH_CUSTOMER_BY_REGNUM_ERROR:
      "고객 정비 완료된 기록이 없습니다 \n 고객정보를 입력해주세요:)",
    UPDATE_STAFF_FAIL: "정비이력이 있는 경우 \n직무 변경이 불가합니다",
    DELETE_STAFF_FAIL: "담당 정비 예약이 존재하는 직원은 삭제할 수 없습니다",
    REPORTED_REVIEW: "이미 신고된 리뷰입니다",
    INVALID_REPORT_REASON: "신고 사유 형식이 유효하지 않습니다.",
    NOT_CORRECT_DATE:
      "정비 일시가 올바르지 않습니다 \n 예약시간을 변경해주세요 :)",
    ADD_THUMBNAIL_IMG_ERROR: "대표 이미지를 추가해주세요 :)",
    ADD_INQUIRY_IMG_WARNING: "이미지는 최대 3개까지 추가할 수 있습니다 :)",
    UPDATE_OPERATION_TIME: "운영 시작 및 종료 시간을 모두 입력해주세요",
    SETUP_ETC_OPERATION_TIME: "운영시간을 설정 후 다시 시도해주세요!",
    NONE_CHECK_CAR_INFO: "차량 조회를 완료해주세요!",
  },
});

export const DRAG_STATUS = Object.freeze({
  START: "start",
  STOP: "stop",
  ENTER: "enter",
  LEAVE: "leave",
});

export const STEP_COLOR_TABLE = Object.freeze({
  planned: { color: "blue_01", timeColor: "", bg: "" },
  accepted: {
    color: "blue_01",
    timeColor: "blue_01",
    bg: "bg_step_complete_reservation",
  },
  repairing: {
    color: "blue_01",
    timeColor: "gray_10",
    bg: "bg_step_proceeding_repair",
  },
  completed: {
    color: "blue_01",
    timeColor: "blue_01",
    bg: "bg_step_complete_repair",
  },
  canceled: {
    color: "error_01",
    timeColor: "",
    bg: "",
  },
});

export const RESERVATION_STATUS_TABLE = Object.freeze({
  planned: "예약 요청",
  accepted: "예약 완료",
  repairing: "정비 중",
  completed: "정비 완료",
  canceled: "예약 취소",
});

export const REPAIR_SHOP_STATUS = Object.freeze({
  ACTIVE: "active",
  INACTIVE: "inactive",
  PREPARING: "preparing",
});

export const APPROVED_MAINTENANCE = Object.freeze({
  ACCEPTED: "accepted",
  APPROVED: "approved",
  CHANGE: "change",
  CANCEL: "cancel",
});

export const IS_SELF_TABLE = {
  y: true,
  n: false,
};

export const TOOLTIP_MSG = Object.freeze({
  RESERVATION_UNAVALIABLE_TIME:
    "*어플 내 예약 불가 시간을 설정합니다.\n해당 설정과 관계없이 전화 예약은 가능합니다.",
});

export const REVIEW_SORT_OPTION_TABLE = Object.freeze({
  advertising: "광고/사기",
  obscenity: "음란성/선정성",
  termAbuse: "욕설/인신공격",
  prsinExpsr: "개인정보 노출",
  directInput: "직접입력",
});

export const ERROR_CODE = {
  CANNOT_USE_SAME_PASSWORD: "CANNOT_USE_SAME_PASSWORD",
  PASSWORD_MISMATCHED: "PASSWORD_MISMATCHED",
  PHONE_ALREADY_EXISTS: "PHONE_ALREADY_EXISTS",
  CANNOT_UPDATE_STAFF: "CANNOT_UPDATE_STAFF",
  INVALID_MODEL_YEAR: "INVALID_MODEL_YEAR",
  REG_NUM_ALREADY_EXISTS: "REG_NUM_ALREADY_EXISTS",
  INVALID_TEMPLATE: "INVALID_TEMPLATE",
  INVALID_REPORT_REASON: "INVALID_REPORT_REASON",
  ALREADY_REPORTED: "ALREADY_REPORTED",
  INVALID_YEAR: "INVALID_YEAR",
  INVALID_PLANNED: "INVALID_PLANNED",
  INVALID_ESTIMATED_TIME: "INVALID_ESTIMATED_TIME",
  INVALID_AUTH_CODE: "INVALID_AUTH_CODE",
};

export const RESERVATION_STATUS = [
  "planned",
  "accepted",
  "repairing",
  "completed",
  "canceled",
];
