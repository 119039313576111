import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { mixinSettingArea, mixin_scrollbar } from "style/mixin";

export const Root = styled.article`
  ${({ theme }) => css`
    ${mixinSettingArea(theme)};
    min-height: 536px;
    padding: 0;
  `}
`;

export const marketingLabelContent = css`
  & > label:first-of-type {
    margin-bottom: 6px;
  }
`;

const mixinLine = (theme: Theme, posY: string) => css`
  ::before {
    content: "";
    position: absolute;
    left: 39px;
    ${posY}: 0;
    height: 1px;
    width: calc(100% - 78px);
    border-bottom: 1px solid ${theme.color.gray_02};
  }
`;

export const Header = styled.header`
  ${({ theme }) => css`
    ${mixinLine(theme, "bottom")}
    position: relative;
    padding: 27px 39px 8px;

    & > h3 {
      ${theme.font.medium_18};
      letter-spacing: -0.02em;
      color: ${theme.color.gray_09};
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    ${mixin_scrollbar(theme)};
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    max-height: calc(
      100vh - 54px - 40px - 22px - 16px - 64px - 36px - 60px - 60px
    );
    padding: 20px 39px;
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    ${mixinLine(theme, "top")};
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  `}
`;

export const workTime = css`
  position: relative;
`;

export const WorkTimeDesc = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: inline-block;
    margin: 6px 0;
    color: ${theme.color.gray_05};
  `}
`;

export const WorkContentWrapper = styled.div<{ haserror: boolean }>`
  ${({ haserror, theme }) => css`
    position: absolute;
    top: 90px;
    left: 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 16px;
    min-height: 233px;
    border: 1px solid ${haserror ? theme.color.error_01 : theme.color.blue_01};
    border-radius: 4px;
    padding: 21px 25px;
    background-color: ${theme.color.white};
    box-shadow: ${theme.boxshadow.shadow2_2_8};
    z-index: 11;

    & > div:first-of-type {
      grid-column: span 2;
    }
  `}
`;

const mixin_input = css`
  width: calc(50% - 12px);
`;

export const inputLabelContent = css`
  ${mixin_input};
`;

export const MarketingCheckText = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_10};
  `}
`;
