import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  display: flex;
  align-items: center;
`;

export const RadioWrapper = styled.ul`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-right: 12px;

    & > li:not(:first-of-type) {
      margin-left: 17px;
    }

    & > li:nth-of-type(2) {
      position: relative;
      display: flex;
      align-items: center;
      margin-left: 34px;

      &::after {
        content: "";
        position: absolute;
        left: -17px;
        width: 1px;
        height: 16px;
        background-color: ${theme.color.gray_02};
      }
    }
  `}
`;

export const selectReview = css`
  width: 179px;
`;

export const settingButton = css`
  padding: 0 11px;
`;
