import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(-50%, -50%);
    z-index: 10;

    & > span {
      ${theme.font.medium_14};
      color: ${theme.color.gray_05};
      white-space: pre-wrap;
    }
  `}
`;

export const exclamationIcon = css`
  margin-bottom: 16px;
`;
