import { useEffect } from "react";
import { useImmer } from "use-immer";

const INITIAL_STATE = {
  name: { value: "", error: "" },
  phone: { value: "", error: "" },
  regNum: { value: "", error: "" },
  modelName: { value: "", error: "" },
  modelYear: { value: "", error: "" },
  brandId: { value: "", error: "" },
  vin: { value: "", error: "" },
  mileage: { value: "", error: "" },
  axle: { value: "", error: "" },
  seat: { value: "", error: "" },
  noshows: { value: "", error: "" },
  userComment: { value: "", error: "" },
  reservationNo: { value: "", error: "" },
  status: { value: "", error: "" },
  isSelf: { value: "", error: "" },
  memo: { value: "", error: "" },
};

const useReservationInfo = (data: any) => {
  const [reservationInfo, setReservationInfo] = useImmer(INITIAL_STATE);

  const initReservationInfo = () => {
    setReservationInfo((draft: any) => {
      for (const key in data) {
        if (draft[key]) {
          draft[key].value = data[key] ?? "";
          draft[key].error = "";
        }
      }
    });
  };

  useEffect(() => {
    initReservationInfo();
  }, [data]);

  return reservationInfo;
};

export default useReservationInfo;
