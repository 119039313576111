import { useRef, useCallback, useEffect } from "react";
import { useImmer } from "use-immer";

import { useToast } from "hooks";
import { useSendAuthLink } from "services";
import { checkEmailValidation, checkInputLength } from "utils";
import { TOAST_MSG, VALID_MESSAGE } from "constants/index";

const useFindPassword = () => {
  const inputRef = useRef<any>(null);

  const [email, setEmail] = useImmer({
    value: "",
    error: "",
  });

  const { addToast } = useToast();
  const { mutate: sendAuthLinkMutate } = useSendAuthLink();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (email.value === "") {
      setEmail((draft) => {
        draft.error = VALID_MESSAGE.REQUIRED;
      });
      inputRef.current.focus();
      return;
    }

    const req = {
      body: {
        email: email.value,
      },
    };

    sendAuthLinkMutate(req, {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.SEND_AUTH_LINK_DONE);
      },
      onError: (err) => {
        if (err) {
          setEmail((draft) => {
            draft.error = VALID_MESSAGE.SEND_AUTH_LINK;
          });
          inputRef.current.focus();
        }
      },
    });
  };

  const handleChangeEmail = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      let error = "";

      if (!checkInputLength("EMAIL", value)) return;
      if (email.error && !checkEmailValidation(value)) {
        error = VALID_MESSAGE.EMAIL;
      }
      setEmail((draft) => {
        draft.value = value;
        draft.error = error;
      });
    },
    [email],
  );

  const handleBlurEmail = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const value = event.target.value;
      let error = "";

      if (value.length === 0) {
        setEmail((draft) => {
          draft.error = VALID_MESSAGE.REQUIRED;
        });

        return;
      }
      !checkEmailValidation(value) && (error = VALID_MESSAGE.EMAIL);
      setEmail((draft) => {
        draft.error = error;
      });
    },
    [],
  );

  useEffect(() => {
    return () => {
      setEmail((draft) => {
        draft.value = "";
        draft.error = "";
      });
    };
  }, []);

  return {
    inputRef,
    email,
    handleChangeEmail,
    handleBlurEmail,
    handleSubmit,
  };
};

export default useFindPassword;
