import React from "react";

import * as S from "./YesNoButton.styled";

interface YesNoButtonProps {
  labels: any;
  status: boolean;
  className?: string;
  props?: any;
}

const YesNoButton = ({
  labels,
  status = true,
  className,
  ...props
}: YesNoButtonProps) => {
  const { y, n } = labels;

  return (
    <S.RootStyle className={className} {...props}>
      {status && <S.YesBadge>{y}</S.YesBadge>}
      {!status && <S.NoBadge>{n}</S.NoBadge>}
    </S.RootStyle>
  );
};

export default YesNoButton;
