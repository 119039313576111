import React from "react";

import { EstimatedTime, DateSelectInput } from "components";
import { SELF_RESERVATION_TIME_INFO } from "assets";
import * as S from "./TimeInfo.styled";

interface TimeInfoProps {
  inputRefs: any;
  data: any;
  handleChangeData: any;
  handleBlurData: any;
  handleSelectDate: any;
}

const TimeInfo = ({
  inputRefs,
  data,
  handleChangeData,
  handleBlurData,
  handleSelectDate,
}: TimeInfoProps) => {
  return (
    <S.Root>
      {SELF_RESERVATION_TIME_INFO.map((item) => (
        <div key={item.key}>
          <S.Label
            htmlFor={`timeInfo-input-${item.key}`}
            isRequired={item.isRequired}
          >
            {item.label}
          </S.Label>
          <S.ContentWrapper>
            {item.type === "dateTime" ? (
              <DateSelectInput
                css={S.dateSelect}
                id={`timeInfo-${item.key}`}
                forwardRef={(el: any) => (inputRefs.current[item.key] = el)}
                name={item.key}
                value={data[item.key].value}
                data-required={item.isRequired ? "true" : ""}
                placeholder={item.placeholder}
                isDisabledBeforeToday={true}
                handleSelectDate={handleSelectDate(item.key)}
                onBlur={handleBlurData}
                validError={data[item.key].error}
              >
                <DateSelectInput.Time
                  css={S.plannedTime}
                  forwardRef={(el: any) => (inputRefs.current[item.key] = el)}
                  id={`timeInfo-time-${item.key}`}
                  onChange={handleChangeData}
                  onBlur={handleBlurData}
                  required={item.isRequired}
                >
                  <EstimatedTime.SelectHour
                    css={S.plannedHour}
                    forwardRef={(el: any) =>
                      (inputRefs.current[item.hourKey as any] = el)
                    }
                    name={item.hourKey}
                    value={data[item.hourKey as any].value}
                    validError={data[item.hourKey as any].error}
                    required={item.isRequired}
                  />
                  <EstimatedTime.SelectMin
                    css={S.plannedMin}
                    forwardRef={(el: any) =>
                      (inputRefs.current[item.minKey] = el)
                    }
                    name={item.minKey}
                    value={data[item.minKey].value}
                    validError={data[item.minKey].error}
                    required={item.isRequired}
                    halfDisable={
                      item.minKey === "plannedTimeMin" &&
                      data.plannedTimeHour.value === 24
                    }
                  />
                </DateSelectInput.Time>
                {data[item.key].error && (
                  <S.ValidText>{data[item.key].error}</S.ValidText>
                )}
              </DateSelectInput>
            ) : (
              <EstimatedTime
                css={S.estimatedTime}
                id={`timeInfo-${item.key}`}
                onChange={handleChangeData}
                onBlur={handleBlurData}
              >
                <S.TimeWrapper>
                  <EstimatedTime.Hour
                    css={S.hourSelector}
                    id={item.key}
                    forwardRef={(el: any) => (inputRefs.current[item.key] = el)}
                    name={item.key}
                    value={data[item.key].value}
                    validError={data[item.key].error || data[item.minKey].error}
                    required={item.isRequired}
                    label={item.hourLabel}
                  />

                  <EstimatedTime.SelectMin
                    css={S.minSelector(item.minKey)}
                    id={item.minKey}
                    forwardRef={(el: any) =>
                      (inputRefs.current[item.minKey] = el)
                    }
                    name={item.minKey}
                    label="분 이내"
                    value={data[item.minKey].value}
                    validError={data[item.key].error || data[item.minKey].error}
                    required={item.isRequired}
                    halfDisable={
                      item.minKey === "plannedTimeMin" &&
                      data.plannedTimeHour.value === 24
                    }
                    zeroDisable={
                      item.minKey === "estimatedTimeMin" &&
                      +data.estimatedTimeHour.value === 0
                    } // TODO: Refactoring
                  />
                </S.TimeWrapper>
                {(data[item.key].error || data[item.minKey].error) && (
                  <S.ValidText>
                    {data[item.key].error
                      ? data[item.key].error
                      : data[item.minKey].error}
                  </S.ValidText>
                )}
              </EstimatedTime>
            )}
          </S.ContentWrapper>
        </div>
      ))}
    </S.Root>
  );
};

export default TimeInfo;
