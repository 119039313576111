import React from "react";
import dayjs from "dayjs";
import "dayjs/locale/ko";

import { EstimatedTime } from "components";
import { formatMaintenanceDateTime } from "utils";
import * as S from "./ApprovedForm.styled";

dayjs.locale("ko");

const ApprovedForm = ({ id, data }: any) => {
  return (
    <div id={id} tabIndex={0}>
      <S.InfoItem isRequired={true}>
        <span>정비 일시</span>
        <S.DateInput value={formatMaintenanceDateTime(data.planned)} readOnly />
      </S.InfoItem>
      <S.InfoItem isRequired={true}>
        <label>예상 정비 시간</label>
        <EstimatedTime css={S.estimatedTimeInput}>
          <EstimatedTime.Hour
            readOnly={true}
            value={data.estimatedTime?.split(":")[0] ?? ""}
            label="시간"
          />
          <EstimatedTime.Min
            readOnly={true}
            value={data.estimatedTime?.split(":")[1] ?? ""}
            label="분 이내"
          />
        </EstimatedTime>
      </S.InfoItem>
    </div>
  );
};

export default ApprovedForm;
