import Compressor from "compressorjs";

import { BYTE } from "constants/index";

export const getNormalizedFile = (file: File) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      minWidth: 598,
      minHeight: 400,
      success(normalizedFile) {
        resolve(normalizedFile);
      },
      error(error) {
        reject(error);
      },
    });
  });
};

export const getFileSize = (type: "MB" | "KB", size: number) => {
  switch (type) {
    case "KB":
      return +(size / BYTE).toFixed(2);
    case "MB":
      return +(size / (BYTE * BYTE)).toFixed(2);
  }
};
