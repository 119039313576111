import { useState } from "react";

import { useMonthYear } from "hooks/index";

const useCalendar = (initDate: any) => {
  const [selectedDate, setSelectedDate] = useState();

  const {
    monthYear,
    changePrevMonthYear,
    changeNextMonthYear,
    changeCurrentMonthYear,
    changeMonthYear,
    changePrevYear,
    changeNextYear,
  } = useMonthYear(initDate);

  const handleSelectDate = (date: any) => {
    setSelectedDate(date);
  };

  return {
    monthYear,
    selectedDate,
    handleSelectDate,
    changePrevMonthYear,
    changeNextMonthYear,
    changeCurrentMonthYear,
    changeMonthYear,
    changePrevYear,
    changeNextYear,
  };
};

export default useCalendar;
