import React, { useState, useEffect } from "react";

import { Button, EstimatedTime } from "components";
import { useNumericInputComma, useSelect } from "hooks";
import { useUpdateReservationEstimatedTime } from "services";
import { checkNull, checkExpectationTime } from "utils";
import * as S from "./EstimatedTimeDialog.styled";

const ESTIMATED_HOUR_ID = "estimatedTime-hour";

interface EstimatedTimeDialogProps {
  className?: string;
  isDialogOpen: boolean;
  dialogRef: any;
  initHour: string;
  initMnute: string;
  reservationId: string;
  handleDialogClose: any;
}

const EstimatedTimeDialog = ({
  className,
  isDialogOpen,
  dialogRef,
  initHour,
  initMnute,
  reservationId,
  handleDialogClose,
}: EstimatedTimeDialogProps) => {
  const { mutate: updateReservationEstimatedTimeMutate } =
    useUpdateReservationEstimatedTime();
  const [hour, handleChangeHour, setHour] = useNumericInputComma(
    "",
    checkExpectationTime,
  );
  const [minute, handleChangeMinute, setMinute] = useSelect();
  const [error, setError] = useState(false);

  const isSubmitDisabled = checkNull([hour, minute]) || error;

  const handleComplete = (event: any) => {
    event.preventDefault();

    const req = {
      reservationId,
      body: {
        estimatedTime: `${hour}:${minute}`,
      },
    };

    updateReservationEstimatedTimeMutate(req, {
      onSuccess: () => {
        handleDialogClose();
      },
      onError: (err) => {
        switch (err.response?.data.code) {
          case "INVALID_ESTIMATED_TIME":
            setError(true);
        }
      },
    });
  };

  useEffect(() => {
    if (!isDialogOpen) {
      setError(false);
      return;
    }

    setHour(initHour);
    setMinute(initMnute);
  }, [isDialogOpen]);

  useEffect(() => {
    if (hour === "0" && minute === "00") {
      setMinute("30");
    }
    setError(false);
  }, [hour, minute]);

  return (
    <>
      {isDialogOpen && (
        <S.DialogRoot className={className} open={isDialogOpen} ref={dialogRef}>
          <S.Content>
            <label htmlFor={ESTIMATED_HOUR_ID}>예상 정비 시간</label>
            <EstimatedTime id={ESTIMATED_HOUR_ID}>
              <EstimatedTime.Hour
                css={S.hourInput}
                required={true}
                label="시"
                value={hour}
                validError={error}
                onChange={handleChangeHour}
              />
              <EstimatedTime.SelectMin
                css={S.minSelector}
                value={minute}
                zeroDisable={hour === "0"}
                label="분"
                validError={error}
                onSelect={handleChangeMinute()} // TODO QUESTION
              />
            </EstimatedTime>
            {error && (
              <S.ErrorText>다음 스케줄 변경 후 다시 시도해주세요</S.ErrorText>
            )}
          </S.Content>
          <S.ButtonWrapper>
            <Button
              css={S.cancelButton}
              variant="secondarySmall"
              onClick={handleDialogClose}
            >
              취소
            </Button>
            <Button
              css={S.submitButton}
              disabled={isSubmitDisabled}
              onClick={handleComplete}
            >
              완료
            </Button>
          </S.ButtonWrapper>
        </S.DialogRoot>
      )}
    </>
  );
};

export default EstimatedTimeDialog;
