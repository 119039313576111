import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Table, YesNoButton } from "components";
import {
  RESERVATION_IS_SELF_LABEL,
  CUSTOMER_RESERVATION_TABLE_DATA,
} from "assets";
import * as S from "./CustomersReservationTable.styled";

interface CustomersReservationTableProps {
  reservationDataInfo: any;
  isDone: boolean;
}

const CustomersReservationTable = ({
  reservationDataInfo,
  isDone,
}: CustomersReservationTableProps) => {
  const navigate = useNavigate();
  const handleRouteToDetail = useCallback((id: string) => {
    navigate(`/reservation/detail?reservationId=${id}`);
  }, []);

  return (
    <Table css={S.root} title="고객 정비 예약 목록">
      <Table.Head columnList={CUSTOMER_RESERVATION_TABLE_DATA.columnList} />
      <Table.Body
        css={(theme) =>
          S.tableBody(theme, reservationDataInfo?.length !== 0 ? true : false)
        }
        isDone={isDone}
        data={reservationDataInfo}
        text="아직 완료 또는 취소된 정비가 없어요!"
      >
        {reservationDataInfo?.map((rowData: any) => (
          <Table.ClickRow
            id={rowData.reservationId}
            key={rowData.reservationId}
            handleClick={handleRouteToDetail}
          >
            {CUSTOMER_RESERVATION_TABLE_DATA.columnList.map((columnData) => (
              <Table.Cell css={S.cell} key={columnData.key}>
                {Column(rowData, columnData.key)}
              </Table.Cell>
            ))}
          </Table.ClickRow>
        ))}
      </Table.Body>
    </Table>
  );
};

export default CustomersReservationTable;

const Column = (item: any, key: string) => {
  switch (key) {
    case "isSelf":
      return (
        <YesNoButton
          css={S.yesNoButton}
          data-status={item[key] === "y"}
          status={item[key] === "y"}
          labels={RESERVATION_IS_SELF_LABEL}
        />
      );

    case "status":
      return item[key] === "canceled" ? (
        <mark data-status="cancel">
          {item.noshow === "y" ? "취소(노쇼)" : "취소"}
        </mark>
      ) : (
        <span>정비 완료</span>
      );

    case "staffNames":
      return <S.TruncateCell>{item[key] ?? "-"}</S.TruncateCell>;

    default:
      return <span>{item[key] ?? "-"}</span>;
  }
};
