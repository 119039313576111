import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  padding-bottom: 24px;

  & > div {
    display: flex;
  }
`;

export const Label = styled.label<{ isRequired: boolean }>`
  ${({ isRequired, theme }) => css`
    ${theme.font.regular_14};
    display: block;
    width: 136px;
    margin-bottom: 6px;
    color: ${theme.color.gray_07};

    &::after {
      content: ${isRequired ? "'*'" : null};
      display: inline-block;
      margin-left: 3px;
      color: ${theme.color.error_01};
    }
  `}
`;
