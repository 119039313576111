import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { CenterLayout } from "components";
import { useSetMetaTags } from "hooks";
import { useGetBrands } from "services";
import ReservationDetailForm from "./container/form/ReservationDetailForm";
import * as S from "./ReservationDetail.styled";

export default function ReservationDetail() {
  const [searchParams] = useSearchParams();

  const { refetch: refetchBrands } = useGetBrands();

  useSetMetaTags("정비관리 | 상세조회");

  useEffect(() => {
    refetchBrands();
  }, [searchParams]);

  return (
    <CenterLayout css={S.centerLayout}>
      <h2 className="a11y">예약 정보</h2>
      <ReservationDetailForm />
    </CenterLayout>
  );
}
