import React, { useState, useCallback, useEffect, useRef } from "react";
import { useRecoilValue, useRecoilState } from "recoil";

import { Button, Textarea } from "components";
import { useToast } from "hooks";
import {
  useCreateCommentByReviewId,
  useUpdateCommentByReviewId,
  useGetFreqComments,
} from "services";
import { freqCommentState, reviewState } from "store";
import { TOAST_MSG, INPUT_MAX_LENGTH } from "constants/index";
import type { Review, FreqCommentTemplate } from "types";
import FrequentlyUsedCommentSelector from "../../frequently/selector/FrequentlyUsedCommentSelector";
import * as S from "./ReplyCommentForm.styled";

const REVIEW_COMMENT_TEXTAREA_ID = "textarea-review-comment";

interface ReplyCommentFormProps {
  reviewId: string;
  comment: string;
  closeReplyCommentForm: any;
}

const ReplyCommentForm = ({
  reviewId,
  comment,
  closeReplyCommentForm,
}: ReplyCommentFormProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const { data } = useGetFreqComments();

  const [freqUseComments, setFreqUseComments] =
    useRecoilState(freqCommentState);

  const freqComments = data?.templates;

  const [reviews, setReviews] = useRecoilState(reviewState);
  const [replyClose, setReplyClose] = useState(false);
  const [commentValue, setCommentValue] = useState(comment ?? "");
  const [frequentlyUsedCommentType, setFrequentlyUsedCommentType] =
    useState("");

  const { mutate: createCommentByReviewIdMutate } =
    useCreateCommentByReviewId();
  const { mutate: updateCommentByReviewIdMutate } =
    useUpdateCommentByReviewId();
  const { addToast } = useToast();

  const adjustTextareaHeight = () => {
    if (textAreaRef.current && textAreaRef.current.scrollHeight < 124) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  const handleCommentChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (value.length > INPUT_MAX_LENGTH.REVIEW_COMMENT) return;

      setCommentValue(value);
      adjustTextareaHeight();
    },
    [],
  );

  const handleChangeCommentType = useCallback((id: any) => {
    setFrequentlyUsedCommentType(id);
  }, []);

  const handleComplete = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const reply = commentValue.trim();

    const req = {
      reviewId,
      body: {
        reply,
      },
    };

    if (comment === "" || comment === null) {
      createCommentByReviewIdMutate(req, {
        onSuccess: () => {
          addToast(TOAST_MSG.SUCCESS.ADD_REVIEW_CMNT_DONE);
          setReplyClose(true);

          const targetReview = reviews.find(
            (reveiw) => reveiw.reservationId === req.reviewId,
          ) as Review;

          const createReview = { ...targetReview, reply };

          const updatedReviewList = reviews.map((review) =>
            review.reservationId === req.reviewId ? createReview : review,
          );

          setReviews(updatedReviewList);
        },
      });
    } else {
      updateCommentByReviewIdMutate(req, {
        onSuccess: () => {
          addToast(TOAST_MSG.SUCCESS.UPDATE_REVIEW_CMNT_DONE);
          setReplyClose(true);

          const targetReview = reviews.find(
            (reveiw) => reveiw.reservationId === req.reviewId,
          ) as Review;

          const updateReview = { ...targetReview, reply };

          const updatedReviewList = reviews.map((review) =>
            review.reservationId === req.reviewId ? updateReview : review,
          );

          setReviews(updatedReviewList);
        },
      });
    }
  };

  useEffect(() => {
    const freqComment = freqUseComments.find(
      (v: FreqCommentTemplate) => v.templateId === frequentlyUsedCommentType,
    );
    const message = freqComment?.content ?? comment ?? "";
    setCommentValue(message);
  }, [frequentlyUsedCommentType]);

  useEffect(() => {
    if (replyClose) {
      closeReplyCommentForm();
      setReplyClose(false);
    }
  }, [replyClose]);

  useEffect(() => {
    adjustTextareaHeight();

    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (freqComments) {
      setFreqUseComments(freqComments);
    }
  }, [freqComments]);

  return (
    <S.Root onSubmit={handleComplete}>
      <S.ContentTop>
        <label htmlFor={REVIEW_COMMENT_TEXTAREA_ID}>댓글 입력</label>
        <FrequentlyUsedCommentSelector
          radioItems={freqUseComments}
          selectedType={frequentlyUsedCommentType}
          onChange={handleChangeCommentType}
        />
      </S.ContentTop>
      <Textarea
        css={S.textarea}
        id={REVIEW_COMMENT_TEXTAREA_ID}
        ref={textAreaRef}
        value={commentValue}
        maxLength={INPUT_MAX_LENGTH.REVIEW_COMMENT}
        onChange={handleCommentChange}
      />
      <S.ButtonWrapper>
        <Button
          css={S.cancelButton}
          variant="secondarySmall"
          onClick={closeReplyCommentForm}
        >
          취소
        </Button>
        <Button type="submit" disabled={!commentValue.trim()}>
          완료
        </Button>
      </S.ButtonWrapper>
    </S.Root>
  );
};

export default ReplyCommentForm;
