import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  display: flex;
  column-gap: 75px;
  padding-top: 20px;
`;

export const Label = styled.label<{ isRequired: boolean }>`
  ${({ isRequired, theme }) => css`
    ${theme.font.regular_14};
    display: block;
    margin-bottom: 6px;
    color: ${theme.color.gray_07};
    letter-spacing: -0.01em;

    &::after {
      content: ${isRequired ? "'*'" : null};
      display: inline-block;
      margin-left: 3px;
      color: ${theme.color.error_01};
    }
  `}
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const inputMixin = css`
  width: 186px;
`;

export const dropdownInput = css`
  ${inputMixin};
`;

export const regNumButton = css`
  margin-left: 6px;
`;

export const ValidText = styled.strong`
  ${({ theme }) => css`
    ${theme.font.regular_12};
    display: flex;
    align-items: center;
    margin-top: 4px;
    color: ${theme.color.error_01};
    white-space: nowrap;

    &[data-status="done"] {
      color: ${theme.color.blue_01};
      fill: ${theme.color.blue_01};
    }

    &[data-status="notDone"] {
      path {
        fill: ${theme.color.error_01};
      }
    }
  `}
`;
