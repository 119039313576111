import React, { useState, useEffect, ChangeEvent } from "react";
import ReactCrop from "react-image-crop";

import { LoadingSpinner, DetailModal } from "components";
import { useFileDragDrop, useImagePreview, useToast } from "hooks";
import { getFileSize } from "utils";
import { ImageIconImg } from "assets";
import { TOAST_MSG } from "constants/index";
import * as S from "./ImageUploadModal.styled";
import { Crop } from "react-image-crop";

const MIN_IMG_WIDTH = 592;
const MIN_IMG_HEIGHT = 399;
const DEFAULT_CARGO_OPT = {
  unit: "px",
  aspect: 595 / 357,
  x: 0,
  y: 20,
  width: MIN_IMG_WIDTH,
  height: 360,
};

interface ImageUploadModalProps {
  className?: string;
  title: string;
  name: string;
  idx?: any;
  handleUploadFile: any;
  handleModalClose: () => void;
}

const ImageUploadModal = React.forwardRef(
  (
    {
      className,
      title = "이미지 업로드",
      name,
      idx,
      handleUploadFile,
      handleModalClose,
    }: ImageUploadModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const [imageRef, setImageRef] = useState<any>(null);
    const [image, setImage] = useState<any>(null);
    const [imageRatio, setImageRatio] = useState(1);
    const [blankSpace, setBlankSpace] = useState(0);
    const [crop, setCrop] = useState(DEFAULT_CARGO_OPT);
    const [completedCrop, setCompletedCrop] = useState(DEFAULT_CARGO_OPT);

    const { dropRef, isDragging } = useFileDragDrop(setImage);
    const [fileDataUrl, loading] = useImagePreview(image, true);
    const { addToast } = useToast();

    const handleChangeFile = (event: ChangeEvent<any>) => {
      const file = event.target.files[0];

      if (getFileSize("MB", file?.size) > 10) {
        addToast(TOAST_MSG.FAIL.UPLOAD_FILE_SIZE);
        return;
      }
      setImage(file);
    };

    const handleComplete = () => {
      getCroppedImg(imageRef, completedCrop);
      handleModalClose();
    };

    const getCroppedImg = (sourceImage: any, crop: any) => {
      const canvas = document.createElement("canvas");
      const scaleX = sourceImage.naturalWidth / sourceImage.width;
      const scaleY = sourceImage.naturalHeight / sourceImage.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      ctx?.drawImage(
        sourceImage,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        Math.max(blankSpace - crop.y / 2, 0),
        crop.width * imageRatio,
        crop.height * imageRatio,
      );
      try {
        return new Promise((resolve) => {
          canvas.toBlob((file) => {
            resolve(handleUploadFile(name, file, idx));
          }, "image/jpeg");
        });
      } catch (error) {
        console.log(error);
        return null;
      }
    };

    const handleImageOnLoad = (
      event: React.SyntheticEvent<HTMLImageElement, Event>,
    ) => {
      const image = event.target as HTMLImageElement;
      if (image) {
        setImageRef(image);
      }
    };

    useEffect(() => {
      if (imageRef) {
        const width = imageRef.width;
        const height = imageRef.height;
        let imgRatio = 1;
        let margin = 0;
        if (width < MIN_IMG_WIDTH) {
          imgRatio = MIN_IMG_WIDTH / width;
        }

        if (height < MIN_IMG_HEIGHT) {
          margin = (MIN_IMG_HEIGHT - height) / 2;
        }
        setCrop(DEFAULT_CARGO_OPT);
        setBlankSpace(margin);
        setImageRatio(imgRatio);
      }
    }, [fileDataUrl, imageRef]);

    return (
      <DetailModal
        className={className}
        ref={ref}
        css={S.detailModal}
        title={title}
        isPosDisabled={!image}
        posBtnName="완료"
        posFn={handleComplete}
      >
        <S.Content>
          {fileDataUrl || loading ? (
            <S.ImgChangerWrapper>
              <S.ImageChanger>
                이미지 변경
                <input
                  type="file"
                  accept="image/jpg,image/jpeg,image/png,image/webp"
                  onChange={handleChangeFile}
                />
              </S.ImageChanger>
              <S.CropContainer ref={dropRef}>
                {fileDataUrl && !loading ? (
                  <ReactCrop
                    css={S.reactCrop}
                    // src={imageRef} // NOTE: ReactCrop에는 src 속성이 없어서 일단 주석처리하겠습니다.
                    crop={crop as Crop}
                    locked
                    minWidth={598}
                    onChange={setCrop as any}
                    onComplete={setCompletedCrop as any}
                  >
                    <S.Image
                      src={fileDataUrl as string}
                      ratio={imageRatio}
                      alt="preveiw"
                      onLoad={handleImageOnLoad}
                    />
                  </ReactCrop>
                ) : (
                  <LoadingSpinner />
                )}
              </S.CropContainer>
            </S.ImgChangerWrapper>
          ) : (
            <>
              <S.ValidMsg>
                <dt>이미지 업로드 시 주의사항</dt>
                <dd>
                  이미지는 JPG,PNG 파일로만 업로드 가능하며 크기는 10MB 이하로
                  제한되어 있습니다.
                </dd>
              </S.ValidMsg>
              <S.UploadContainer>
                <S.UploadBox ref={dropRef} isDragging={isDragging}>
                  <ImageIconImg />
                  <p>
                    여기에 이미지 드래그
                    <span>또는</span>
                  </p>
                  <S.Uploader>
                    파일 업로드
                    <input
                      type="file"
                      accept="image/jpg,image/jpeg,image/png,image/webp"
                      onChange={handleChangeFile}
                    />
                  </S.Uploader>
                </S.UploadBox>
              </S.UploadContainer>
            </>
          )}
        </S.Content>
      </DetailModal>
    );
  },
);

ImageUploadModal.displayName = "ImageUploadModal";

export default ImageUploadModal;
