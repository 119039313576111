import { useMutation, useQuery } from "@tanstack/react-query";

import { createInquiryAPI, getInquiryDetailAPI, getInquirysAPI } from "apis";
import { formatDate, numToStringPhoneNum } from "utils";
import type {
  GetInquiryQueryModel,
  CreateInquiryQueryModel,
  GetDetailInquiryServerModel,
  APIError,
} from "types";

const inquiryKeys = {
  all: ["inquiry"] as const,
  lists: () => [...inquiryKeys.all, "list"],
  list: (filters: GetInquiryQueryModel) => [
    ...inquiryKeys.lists(),
    { filters },
  ],
  details: () => [...inquiryKeys.all, "detail"],
  detail: (helpId: string) => [...inquiryKeys.details(), helpId],
};

export const useGetInquiries = (filters: GetInquiryQueryModel) => {
  return useQuery({
    queryKey: inquiryKeys.list(filters),
    queryFn: () => getInquirysAPI(filters),
    select: (res) => ({
      helps: res.helps.map((help) => ({
        ...help,
        created: formatDate(help.created, "YY.MM.DD. HH:mm"),
      })),
      pageInfo: res.pageInfo,
    }),
  });
};

export const useCreateInquiry = () => {
  return useMutation<
    GetDetailInquiryServerModel,
    APIError,
    CreateInquiryQueryModel
  >({
    mutationFn: (req) => createInquiryAPI(req),
  });
};

export const useGetInquiryDetail = (req: string) => {
  return useQuery({
    queryKey: inquiryKeys.detail(req),
    queryFn: () => getInquiryDetailAPI(req),
    select: (res) => ({
      inquiryDetailList: { ...res, phone: numToStringPhoneNum(res.phone) },
      attachedFileList: res.attachedFileList,
    }),
  });
};
