export const ADD_INQUIRY_FORM = [
  {
    label: "문의 유형",
    key: "category",
    type: "select",
    placeholder: "선택하세요",
    isRequired: true,
  },
  {
    label: "제목",
    key: "title",
    type: "maxInput",
    placeholder: "제목을 입력하세요.",
    isRequired: true,
    maxLength: 30,
  },
  {
    label: "문의내용",
    key: "content",
    type: "textarea",
    placeholder: `문의 유형을 선택후 문의 내용을 자세하게 작성해주세요. 
자세한 내용을 함께 보내주시면 더욱 신속히 답변드릴 수 있습니다. \n
2018년 10월 18일부터 산업안전보건법에 고객응대 근로자 보호조치가 시행됩니다.
고객응대근로자에게 폭언,욕설 등을 하지 말아주세요.`,
    isRequired: true,
  },
  {
    label: "첨부이미지",
    key: "attach",
    type: "file",
    placeholder: "",
    isRequired: false,
  },
  {
    label: "정비소명",
    key: "repairShop",
    type: "input",
    placeholder: "작성자 이름을 입력하세요",
    isRequired: false,
    isDisabled: true,
  },
  {
    label: "작성자명",
    key: "name",
    type: "input",
    placeholder: "작성자 이름을 입력하세요",
    isRequired: true,
  },
  {
    label: "연락처",
    key: "phone",
    type: "phone",
    placeholder: "연락처를 입력하세요",
    isRequired: true,
  },
  {
    label: "이메일 주소",
    key: "email",
    type: "input",
    placeholder: "예) example@truckdoctor.com",
    isRequired: true,
  },
];

export const INQUIRY_FILTER = {
  all: "전체",
  system: "시스템 오류",
  service: "서비스 이용",
  suggestion: "건의사항",
  etc: "기타",
};
export const INQUIRY_TABLE = {
  system: "시스템 오류",
  service: "서비스 이용",
  suggestion: "건의사항",
  etc: "기타",
};
export const INQUIRY_IMG_TABLE = {
  maxCount: 3,
  maxSize: 99999999999999,
};
