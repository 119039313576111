import React, { useState, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { Pagination } from "components";
import { useFilterParams } from "hooks";
import { useGetStaffs } from "services";
import type { GetStaffsQueryModel } from "types";
import ManagementStaffHeader from "./container/header/ManagementStaffHeader";
import StaffsTable from "./container/staffmodal/StaffsTable";
import * as S from "./ManagementStaff.styled";

const SettingEmployee = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedSortOption, setSelectedSortOption] = useState("name");

  const { filters } = useFilterParams<GetStaffsQueryModel["query"]>([
    "sort",
    "q",
    "page",
  ]);
  const { data, isSuccess } = useGetStaffs({
    query: {
      ...filters,
      page: filters.page ?? "1",
      pageSize: filters.pageSize ?? "20",
    },
  });

  const handleSelectSortOption = useCallback(
    (option: string) => {
      setSelectedSortOption(option);
      setSearchParams({
        ...Object.fromEntries(
          [...searchParams].filter((item) => item[0] !== "page"),
        ),
        sort: option,
      });
    },
    [searchParams],
  );

  return (
    <S.Root>
      <ManagementStaffHeader
        selectedSortOption={selectedSortOption}
        handleSelectSortOption={handleSelectSortOption}
      />
      <StaffsTable isDone={!!isSuccess} staffs={data?.staffs} />
      <Pagination pageInfo={data?.pageInfo ?? {}} />
    </S.Root>
  );
};

export default SettingEmployee;
