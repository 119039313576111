import React from "react";
import dayjs from "dayjs";

import { Textarea } from "components";
import * as S from "./CanceledForm.styled";

const CanceledForm = ({ data }: any) => {
  return (
    <dl>
      <S.InfoItem>
        <dt>정비 일시</dt>
        <dd>
          <span>{dayjs(data.planned).format("YYYY.MM.DD (ddd) a hh:mm")}</span>
        </dd>
      </S.InfoItem>
      <S.InfoItem>
        <dt>취소 사유</dt>
        <dd>
          <Textarea
            css={S.textarea}
            value={data.cancelComment ?? ""}
            readOnly={true}
          />
        </dd>
      </S.InfoItem>
    </dl>
  );
};

export default CanceledForm;
