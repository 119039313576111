import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Table } from "components";

interface NoticeTableRowProps {
  rowData: any;
  columnList: any[];
}

const NoticeTableRow = ({ rowData, columnList }: NoticeTableRowProps) => {
  const navigate = useNavigate();
  const handleRouteToDetail = useCallback((id: string) => {
    navigate(id);
  }, []);

  return (
    <Table.ClickRow id={rowData.noticeId} handleClick={handleRouteToDetail}>
      {columnList.map((columnData) => (
        <Table.Cell key={columnData.key}>
          <span>{rowData[columnData.key]}</span>
        </Table.Cell>
      ))}
    </Table.ClickRow>
  );
};

export default NoticeTableRow;
