import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.label<{ selected: boolean }>`
  ${({ selected, theme }) => css`
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
  }

  span {
    ${theme.font.regular_14}
    color: ${theme.color.gray_07};
  }

  div {
    display: flex;
    align-items: center;
    width: 16px;
    height: 16px;
    border: 1px solid ${selected ? theme.color.blue_01 : theme.color.gray_03};
    border-radius: 3px;

    svg {
      width: 100%;
      height: 100%;
      fill: ${selected ? theme.color.blue_01 : theme.color.gray_04};
    }
 `}
`;
