import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash-es";
import type { AxiosError } from "axios";

import {
  downloadFileAPI,
  downloadZipAPI,
  getBrandsAPI,
  getHolidays,
  getMechanicsAPI,
  getPresignedUrlAPI,
} from "apis";
import { downloadFile, downloadZip } from "utils";
import type {
  FetchBrandServerModel,
  FetchHolidayServerModel,
  GetMechanicServerModel,
  S3PresignedServerModel,
} from "types";

export const useGetPresignedUrl = (
  url: string | null,
  options?: UseQueryOptions<
    string | null,
    AxiosError,
    string,
    readonly [string | null]
  >,
) => {
  return useQuery({
    ...options,
    queryKey: [url],
    queryFn: () => getPresignedUrlAPI(url),
  });
};

export const useDownloadFile = (
  req: { [key: string]: string },
  options?: UseQueryOptions<
    S3PresignedServerModel,
    AxiosError,
    S3PresignedServerModel,
    readonly [string]
  >,
) => {
  return useQuery({
    ...options,
    queryKey: [req.file],
    queryFn: () => downloadFileAPI(req.file),
    onSuccess: (data) => {
      const fileName = `${req.name}${req.extension}`;
      downloadFile(data, fileName);
    },
  });
};

export const useDownloadZip = (
  req: { [key: string]: string }[],
  options?: UseQueryOptions<
    {
      files: { [key: string]: string }[];
      zipName: string;
    },
    AxiosError,
    {
      files: { [key: string]: string }[];
      zipName: string;
    },
    readonly [string]
  >,
) => {
  return useQuery({
    ...options,
    queryKey: [req[0].name],
    queryFn: () => downloadZipAPI(req),
    onSuccess: (data) => {
      downloadZip(data.files, data.zipName);
    },
  });
};

export const useGetHolidays = (
  options?: UseQueryOptions<
    FetchHolidayServerModel,
    AxiosError,
    any,
    readonly [string]
  >,
) => {
  return useQuery({
    ...options,
    queryKey: ["holidays"],
    queryFn: () => getHolidays(),
    select: (res) => [...res.holidays.map((holiday) => holiday.locdate)],
  });
};

export const useGetBrands = (
  options?: UseQueryOptions<
    FetchBrandServerModel,
    AxiosError,
    { [key: string]: string },
    readonly [string]
  >,
) => {
  return useQuery({
    ...options,
    queryKey: ["brands"],
    queryFn: () => getBrandsAPI(),
    select: (res) => ({
      ...res.brands.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.brandId]: cur.title,
        }),
        {} as { [key: string]: string },
      ),
    }),
  });
};

export const useGetMechanics = (
  options?: UseQueryOptions<
    GetMechanicServerModel,
    AxiosError,
    { [key: string]: string },
    readonly [string]
  >,
) => {
  return useQuery({
    ...options,
    queryKey: ["mechanics"],
    queryFn: () => getMechanicsAPI(),
    select: (res) => ({
      ...res.staffs.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.staffId]: cur.name,
        }),
        {} as { [key: string]: string },
      ),
    }),
    onSuccess(data) {
      if (isEmpty(data)) {
        window.alert(
          "현재 등록되어있는 정비사가 없습니다.\n [환경설정> 정비사 관리] 에서 정비사를 추가해주세요.",
        );
      }
    },
  });
};
