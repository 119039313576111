import React from "react";

import { ReservationForm } from "components";
import { ReservationInfo, CanceledForm } from "components/reservation";
import { useReservationInfo } from "hooks/index";

interface CanceledProps {
  reservationData: any;
}

const Canceled = ({ reservationData }: CanceledProps) => {
  const reservationInfo = useReservationInfo(reservationData);

  return (
    <ReservationForm title="예약 상세" requestDate={reservationData.requested}>
      <ReservationForm.LeftContent>
        <ReservationInfo
          data={reservationInfo}
          reservationData={reservationData}
          accepted={reservationData.accepted}
          status={reservationData.status}
          isSelf={false}
        />
      </ReservationForm.LeftContent>
      <ReservationForm.RightContent>
        <ReservationForm.Field title="정비 정보">
          <CanceledForm data={reservationData} />
        </ReservationForm.Field>
      </ReservationForm.RightContent>
    </ReservationForm>
  );
};

export default Canceled;
