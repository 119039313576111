import { APPROVED_MAINTENANCE } from "constants/index";

export const RESERVATION_STATUS = {
  planned: { content: "예약 요청", bgColor: "blue_01" },
  accepted: {
    content: "예약 완료",
    bgColor: "blue_01",
  },
  repairing: { content: "정비 중", bgColor: "blue_01" },
  completed: { content: "정비 완료", bgColor: "blue_01" },
  canceled: {
    content: "예약 취소",
    bgColor: "error_01",
  },
};

export const RESERVATION_NOHISTORY_MSG = {
  planned: {
    text: "아직 요청받은 예약이 없어요! \n 운영시간이 제대로 설정됐는지 확인해 보세요 :)",
    link: "운영일 및 시간 설정 >",
  },
  accepted: {
    text: "아직 예약완료 내역이 없어요! \n 요청 받은 예약내역을 확인해보세요!",
    link: "예약 요청 알림 확인 >",
  },
  repairing: {
    text: "아직 정비중인 내역이 없어요! \n 요청받은 예약 또는 예약 완료 내역을 확인해보세요!",
    link: "",
  },
  completed: {
    text: "아직 완료된 정비가 없어요!",
    link: "",
  },
  canceled: {
    text: "취소된 예약이 없어요!",
    link: "",
  },
};

export const RESERVATION_TABINFO = {
  planned: {
    label: "예약요청",
    value: "planned",
    panel: "reservation-planned",
  },
  accepted: {
    label: "예약완료",
    value: "accepted",
    panel: "reservation-accepted",
  },
  repairing: {
    label: "정비중",
    value: "repairing",
    panel: "reservation-repairing",
  },
  completed: {
    label: "정비완료",
    value: "completed",
    panel: "reservation-completed",
  },
  canceled: {
    label: "예약취소",
    value: "canceled",
    panel: "reservation-cancelled",
  },
};

const RESERVATION_PLANNED_TABLE_COLUMNLIST = [
  { key: "planned", label: "정비 시간" },
  { key: "name", label: "고객명" },
  { key: "phone", label: "연락처" },
  { key: "regNum", label: "차량번호" },
  { key: "vin", label: "차대번호" },
  { key: "modelYear", label: "모델 연도" },
  { key: "modelName", label: "차명" },
  { key: "reservationNo", label: "정비번호" },
] as const;

const RESERVATION_ACCEPTED_TABLE_COLUMNLIST = [
  { key: "planned", label: "정비 시간" },
  { key: "name", label: "고객명" },
  { key: "phone", label: "연락처" },
  { key: "regNum", label: "차량번호" },
  { key: "vin", label: "차대번호" },
  { key: "modelYear", label: "모델 연도" },
  { key: "modelName", label: "차명" },
  { key: "isSelf", label: "예약방법" },
  { key: "reservationNo", label: "정비번호" },
] as const;

const RESERVATION_REPAIRING_TABLE_COLUMNLIST = [
  { key: "planned", label: "정비 시간" },
  { key: "name", label: "고객명" },
  { key: "phone", label: "연락처" },
  { key: "regNum", label: "차량번호" },
  { key: "vin", label: "차대번호" },
  { key: "modelYear", label: "모델 연도" },
  { key: "modelName", label: "차명" },
  { key: "isSelf", label: "예약방법" },
  { key: "reservationNo", label: "정비번호" },
];

const RESERVATION_COMPLETED_TABLE_COLUMNLIST = [
  { key: "planned", label: "정비 시간" },
  { key: "name", label: "고객명" },
  { key: "phone", label: "연락처" },
  { key: "regNum", label: "차량번호" },
  { key: "vin", label: "차대번호" },
  { key: "modelYear", label: "모델 연도" },
  { key: "modelName", label: "차명" },
  { key: "isSelf", label: "예약방법" },
  { key: "reservationNo", label: "정비번호" },
] as const;

const RESERVATION_CANCELLED_TABLE_COLUMNLIST = [
  { key: "planned", label: "정비 시간" },
  { key: "name", label: "고객명" },
  { key: "phone", label: "연락처" },
  { key: "regNum", label: "차량번호" },
  { key: "vin", label: "차대번호" },
  { key: "modelYear", label: "모델 연도" },
  { key: "modelName", label: "차명" },
  { key: "isSelf", label: "예약방법" },
  { key: "reservationNo", label: "정비번호" },
] as const;

export const RESERVATION_TABLE_COLUMNLIST = {
  planned: RESERVATION_PLANNED_TABLE_COLUMNLIST,
  accepted: RESERVATION_ACCEPTED_TABLE_COLUMNLIST,
  repairing: RESERVATION_REPAIRING_TABLE_COLUMNLIST,
  completed: RESERVATION_COMPLETED_TABLE_COLUMNLIST,
  canceled: RESERVATION_CANCELLED_TABLE_COLUMNLIST,
} as const;

export const RESERVATION_PLANNED_TABLELIST = {
  reservations: [
    {
      id: 1,
      requested: "2022-06-02T06:51:26.000Z",
      name: "김중진",
      phone: "01054584793",
      regNum: "39거1034",
      vin: "TESTVN00000000001",
      modelYear: "2020",
      modelName: "뉴 MAN TGX 640마력 GX캡 플래그쉽 슈프림 라이온",
      reservationNo: "872970034268",
    },
    {
      id: 2,
      requested: "2022-06-02T06:51:26.000Z",
      name: "김중진",
      phone: "01054584793",
      regNum: "39거1034",
      vin: "TESTVN00000000001",
      modelYear: "2020",
      modelName: "뉴 MAN TGX 640마력 GX캡 플래그쉽 슈프림 라이온",
      reservationNo: "872970034268",
    },
    {
      id: 3,
      requested: "2022-06-02T06:51:26.000Z",
      name: "김중진",
      phone: "01054584793",
      regNum: "39거1034",
      vin: "TESTVN00000000001",
      modelYear: "2020",
      modelName: "뉴 MAN TGX 640마력 GX캡 플래그쉽 슈프림 라이온",
      reservationNo: "872970034268",
    },
    {
      id: 4,
      requested: "2022-06-02T06:51:26.000Z",
      name: "김중진",
      phone: "01054584793",
      regNum: "39거1034",
      vin: "TESTVN00000000001",
      modelYear: "2020",
      modelName: "뉴 MAN TGX 640마력 GX캡 플래그쉽 슈프림 라이온",
      reservationNo: "872970034268",
    },
    {
      id: 5,
      requested: "2022-06-02T06:51:26.000Z",
      name: "김중진",
      phone: "01054584793",
      regNum: "39거1034",
      vin: "TESTVN00000000001",
      modelYear: "2020",
      modelName: "뉴 MAN TGX 640마력 GX캡 플래그쉽 슈프림 라이온",
      reservationNo: "872970034268",
    },
    {
      id: 6,
      requested: "2022-06-02T06:51:26.000Z",
      name: "김중진",
      phone: "01054584793",
      regNum: "39거1034",
      vin: "TESTVN00000000001",
      modelYear: "2020",
      modelName: "뉴 MAN TGX 640마력 GX캡 플래그쉽 슈프림 라이온",
      reservationNo: "872970034268",
    },
    {
      id: 7,
      requested: "2022-06-02T06:51:26.000Z",
      name: "김중진",
      phone: "01054584793",
      regNum: "39거1034",
      vin: "TESTVN00000000001",
      modelYear: "2020",
      modelName: "뉴 MAN TGX 640마력 GX캡 플래그쉽 슈프림 라이온",
      reservationNo: "872970034268",
    },
    {
      id: 8,
      requested: "2022-06-02T06:51:26.000Z",
      name: "김중진",
      phone: "01054584793",
      regNum: "39거1034",
      vin: "TESTVN00000000001",
      modelYear: "2020",
      modelName: "뉴 MAN TGX 640마력 GX캡 플래그쉽 슈프림 라이온",
      reservationNo: "872970034268",
    },
    {
      id: 9,
      requested: "2022-06-02T06:51:26.000Z",
      name: "김중진",
      phone: "01054584793",
      regNum: "39거1034",
      vin: "TESTVN00000000001",
      modelYear: "2020",
      modelName: "뉴 MAN TGX 640마력 GX캡 플래그쉽 슈프림 라이온",
      reservationNo: "872970034268",
    },
    {
      id: 10,
      requested: "2022-06-02T06:51:26.000Z",
      name: "김중진",
      phone: "01054584793",
      regNum: "39거1034",
      vin: "TESTVN00000000001",
      modelYear: "2020",
      modelName: "뉴 MAN TGX 640마력 GX캡 플래그쉽 슈프림 라이온",
      reservationNo: "872970034268",
    },
  ],
  pageInfo: {
    totalData: 109,
    currentPage: 1,
    dataPerPage: 10,
    totalPages: 11,
    startRow: 20,
  },
};

export const RESERVATION_DEFAULT_INFO = [
  {
    key: "status",
    label: "처리상태",
    type: "text",
    placeholder: "",
    isRequired: false,
  },
  {
    key: "reservationNo",
    label: "정비번호",
    type: "text",
    placeholder: "",
    isRequired: false,
  },
  {
    key: "isSelf",
    label: "예약방법",
    type: "text",
    placeholder: "",
    isRequired: false,
  },
];

export const RESERVATION_CUSTOMER_INFO = [
  {
    key: "name",
    label: "이름",
    type: "text",
    placeholder: "예약자 이름을 입력하세요",
    isRequired: true,
  },
  {
    key: "phone",
    label: "연락처",
    type: "phone",
    placeholder: "-없이 숫자만 입력하세요",
    isRequired: true,
  },
  {
    key: "regNum",
    label: "차량 번호",
    placeholder: "예) 경기 12나 3456/ 123나 1234",
    type: "regNum",
    isRequired: true,
    isReadOnly: true,
  },
  {
    key: "modelName",
    label: "차명",
    type: "text",
    placeholder: "예) 마이티2 더블탭(터보)",
    isRequired: true,
  },
  {
    key: "modelYear",
    label: "모델연도",
    type: "text",
    placeholder: "모델연도를 입력하세요",
  },
  {
    key: "brandId",
    label: "차량 브랜드",
    type: "select",
    placeholder: "브랜드를 선택하세요",
    labelTable: {},
    isRequired: true,
  },
  {
    key: "vin",
    label: "차대번호",
    type: "text",
    placeholder: "예) KMFGE17LP2C169537",
    isRequired: true,
  },
  {
    key: "mileage",
    label: "누적 주행거리",
    type: "text",
    placeholder: "주행거리를 입력하세요",
    suffixText: "km",
  },
];

export const RESERVATION_REQUEST_INFO = [
  {
    key: "axle",
    label: "요청 정비 축",
    type: "radio",
    radioItems: [
      { label: "1축", value: 1 },
      { label: "2축", value: 2 },
      { label: "3축", value: 3 },
      { label: "4축", value: 4 },
      { label: "5축", value: 5 },
    ],
    /* NOTE: 아래의 속성들은 radio 타입에 필요하지 않은 것인데 타입에러 때문에 임시로 추가하였는데 TS 마이그레이션 작업 이후 어떻게 처리해야할지 논의 필요*/
    isRequired: false,
    suffixText: "",
    labelTable: [],
    hourLabel: "",
    minKey: "",
  },
  {
    key: "seat",
    label: "요청 정비 위치",
    type: "radio",
    isRequired: false,
    radioItems: [
      { label: "운전석", value: "driver" },
      { label: "조수석", value: "passenger" },
    ],
  },
  {
    key: "userComment",
    label: "요청 내용",
    isRequired: false,
    type: "textarea",
    placeholder: "예약 내용을 입력하세요",
  },
];

export const ATTACHED_FILE = [
  {
    key: "presignedExternals",
    label: "차량 외부사진",
    type: "외부",
  },
  {
    key: "presignedInternals",
    label: "차량 내부사진",
    type: "내부",
  },
  {
    key: "presignedDashboard",
    label: "계기판 사진",
    type: "계기판",
  },
  {
    key: "presignedAudio",
    label: "녹음 파일",
    type: "음성",
  },
];

export const ACCEPTED_FORM = [
  {
    key: "plannedDate",
    hourKey: "plannedTimeHour",
    minKey: "plannedTimeMin",
    label: "정비 일시",
    type: "dateTime",
    isRequired: true,
  },
  {
    key: "estimatedTimeHour",
    minKey: "estimatedTimeMin",
    label: "예상 정비 시간",
    hourLabel: "시간",
    type: "time",
    isRequired: true,
  },
];

export const APPROVED_MAINTENANCE_RADIO = {
  key: "approvedMaintenance",
  radioItems: [
    { label: "승인", value: APPROVED_MAINTENANCE.APPROVED },
    { label: "예약 변경", value: APPROVED_MAINTENANCE.CHANGE },
    { label: "취소", value: APPROVED_MAINTENANCE.CANCEL },
  ],
  isRequired: true,
};

export const REPAIRING_FORM = [
  {
    key: "staffIds",
    label: "담당 정비사",
    type: "selectArray",
    isRequired: true,
    placeholder: "선택",
  },
  {
    key: "actualTimeHour",
    minKey: "actualTimeMin",
    label: "실제 정비 시간",
    hourLabel: "시간",
    type: "time",
    isRequired: true,
  },
  {
    key: "cost",
    label: "총 청구 금액",
    type: "text",
    placeholder: "실제 청구 금액",
    isRequired: true,
    suffixText: "원",
  },
  {
    key: "shopComment",
    label: "정비 소견",
    type: "textarea",
    placeholder: "정비 소견을 입력하세요",
  },
];

export const SELF_RESERVATION_BASIC_INFO = [
  {
    key: "name",
    label: "이름",
    type: "text",
    isRequired: true,
    placeholder: "예약자 이름을 입력하세요",
  },
  {
    key: "phone",
    label: "연락처",
    type: "phone",
    isRequired: true,
    placeholder: "- 없이 숫자만 입력하세요",
  },
  {
    key: "originRegNum",
    label: "차량 번호",
    type: "text",
    isRequired: true,
    readOnly: true,
    placeholder: "",
  },

  {
    key: "modelName",
    label: "차량명",
    type: "text",
    isRequired: true,
    placeholder: "예) 마이티2 더블탭(터보)",
  },
  {
    key: "modelYear",
    label: "모델연도",
    type: "text",
    isRequired: false,
    placeholder: "모델연도를 입력하세요",
  },
  {
    key: "brandId",
    label: "차량 브랜드",
    type: "select",
    isRequired: true,
    placeholder: "브랜드를 선택하세요",
    labelTable: {},
  },
  {
    key: "vin",
    label: "차대번호",
    type: "text",
    isRequired: false,
    placeholder: "예) KMFGE17LP2C169537",
  },
  {
    key: "mileage",
    label: "누적 주행거리",
    type: "text",
    isRequired: false,
    placeholder: "주행거리를 입력하세요",
    suffixText: "Km",
  },
];

export const SELF_RESERVATION_ADDITION_INFO = [
  {
    key: "memo",
    label: "메모",
    type: "textarea",
    placeholder: "메모를 입력하세요",
    isRequired: false,
  },
];

export const SELF_RESERVATION_TIME_INFO = [
  {
    key: "plannedDate",
    hourKey: "plannedTimeHour",
    minKey: "plannedTimeMin",
    label: "정비 일시",
    type: "dateTime",
    placeholder: "날짜를 선택하세요",
    isRequired: true,
  },

  {
    key: "estimatedTimeHour",
    minKey: "estimatedTimeMin",
    label: "예상 정비 시간",
    hourLabel: "시간",
    type: "time",
    isRequired: true,
  },
];

export const ACCEPTED_BUTTON_TABLE = {
  [APPROVED_MAINTENANCE.APPROVED]: "정비 시작",
  [APPROVED_MAINTENANCE.CHANGE]: "예약 변경",
  [APPROVED_MAINTENANCE.CANCEL]: "완료",
};

export const PLANNED_BUTTON_TABLE = {
  [APPROVED_MAINTENANCE.APPROVED]: "예약 완료",
  [APPROVED_MAINTENANCE.CHANGE]: "예약 변경",
  [APPROVED_MAINTENANCE.CANCEL]: "완료",
};

export const SEAT_TABLE = {
  driver: "운전석 측",
  passenger: "조수석 측",
};
