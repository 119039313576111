import React from "react";

import { Button } from "components";
import CustomerEditModal from "pages/customer/container/modal/customerEdit/CustomerEditModal";
import CustomerDeleteModal from "pages/customer/container/modal/customerDelete/CustomerDeleteModal";
import { useModal } from "hooks";
import { CUSTOMER_DETAIL_INFO_TABLE_DATA } from "assets";
import type { GetCustomerDetailClientModel } from "types";
import * as S from "./CustomersInfo.styled";

interface CustomersInfoProps {
  customerInfo?: GetCustomerDetailClientModel;
}

const CustomersInfo = ({ customerInfo }: CustomersInfoProps) => {
  const noShowCount = customerInfo?.["noshows"];
  const {
    modalRef: editModalRef,
    handleModalOpen: handleEditModalOpen,
    handleModalClose: handleEditModalClose,
  } = useModal();

  const {
    modalRef: delModalRef,
    handleModalOpen: handleDelModalOpen,
    handleModalAllClose: handleDelModalAllClose,
  } = useModal();

  const InfoItem = ({
    label,
    value,
  }: {
    label: string;
    value: keyof GetCustomerDetailClientModel;
  }) => {
    if (!noShowCount) return null;

    return (
      <S.ItemBox noShowCount={noShowCount}>
        <dt>{label}</dt>
        <dd id={value}>{customerInfo[value] ?? "-"}</dd>
      </S.ItemBox>
    );
  };

  return (
    <S.RootStyle>
      <S.InfoList>
        {CUSTOMER_DETAIL_INFO_TABLE_DATA.map((item) => (
          <InfoItem key={item.key} label={item.label} value={item.key} />
        ))}
      </S.InfoList>
      <S.ButtonGroup>
        <Button
          css={S.addButton}
          onClick={handleEditModalOpen(
            <CustomerEditModal
              ref={editModalRef}
              data={customerInfo}
              handleModalClose={handleEditModalClose}
            />,
          )}
        >
          수정
        </Button>
        <Button
          css={S.deleteButton}
          variant="secondarySmall"
          onClick={handleDelModalOpen(
            <CustomerDeleteModal
              ref={delModalRef}
              customerId={customerInfo?.customerId!}
              handleDelModalAllClose={handleDelModalAllClose}
            />,
          )}
        >
          삭제
        </Button>
      </S.ButtonGroup>
    </S.RootStyle>
  );
};

export default CustomersInfo;
