export const VALID_TARGET_TYPE = Object.freeze({
  EMAIL: "email",
  PW: "password",
  PW_C: "passwordConfirm",
  PHONE: "phoneNum",
  TIMEOUT: "timeout",
  ACCOUNT: "account",
});

export const VALID_MESSAGE = Object.freeze({
  COMPLETED: "차량조회 완료.",
  REQUIRED: "필수 입력 정보입니다.",
  REQUIRED_NO_ASTERISK: "필수 입력 정보입니다.",
  EMAIL: "올바른 형식의 이메일 주소를 입력하세요.",
  LOGIN_PW: "*입력하신 정보를 다시 확인해주세요.",
  PW: "*영문,숫자,특수문자를 활용한 8-16자의 비밀번호를 입력하세요.",
  PW_C: "비밀번호가 일치하지 않습니다.",
  FAIL: "*입력하신 정보를 다시 확인해 주세요.",
  FAIL_NO_ASTERISK: "입력하신 정보를 다시 확인해주세요.",
  CHANGE_PW: "*기존과 동일한 비밀번호입니다. 다른 비밀번호를 입력하세요.",
  SEND_AUTH_LINK:
    "가입이력이 없는 이메일입니다. 입력하신 정보를 다시 확인해주세요.",
  DATA: "*입력하신 정보를 다시 확인해 주세요.",
  DATA_NO_ASTERISK: "입력하신 정보를 다시 확인해 주세요.",
  REGNUM: "올바른 형식으로 입력해주세요",
  REGNUM_EXAM: `형식에 맞게 입력해주세요.\n(예) 서울 OO가OOOO/ OO나OOOO/ OOO가OOOO)`,
  REGNUM_SELECT: "차량 조회를 완료해주세요.",
  PHONE_NUM: "7-8자리 숫자를 입력해주세요",
  TELEPHONE_NUM: "8-12자리 숫자를 입력해주세요",
  PHONE_FORMAT: "전화번호 형식에 맞춰 입력해주세요",
  VIN: "17자의 차대번호를 입력해주세요",
  VIN_INCORRECT: "올바르지 않은 차대번호 입니다. 다시 한번 확인해주세요",
  VIN_DUPLICATE: "중복된 차대번호 입니다. 다시 한번 확인해주세요",
  OPERATION_TIME: "운영 시작 및 종료 시간을 입력하세요.",
});
