import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const DiagnosisSolutionPreviewWrapper = styled.article`
  ${({ theme }) => css`
    position: relative;
    min-width: 822px;
    min-height: 686px;
    border: 1px solid ${theme.color.gray_02};
    border-radius: 3px;
    padding: 28px 32px;
    background-color: ${theme.color.white};
  `}
`;

export const Title = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_16};
    margin-bottom: 12px;
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.span<{ isOrigin: boolean }>`
  ${({ theme, isOrigin }) => css`
    ${theme.font.medium_13};
    margin-bottom: 6px;
    border-radius: 4px;
    padding: 4px 9px;
    color: ${isOrigin ? theme.color.gray_05 : theme.color.blue_01};
    background-color: ${isOrigin ? theme.color.gray_02 : theme.color.blue_02};
  `}
`;

export const Preview = styled.img<{ isWidthMoreThanHeight: boolean }>`
  ${({ theme, isWidthMoreThanHeight }) => css`
    max-width: ${!isWidthMoreThanHeight ? 240 : 426}px;
    max-height: ${isWidthMoreThanHeight ? 240 : 584}px;
    margin-bottom: 20px;
    border: 1px solid ${theme.color.gray_03};
    border-radius: 4px;
    object-fit: cover;
  `}
`;

export const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
