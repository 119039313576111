import React from "react";

import { Table } from "components";
import { EVENT_TABLE_LIST } from "assets";
import { dummyEventList } from "assets/dummy/cs";
import EventTableRow from "../row/EventTableRow";
import * as S from "./EventTable.styled";

const EventTable = () => {
  return (
    <Table css={S.root} title="이벤트 리스트">
      <Table.Head columnList={EVENT_TABLE_LIST} />
      <Table.Body css={S.tableBody} text="등록된 이벤트가 없습니다">
        {dummyEventList.map((rowData) => (
          <EventTableRow
            key={rowData.id}
            rowData={rowData}
            columnList={EVENT_TABLE_LIST}
          />
        ))}
      </Table.Body>
    </Table>
  );
};
export default EventTable;
