import React from "react";

import { CloseIconImg } from "assets";

interface CloseButtonProps {
  type?: string;
  className?: string;
  onClick: () => void;
}

const CloseButton = ({ className, onClick }: CloseButtonProps) => {
  return (
    <button
      type="button"
      className={className}
      aria-label="닫기"
      onClick={onClick}
    >
      <CloseIconImg />
    </button>
  );
};

export default CloseButton;
