import React, { forwardRef } from "react";

import { Input, HasNoneData } from "components";
import { useDropdownInput, useOnClickOutside } from "hooks";
import { VEHICLE_NODATA_MSG, VEHICLE_NODATA_DESC_INFO } from "assets";
import * as S from "./DropdownInput.styled";

interface DropdownInputProps {
  className?: string;
  id?: string;
  name?: string;
  refEl: any;
  value: string;
  validError?: string;
  placeholder?: string;
  searchValue: string;
  onChange?: any;
  onBlur?: any;
  handleSelect: any;
  handleSearch: any;
}

const DropdownInput = forwardRef(
  (
    {
      className,
      refEl,
      value,
      searchValue,
      handleSelect,
      handleSearch,
      ...restProps
    }: DropdownInputProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const {
      listBoxRef,
      listItemRef,
      isUnfold,
      selectIdx,
      dropdownDatas,
      handleFocus,
      handleBlur,
      handleSelectOption,
      handleKeyDown,
    } = useDropdownInput(value, searchValue, handleSelect, handleSearch);

    useOnClickOutside(listBoxRef, handleBlur, refEl);

    return (
      <S.Root>
        <Input
          ref={ref}
          aria-haspopup="listbox"
          aria-controls="list"
          aria-expanded={isUnfold}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          {...restProps}
        />
        {isUnfold && (
          <S.DropdownWrapper
            ref={listBoxRef}
            id="list"
            role="listbox"
            aria-label="dropdown list"
          >
            {value && !!dropdownDatas?.length ? (
              dropdownDatas.map((item, i) => {
                const coloredCode = item.regNum.replaceAll(
                  value,
                  `<span style="color: #388CFF;">${value}</span>`,
                );

                return (
                  <S.DropdownItem
                    key={i}
                    ref={(el) => (listItemRef.current[i] = el)}
                  >
                    <S.DropdownItemBtn
                      type="button"
                      data-focus={i === selectIdx}
                      onClick={handleSelectOption(item.regNum)}
                    >
                      <S.RegNumInfo>
                        <span
                          dangerouslySetInnerHTML={{ __html: coloredCode }}
                        ></span>
                      </S.RegNumInfo>
                      <S.CustomerInfo>
                        <span>{item.name}</span>
                        <span>{item.phone}</span>
                      </S.CustomerInfo>
                    </S.DropdownItemBtn>
                  </S.DropdownItem>
                );
              })
            ) : (
              <HasNoneData css={S.noData} asProp="li" text={VEHICLE_NODATA_MSG}>
                <pre>{VEHICLE_NODATA_DESC_INFO}</pre>
              </HasNoneData>
            )}
          </S.DropdownWrapper>
        )}
      </S.Root>
    );
  },
);

DropdownInput.displayName = "DropdownInput";

export default DropdownInput;
