import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const InfoItem = styled.div<{ isRequired: boolean }>`
  ${({ isRequired, theme }) => css`
    ${theme.font.regular_14};
    display: grid;
    grid-template-columns: 132px 1fr;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    & > span,
    & > label {
      color: ${theme.color.gray_09};

      &::after {
        content: ${isRequired ? "'*'" : null};
        display: inline-block;
        margin-left: 3px;
        font-size: 14px;
        color: ${theme.color.error_01};
      }
    }
  `}
`;

export const DateInput = styled.input`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    width: 100%;
    height: 34px;
    border: 1px solid ${theme.color.gray_03};
    border-radius: 4px;
    padding: 10px 12px;

    &[readonly] {
      color: ${theme.color.gray_08};
      background-color: ${theme.color.gray_01};
    }
  `}
`;

// export const CustomSelect = styled(Select)`
//   width: 184px;
// `;

export const validText = css`
  position: static;
  grid-column: 2 / -1;
  margin-top: 6px;
  white-space: normal;
`;
