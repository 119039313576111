import React from "react";

import { DetailModal, Select, Textarea } from "components";
import {
  INPUT_MAX_LENGTH,
  REVIEW_SORT_OPTION_TABLE,
  VALID_MESSAGE,
} from "constants/index";
import useReportReveiwForm from "../hooks/useReportReview";
import * as S from "./ReportReviewModal.styled";

const REASON_REPORT_SELECT_ID = "reason-report-select";
const REASON_REPORT_DETAIL_ID = "reason-report-detail";

interface ReportReviewModalProps {
  reviewId: string;
  handleModalClose: () => void;
}

const ReportReviewModal = React.forwardRef(
  (
    { reviewId, handleModalClose }: ReportReviewModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const handleClose = () => {
      initReportForm();
      handleModalClose();
    };

    const {
      selectedCategory,
      reason,
      isSubmitDisabled,
      validError,
      handleSelectCategory,
      handleChangeReason,
      handleSubmit,
      initReportForm,
      handleOnBlurReason,
    } = useReportReveiwForm({ reviewId, handleClose });

    return (
      <DetailModal
        ref={ref}
        css={S.detailModal}
        title="리뷰 신고"
        cancelBtnName="취소"
        posBtnName="등록"
        isPosDisabled={!!isSubmitDisabled}
        posFn={handleSubmit}
      >
        <S.Content>
          <S.ReasonReportSelectWrapper>
            <S.Label htmlFor={REASON_REPORT_SELECT_ID} isRequired>
              신고사유
            </S.Label>
            <Select
              css={S.select}
              id={REASON_REPORT_SELECT_ID}
              selectedOption={selectedCategory}
              labelTable={REVIEW_SORT_OPTION_TABLE}
              placeholder="사유를 선택하세요"
              onSelect={handleSelectCategory}
            >
              {Object.entries(REVIEW_SORT_OPTION_TABLE).map(
                ([value, label]) => (
                  <Select.Option key={label} value={value}>
                    {label}
                  </Select.Option>
                ),
              )}
            </Select>
          </S.ReasonReportSelectWrapper>
          <S.ReasonReportDetailWrapper>
            <S.Label htmlFor={REASON_REPORT_DETAIL_ID} isRequired>
              신고사유 상세
            </S.Label>
            <S.ReasonReportTextAreaWrapper>
              <Textarea
                css={S.textarea}
                id={REASON_REPORT_DETAIL_ID}
                value={reason}
                validError={validError}
                maxLength={INPUT_MAX_LENGTH.REPORT_REVIEW_CONTENT}
                placeholder="내용을 입력해주세요"
                readOnly={selectedCategory !== "directInput"}
                onChange={handleChangeReason}
                onBlur={handleOnBlurReason}
              />
              {validError && (
                <S.ValidText>{VALID_MESSAGE.REQUIRED_NO_ASTERISK}</S.ValidText>
              )}
            </S.ReasonReportTextAreaWrapper>
          </S.ReasonReportDetailWrapper>
        </S.Content>
      </DetailModal>
    );
  },
);

ReportReviewModal.displayName = "ReportReviewModal";

export default ReportReviewModal;
