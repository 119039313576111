import { useState, useEffect } from "react";

import { getNormalizedFile } from "utils";

const useImagePreview = (file: File, isCompress = false) => {
  const [fileDataUrl, setFileDataUrl] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let fileReader: any,
      isCancel = false;

    if (file) {
      fileReader = new FileReader();
      setLoading(true);
      fileReader.onload = (e: any) => {
        const result = e.target.result;
        if (result && !isCancel) {
          setFileDataUrl(result);
          setLoading(false);
        }
      };
      if (isCompress) {
        getNormalizedFile(file)
          .then((normalizedFile) => fileReader.readAsDataURL(normalizedFile))
          .catch((err) => console.log(err));
      } else {
        fileReader.readAsDataURL(file);
      }
    } else {
      setFileDataUrl("");
    }

    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  return [fileDataUrl, loading];
};

export default useImagePreview;
