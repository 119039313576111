import React from "react";

import { useDialog } from "hooks/index";

interface DialogButtonProps {
  className?: string;
  children: React.ReactNode;
  hasRqstdReservation?: boolean;
  popup: any;
}

const DialogButton = ({ children, className, popup }: DialogButtonProps) => {
  const {
    isDialogOpen,
    dialogRef,
    handleToggleDialogOpen,
    handleDialogOpen,
    handleDialogClose,
  } = useDialog();

  return (
    <>
      <button
        type="button"
        className={className}
        onClick={handleToggleDialogOpen}
      >
        {children}
      </button>
      {typeof popup === "function" &&
        popup(isDialogOpen, dialogRef, handleDialogClose, handleDialogOpen)}
    </>
  );
};

export default DialogButton;
