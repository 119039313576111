import React from "react";
import { useNavigate } from "react-router-dom";

import { ConfirmModal } from "components";
import { useToast } from "hooks";
import { useDeleteCustomer } from "services";
import { PATH, TOAST_MSG } from "constants/index";
interface CustomerDeleteModalProps {
  customerId: string;
  handleDelModalAllClose: () => void;
}

const CustomerDeleteModal = React.forwardRef(
  (
    { customerId, handleDelModalAllClose }: CustomerDeleteModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const navigate = useNavigate();

    const { mutate: deleteCustomerMutate } = useDeleteCustomer();
    const { addToast } = useToast();

    const handleDeleteCustomer = () => {
      deleteCustomerMutate(customerId, {
        onSuccess: () => {
          addToast(TOAST_MSG.SUCCESS.DELETE_CUSTOMER_DONE);
          handleDelModalAllClose();
          navigate(PATH.customer);
        },
      });
    };

    return (
      <ConfirmModal
        ref={ref}
        title="고객 삭제"
        desc={`삭제내역은 복구가 불가합니다.\n정보를 정말 삭제하시겠습니까?`}
        btnType="alert"
        activeBtnName="삭제"
        cancelBtnName="취소"
        activeFn={handleDeleteCustomer}
      />
    );
  },
);

CustomerDeleteModal.displayName = "CustomerDeleteModal";

export default CustomerDeleteModal;
