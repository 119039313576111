import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import * as P from "pages";
import { AuthRoute, PublicRoute, SideLayout } from "components";
import { useHoliday } from "hooks";
import { CONFIGURATION_SITE_MAP, CUSTOMER_SERVICE_SITE_MAP } from "assets";
import { PATH } from "constants/index";

interface RouterProps {
  children: React.ReactNode;
}

export default function CustomRouter({ children }: RouterProps) {
  useHoliday();

  return (
    <BrowserRouter>
      {children}
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path={PATH.login} element={<P.Login />} />
          <Route path={PATH.password} element={<P.Password />} />
        </Route>
        <Route element={<AuthRoute />}>
          <Route path={PATH.root} element={<P.Reservation />} />
          <Route path="addReservation">
            <Route path="" element={<P.AddReservation />} />
          </Route>
          <Route path="reservation">
            <Route path="" element={<P.Reservation />} />
            <Route path="detail" element={<P.ReservationDetail />} />
            <Route path="schedule" element={<P.ReservationSchedule />} />
            <Route path="diagnosisSolution" element={<P.DiagnosisSolution />} />
          </Route>
          <Route path="customer">
            <Route path="" element={<P.Customer />} />
            <Route path="detail" element={<P.CustomerDetail />} />
          </Route>
          <Route path="review">
            <Route path="" element={<P.Review />} />
          </Route>
          <Route path="configuration">
            <Route
              path=""
              element={
                <SideLayout
                  title="환경설정"
                  siteMap={CONFIGURATION_SITE_MAP}
                  isCsCenter={false}
                />
              }
            >
              <Route path="repairshop" element={<P.SettingRepairshop />} />
              <Route path="staff" element={<P.ManagementStaff />} />
              <Route path="changePassword" element={<P.ChangePassword />} />
              <Route path="inquiry">
                <Route path="" element={<P.SettingInquiry />} />
                <Route path="create" element={<P.SettingInquiryCreate />} />
                <Route path="detail" element={<P.SettingInquiryDetail />} />
              </Route>
            </Route>
          </Route>
          <Route path="cs">
            <Route
              path=""
              element={
                <SideLayout
                  title="고객센터"
                  siteMap={CUSTOMER_SERVICE_SITE_MAP}
                  isCsCenter
                />
              }
            >
              <Route path="notice">
                <Route path="" element={<P.Notice />} />
                <Route path=":id" element={<P.NoticeDetail />} />
              </Route>
              <Route path="event" element={<P.Event />} />
              <Route path="faq" element={<P.FaQ />} />
              <Route path="inquiry">
                <Route path="" element={<P.SettingInquiry />} />
                <Route path="create" element={<P.SettingInquiryCreate />} />
                <Route path="detail" element={<P.SettingInquiryDetail />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<P.NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
