import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.form`
  width: 100%;
`;

export const ContentTop = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    height: 50px;

    & > label {
      ${theme.font.medium_14};
      display: flex;
      align-items: center;
      height: 100%;
      letter-spacing: -0.01em;
      color: ${theme.color.gray_10};
    }
  `}
`;

export const textarea = css`
  height: inherit;
  margin-bottom: 8px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const cancelButton = css`
  margin-right: 4px;
`;
