import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  position: relative;
`;

export const Label = styled.label<{ isRequired?: boolean }>`
  ${({ isRequired, theme }) => css`
    ${theme.font.regular_14};
    display: block;
    margin-bottom: 2px;
    color: ${theme.color.gray_07};

    &::after {
      content: ${isRequired ? "'*'" : null};
      margin-left: 2px;
      color: ${theme.color.error_01};
    }
  `}
`;

export const ValidText = styled.p`
  ${({ theme }) => css`
    margin-top: 4px;
    ${theme.font.regular_12};
    color: ${theme.color.error_01};
  `}
`;

export const AddressWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px 22px;

  input:read-only {
    background-color: inherit;
  }

  & > input {
    grid-row-start: 2;
  }
`;
const mixinInput = (theme: Theme, validError: boolean) => css`
  height: 34px;
  border: ${validError
    ? `1px solid ${theme.color.error_01}`
    : `1px solid ${theme.color.gray_03}`};

  &[disabled] {
    color: ${theme.color.gray_07};
    background-color: ${theme.color.gray_02};
  }
`;

export const input = (theme: Theme, validError?: any) => css`
  ${mixinInput(theme, validError)};
`;

export const lengthInput = (theme: Theme, validError: any) => css`
  & > input {
    ${mixinInput(theme, validError)};
  }
`;

export const AddressSearch = styled.div`
  display: grid;
  grid-template-columns: 1fr 82px;
  column-gap: 6px;
`;

export const selectAddListSelect = css`
  width: 232px;
  height: 34px;
`;

export const selectItems = css`
  grid-template-columns: repeat(4, 102px);
  margin-left: 0;
`;

interface CheckboxWrapperProps {
  checked: boolean;
  disabled?: boolean;
}

export const CheckboxWrapper = styled.label<CheckboxWrapperProps>`
  ${({ checked, disabled, theme }) => css`
    display: flex;
    align-items: center;
    width: max-content;
    cursor: ${disabled ? "unset" : "pointer"};

    & > input {
      display: none;
    }

    & > svg {
      margin-right: 9px;

      .border {
        stroke: ${checked ? theme.color.blue_01 : theme.color.gray_03};
      }
      .content {
        fill: ${checked ? theme.color.blue_01 : theme.color.gray_03};
      }
    }
  `}
`;

export const selectAddList = css`
  flex-direction: column;
  row-gap: 5px;

  & > div > button {
    width: 232px;
  }
`;
