import { theme } from "style";

// 마지막 컬럼만 빼고 넣어주면 계산해줍니다.
export const calcTableWidth = (arr: any) => {
  return makeTableColumnWidthArr(theme.size.MAIN_MIN_WIDTH, arr);
};

export const calcSmallTableWidth = (arr: any) => {
  return makeTableColumnWidthArr(theme.size.SMALL_MIN_WIDTH, arr);
};

const makeTableColumnWidthArr = (designTableWidth: any, arr: any[]) => {
  designTableWidth = Number(designTableWidth.replace("px", ""));

  const tableColumnArr = arr.map(
    (designColumnWidth) =>
      `${(designColumnWidth / (designTableWidth - 20)) * 100}%`, // Table 컴포넌트 padding-left: 20px
  );
  tableColumnArr.push("1fr");
  return tableColumnArr.join(" ");
};

export const calcTableBodyMinHeight = (designTableHeight = "596px") => {
  return `${designTableHeight} - 40.5px - ${theme.size.PAGINATION_HEIGHT}`;
};

// table body를 제외한 모든 height 뺄셈
export const calcTableBodyMaxHeight = (arr: any[]) => {
  const defaultHeightArr = [
    theme.size.HEADER_HEIGHT, // Header height
    theme.size.MAIN_PADDING_TOP, // main padding-top
    "40.5px", // table th
    theme.size.PAGINATION_HEIGHT, // pagination height
    theme.size.MAIN_PADDING_BOTTOM, // main padding-bottom
    theme.size.FOOTER_HEIGHT, // Footer height
  ];
  const exceptTableMaxHeight = [...defaultHeightArr, ...arr].join(" + ");

  return `100vh - (${exceptTableMaxHeight})`;
};
