import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

const useTab = <T>(init: Partial<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const tab = (searchParams.get("tab") as T) || init;

  const handleChangeTab = useCallback(
    (tabName: any) => () => {
      setSearchParams({
        ...Object.fromEntries(
          [...searchParams].filter((item) => item[0] !== "page"),
        ),
        tab: tabName,
      });
    },
    [searchParams],
  );

  return { tab, handleChangeTab };
};

export default useTab;
