import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  display: flex;
`;
export const Wrapper = styled.div<{ labelLength?: number }>`
  ${({ labelLength }) => css`
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      margin-right: ${labelLength === 1 ? "20px" : "7px"};
    }
  `}
`;

export const timeInput = css`
  width: 50px;
  text-align: center;
`;

export const hourSelect = (isNoneData = false, isReverse = false) => css`
  width: 70px;

  & > button {
    padding: 0 8px 0 12px;
  }

  & > ul {
    display: ${isNoneData && "none"};
    top: ${isReverse && "-136px"};
    max-height: 190px;
  }
`;

export const minSelect = css`
  width: 76px;

  & > button {
    padding: 0 8px 0 12px;
  }
`;

export const SuffixText = styled.label`
  display: inline-block;
  margin-left: 6px;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.02em;
`;
