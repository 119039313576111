import React, { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";

import { Button, MobileInput } from "components";
import { useWorkTime, useToast } from "hooks";
import {
  useGetReapirshop,
  useUpdateRepairshopInfo,
  useGetBrands,
} from "services";
import { repairShopInfo } from "store";
import { userStorage } from "utils";
import { REPAIR_PREFIX_KEY } from "assets";
import { TOAST_MSG, INPUT_MAX_LENGTH } from "constants/index";
import type { UpdateRepairshopQueryModel, RepairShopInfo } from "types";
import { ImageContainer, WorkTime, LabelContent } from "./container";
import useUpdateRepairshop from "./hooks/useUpdateRepairshop";
import * as S from "./SettingRepairshop.styled";

export default function SettingRepairshop() {
  const setRepairShopInfo = useSetRecoilState<RepairShopInfo>(repairShopInfo);

  const { data: repairshopInfo } = useGetReapirshop();
  const { data: brands } = useGetBrands();
  const { mutate: updateRepairshopMutate } = useUpdateRepairshopInfo();
  const { addToast } = useToast();

  const originAdditonalImgLength = [
    repairshopInfo?.extra1,
    repairshopInfo?.extra2,
    repairshopInfo?.extra3,
    repairshopInfo?.extra4,
  ].filter(Boolean).length;

  const {
    inputRefs,
    form,
    checkRequired,
    handleChangeForm,
    handleBlurForm,
    handleSelectAddr,
    handleAddBrand,
    handleDeleteBrand,
    handleDeleteImage,
    handleChangeImage,
  } = useUpdateRepairshop(repairshopInfo);

  const {
    workTimeRef,
    workDateRef,
    operationTimeObj,
    selectedDay,
    setSelectedDay,
    getFormattedOperationTime,
    handleChangeDay,
    handleToggleTime,
    handleChangeIsWork,
    handleChangeTime,
  } = useWorkTime(form.operationTime.value);

  const handleSubmit = useCallback(() => {
    const operationTime = getFormattedOperationTime(operationTimeObj);
    const extras = [...form.extras.value];

    if (checkRequired()) return;

    for (let i = extras.length; i <= originAdditonalImgLength; i++) {
      extras.push({ status: "default", file: "", url: "" });
    }

    setSelectedDay("");

    const thumbnailFile = form.thumbnail.value[0].file;
    const thumbnailKey = (typeof thumbnailFile !== "string" &&
      `${REPAIR_PREFIX_KEY}${uuidv4()}.${
        thumbnailFile.type.split("/")[1]
      }`) as string;
    const additionalFileList = form.extras.value.map((item) => item.file);

    const req: UpdateRepairshopQueryModel = {
      body: {
        name: form.name.value.trim(),
        zipcode: form.zipcode.value,
        addr: form.addr.value,
        addrDetail: form.addrDetail.value.trim(),
        ...(form.districtCode.value && {
          districtCode: form.districtCode.value,
        }),
        phone: `${form.phoneLocal.value}${form.phone.value}`,
        intro: form.intro.value.trim(),
        emailYn: form.emailYn.value,
        brands: form.brands.value.map((brand) => ({ brandId: brand })),
        operationTime,
        ...(form.latitude.value !== "" && { latitude: +form.latitude.value }),
        ...(form.longitude.value !== "" && {
          longitude: +form.longitude.value,
        }),
        thumbnail: form.thumbnail.value[0].status,
        ...(extras[0]?.status && { extra1: extras[0].status }),
        ...(extras[1]?.status && { extra2: extras[1].status }),
        ...(extras[2]?.status && { extra3: extras[2].status }),
        ...(extras[3]?.status && { extra4: extras[3].status }),
        memo: form.memo.value.trim(),
      },
      thumbnailKey,
      thumbnailFile,
      additionalFileList,
    };

    if (req.body.thumbnail === "default" || !req.body.thumbnail) {
      addToast(TOAST_MSG.FAIL.ADD_THUMBNAIL_IMG_ERROR);
      return;
    }

    updateRepairshopMutate(req, {
      onSuccess: (_, variables) => {
        const { thumbnailKey } = variables;

        const userInfo = {
          ...userStorage.load(),
          ...(thumbnailKey && { thumbnailKey }),
          name: req.body.name,
        };

        setRepairShopInfo(userInfo);

        addToast(TOAST_MSG.SUCCESS.UPDATE_SHOP_INFO_DONE);
      },
    });
  }, [form, operationTimeObj]);

  const isDisabledWorkTime =
    Object.values(operationTimeObj).filter((dates: any) => {
      const errorDate =
        Object.values(dates).filter((date: any) => {
          if (typeof date === "object") {
            return !!date.error;
          } else {
            return false;
          }
        }).length > 0;
      return errorDate;
    }).length > 0;

  const isSubmitDisabled =
    Object.values(form).filter((data) => data.error !== "").length > 0 ||
    isDisabledWorkTime;

  return (
    <S.Root>
      <S.Header>
        <h3>정비소 정보</h3>
      </S.Header>
      <S.Container>
        <ImageContainer>
          <ImageContainer.Profile
            image={form.thumbnail.value[0]}
            isRequired
            handleChangeImage={handleChangeImage}
            handleDeleteImage={handleDeleteImage}
          />
          <ImageContainer.Additional
            images={form.extras.value}
            handleChangeImage={handleChangeImage}
            handleDeleteImage={handleDeleteImage}
          />
        </ImageContainer>
        <LabelContent id="id" label="아이디">
          <LabelContent.Input
            css={S.inputLabelContent}
            disabled
            value={form.email.value}
          />
        </LabelContent>
        <LabelContent
          id="name"
          label="정비소명"
          isRequired
          err={form.name.error}
        >
          <LabelContent.Input
            css={S.inputLabelContent}
            ref={(el) => (inputRefs.current["name"] = el)}
            name="name"
            placeholder="정비소명을 입력해주세요"
            value={form.name.value}
            onChange={handleChangeForm}
            onBlur={handleBlurForm}
          />
        </LabelContent>
        <LabelContent id="address" label="주소" isRequired>
          <LabelContent.AddressInput
            zipCode={form.zipcode.value}
            address={form.addr.value}
            addrDetail={form.addrDetail.value}
            onChange={handleChangeForm}
            onSelect={handleSelectAddr}
          />
        </LabelContent>
        <LabelContent
          id="phone"
          label="유선전화"
          isRequired
          err={form.phone.error}
        >
          <MobileInput
            ref={inputRefs}
            isRequired
            type="mobileAreaDirect"
            data={form}
            phoneKey="phone"
            phoneLocalKey="phoneLocal"
            placeholder="유선 전화를 입력해주세요"
            readOnly={false}
            handleChange={handleChangeForm}
            handleBlur={handleBlurForm}
          />
        </LabelContent>
        <LabelContent
          id="brands"
          label="담당 브랜드"
          isRequired
          err={form.brands.error}
        >
          <LabelContent.AddList
            ref={(el) => (inputRefs.current["brands"] = el)}
            labelTable={brands}
            selectedItems={form.brands.value}
            name="brands"
            placeholder="브랜드를 선택하세요"
            onSelect={handleAddBrand}
            onDeleteItem={handleDeleteBrand}
            onBlur={handleBlurForm}
          />
        </LabelContent>
        <LabelContent label="운영일 / 시간">
          <S.WorkTimeDesc>
            운영일을 선택하고 운행시간을 입력하세요. (운영일은 파란색, 휴무일은
            하얀색으로 표시됩니다.)
          </S.WorkTimeDesc>
          <WorkTime
            css={S.workTime}
            operationTimeObj={operationTimeObj}
            day={selectedDay}
          >
            <WorkTime.WorkDate
              ref={workDateRef}
              haserror={isDisabledWorkTime}
              memoValue={form.memo}
              handleChangeMemo={handleChangeForm}
              handleBlurMemo={handleBlurForm}
              handleChangeDay={handleChangeDay}
            />
            {selectedDay && (
              <S.WorkContentWrapper
                ref={workTimeRef}
                haserror={isDisabledWorkTime}
              >
                <WorkTime.WorkPossible
                  handleChangeIsWork={handleChangeIsWork}
                />
                <WorkTime.DayTime handleChangeTime={handleChangeTime} />
                <WorkTime.NightTime
                  handleChangeTime={handleChangeTime}
                  handleToggleTime={handleToggleTime}
                />
                <WorkTime.BreakTime
                  handleChangeTime={handleChangeTime}
                  handleToggleTime={handleToggleTime}
                />
              </S.WorkContentWrapper>
            )}
          </WorkTime>
        </LabelContent>
        <LabelContent id="intro" label="소개">
          <LabelContent.Textarea
            name="intro"
            maxLength={INPUT_MAX_LENGTH.INTRODUCTION}
            placeholder="정비소 소개를 입력하세요"
            value={form.intro.value}
            onChange={handleChangeForm}
          />
        </LabelContent>
        <LabelContent
          css={S.marketingLabelContent}
          id="marketing"
          label="마케팅 정보 수신 동의"
        >
          <LabelContent.Checkbox
            name="emailYn"
            checked={form.emailYn.value === "y"}
            onChange={handleChangeForm}
          >
            <S.MarketingCheckText>이메일</S.MarketingCheckText>
          </LabelContent.Checkbox>
        </LabelContent>
      </S.Container>
      <S.ButtonWrapper>
        <Button
          variant="primaryMedium"
          disabled={isSubmitDisabled}
          onClick={handleSubmit}
        >
          완료
        </Button>
      </S.ButtonWrapper>
    </S.Root>
  );
}
