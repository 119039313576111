import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.footer`
  ${({ theme }) => css`
    position: fixed;
    bottom: 0;
    left: 0;
    min-width: 100%;
    height: ${theme.size.FOOTER_HEIGHT};
    border-top: 1px solid ${theme.color.gray_02};
    padding-left: ${theme.size.NAV_WIDTH};
    background-color: ${theme.color.background};
    z-index: ${theme.zIndex.HEADER_FOOTER};
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.size.LAYOUT_PADDING_SIDE};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    min-width: ${theme.size.MAIN_MIN_WIDTH};
    max-width: ${theme.size.MAIN_MAX_WIDTH};
    margin: 0 auto;
    font-size: 14px;
    color: ${theme.color.gray_05};
  `}
`;

export const LinkWrapper = styled.ul`
  display: flex;
  margin-top: 12px;

  & li {
    white-space: nowrap;
    &:not(:last-child) {
      margin-right: 35px;
    }
  }
`;

export const Copyrights = styled.div`
  margin-top: 12px;
  white-space: nowrap;
`;
