import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const ReservationInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  margin-bottom: 14px;
`;

export const TotalCnt = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_16};

    span:nth-of-type(1) {
      margin-right: 6px;
    }

    span:nth-of-type(2) {
      ${theme.font.medium_16};
    }
  `}
`;

export const downloadBtn = css`
  padding: 0 10px;
`;
