import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface ExternalLinkProps {
  variant: "secondarySmall" | "ghostPrimary" | "ghostSecondary";
}

export const ExternalLink = styled.a<ExternalLinkProps>`
  ${({ theme, variant }) => css`
    ${variant === "secondarySmall" && theme.button.secondarySmall};
    ${variant === "ghostPrimary" && theme.button.ghostPrimary};
    ${variant === "ghostSecondary" && theme.button.ghostSecondary};
  `}
`;
