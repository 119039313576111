import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const InfoItem = styled.div<{ isRequired: boolean }>`
  ${({ isRequired, theme }) => css`
    ${theme.font.regular_14};
    display: grid;
    grid-template-columns: 132px 1fr;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    & > label {
      color: ${theme.color.gray_09};

      &::after {
        content: ${isRequired ? "'*'" : null};
        display: inline-block;
        margin-left: 3px;
        font-size: 14px;
        color: ${theme.color.error_01};
      }
    }
  `}
`;

export const dateSelect = css`
  display: flex;
  flex-direction: column;
  width: 184px;

  && + div {
    & > dialog {
      top: -330px;
      left: -184px;

      &::before,
      &::after {
        right: unset;
        left: 18px;
      }
    }
  }
`;

export const plannedTime = css`
  width: 100%;
  margin-top: 6px;

  & > div:first-of-type {
    margin-right: 6px;
  }
`;

export const estimatedTime = css`
  & > div:first-of-type {
    margin-right: 14px;
  }

  & > div:last-of-type {
    & > div {
      width: 90px;
    }
  }
`;

export const select = css`
  width: 184px;
`;

export const plannedMin = css`
  & > div {
    width: 70px;
  }
`;

export const validText = css`
  position: static;
  grid-column: 2 / -1;
  margin-top: 6px;
  white-space: normal;
`;
