import React from "react";

import * as S from "./RangeSlider.styled";

interface RangeSliderProps {
  className?: string;
  handleChangeRange?: any;
  min?: string;
  max?: string;
  step?: string;
  value?: any;
  restProps?: any;
  onChange?: any;
}

const RangeSlider = ({
  handleChangeRange,
  className,
  ...restProps
}: RangeSliderProps) => {
  const { value, min, max } = restProps as any;
  const handleChange = (event: any) => {
    const { value } = event.target;
    handleChangeRange(value);
  };

  const currentRatio = value - +min;
  const totalRatio = +max - +min;
  const size =
    (currentRatio < 0.01 ? 0 : (currentRatio / totalRatio) * 100) + "%";

  return (
    <S.Root size={size} className={className}>
      <input type="range" onChange={handleChange} {...restProps} />
    </S.Root>
  );
};

export default RangeSlider;
