import { useState, useCallback } from "react";

const useImgDeg = () => {
  const [deg, setDeg] = useState(0);

  const handleClickRotate = useCallback(() => {
    setDeg((prev) => (prev === 270 ? 0 : prev + 90));
  }, []);

  return {
    deg,
    setDeg,
    handleClickRotate,
  };
};

export default useImgDeg;
