export const deleteObjectKey = (obj: any, key: string) => {
  const result = { ...obj };
  delete result[key];

  return result;
};

export const deleteObjectKeyWithEmptyValue = (obj: any) => {
  for (const key in obj) {
    if (obj[key] !== 0 && !obj[key]) {
      delete obj[key];
    }
  }

  return obj;
};

export const isEmptyObject = (obj: any) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};
