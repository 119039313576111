import React, { useState, forwardRef } from "react";

import { VisuallyHidden } from "components";
import { EyeIconImg } from "assets";
import * as S from "./LoginPasswordInput.styled";

interface LoginPasswordInputProps {
  ref?: React.ForwardedRef<HTMLInputElement>;
  className?: string;
  isPasswordEyeIcon?: boolean;
  isPasswordConfirmEyeIcon?: boolean;
  label: string;
  id: string;
  name?: string;
  value?: string;
  placeholder?: string;
  validError?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const LoginPasswordInput = (
  {
    id,
    label,
    className,
    isPasswordEyeIcon,
    isPasswordConfirmEyeIcon,
    ...restProps
  }: LoginPasswordInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleToggleShowPassword = () => {
    setIsShowPassword((prev) => !prev);
  };

  const uuid = `input-${id}`;

  return (
    <S.Root className={className}>
      <VisuallyHidden as="label" htmlFor={uuid}>
        {label}
      </VisuallyHidden>
      <S.Input
        ref={ref}
        type={isShowPassword ? "text" : "password"}
        id={uuid}
        {...restProps}
        autoComplete="off"
      />
      <S.ToggleButton
        type="button"
        aria-label={isShowPassword ? "비밀번호 숨기기" : "비밀번호 보기"}
        aria-pressed={isShowPassword}
        onClick={handleToggleShowPassword}
      >
        {isPasswordEyeIcon && (
          <EyeIconImg
            css={(theme) => S.eyeIcon(theme, isShowPassword ? 1 : 0)}
          />
        )}
        {isPasswordConfirmEyeIcon && (
          <EyeIconImg
            css={(theme) => S.eyeIcon(theme, isShowPassword ? 1 : 0)}
          />
        )}
      </S.ToggleButton>
    </S.Root>
  );
};

export default forwardRef(LoginPasswordInput);
