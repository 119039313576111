import axios from "axios";

import { renewAccessTokenAPI } from "apis";

interface Fn {
  (accessToken: string): void;
}

export class TokenService {
  #isAlreadyFetchingAccessToken = false;
  #subscribers: Fn[] = [];
  #auth;
  constructor(auth: any) {
    this.#auth = auth;
  }

  async resetTokenAndReattemptRequest(error: any) {
    try {
      const { response: errorResponse } = error;
      const refreshToken = this.#auth.refreshToken;

      if (!refreshToken) {
        this.expireSession();
        return Promise.reject(error);
      }
      const retryOriginalRequest = new Promise((resolve) => {
        this.addSubscriber((accessToken: string) => {
          errorResponse.config.headers.Authorization = `Bearer ${accessToken}`;
          resolve(axios(errorResponse.config));
        });
      });
      if (!this.#isAlreadyFetchingAccessToken) {
        try {
          this.#isAlreadyFetchingAccessToken = true;
          const { accessToken: newAccessToken } = await renewAccessTokenAPI(
            refreshToken,
          );
          if (!newAccessToken) {
            return Promise.reject(error);
          }
          this.#auth.changeAccessToken(newAccessToken);
          this.#isAlreadyFetchingAccessToken = false;
          this.onAccessTokenFetched(newAccessToken);
        } catch (err: any) {
          const { response: errorResponse } = err;

          const authErrorCode = ["INVALID_REFRESH_TOKEN"];
          if (authErrorCode.includes(errorResponse?.data.code)) {
            this.expireSession();
            return Promise.reject(err);
          }
        }
      }
      return retryOriginalRequest;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  getAccessToken() {
    return this.#auth.accessToken;
  }

  onAccessTokenFetched(accessToken: string) {
    this.#subscribers.forEach((callback) => callback(accessToken));
    this.#subscribers = [];
  }

  addSubscriber(callback: Fn) {
    this.#subscribers.push(callback);
  }

  expireSession() {
    this.#auth.refreshToken && alert("로그인 세션이 만료되었습니다.");
    this.#auth.clear();
    window.location.reload();
  }
}
