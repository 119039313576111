import React from "react";
import { useSearchParams } from "react-router-dom";

import { useGetReservationsDetail } from "services";
import Planned from "../reservationForm/planned/Planned";
import Accepted from "../reservationForm/accepted/Accepted";
import Repairing from "../reservationForm/repairing/Repairing";
import Completed from "../reservationForm/completed/Completed";
import Canceled from "../reservationForm/cancled/Canceled";

const ReservationDetailForm = () => {
  const [searchParams] = useSearchParams();

  const { data: reservationData } = useGetReservationsDetail(
    searchParams.get("reservationId")!,
  );

  const form = (status: any) => {
    switch (status) {
      case "planned":
        return <Planned reservationData={reservationData} />;

      case "accepted":
        return <Accepted reservationData={reservationData} />;

      case "repairing":
        return <Repairing reservationData={reservationData} />;

      case "completed":
        return <Completed reservationData={reservationData} />;

      case "canceled":
        return <Canceled reservationData={reservationData} />;

      default:
        return null;
    }
  };

  return form(reservationData?.status ?? "");
};

export default ReservationDetailForm;
