import React, { useRef } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

import { useScrollXFixedEl } from "hooks";
import { LINKS } from "assets";
import { PATH } from "constants/index";
import * as S from "./Footer.styled";

const Footer = () => {
  const footerRef = useRef(null);
  useScrollXFixedEl(footerRef);

  return (
    <S.Root ref={footerRef}>
      <S.Container>
        <S.Content>
          <S.LinkWrapper>
            {LINKS.map((item) => (
              <li key={item.name}>
                <a href={item.path} target="_blank" rel="noreferrer">
                  {item.name}
                </a>
              </li>
            ))}
            <Link to={PATH.notice}>고객센터</Link>
          </S.LinkWrapper>
          <S.Copyrights>
            © {dayjs().format("YYYY")} Coconut Silo. All rights reserved.
          </S.Copyrights>
        </S.Content>
      </S.Container>
    </S.Root>
  );
};

export default Footer;
