import type {
  GetReviewQueryModel,
  GetReviewServerModel,
  GetFreqCommentsServerModel,
  CreateFreqCommentQueryModel,
  UpdateFreqCommentQueryModel,
  CreateReviewQueryModel,
  UpdateReviewQueryModel,
  DeleteReviewQueryModel,
} from "types";
import { ax } from "../axios";

export const getReviewsAPI = async (req: GetReviewQueryModel) => {
  const { data } = await ax.get<GetReviewServerModel>("/reviews", {
    params: req.query,
  });

  return data;
};

export const createCommentByReviewIdAPI = (req: CreateReviewQueryModel) => {
  return ax.post(`/reviews/${req.reviewId}`, req.body);
};

export const updateCommentByReviewIdAPI = (req: UpdateReviewQueryModel) => {
  return ax.put(`/reviews/${req.reviewId}`, req.body);
};

export const deleteCommentByReviewIdAPI = (id: string) => {
  return ax.delete(`/reviews/${id}`);
};

export const getFreqCommentsAPI = async () => {
  const { data } = await ax.get<GetFreqCommentsServerModel>(
    `/reviews/replies/templates`,
  );

  return data;
};

export const createFreqCommentAPI = (req: CreateFreqCommentQueryModel) => {
  return ax.post("/reviews/replies/templates", req.query);
};

export const updateFreqCommentsAPI = (req: UpdateFreqCommentQueryModel) => {
  return ax.put(`/reviews/replies/templates/${req.templateId}`, req.body);
};

export const deleteFreqCommentAPI = (id: string) => {
  return ax.delete(`/reviews/replies/templates/${id}`);
};

export const reportReviewAPI = (req: DeleteReviewQueryModel) => {
  return ax.post(`/reviews/report/${req.reviewId}`, req.body);
};
