import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.li<{ disabled?: boolean }>`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-height: 112px;
    height: 100%;
    padding: 8px 0 14px 10px;
    background-color: ${theme.color.white};

    & > time {
      ${theme.font.medium_16};
      display: flex;
      align-items: center;
      height: 26px;
      padding-right: 17px;
      color: ${theme.color.gray_09};
    }

    &[disabled] > time {
      color: ${theme.color.gray_04};
    }

    &:nth-of-type(7n + 1):not([disabled]) > time {
      color: ${theme.color.sunday};
    }

    &:nth-of-type(7n + 7):not([disabled]) > time {
      color: ${theme.color.saturday};
    }

    &[aria-current="date"] > time {
      ${theme.font.bold_16};
      color: ${theme.color.blue_01};
    }

    &[data-status="true"]:not([disabled]) > time {
      color: ${theme.color.sunday};
    }
  `}
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 4px;
  width: 100%;
  margin-top: 8px;
  padding-right: 12px;
`;

export const DateButton = styled.button`
  position: relative;
  padding: 5px 6px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:not([disabled]):hover {
    &::before {
      border-radius: 5px;
      background-color: ${({ theme }) => theme.color.blue_02};
    }
  }
`;
