export const RESERVATION_STATUS_TABLE = Object.freeze({
  planned: "예약 요청",
  accepted: "예약 완료",
  repairing: "정비 중",
  completed: "정비 완료",
  canceled: "예약 취소",
});

export const RESERVATION_STATUS = [
  "planned",
  "accepted",
  "repairing",
  "completed",
  "canceled",
];

export const TOOLTIP_MSG = Object.freeze({
  RESERVATION_UNAVALIABLE_TIME:
    "*어플 내 예약 불가 시간을 설정합니다.\n해당 설정과 관계없이 전화 예약은 가능합니다.",
});
