import React from "react";

import * as S from "./NoshowCnt.styled";

interface NoshowCntProps {
  className?: string;
  noshowCnt: number;
}

const NoshowCnt = ({ className, noshowCnt }: NoshowCntProps) => {
  return (
    <S.NoShowText className={className} isNoShow={noshowCnt > 0}>
      노쇼 이력 : {`${noshowCnt}회`}
    </S.NoShowText>
  );
};

export default NoshowCnt;
