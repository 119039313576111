import React from "react";

import { ReservationForm } from "components";
import { ReservationInfo, CompletedForm } from "components/reservation";
import { useReservationInfo } from "hooks";

interface CompletedProps {
  reservationData: any;
}

const Completed = ({ reservationData }: CompletedProps) => {
  const reservationInfo = useReservationInfo(reservationData);

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <ReservationForm
      title="예약 상세"
      requestDate={reservationData.requested}
      handleSubmit={handleSubmit}
    >
      <ReservationForm.LeftContent>
        <ReservationInfo
          data={reservationInfo}
          reservationData={reservationData}
          accepted={reservationData.accepted}
          status={reservationData.status}
          isSelf={false}
        />
      </ReservationForm.LeftContent>
      <ReservationForm.RightContent>
        <ReservationForm.Field title="정비 정보">
          <CompletedForm data={reservationData} />
        </ReservationForm.Field>
      </ReservationForm.RightContent>
    </ReservationForm>
  );
};

export default Completed;
