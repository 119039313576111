import React, { useRef, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import { isEmpty } from "lodash-es";
import dayjs from "dayjs";

import { TruckSearchResultModal } from "components";
import { useCheckRequired, useToast, useModal } from "hooks";
import {
  useGetCustomerByRegNum,
  useFetchCustomerNoShowCnt,
  useCreateReservation,
} from "services";
import {
  vinFormatter,
  numericOnly,
  checkRegNumValidation,
  checkVinValidation,
  estimateHourFormatter,
  dateFormatter,
  formatPlannedDateTime,
  getPhoneInfo,
  findSearchedCustomerInfo,
  checkDrivingDistance,
  checkVinExact,
  findCustomerInfoByRegNum,
  formatUtcISODateTime,
  checkPhoneValidation,
  checkInputLength,
  checkExpectationTime,
  getCommaThousandUnitInputAfterChange,
  getCommaThousandUnitAfterDeleteMarker,
} from "utils";
import { TOAST_MSG, VALID_MESSAGE } from "constants/index";
import type { GetCustomerInfoByRegNumServerModel } from "types";

const INIT_STATE = {
  originRegNum: { value: "", error: "" },
  regNum: { value: "", error: "", step: 1 }, // step: 1) 오류, step: 2) 차량 조회 O
  regNumSearchText: { value: "", error: "" },
  name: { value: "", error: "" },
  phoneLocal: { value: "010", error: "" },
  phone: { value: "", error: "" },
  vin: { value: "", error: "" },
  modelName: { value: "", error: "" },
  brandId: { value: "", error: "" },
  modelYear: { value: "", error: "" },
  mileage: { value: "", error: "" },
  axle: { value: "", error: "" },
  seat: { value: "", error: "" },
  planned: { value: "", error: "" },
  plannedDate: {
    value: "",
    error: "",
  },
  plannedTimeHour: { value: "", error: "" },
  plannedTimeMin: { value: "", error: "" },
  estimatedTime: { value: "", error: "" },
  estimatedTimeHour: { value: "", error: "" },
  estimatedTimeMin: { value: "", error: "" },
  userComment: { value: "", error: "" },
  memo: { value: "", error: "" },
};

const useAddReservation = () => {
  const navigate = useNavigate();

  const inputRefs = useRef<any>({});
  const isInited = useRef(false);

  const [form, setForm] = useImmer(INIT_STATE);
  const [cursor, setCursor] = useImmer({
    mileage: -1,
    phone: -1,
  });
  const [customerInfo, setCustomerInfo] =
    useState<GetCustomerInfoByRegNumServerModel>([]);
  const [noshowCnt, setNoshowCnt] = useState(0);
  const [isSearchedRegNum, setIsSearchedRegNum] = useState(false);

  const { mutate: createReservationMutate } = useCreateReservation();
  const { data: customerSearchInfos } = useGetCustomerByRegNum();
  const {
    isFetching,
    isSuccess,
    data: noshowInfo,
    refetch,
  } = useFetchCustomerNoShowCnt(`${form.phoneLocal.value}${form.phone.value}`, {
    enabled:
      form.phone.value.length >= 7 &&
      document.activeElement !== inputRefs.current["phone"],
  });
  const { addToast } = useToast();
  const { modalRef, handleModalOpen, handleModalClose } = useModal();
  const [checkRequired] = useCheckRequired(
    inputRefs,
    form,
    setForm,
    VALID_MESSAGE.REQUIRED_NO_ASTERISK,
  );

  const isSubmitDisabled =
    Object.values(form).filter((data) => data.error !== "").length > 0;
  const isDisabledRegNum =
    form.regNum.error && form.regNum.error !== VALID_MESSAGE.REGNUM_SELECT;

  const handleSubmit = useCallback(() => {
    if (form.regNum.step < 2) {
      let error = "";

      if (!form.regNum.value) {
        error = VALID_MESSAGE.REQUIRED;
      } else if (!checkRegNumValidation(form.regNum.value)) {
        error = VALID_MESSAGE.REGNUM_EXAM;
      } else {
        error = VALID_MESSAGE.REGNUM_SELECT;
        addToast(TOAST_MSG.FAIL.NONE_CHECK_CAR_INFO);
      }

      setForm((draft) => {
        draft["regNum"].step = 1;
        draft["regNum"].error = error;
      });

      return;
    }

    if (checkRequired()) return;

    setForm((draft: any) => {
      for (const key of Object.keys(form)) {
        draft[key].error = "";
      }
    });

    const formatPlannedDate = formatPlannedDateTime(
      form.plannedDate.value,
      form.plannedTimeHour.value,
      form.plannedTimeMin.value,
    );

    const body = {
      regNum: form.regNum.value.replace(/\s/gi, ""),
      name: form.name.value.trim(),
      phone: `${form.phoneLocal.value}${form.phone.value}`,
      modelName: form.modelName.value.trim(),
      brandId: form.brandId.value,
      ...(form.vin.value && { vin: form.vin.value }),
      ...(form.modelYear.value && { modelYear: form.modelYear.value }),
      planned: formatUtcISODateTime(formatPlannedDate, "YYYY-MM-DD HH:mm"),
      estimatedTime: `${form.estimatedTimeHour.value}:${form.estimatedTimeMin.value}`,
      ...(form.mileage.value && { mileage: numericOnly(form.mileage.value) }),
      ...(form.axle.value && { axle: form.axle.value }),
      ...(form.seat.value && { seat: form.seat.value }),
      ...(form.userComment.value && {
        userComment: !form.userComment.value.trim()
          ? undefined
          : form.userComment.value.trim(),
      }),
      ...(form.memo.value && {
        memo: !form.userComment.value.trim()
          ? undefined
          : form.memo.value.trim(),
      }),
    };

    const url = `/reservation?tab=accepted&date=${dateFormatter(
      form.plannedDate.value,
    )}`;

    const req = { body };

    createReservationMutate(req, {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.ADD_RESERVATION_DONE);
        navigate(url);
      },
      onError: (err) => {
        switch (err.response?.data.code) {
          case "INVALID_YEAR":
            setForm((draft) => {
              draft.modelYear.error = VALID_MESSAGE.FAIL_NO_ASTERISK;
            });
            inputRefs.current["modelYear"].focus();
            break;
        }
      },
    });
  }, [form]);

  const handleChangeForm = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
      const name = event?.target?.name ?? key;
      let value = event?.target?.value ?? event;
      let error = "";

      switch (name) {
        case "name":
          value = value.replaceAll(" ", "");
          if (!checkInputLength("NAME", value)) return;

          break;

        case "modelName":
          if (!checkInputLength("MODEL_NAME", value)) return;

          break;

        case "regNum":
          if (!checkInputLength("REGNUM", value)) return;

          value = value.trim();

          if (form[name].step > 1) {
            setForm((draft) => {
              draft[name].step = 1;
            });
          }
          if (form["regNumSearchText"].value) {
            setForm((draft) => {
              draft["regNumSearchText"].value = "";
            });
          }
          break;

        case "phone": {
          if (!checkInputLength("PHONE", value)) return;

          value = value.replace(/[^0-9]/g, "");

          if (form[name].error && value.length < 7) {
            error = VALID_MESSAGE.PHONE_NUM;
          }

          break;
        }
        case "modelYear":
          value = numericOnly(value);
          if (!checkInputLength("MODEL_YEAR", value)) return;
          break;

        case "vin":
          value = vinFormatter(value);
          if (!checkInputLength("VIN", value)) return;

          if (form[name].error) {
            if (!checkVinExact(value) && value.length) {
              error = VALID_MESSAGE.VIN;
            } else if (!checkVinValidation(value)) {
              error = VALID_MESSAGE.VIN_INCORRECT;
            }
          }
          break;

        case "mileage": {
          if (!checkDrivingDistance(value)) return;

          const mileageInputInfo = getCommaThousandUnitInputAfterChange(
            event,
            value,
          );
          if (!mileageInputInfo) return;

          value = mileageInputInfo.value;
          setCursor((draft) => {
            draft.mileage = mileageInputInfo.cursor;
          });

          break;
        }

        case "plannedDate":
          value = dayjs(value).format("YYYY.MM.DD");
          break;

        case "userComment":
        case "memo":
          if (!checkInputLength("COMMON_TEXTAREA", value)) return;
          break;

        case "estimatedTimeHour":
          if (!checkExpectationTime(+value)) return;
          value = `${estimateHourFormatter(value)}`;
          if (+value === 0) {
            setForm((draft) => {
              draft.estimatedTimeMin.value = "30";
              draft.estimatedTimeMin.error = "";
            });
          }
          break;
      }

      setForm((draft: any) => {
        draft[name].value = value;
        draft[name].error = error;
      });
    },
    [form],
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch ((e.target as HTMLInputElement).name) {
      case "mileage":
        {
          const mileageInfo = getCommaThousandUnitAfterDeleteMarker(e);
          if (!mileageInfo) return;

          setForm((draft) => {
            draft.mileage.value = mileageInfo.value;
          });
          setCursor((draft) => {
            draft.mileage = mileageInfo.cursor;
          });
        }
        break;
    }
  };

  const handleBlurForm = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const {
        value,
        name,
        dataset: { required },
      } = event.target;
      let error = "";

      if (required && form[name as keyof typeof form].value === "") {
        setForm((draft: any) => {
          draft[name].error = VALID_MESSAGE.REQUIRED_NO_ASTERISK;
        });
        return;
      }

      switch (name) {
        case "regNum":
          if (!checkRegNumValidation(value)) {
            error = VALID_MESSAGE.REGNUM;
          }
          break;

        case "phone":
          if (!checkPhoneValidation(value)) {
            error = VALID_MESSAGE.PHONE_NUM;
          } else {
            refetch();
          }
          break;

        case "vin":
          if (!checkVinExact(value) && value.length) {
            error = VALID_MESSAGE.VIN;
          } else if (!checkVinValidation(value)) {
            error = VALID_MESSAGE.VIN_INCORRECT;
          }
          break;
        case "modelName":
          if (!value.trim()) {
            error = VALID_MESSAGE.REQUIRED_NO_ASTERISK;
          }
          break;
      }

      setForm((draft: any) => {
        draft[name].error = error;
      });
    },
    [form],
  );

  const handleSelectDate = useCallback(
    (name: any) => (date: any) => {
      handleChangeForm(date, name);
    },
    [],
  );

  const handleSelectRegNum = useCallback(
    (regNum: string, selected = false) => {
      if (selected) {
        setForm((draft) => {
          draft["regNumSearchText"].value = regNum;
        });
      } else {
        setForm((draft) => {
          draft.regNum.value = regNum;
          draft.regNum.error = "";
          draft.regNum.step = 1;
        });
      }
    },
    [form],
  );

  const handleOverwriteInfo = () => {
    setForm((draft) => {
      draft.originRegNum.value = form.regNum.value;
      draft.regNum.value = form.regNum.value;
      draft.regNum.step = 2;
      draft.regNum.error = "";
    });

    const searchedCustomerInfo = findCustomerInfoByRegNum(
      customerSearchInfos ?? [],
      form.regNum.value,
    );

    setCustomerInfo(searchedCustomerInfo);
    addToast(
      `${form.regNum.value} ${TOAST_MSG.SUCCESS.SEARCH_CUSTOMER_BY_REGNUM_DONE}`,
    );
  };

  const changeRegNum = (regNum: string) => {
    setForm((draft) => {
      draft.originRegNum.value = regNum;
      draft.regNum.value = regNum;
      draft.regNum.step = 2;
      draft.regNum.error = "";
    });
  };

  const handleModalActive = () => {
    handleOverwriteInfo();
    handleModalClose();
  };

  const handleModalCancel = (regNum: string) => () => {
    changeRegNum(regNum);
    handleModalClose();
  };

  const handleModalCloseBtn = () => {
    setForm((draft) => {
      draft.regNum.step = 1;
      draft.regNum.error = VALID_MESSAGE.REGNUM_SELECT;
    });
    handleModalClose();
  };

  const handleSearchCustomer = useCallback(
    (e: any) => {
      e.preventDefault();

      const regNum =
        e.type === "click"
          ? form.regNum.value
          : form.regNumSearchText.value || form.regNum.value;

      if (!regNum) {
        setForm((draft) => {
          draft.regNum.error = VALID_MESSAGE.REQUIRED_NO_ASTERISK;
        });
        return;
      }

      if (!checkRegNumValidation(regNum)) {
        setForm((draft) => {
          draft.regNum.error = VALID_MESSAGE.REGNUM_EXAM;
        });
        return;
      }

      const searchedCustomerInfo = findCustomerInfoByRegNum(
        customerSearchInfos ?? [],
        regNum,
      );

      if (isEmpty(searchedCustomerInfo)) {
        changeRegNum(regNum);
        addToast(`${regNum} ${TOAST_MSG.FAIL.SEARCH_CUSTOMER_BY_REGNUM_ERROR}`);

        if (!checkUserInfo()) {
          setCustomerInfo(searchedCustomerInfo);
        }
      } else {
        if (checkUserInfo()) {
          handleModalOpen(
            <TruckSearchResultModal
              ref={modalRef}
              handleActive={handleModalActive}
              handleCancel={handleModalCancel(regNum)}
              handleClose={handleModalCloseBtn}
            />,
          )();
        } else {
          setCustomerInfo(searchedCustomerInfo);
          changeRegNum(regNum);
          addToast(
            `${regNum} ${TOAST_MSG.SUCCESS.SEARCH_CUSTOMER_BY_REGNUM_DONE}`,
          );
        }
      }

      setIsSearchedRegNum(true);
    },
    [form],
  );

  const checkUserInfo = () => {
    const userInfoLabelList = [
      "name",
      "phone",
      "modelName",
      "modelYear",
      "brandId",
    ];

    return !!userInfoLabelList.filter(
      (item) => form[item as keyof typeof form].value,
    ).length;
  };

  useEffect(() => {
    if (isEmpty(customerInfo)) return;

    const searchedCustomer = findSearchedCustomerInfo(
      customerInfo,
      form.regNum.value,
    );

    if (!searchedCustomer) return;

    const { areaCode, phone } = getPhoneInfo(searchedCustomer.phone || "");

    setForm((draft) => {
      draft.name.value = searchedCustomer.name || "";
      draft.name.error = "";
      draft.phoneLocal.value = areaCode ?? "";
      draft.phone.value = phone;
      draft.phone.error = "";
      draft.vin.value = searchedCustomer.vin || "";
      draft.vin.error = "";
      draft.modelName.value = searchedCustomer.modelName || "";
      draft.modelName.error = "";
      draft.brandId.value = searchedCustomer.brandId || "";
      draft.brandId.error = "";
      draft.modelYear.value = searchedCustomer.modelYear || "";
      draft.modelYear.error = "";
    });

    if (searchedCustomer.phone) {
      refetch();
    }
  }, [customerInfo]);

  useEffect(() => {
    const mileageInput = inputRefs.current.mileage;

    mileageInput?.setSelectionRange(cursor.mileage, cursor.mileage);
  }, [inputRefs.current.mileage, cursor.mileage, form.mileage.value]);

  useEffect(() => {
    if (isSuccess && noshowInfo) {
      setNoshowCnt(noshowInfo.noshows);
    }
  }, [isFetching]);

  return {
    isInited,
    inputRefs,
    form,
    noshowCnt,
    isSubmitDisabled,
    isSearchedRegNum,
    isDisabledRegNum,
    handleKeyDown,
    handleChangeForm,
    handleBlurForm,
    handleSubmit,
    handleSelectDate,
    handleSearchCustomer,
    handleSelectRegNum,
  };
};

export default useAddReservation;
