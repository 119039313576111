export const ADD_CUSTOMER_FORM = [
  {
    label: "이름",
    key: "name",
    type: "input",
    placeholder: "이름을 입력하세요",
    isRequired: true,
  },
  {
    label: "연락처",
    key: "phone",
    type: "phone",
    placeholder: "-없이 숫자만 입력하세요",
    isRequired: true,
    isReadOnly: false,
  },
  {
    label: "차량번호",
    key: "regNum",
    type: "input",
    placeholder: "예)12나 3456 123나 1234",
    isRequired: true,
  },
  {
    label: "차량브랜드",
    key: "brandId",
    type: "select",
    placeholder: "브랜드를 선택하세요",
    isRequired: true,
    labelTable: {},
  },
  {
    label: "차명",
    key: "modelName",
    type: "input",
    placeholder: "예)마이티 2 더블탭(터보)",
    isRequired: true,
  },
  {
    label: "모델연도",
    key: "modelYear",
    type: "input",
    placeholder: "모델연도를 입력하세요",
    isRequired: true,
  },
  {
    label: "차대번호",
    key: "vin",
    type: "input",
    placeholder: "예) KMFGE17LP2C169537",
    isRequired: true,
  },
];

export const CUSTOMER_TABLE_DATA = {
  columnList: [
    { label: "고객명", key: "name" },
    { label: "연락처", key: "phone" },
    { label: "차량 번호", key: "regNum" },
    { label: "차대번호", key: "vin" },
    { label: "모델 연도", key: "modelYear" },
    { label: "차량 브랜드 / 차명", key: "brandModelName" },
    { label: "총 방문 수", key: "totalVisits" },
    { label: "노쇼", key: "noshows" },
    { label: "최근 방문일", key: "lastVisit" },
  ],
  detailInfoList: [
    {
      label: "이름",
      key: "name",
      type: "input",
      placeholder: "이름을 입력하세요",
      isRequired: true,
    },
    {
      label: "차량 번호",
      key: "regNum",
      type: "input",
      placeholder: "예) 12나 3456/123나 1234",
      isRequired: true,
    },
    {
      label: "연락처",
      key: "phone",
      type: "input",
      placeholder: "예) 01012345678",
      isRequired: true,
    },
    {
      label: "차량 브랜드",
      key: "brandId",
      type: "select",
      placeholder: "브랜드를 선택하세요",
      isRequired: true,
    },
    { label: "최근 방문일", key: "lastVisit", type: "text" },
    {
      label: "차량명",
      key: "modelName",
      type: "input",
      placeholder: "예) 마이티2 더블탭(터보)",
      isRequired: true,
    },
    { label: "방문 횟수", key: "totalVisits", type: "text", suffixText: "회" },
    {
      label: "모델 연도",
      key: "modelYear",
      type: "input",
      placeholder: "모델연도를 입력하세요",
      isRequired: true,
    },
    { label: "노쇼 이력", key: "noshows", type: "text", suffixText: "회" },
    {
      label: "차대번호",
      key: "vin",
      type: "input",
      placeholder: "예) KMFGE17LP2C169537",
      isRequired: true,
    },
  ],
} as const;

export const CUSTOMER_SORT_OPTION_TABLE = {
  recent: "최근 방문순",
  visit: "방문 횟수순",
  name: "가나다순",
  noshow: "노쇼순",
};

export const CUSTOMER_DETAIL_INFO_TABLE_DATA = [
  { label: "고객명", key: "name" },
  { label: "연락처", key: "phone" },
  { label: "총 방문 수", key: "totalVisits" },
  { label: "노쇼", key: "noshows" },
  { label: "최근 방문일", key: "lastVisit" },
  { label: "차량번호", key: "regNum" },
  { label: "차대번호", key: "vin" },
  { label: "차량 브랜드", key: "brandTitle" },
  { label: "차명", key: "modelName" },
  { label: "모델 연도", key: "modelYear" },
] as const;

export const CUSTOMER_RESERVATION_TABLE_DATA = {
  columnList: [
    { label: "정비상태", key: "status" },
    { label: "정비번호", key: "reservationNo" },
    { label: "정비일시", key: "planned" },
    { label: "예약종류", key: "isSelf" },
    { label: "실제 정비 시간", key: "actualTime" },
    { label: "총 청구 금액 (원)", key: "cost" },
    { label: "담당 정비사", key: "staffNames" },
  ],
};

export const RESERVATION_IS_SELF_LABEL = {
  y: "자체 예약",
  n: "앱 예약",
};
