import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import dompurify from "dompurify";

import { LoadingSpinner } from "components";
import { useGetNoticeDetail } from "services";
import * as S from "./NoticeDetail.styled";

const NoticeDetail = () => {
  const { id: noticeId } = useParams();
  const { data, isSuccess } = useGetNoticeDetail(noticeId as string);

  const sanitizer = dompurify.sanitize;
  const contents = useMemo(() => (data ? sanitizer(data.content) : ""), [data]);

  if (!isSuccess) {
    return <LoadingSpinner />;
  }

  return (
    <S.Root>
      <S.Wrapper>
        <S.HeaderWrapper>
          <S.Title>공지사항 상세</S.Title>
          <S.CreatedBox>작성일시 {data.created}</S.CreatedBox>
        </S.HeaderWrapper>
        <S.ContentWrapper>
          <S.NoticeTitle>
            <label>제목</label>
            <h3>{data.title}</h3>
          </S.NoticeTitle>
          <S.NoticeContent>
            <label>내용</label>
            <S.Contents
              dangerouslySetInnerHTML={{
                __html: contents,
              }}
            />
          </S.NoticeContent>
        </S.ContentWrapper>
      </S.Wrapper>
    </S.Root>
  );
};

export default NoticeDetail;
