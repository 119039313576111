import React, { forwardRef } from "react";

import * as S from "./Input.styled";

interface InputProps {
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  id?: string;
  placeholder?: string;
  name?: string;
  suffixText?: string;
  value?: string;
  validError?: any;
  onFocus?: any;
  onChange?: any;
  onBlur?: any;
  onKeyDown?: any;
}

const Input = forwardRef(({ ...restProps }: InputProps, ref: any) => {
  return <S.Root type="text" ref={ref} autoComplete="off" {...restProps} />;
});

Input.displayName = "Input";
export default Input;
