export const ERROR_CODE_TYPE = Object.freeze({
  PASSWORD_MISMATCHED: {
    CODE: "PASSWORD_MISMATCHED",
    MSG: "입력하신 정보를 다시 확인해 주세요.",
  },
  CANNOT_USE_SAME_PASSWORD: {
    CODE: "CANNOT_USE_SAME_PASSWORD",
    MSG: "기존과 동일한 비밀번호입니다. 다른 비밀번호를 입력하세요.",
  },
});

export const ERROR_CODE = {
  CANNOT_USE_SAME_PASSWORD: "CANNOT_USE_SAME_PASSWORD",
  PASSWORD_MISMATCHED: "PASSWORD_MISMATCHED",
  PHONE_ALREADY_EXISTS: "PHONE_ALREADY_EXISTS",
  CANNOT_UPDATE_STAFF: "CANNOT_UPDATE_STAFF",
  INVALID_MODEL_YEAR: "INVALID_MODEL_YEAR",
  REG_NUM_ALREADY_EXISTS: "REG_NUM_ALREADY_EXISTS",
  INVALID_TEMPLATE: "INVALID_TEMPLATE",
  INVALID_REPORT_REASON: "INVALID_REPORT_REASON",
  ALREADY_REPORTED: "ALREADY_REPORTED",
  INVALID_YEAR: "INVALID_YEAR",
  INVALID_PLANNED: "INVALID_PLANNED",
  INVALID_ESTIMATED_TIME: "INVALID_ESTIMATED_TIME",
  INVALID_AUTH_CODE: "INVALID_AUTH_CODE",
};
