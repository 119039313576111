import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useGetInquiryDetail } from "services";
import { userStorage } from "utils";

interface PreviewAttachedFileType {
  name: string;
  label: string;
  presignedUrl: string;
}

const useLoadInquiryDetail = () => {
  const [searchParams] = useSearchParams();

  const { data } = useGetInquiryDetail(searchParams.get("helpId") as string);

  const { inquiryDetailList, attachedFileList } = data ?? {};

  const [previewAttachedFiles, setPreviewAttachedFiles] = useState<
    PreviewAttachedFileType[]
  >([]);
  const [repairshopName, setRepairshopName] = useState("");

  useEffect(() => {
    const userInfo = userStorage.load();
    setRepairshopName(userInfo.name);
  }, [searchParams]);

  useEffect(() => {
    if (!attachedFileList?.length) return;

    const newAttachedFileList = attachedFileList
      ?.map((item: string, i: number) => ({
        name: `사진 0${i + 1}`,
        label: `사진 0${i + 1}`,
        presignedUrl: item,
      }))
      .filter((attachFile: any) => attachFile.presignedUrl !== null);
    setPreviewAttachedFiles(newAttachedFileList);
  }, [attachedFileList]);

  return {
    repairshopName,
    inquiryDetailList,
    attachedFileList,
    previewAttachedFiles,
  };
};

export default useLoadInquiryDetail;
