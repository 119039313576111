import { useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { useImmer } from "use-immer";

import { useCheckRequired } from "hooks";
import { useDeleteRefreshToken, useLogin } from "services";
import { repairShopInfo } from "store";
import {
  makeCryptoFunction,
  checkEmailValidation,
  auth,
  checkInputLength,
} from "utils";
import { PATH, VALID_MESSAGE } from "constants/index";
import type { RepairShopInfo } from "types";

const useLoginForm = () => {
  const navigate = useNavigate();

  const { mutateAsync: deleteRefreshTokenMutate } = useDeleteRefreshToken();
  const { mutate: loginMutate } = useLogin();
  const inputRefs = useRef<any>({});

  const [form, setForm] = useImmer({
    email: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  });

  const setRepairShopInfo = useSetRecoilState<RepairShopInfo>(repairShopInfo);
  const [isKeepLogin, setIsKeepLogin] = useState(false);
  const [isPasswordEyeIcon, setIsPasswordEyeIcon] = useState(false);
  const isSubmitDisabled = form.email.error || form.password.error;

  const [checkRequired] = useCheckRequired(inputRefs, form, setForm);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (checkRequired()) return;

    setForm((draft) => {
      draft.email.error = "";
      draft.password.error = "";
    });

    if (auth.refreshToken) {
      const req = {
        body: {
          repairShopId: auth.repairShopId,
          refreshToken: auth.refreshToken,
        },
      };
      await deleteRefreshTokenMutate(req);
    }

    const req = {
      isKeepLogin,
      body: {
        email: form.email.value,
        password: makeCryptoFunction(form.password.value),
      },
    };

    loginMutate(req, {
      onSuccess: async (res, variables) => {
        const {
          refreshToken,
          accessToken,
          thumbnail,
          thumbnailKey,
          name,
          status,
          available,
          pwUpdated,
        } = res;

        const userInfo = {
          name,
          status,
          available,
          pwUpdated,
          thumbnail,
          thumbnailKey,
        };

        await auth.save({ refreshToken, accessToken }, variables.isKeepLogin);
        await setRepairShopInfo(userInfo);

        navigate(PATH.root);
      },
      onError: (err) => {
        if (err) {
          setForm((draft) => {
            draft.email.error = VALID_MESSAGE.FAIL;
            draft.password.error = VALID_MESSAGE.FAIL;
          });
          inputRefs.current[0].focus();
        }
      },
    });
  };

  const handleChangeFormValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      let error = "";

      switch (name) {
        case "email":
          if (!checkInputLength("EMAIL", value)) return;
          if (form[name].error && !checkEmailValidation(value)) {
            error = VALID_MESSAGE.EMAIL;
          }
          break;

        case "password":
          if (!checkInputLength("PASSWORD", value)) return;
          break;
      }

      if (name === "password") {
        value.length !== 0
          ? setIsPasswordEyeIcon(true)
          : setIsPasswordEyeIcon(false);
      }

      setForm((draft: any) => {
        draft[name].value = value;
        draft[name].error = error;
      });
    },
    [form],
  );

  const handleBlurFormValue = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const {
        name,
        value,
        dataset: { required },
      } = event.target;
      let error = "";

      if (required && value.length === 0) {
        setForm((draft) => {
          draft[name as keyof typeof form].error = VALID_MESSAGE.REQUIRED;
        });

        return;
      }

      switch (name) {
        case "email":
          !checkEmailValidation(value) && (error = VALID_MESSAGE.EMAIL);
          break;
      }

      setForm((draft: any) => {
        draft[name].error = error;
      });
    },
    [],
  );

  const handleToggleKeepLogin = useCallback(() => {
    setIsKeepLogin((prev) => !prev);
  }, []);

  return {
    inputRefs,
    form,
    isKeepLogin,
    isSubmitDisabled,
    isPasswordEyeIcon,
    handleChangeFormValue,
    handleBlurFormValue,
    handleToggleKeepLogin,
    handleSubmit,
  };
};

export default useLoginForm;
