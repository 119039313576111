export const INQUIRY_TABLE_LIST = [
  { label: "분류", key: "category" },
  { label: "제목", key: "title" },
  { label: "접수일시", key: "created" },
  { label: "처리상태", key: "status" },
];

export const EMPLOY_TABLE_DATA = [
  { label: "직원명", key: "name" },
  { label: "직무", key: "work" },
  { label: "직급", key: "position" },
  { label: "연락처", key: "phone" },
  { label: "정비 건수", key: "count" },
  { label: "알림톡", key: "sns" },
];

export const NOTICE_TABLE_LIST = [
  { label: "제목", key: "title" },
  { label: "업로드일시", key: "created" },
];

export const EVENT_TABLE_LIST = [
  { label: "제목", key: "title" },
  { label: "이벤트 기간", key: "period" },
];

export const FAQ_TABLE_LIST = [
  { label: "질문구분", key: "category" },
  { label: "제목", key: "title" },
];
