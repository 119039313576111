import React, { forwardRef } from "react";

import { Select, Input } from "components";
import { CONTACT_INFO } from "assets";
import type { ContactType } from "types";
import * as S from "./MobileInput.styled";

interface MobileInputProps {
  className?: string;
  isRequired?: boolean;
  type?: ContactType;
  data: any;
  phoneKey: string;
  phoneLocalKey: string;
  placeholder: string;
  readOnly?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, key: string) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const MobileInput = forwardRef(
  (
    {
      className,
      isRequired,
      type = "mobileArea",
      data,
      phoneKey,
      phoneLocalKey,
      placeholder,
      readOnly = false,
      handleChange,
      handleBlur,
    }: MobileInputProps,
    ref: any,
  ) => {
    const selectedPhoneCode = CONTACT_INFO[type];

    return (
      <S.Wrapper className={className}>
        <Select
          css={S.mobileSelect}
          key={phoneLocalKey}
          name={phoneLocalKey}
          labelTable={selectedPhoneCode}
          selectedOption={`${data[phoneLocalKey].value}`}
          required={isRequired}
          validError={data[phoneKey].error}
          onSelect={handleChange}
        >
          {Object.entries(selectedPhoneCode).map(([localValue, label]) => (
            <Select.Option key={label} value={localValue} onBlur={handleBlur}>
              {label}
            </Select.Option>
          ))}
        </Select>
        <Input
          css={S.mobileInput}
          ref={(el) => (ref.current[phoneKey] = el)}
          id={phoneKey}
          name={phoneKey}
          data-required={isRequired ? "true" : ""}
          value={data[phoneKey].value}
          validError={data[phoneKey].error}
          placeholder={placeholder}
          readOnly={readOnly}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </S.Wrapper>
    );
  },
);

export default MobileInput;

MobileInput.displayName = "MobileInput";
