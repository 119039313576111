import React, { useEffect } from "react";

import { useToast } from "hooks";
import * as S from "./ToastItem.styled";

interface ToastItemProps {
  content: string;
}

const TRANSITION_DURATION = 1000;
const TOAST_DURATION = 3000;

const ToastItem = ({ content }: ToastItemProps) => {
  const { removeToast } = useToast();

  const newLineMessage = content?.replace("\n", "<br />");
  const isIncludeNewLine =
    content?.split("\n").length > 1 || content.length > 21;

  useEffect(() => {
    const expireToastTimeout = setTimeout(() => {
      removeToast();
    }, TOAST_DURATION + TRANSITION_DURATION);

    return () => {
      clearTimeout(expireToastTimeout);
    };
  }, []);

  return (
    <S.ToastItem isIncludeNewLine={isIncludeNewLine}>
      <p dangerouslySetInnerHTML={{ __html: newLineMessage }}></p>
    </S.ToastItem>
  );
};

export default ToastItem;
