import {
  useMutation,
  useQuery,
  UseQueryOptions,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import dayjs from "dayjs";
import type { AxiosError } from "axios";

import {
  createCustomerAPI,
  deleteCustomerAPI,
  getCustomerDetailAPI,
  getCustomerDetailReservationsAPI,
  getCustomerInfoByRegNumAPI,
  getCustomersAPI,
  getNoshowCntAPI,
  updateCustomerAPI,
} from "apis";
import {
  getBrandModel,
  numToStringPhoneNum,
  getTimeOnTableHoursHidden,
  reservationNumFormatter,
  getTimeOnTableIncludeHours,
  formatActualTimes,
  formatCurrency,
} from "utils";
import type {
  GetCustomersQueryModel,
  CreateCustomerQueryModel,
  GetCustomerDetailClientModel,
  UpdateCustomerQueryModel,
  GetCustomerNoShowCntServerModel,
  GetCustomerReservationQueryModel,
  APIError,
} from "types";

const customerKeys = {
  all: ["customer"] as const,
  lists: () => [...customerKeys.all, "list"] as const,
  list: (filters: GetCustomersQueryModel) =>
    [...customerKeys.lists(), { filters }] as const,
  subList: (sub: string, filters: Object) => [sub, filters] as const,
  details: () => [...customerKeys.all, "detail"] as const,
  detail: (id: string) => [...customerKeys.details(), id] as const,
  subDetail: (sub: string, filters: Object) => [sub, filters] as const,
};

export const useGetCustomers = (filters: GetCustomersQueryModel) => {
  return useQuery({
    queryKey: customerKeys.list(filters),
    queryFn: () => getCustomersAPI(filters),
    select: (res) => ({
      ...res,
      customers: res.customers.map((customer) => ({
        ...customer,
        lastVisit: customer.lastVisit
          ? dayjs(customer.lastVisit).format("YYYY.MM.DD")
          : customer.lastVisit,
        phone: numToStringPhoneNum(customer.phone),
        userName: customer.name,
        brandModelName: getBrandModel(customer.brandTitle, customer.modelName),
      })),
    }),
  });
};

export const useGetCustomerDetail = (
  customerId: string,
): UseQueryResult<GetCustomerDetailClientModel> => {
  return useQuery({
    queryKey: customerKeys.subDetail(customerId, "detail"),
    queryFn: () => getCustomerDetailAPI(customerId),
    select: (res) => ({
      ...res,
      phone: numToStringPhoneNum(res.phone),
      noshows: `${res.noshows}건`,
      totalVisits: `${res.totalVisits}건`,
      lastVisit: res.lastVisit && getTimeOnTableHoursHidden(res.lastVisit),
    }),
  });
};

export const useGetCustomerReservation = (
  filters: GetCustomerReservationQueryModel,
) => {
  return useQuery({
    queryKey: customerKeys.subDetail(filters.customerId, filters),
    queryFn: () => getCustomerDetailReservationsAPI(filters),
    select: (res) => ({
      ...res,
      reservations: res.reservations.map((reservation) => ({
        ...reservation,
        planned: getTimeOnTableIncludeHours(reservation.planned),
        reservationNo: `${reservationNumFormatter(reservation.reservationNo)}`,
        actualTime:
          reservation.actualTime && formatActualTimes(reservation.actualTime),
        cost:
          reservation.cost === null
            ? "-"
            : `${formatCurrency(reservation.cost)}`,
      })),
    }),
  });
};

export const useGetCustomerByRegNum = () => {
  return useQuery({
    queryKey: customerKeys.subList("regNum", {}),
    queryFn: () => getCustomerInfoByRegNumAPI(),
  });
};

export const useFetchCustomerNoShowCnt = (
  phone: string,
  options?: UseQueryOptions<
    GetCustomerNoShowCntServerModel,
    AxiosError,
    GetCustomerNoShowCntServerModel,
    ReturnType<typeof customerKeys.subList>
  >,
) => {
  return useQuery({
    ...options,
    queryKey: customerKeys.subList("regNum", phone),
    queryFn: () => getNoshowCntAPI(phone),
  });
};

export const useCreateCustomer = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, CreateCustomerQueryModel>({
    mutationFn: (req: CreateCustomerQueryModel) => createCustomerAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(customerKeys.list({ query: {} }));
    },
  });
};

export const useUpdateCustomer = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, UpdateCustomerQueryModel>({
    mutationFn: (req: UpdateCustomerQueryModel) => updateCustomerAPI(req),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(
        customerKeys.subDetail(variables.customerId, "detail"),
      );
      queryClient.invalidateQueries(
        customerKeys.subDetail(variables.customerId, {
          customerId: variables.customerId,
          query: {},
        }),
      );
    },
  });
};

export const useDeleteCustomer = () =>
  useMutation<unknown, APIError, string>({
    mutationFn: (customerId: string) => deleteCustomerAPI(customerId),
  });
