import {
  useMutation,
  useQuery,
  UseQueryOptions,
  useQueryClient,
} from "@tanstack/react-query";
import type { AxiosError } from "axios";

import { useToast } from "hooks";
import {
  createStaffAPI,
  deleteStaffAPI,
  getStaffsAPI,
  updateStaffAPI,
} from "apis";
import { numToStringPhoneNum } from "utils";
import { TOAST_MSG } from "constants/index";
import type {
  CreateStaffQueryModel,
  UpdateStaffQueryModel,
  GetInquiryQueryModel,
  GetStaffsServerModel,
  GetStaffsClientModel,
  APIError,
} from "types";

const staffKeys = {
  all: ["staff"] as const,
  lists: () => [...staffKeys.all, "list"] as const,
  list: (filters: GetInquiryQueryModel) =>
    [...staffKeys.lists(), { filters }] as const,
};

export const useGetStaffs = (
  filters: GetInquiryQueryModel,
  options?: UseQueryOptions<
    GetStaffsServerModel,
    AxiosError,
    GetStaffsClientModel,
    ReturnType<typeof staffKeys.list>
  >,
) => {
  return {
    queryKey: staffKeys.list(filters),
    ...useQuery({
      ...options,
      queryKey: staffKeys.list(filters),
      queryFn: () => getStaffsAPI(filters),
      select: (res) => ({
        ...res,
        staffs: res.staffs.map((staff) => ({
          ...staff,
          phone: numToStringPhoneNum(staff.phone),
          userName: staff.name,
          repairCount:
            staff.isMechanic === "y" ? `${staff.repairCount}건` : "- 건",
        })),
      }),
    }),
  };
};

export const useCreateStaff = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, CreateStaffQueryModel>({
    mutationFn: (req: CreateStaffQueryModel) => createStaffAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(staffKeys.lists());
    },
  });
};

export const useUpdateStaff = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, UpdateStaffQueryModel>({
    mutationFn: (req: UpdateStaffQueryModel) => updateStaffAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(staffKeys.lists());
    },
  });
};

export const useDeleteStaff = () => {
  const queryClient = useQueryClient();

  const { addToast } = useToast();

  return useMutation<unknown, APIError, string>({
    mutationFn: (staffId: string) => deleteStaffAPI(staffId),
    onSuccess: () => {
      queryClient.invalidateQueries(staffKeys.lists());
      addToast(TOAST_MSG.SUCCESS.DELETE_STAFF_DONE);
    },
    onError: () => {
      addToast(TOAST_MSG.FAIL.DELETE_STAFF_FAIL);
    },
  });
};
