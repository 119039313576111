import React, { useContext, createContext, forwardRef } from "react";

import { EstimatedTime, Radio, MaxLengthInput, Button } from "components";
import {
  filterAvailableBreakEndTime,
  filterAvailableBreakStartTime,
  filterAvailableEndTime,
  filterAvailableNightEndTime,
  filterAvailableNightStartTime,
} from "utils";
import { DAY_LIST, CheckIconImg } from "assets";
import * as S from "./WorkTime.styled";

const WorkTimeContext = createContext({});

interface WorkTimeProps {
  className?: string;
  children: React.ReactNode;
  operationTimeObj: any;
  day: string;
}

interface WorkDateProps {
  ref?: React.ForwardedRef<HTMLDivElement>;
  memoValue: any;
  haserror?: boolean;
  handleChangeMemo: (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
  ) => void;
  handleBlurMemo: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleChangeDay: any;
}

interface WorkPossibleProps {
  handleChangeIsWork: (day: string) => void;
}

interface DayTimeProps {
  handleChangeTime: (day: string) => void;
}

interface NightTimeProps {
  handleChangeTime: (day: string) => void;
  handleToggleTime: (day: string) => (e: any) => void;
}

interface BreakTimeProps {
  handleChangeTime: (day: string) => void;
  handleToggleTime: (day: string) => (e: any) => void;
}

interface WorkTimeCompoundComponent
  extends React.ForwardRefExoticComponent<
    WorkTimeProps & React.RefAttributes<unknown>
  > {
  WorkDate: (props: WorkDateProps, ref: any) => any;
  WorkPossible: (props: WorkPossibleProps) => JSX.Element;
  DayTime: (props: DayTimeProps) => JSX.Element;
  NightTime: (props: NightTimeProps) => JSX.Element;
  BreakTime: (props: BreakTimeProps) => JSX.Element;
}

const WorkTime = forwardRef(
  (
    { className, operationTimeObj, day, children }: WorkTimeProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <WorkTimeContext.Provider
        value={{
          operationTimeObj,
          day,
          data: operationTimeObj[day],
        }}
      >
        <S.Root className={className} ref={ref}>
          {children}
        </S.Root>
      </WorkTimeContext.Provider>
    );
  },
) as WorkTimeCompoundComponent;

WorkTime.displayName = "WorkTime";
export default WorkTime;

WorkTime.WorkDate = forwardRef(function WorkDateItem(
  {
    memoValue,
    handleChangeMemo,
    handleBlurMemo,
    handleChangeDay,
  }: WorkDateProps,
  ref: any,
) {
  const { operationTimeObj, day } = useContext(WorkTimeContext) as any;

  return (
    <S.WorkDateWrapper>
      <S.Label>운영일</S.Label>
      <S.InnerWrapper>
        <S.DateWrapper ref={ref}>
          {Object.entries(operationTimeObj).map(
            ([d, item]: [string, any], idx) => {
              const { isWork } = item;
              const selected = day === d;

              return (
                <Button
                  css={(theme) => S.dateButton(theme, isWork, selected, !day)}
                  key={DAY_LIST[idx]}
                  variant={isWork ? "primarySmall" : "secondarySmall"}
                  onClick={handleChangeDay(d)}
                >
                  {DAY_LIST[idx]}
                </Button>
              );
            },
          )}
        </S.DateWrapper>
      </S.InnerWrapper>
      <S.Label>메모</S.Label>
      <MaxLengthInput
        css={S.memoInput}
        id="workTime-memo"
        name="memo"
        value={memoValue.value}
        placeholder="휴무일 등과 같이 전달하고 싶은 메모를 남겨주세요"
        maxLength={50}
        onChange={handleChangeMemo}
        onBlur={handleBlurMemo}
      />
    </S.WorkDateWrapper>
  );
});

WorkTime.WorkPossible = function WorkPossibleItem({
  handleChangeIsWork,
}: WorkPossibleProps) {
  const { data, day } = useContext(WorkTimeContext) as any;
  const { isWork } = data;

  return (
    <S.ContentWrapper>
      <S.LabelWrapper>
        <S.DialogLabel>운영 여부</S.DialogLabel>
      </S.LabelWrapper>
      <S.RadioWrapper>
        <Radio
          css={(theme) => S.radio(theme, isWork === true)}
          id="work"
          name="work-time"
          checked={isWork === true}
          value={true}
          onChange={handleChangeIsWork(day)}
        >
          운영
        </Radio>
        <Radio
          css={(theme) => S.radio(theme, isWork === false)}
          id="not-work"
          name="work-time"
          checked={isWork === false}
          value={false}
          onChange={handleChangeIsWork(day)}
        >
          휴무
        </Radio>
      </S.RadioWrapper>
    </S.ContentWrapper>
  );
};

WorkTime.DayTime = function DayTimeItem({ handleChangeTime }: DayTimeProps) {
  const { data, day } = useContext(WorkTimeContext) as any;
  const error =
    data.openHour.error ||
    data.openMin.error ||
    data.closeHour.error ||
    data.closeMin.error;

  return (
    <S.ContentWrapper>
      <S.LabelWrapper>
        <S.DialogLabel>운영 시간</S.DialogLabel>
      </S.LabelWrapper>
      <S.TimeWrapper>
        <EstimatedTime
          css={S.estimatedTime}
          id="operatingTime-open"
          disabled={!data.isWork}
          onChange={handleChangeTime(day)}
        >
          <EstimatedTime.SelectHour
            id="operatingTime-open-hour"
            placeholder="선택"
            name="openHour"
            label=""
            value={data.openHour.value}
            validError={data.openHour.error}
          />
          <EstimatedTime.SelectMin
            id="operatingTime-open-min"
            placeholder="선택"
            name="openMin"
            label=""
            value={data.openMin.value}
            validError={data.openMin.error}
          />
        </EstimatedTime>
        <EstimatedTime
          css={S.estimatedTime}
          id="operatingTime-close"
          disabled={!data.isWork}
          onChange={handleChangeTime(day)}
        >
          <EstimatedTime.SelectExpndHour
            id="operatingTime-close-hour"
            placeholder="선택"
            name="closeHour"
            label=""
            value={data.closeHour.value}
            validError={data.closeHour.error}
            filterTime={filterAvailableEndTime(
              data.openHour.value,
              data.openMin.value,
            )}
          />
          <EstimatedTime.SelectMin
            id="operatingTime-close-min"
            placeholder="선택"
            name="closeMin"
            value={data.closeMin.value}
            label=""
            validError={data.closeMin.error}
            halfDisable={
              (data.openHour.value === data.closeHour.value &&
                data.openMin.value === "30") ||
              data.closeHour.value === 24
            }
            zeroDisable={
              data.openHour.value === data.closeHour.value &&
              data.openMin.value === "00"
            }
          />
        </EstimatedTime>
        <S.ErrorMsg hasErr={!!error}>{error}</S.ErrorMsg>
      </S.TimeWrapper>
    </S.ContentWrapper>
  );
};
WorkTime.NightTime = function NightTimeItem({
  handleChangeTime,
  handleToggleTime,
}: NightTimeProps) {
  const { data, day } = useContext(WorkTimeContext) as any;
  const error =
    data.nightStartHour.error ||
    data.nightStartMin.error ||
    data.nightEndHour.error ||
    data.nightEndMin.error;

  return (
    <S.ContentWrapper>
      <S.LabelWrapper>
        <S.DialogLabel>야간 운영 시간</S.DialogLabel>
        <S.Checkbox
          selected={data.nightTimeSelected}
          tabIndex={0}
          disabled={!data.isWork}
        >
          <input
            type="checkbox"
            checked={data.nightTimeSelected}
            onChange={handleToggleTime(day)}
            name="nightTimeSelected"
            disabled={!data.isWork}
          />
          <CheckIconImg />
        </S.Checkbox>
      </S.LabelWrapper>
      <S.TimeWrapper>
        <EstimatedTime
          css={S.estimatedTime}
          id="operatingTime-nightStart"
          disabled={!data.nightTimeSelected}
          onChange={handleChangeTime(day)}
        >
          <EstimatedTime.SelectExpndHour
            id="operatingTime-nightStart-hour"
            placeholder="선택"
            name="nightStartHour"
            label=""
            value={data.nightStartHour.value}
            validError={data.nightStartHour.error}
            filterTime={filterAvailableNightStartTime(data.closeHour.value)}
          />
          <EstimatedTime.SelectMin
            id="operatingTime-nightStart-min"
            placeholder="선택"
            name="nightStartMin"
            label=""
            value={data.nightStartMin.value}
            validError={data.nightStartMin.error}
            zeroDisable={
              data.nightStartHour.value === data.closeHour.value &&
              data.closeMin.value === "30"
            }
            halfDisable={data.nightStartHour.value === 24}
          />
        </EstimatedTime>
        <EstimatedTime
          css={S.estimatedTime}
          id="operatingTime-nightEnd"
          disabled={!data.nightTimeSelected}
          onChange={handleChangeTime(day)}
        >
          <EstimatedTime.SelectExpndHour
            id="operatingTime-nightEnd-hour"
            isItemDisabled={data.nightStartHour.value === ""}
            placeholder="선택"
            name="nightEndHour"
            label=""
            value={data.nightEndHour.value}
            validError={data.nightEndHour.error}
            filterTime={filterAvailableNightEndTime(
              data.nightStartHour.value,
              data.nightStartHour.value !== 24 &&
                data.nightStartMin.value !== "30",
            )}
          />
          <EstimatedTime.SelectMin
            id="operatingTime-nightEnd-min"
            isItemDisabled={data.nightStartHour.value === ""}
            placeholder="선택"
            name="nightEndMin"
            label=""
            value={data.nightEndMin.value}
            validError={data.nightEndMin.error}
            zeroDisable={
              data.nightStartHour.value === data.nightEndHour.value &&
              data.nightStartMin.value === "00"
            }
            halfDisable={
              (data.nightStartHour.value === data.nightEndHour.value &&
                data.nightStartMin.value === "30") ||
              data.nightEndHour.value === 24
            }
          />
        </EstimatedTime>
        <S.ErrorMsg hasErr={error}>{error}</S.ErrorMsg>
      </S.TimeWrapper>
    </S.ContentWrapper>
  );
};
WorkTime.BreakTime = function BreakTimeItem({
  handleChangeTime,
  handleToggleTime,
}: BreakTimeProps) {
  const { data, day } = useContext(WorkTimeContext) as any;
  const error =
    data.restStartHour.error ||
    data.restStartMin.error ||
    data.restEndHour.error ||
    data.restEndMin.error;

  return (
    <S.ContentWrapper>
      <S.LabelWrapper>
        <S.DialogLabel>휴게시간</S.DialogLabel>
        <S.Checkbox
          disabled={!data.isWork}
          selected={data.breakTimeSelected}
          tabIndex={0}
        >
          <input
            type="checkbox"
            checked={data.breakTimeSelected}
            onChange={handleToggleTime(day)}
            name="breakTimeSelected"
            disabled={!data.isWork}
          />
          <CheckIconImg />
        </S.Checkbox>
      </S.LabelWrapper>
      <S.TimeWrapper>
        <EstimatedTime
          css={S.estimatedTime}
          id="operatingTime-restStart"
          disabled={!data.breakTimeSelected}
          onChange={handleChangeTime(day)}
        >
          <EstimatedTime.SelectExpndHour
            id="operatingTime-restStart-hour"
            placeholder="선택"
            name="restStartHour"
            label=""
            value={data.restStartHour.value}
            validError={data.restStartHour.error}
            filterTime={filterAvailableBreakStartTime(
              data.openHour.value,
              data.closeHour.value,
              data.closeMin.value !== "00",
            )}
          />
          <EstimatedTime.SelectMin
            id="operatingTime-restStart-min"
            placeholder="선택"
            name="restStartMin"
            label=""
            value={data.restStartMin.value}
            validError={data.restStartMin.error}
            zeroDisable={
              data.openMin.value === "30"
                ? data.openHour.value === data.restStartHour.value
                : false
            }
            halfDisable={data.closeHour.value === data.restStartHour.value}
          />
        </EstimatedTime>
        <EstimatedTime
          css={S.estimatedTime}
          id="operatingTime-restEnd"
          disabled={!data.breakTimeSelected}
          onChange={handleChangeTime(day)}
        >
          <EstimatedTime.SelectExpndHour
            id="operatingTime-restEnd-hour"
            isItemDisabled={data.restStartHour.value === ""}
            placeholder="선택"
            name="restEndHour"
            label=""
            value={data.restEndHour.value}
            validError={data.restEndHour.error}
            filterTime={filterAvailableBreakEndTime(
              data.restStartHour.value || data.openHour.value,
              data.closeHour.value,
              data.closeMin.value === "00"
                ? data.restStartMin.value !== "30"
                : data.restStartHour.value === data.closeHour.value ||
                    data.restStartMin.value === "00",
              data.restEndMin.value !== "30" || data.closeMin.value === "30",
            )}
          />
          <EstimatedTime.SelectMin
            id="operatingTime-restEnd-min"
            isItemDisabled={data.restStartHour.value === ""}
            placeholder="선택"
            name="restEndMin"
            label=""
            value={data.restEndMin.value}
            validError={data.restEndMin.error}
            zeroDisable={
              (data.closeHour.value === data.restStartHour.value &&
                data.closeMin.value === "30") ||
              (data.restStartHour.value === data.restEndHour.value &&
                (data.closeMin.value === "00" ||
                  data.restStartMin.value === "00"))
            }
            halfDisable={
              (data.closeHour.value - 1 === data.restStartHour.value &&
                data.closeMin.value === "00" &&
                data.restStartMin.value !== "00") ||
              (data.closeHour.value === data.restEndHour.value &&
                data.closeMin.value === "00")
            }
          />
        </EstimatedTime>
        <S.ErrorMsg hasErr={!!error}>{error}</S.ErrorMsg>
      </S.TimeWrapper>
    </S.ContentWrapper>
  );
};
