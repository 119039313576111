import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

import type { LoginServerModel } from "types";

export type UserCB = (user: LoginServerModel | null, error: any) => void;

class Auth {
  private key;
  private accountInfo: any;
  private cb: UserCB | null = null;

  constructor() {
    this.key = "TRUCKDOCTOR_REPAIRSHOP";
    this.accountInfo = null;
    this.load();
  }

  get isAuth() {
    return !!this.accountInfo?.refreshToken;
  }

  get grade() {
    return this.accountInfo?.grade;
  }

  get accessToken() {
    return this.accountInfo?.accessToken;
  }

  get refreshToken() {
    return this.accountInfo?.refreshToken;
  }

  get repairShopId() {
    const { id }: { id: any } = jwt_decode(this.accountInfo.refreshToken);
    return id;
  }

  changeAccessToken(token: string) {
    const newAccountInfo = { ...this.accountInfo, accessToken: token };
    Cookies.set(this.key, JSON.stringify(newAccountInfo));
    this.#setAccountInfo(newAccountInfo);
  }

  #setAccountInfo(accountInfo: any) {
    this.accountInfo = accountInfo;
  }

  save(data: any, isKeepLogin: boolean) {
    Cookies.set(this.key, JSON.stringify(data), {
      ...(isKeepLogin && { expires: 14 }),
    });
    this.load();
  }

  onAuthStateChanged(cb: UserCB) {
    this.cb = cb;

    this.onUserChange(this.accountInfo);

    return () => {
      this.cb = null;
    };
  }

  private onUserChange(
    user: LoginServerModel | null,
    error?: { message: string },
  ) {
    this.cb && this.cb(user, error);
  }

  load() {
    const key = Cookies.get(this.key);
    if (!key) return;

    this.accountInfo = JSON.parse(key);
  }

  resolveUser() {
    if (window) {
      const signedInUser = Cookies.get(this.key);

      if (signedInUser) {
        this.accountInfo = JSON.parse(Cookies.get(this.key)!);
      }
    }

    return this;
  }

  clear() {
    Cookies.remove(this.key);
    this.onUserChange(null);
    this.accountInfo = null;
  }
}
export const auth = new Auth();
