import React from "react";

import { RoundCheckIconImg, RoundNoCheckIconImg } from "assets";
import * as S from "./KeepLoginButton.styled";

interface KeepLoginButtonProps {
  selected: boolean;
  restProps?: any;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const KeepLoginButton = ({ selected, ...restProps }: KeepLoginButtonProps) => {
  return (
    <S.Root
      type="button"
      selected={selected}
      aria-pressed={selected}
      {...restProps}
    >
      {selected ? <RoundCheckIconImg /> : <RoundNoCheckIconImg />}
      <span>로그인 상태 유지</span>
    </S.Root>
  );
};

export default KeepLoginButton;
