import { useState, useRef, useCallback, useEffect } from "react";
import { useImmer } from "use-immer";
import dayjs from "dayjs";

import { useToast } from "hooks";
import {
  operationTimeListInit,
  deleteKeyNullInValue,
  isBetweenDateTime,
  getHourSepartorStr,
  getTwoDigitTime,
  getMinSepartorStr,
} from "utils";
import { TOAST_MSG, VALID_MESSAGE } from "constants/index";
import useOnClickOutside from "./useOnClickOutside";

const useWorkTime = (initData: any) => {
  const workTimeRef = useRef<any>(null);
  const workDateRef = useRef<any>(null);

  const [operationTimeObj, setOperationTimeObj] = useImmer(
    operationTimeListInit(),
  );
  const [selectedDay, setSelectedDay] = useState("");

  const { addToast } = useToast();

  const handleCloseWorkTime = () => {
    if (!selectedDay) return;

    const operationTimeInfo = operationTimeObj[selectedDay];

    const errors = {
      openHour: "",
      openMin: "",
      closeHour: "",
      closeMin: "",
      nightStartHour: "",
      nightStartMin: "",
      nightEndHour: "",
      nightEndMin: "",
      restStartHour: "",
      restStartMin: "",
      restEndHour: "",
      restEndMin: "",
    };

    if (operationTimeInfo.isWork) {
      if (operationTimeInfo.openHour.value === "") {
        errors.openHour = VALID_MESSAGE.OPERATION_TIME;
        errors.openMin = VALID_MESSAGE.OPERATION_TIME;
      }

      if (operationTimeInfo.closeHour.value === "") {
        errors.closeHour = VALID_MESSAGE.OPERATION_TIME;
        errors.closeMin = VALID_MESSAGE.OPERATION_TIME;
      }
    }

    if (operationTimeInfo.nightTimeSelected) {
      if (operationTimeInfo.nightStartHour.value === "") {
        errors.nightStartHour = VALID_MESSAGE.OPERATION_TIME;
        errors.nightStartMin = VALID_MESSAGE.OPERATION_TIME;
      }

      if (operationTimeInfo.nightEndHour.value === "") {
        errors.nightEndHour = VALID_MESSAGE.OPERATION_TIME;
        errors.nightEndMin = VALID_MESSAGE.OPERATION_TIME;
      }
    }

    if (operationTimeInfo.breakTimeSelected) {
      if (operationTimeInfo.restStartHour.value === "") {
        errors.restStartHour = VALID_MESSAGE.OPERATION_TIME;
        errors.restStartMin = VALID_MESSAGE.OPERATION_TIME;
      }

      if (operationTimeInfo.restEndHour.value === "") {
        errors.restEndHour = VALID_MESSAGE.OPERATION_TIME;
        errors.restEndMin = VALID_MESSAGE.OPERATION_TIME;
      }
    }

    setOperationTimeObj((draft: any) => {
      draft[selectedDay].openHour.error = errors.openHour;
      draft[selectedDay].openMin.error = errors.openMin;
      draft[selectedDay].closeHour.error = errors.closeHour;
      draft[selectedDay].closeMin.error = errors.closeMin;
      draft[selectedDay].nightStartHour.error = errors.nightStartHour;
      draft[selectedDay].nightStartMin.error = errors.nightStartMin;
      draft[selectedDay].nightEndHour.error = errors.nightEndHour;
      draft[selectedDay].nightEndMin.error = errors.nightEndMin;
      draft[selectedDay].restStartHour.error = errors.restStartHour;
      draft[selectedDay].restStartMin.error = errors.restStartMin;
      draft[selectedDay].restEndHour.error = errors.restEndHour;
      draft[selectedDay].restEndMin.error = errors.restEndMin;
    });

    const hasError = Object.values(errors).filter(Boolean).length;

    if (hasError) {
      addToast(TOAST_MSG.FAIL.UPDATE_OPERATION_TIME);
      return true;
    } else {
      setSelectedDay("");
      return false;
    }
  };

  useOnClickOutside(workTimeRef, handleCloseWorkTime, workDateRef?.current);

  const getFormattedOperationTime = (operationTime: any) => {
    return Object.entries(operationTime)
      .map(([day, item]: [string, any]) => {
        const {
          open,
          close,
          restStart,
          restEnd,
          nightStart,
          nightEnd,
          isWork,
          breakTimeSelected,
          nightTimeSelected,
        } = item;
        if (isWork) {
          return deleteKeyNullInValue({
            day,
            open: open.value,
            close: close.value,
            restStart: breakTimeSelected ? restStart.value : "",
            restEnd: breakTimeSelected ? restEnd.value : "",
            nightStart: nightTimeSelected ? nightStart.value : "",
            nightEnd: nightTimeSelected ? nightEnd.value : "",
          });
        }
      })
      .filter(Boolean);
  };

  const handleChangeDay = useCallback(
    (day: string) => () => {
      const selected = day === selectedDay ? "" : day;
      const hasError = handleCloseWorkTime();

      if (!hasError) {
        setSelectedDay(selected);
      }
    },
    [operationTimeObj, selectedDay],
  );
  const handleChangeIsWork = (day: string) => (event: any) => {
    const value = event.target.value;

    setOperationTimeObj((draft: any) => {
      draft[day].isWork = JSON.parse(value);
      draft[day].nightTimeSelected = false;
      draft[day].breakTimeSelected = false;
      draft[day].open.value = "";
      draft[day].open.error = "";
      draft[day].openHour.value = "";
      draft[day].openHour.error = "";
      draft[day].openMin.value = "00";
      draft[day].openMin.error = "";
      draft[day].close.value = "";
      draft[day].close.error = "";
      draft[day].closeHour.value = "";
      draft[day].closeHour.error = "";
      draft[day].closeMin.value = "00";
      draft[day].closeMin.error = "";
      draft[day].nightStartHour.value = "";
      draft[day].nightStartHour.error = "";
      draft[day].nightStartMin.value = "00";
      draft[day].nightStartMin.error = "";
      draft[day].nightEndHour.value = "";
      draft[day].nightEndHour.error = "";
      draft[day].nightEndMin.value = "00";
      draft[day].nightEndMin.error = "";
      draft[day].restStartHour.value = "";
      draft[day].restStartHour.error = "";
      draft[day].restStartMin.value = "00";
      draft[day].restStartMin.error = "";
      draft[day].restEndHour.value = "";
      draft[day].restEndHour.error = "";
      draft[day].restEndMin.value = "00";
      draft[day].restEndMin.error = "";
    });
  };

  const handleToggleTime = (day: string) => (event: any) => {
    const name = event.target.name;
    const selectedOperationTimeInfo = operationTimeObj[day];

    if (
      selectedOperationTimeInfo.openHour.value === "" ||
      !selectedOperationTimeInfo.openMin.value ||
      selectedOperationTimeInfo.closeHour.value === "" ||
      !selectedOperationTimeInfo.closeMin.value
    ) {
      addToast(TOAST_MSG.FAIL.SETUP_ETC_OPERATION_TIME);

      return;
    }

    if (
      name === "nightTimeSelected" &&
      !operationTimeObj[day].nightTimeSelected &&
      operationTimeObj[day].closeHour.value === 24
    ) {
      addToast(TOAST_MSG.FAIL.RE_SETUP_ETC_OPERATION_TIME);

      return;
    }

    if (operationTimeObj[day][name]) {
      if (name === "nightTimeSelected") {
        setOperationTimeObj((draft: any) => {
          draft[day]["nightStart"].error = "";
          draft[day]["nightStartHour"].error = "";
          draft[day]["nightStartMin"].error = "";
          draft[day]["nightEnd"].error = "";
          draft[day]["nightEndHour"].error = "";
          draft[day]["nightEndMin"].error = "";
        });
      }
      if (name === "breakTimeSelected") {
        setOperationTimeObj((draft: any) => {
          draft[day]["restStart"].error = "";
          draft[day]["restStartHour"].error = "";
          draft[day]["restStartMin"].error = "";
          draft[day]["restEnd"].error = "";
          draft[day]["restEndHour"].error = "";
          draft[day]["restEndMin"].error = "";
        });
      }
    }

    setOperationTimeObj((draft: any) => {
      draft[day][name] = !draft[day][name];
    });
  };

  const setMinuteByHour = (type: string, day: string, value: number) => {
    if (type === "restStart") {
      if (
        value === operationTimeObj[day]["openHour"].value &&
        operationTimeObj[day]["openMin"].value === "30"
      ) {
        setOperationTimeObj((draft: any) => {
          draft[day][`${type}Min`].value = "30";
        });
      }

      if (
        value === operationTimeObj[day]["closeHour"].value &&
        operationTimeObj[day]["closeMin"].value ===
          operationTimeObj[day][`${type}Min`].value
      ) {
        setOperationTimeObj((draft: any) => {
          draft[day][`${type}Min`].value = "00";
        });
      }
    }

    if (
      type === "nightStart" &&
      value === operationTimeObj[day]["closeHour"].value &&
      operationTimeObj[day]["closeMin"].value === "30" &&
      operationTimeObj[day][`${type}Min`].value === "00"
    ) {
      setOperationTimeObj((draft: any) => {
        draft[day][`${type}Min`].value = "30";
      });
    }
  };

  const restrictOperationEndHour = (type: string, day: string) => {
    if (type === "close" && operationTimeObj[day].nightTimeSelected) {
      initTimeValue("close", day);
      return true;
    }
    return false;
  };

  const changeTimeValueWithHour = (
    type: string,
    day: string,
    value: number,
  ) => {
    setMinuteByHour(type, day, value);

    if (value === 24) {
      const isNotAllowedNightTime = restrictOperationEndHour(type, day);

      setOperationTimeObj((draft: any) => {
        draft[day][`${type}Min`].value = "00";
        draft[day][type].value = `${getTwoDigitTime(+value)}:00`;
        draft[day][`${type}Hour`].error = "";
        draft[day][`${type}Min`].error = "";
        draft[day].nightStartHour.error = "";
        draft[day].nightStartMin.error = "";
        draft[day].nightEndHour.error = "";
        draft[day].nightEndMin.error = "";
        draft[day].nightTimeSelected = isNotAllowedNightTime
          ? false
          : draft[day].nightTimeSelected;
      });
      return;
    }

    setOperationTimeObj((draft: any) => {
      draft[day][`${type}Hour`].error = "";
      draft[day][`${type}Min`].error = "";
      draft[day][type].value = `${getTwoDigitTime(+value)}:${
        draft[day][`${type}Min`].value
      }`;
    });
  };

  const changeTimeValueWithMin = (type: string, day: string, value: number) => {
    setOperationTimeObj((draft: any) => {
      draft[day][type].value = `${draft[day][`${type}Hour`].value}:${value}`;
      draft[day][`${type}Min`].error = "";
    });
  };

  const initTimeValue = (type: string, day: string) => {
    setOperationTimeObj((draft: any) => {
      draft[day][type].value = "";
      draft[day][`${type}Hour`].value = "";
      draft[day][`${type}Min`].value = "00";
    });
  };

  const checkValidTimePeriod = (selectedDayTime: any) => {
    const currentNightStartTime =
      selectedDayTime.nightStart.value ||
      selectedDayTime.close.value ||
      selectedDayTime.open.value;
    const currentRestStartTime =
      selectedDayTime.restStart.value || selectedDayTime.open.value;
    const currentRestEndTime =
      selectedDayTime.restEnd.value || selectedDayTime.close.value;

    const isValidNightStartTime = dayjs(
      currentNightStartTime,
      "HH:mm",
    ).isSameOrAfter(
      dayjs(selectedDayTime.close.value || selectedDayTime.open.value, "HH:mm"),
      "minute",
    );
    const isValidRestStartTime = isBetweenDateTime(
      currentRestStartTime,
      selectedDayTime.open.value,
      selectedDayTime.close.value,
    );
    const isValidRestEndTime = isBetweenDateTime(
      currentRestEndTime,
      selectedDayTime.open.value,
      selectedDayTime.close.value,
    );

    if (!isValidRestStartTime || !isValidRestEndTime) {
      setOperationTimeObj((draft: any) => {
        draft[selectedDay]["restStartHour"].value = "";
        draft[selectedDay]["restStartMin"].value = "00";
        draft[selectedDay]["restStart"].value = "";
        draft[selectedDay]["restEndHour"].value = "";
        draft[selectedDay]["restEndMin"].value = "00";
        draft[selectedDay]["restEnd"].value = "";
      });
    }
    if (!isValidNightStartTime) {
      setOperationTimeObj((draft: any) => {
        draft[selectedDay]["nightStartHour"].value = "";
        draft[selectedDay]["nightStartMin"].value = "00";
        draft[selectedDay]["nightStart"].value = "";
        draft[selectedDay]["nightEndHour"].value = "";
        draft[selectedDay]["nightEndMin"].value = "00";
        draft[selectedDay]["nightEnd"].value = "";
      });
    }
  };

  const handleChangeTime = useCallback(
    (day: string) => (value: number, name: string) => {
      switch (name) {
        case "openHour":
          changeTimeValueWithHour("open", day, value);
          if (
            operationTimeObj[day]["closeHour"].value < value ||
            (operationTimeObj[day]["closeHour"].value === value &&
              operationTimeObj[day]["openMin"].value >=
                operationTimeObj[day]["closeMin"].value)
          ) {
            initTimeValue("close", day);
          }
          break;
        case "openMin":
          if (operationTimeObj[day]["openHour"].value !== "") {
            changeTimeValueWithMin("open", day, value);
            if (
              operationTimeObj[day]["openHour"].value ===
              operationTimeObj[day]["closeHour"].value
            ) {
              initTimeValue("close", day);
            }
          }
          break;

        case "closeHour":
          changeTimeValueWithHour("close", day, value);
          if (
            operationTimeObj[day]["openHour"].value === value &&
            operationTimeObj[day]["closeMin"].value === "00"
          ) {
            setOperationTimeObj((draft: any) => {
              draft[day]["closeMin"].value = "30";
              draft[day]["close"].value = `${getTwoDigitTime(+value)}:${30}`;
            });
          }
          break;
        case "closeMin":
          if (operationTimeObj[day]["closeHour"].value !== "") {
            changeTimeValueWithMin("close", day, value);
          }
          break;

        case "nightStartHour":
          changeTimeValueWithHour("nightStart", day, value);
          if (
            operationTimeObj[day]["nightEndHour"].value < value ||
            (operationTimeObj[day]["nightEndHour"].value === value &&
              operationTimeObj[day]["nightStartMin"].value >=
                operationTimeObj[day]["nightEndMin"].value)
          ) {
            initTimeValue("nightEnd", day);
          }
          break;
        case "nightStartMin":
          if (operationTimeObj[day]["nightStartHour"].value !== "") {
            changeTimeValueWithMin("nightStart", day, value);
            if (
              operationTimeObj[day]["nightStartHour"].value ===
              operationTimeObj[day]["nightEndHour"].value
            ) {
              initTimeValue("nightEnd", day);
            }
          }
          break;

        case "nightEndHour":
          changeTimeValueWithHour("nightEnd", day, value);
          if (
            operationTimeObj[day]["nightStartHour"].value === value &&
            operationTimeObj[day]["nightEndMin"].value === "00"
          ) {
            setOperationTimeObj((draft: any) => {
              draft[day]["nightEndMin"].value = "30";
              draft[day]["nightEnd"].value = `${getTwoDigitTime(+value)}:${30}`;
            });
          }
          break;
        case "nightEndMin":
          if (operationTimeObj[day]["nightEndHour"].value !== "") {
            changeTimeValueWithMin("nightEnd", day, value);
          }
          break;

        case "restStartHour":
          changeTimeValueWithHour("restStart", day, value);
          if (
            operationTimeObj[day]["restEndHour"].value < value ||
            (operationTimeObj[day]["restEndHour"].value === value &&
              operationTimeObj[day]["restStartMin"].value >=
                operationTimeObj[day]["restEndMin"].value)
          ) {
            initTimeValue("restEnd", day);
          }
          break;
        case "restStartMin":
          if (operationTimeObj[day]["restStartHour"].value !== "") {
            changeTimeValueWithMin("restStart", day, value);
            if (
              operationTimeObj[day]["restStartHour"].value ===
              operationTimeObj[day]["restEndHour"].value
            ) {
              initTimeValue("restEnd", day);
            }
          }
          break;

        case "restEndHour":
          changeTimeValueWithHour("restEnd", day, value);
          if (
            operationTimeObj[day]["restStartHour"].value === value &&
            operationTimeObj[day]["restEndMin"].value === "00"
          ) {
            setOperationTimeObj((draft: any) => {
              draft[day]["restEndMin"].value = "30";
              draft[day]["restEnd"].value = `${getTwoDigitTime(+value)}:${30}`;
            });
          }
          break;
        case "restEndMin":
          if (operationTimeObj[day]["restEndHour"].value !== "") {
            changeTimeValueWithMin("restEnd", day, value);
          }
          break;
      }

      setOperationTimeObj((draft: any) => {
        draft[day][name].value = value;
      });
    },
    [operationTimeObj],
  );

  useEffect(() => {
    if (initData) {
      const copyOperationTime = { ...operationTimeObj };

      initData.forEach((data: any) => {
        const { close, day, nightEnd, nightStart, open, restEnd, restStart } =
          data;
        const obj = {
          ...initData[day],
          open: {
            value: open ?? "",
            error: "",
          },
          openHour: {
            value: getHourSepartorStr(open),
            error: "",
          },
          openMin: {
            value: getMinSepartorStr(open),
            error: "",
          },
          close: {
            value: close ?? "",
            error: "",
          },
          closeHour: {
            value: getHourSepartorStr(close),
            error: "",
          },
          closeMin: {
            value: getMinSepartorStr(close),
            error: "",
          },
          nightStart: {
            value: nightStart ?? "",
            error: "",
          },

          nightStartHour: {
            value: getHourSepartorStr(nightStart),
            error: "",
          },
          nightStartMin: {
            value: getMinSepartorStr(nightStart),
            error: "",
          },
          nightEnd: {
            value: nightEnd ?? "",
            error: "",
          },
          nightEndHour: {
            value: getHourSepartorStr(nightEnd),
            error: "",
          },
          nightEndMin: {
            value: getMinSepartorStr(nightEnd),
            error: "",
          },
          restStart: {
            value: restStart ?? "",
            error: "",
          },
          restStartHour: {
            value: getHourSepartorStr(restStart),
            error: "",
          },
          restStartMin: {
            value: getMinSepartorStr(restStart),
            error: "",
          },
          restEnd: {
            value: restEnd ?? "",
            error: "",
          },
          restEndHour: {
            value: getHourSepartorStr(restEnd),
            error: "",
          },
          restEndMin: {
            value: getMinSepartorStr(restEnd),
            error: "",
          },
          nightTimeSelected: nightStart ? true : false,
          breakTimeSelected: restStart ? true : false,
          isWork: open ? true : false,
        };
        copyOperationTime[day] = obj;
      });
      setOperationTimeObj(copyOperationTime);
    }
  }, [initData]);

  useEffect(() => {
    if (!selectedDay) return;

    const selectedDayTime = operationTimeObj[selectedDay];
    if (!selectedDayTime.isWork) return;

    checkValidTimePeriod(selectedDayTime);
  }, [
    operationTimeObj[selectedDay]?.open.value,
    operationTimeObj[selectedDay]?.close.value,
  ]);

  return {
    workTimeRef,
    workDateRef,
    operationTimeObj,
    selectedDay,
    setSelectedDay,
    getFormattedOperationTime,
    handleChangeDay,
    handleToggleTime,
    handleChangeIsWork,
    handleChangeTime,
  };
};

export default useWorkTime;
