import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const root = css`
  min-width: 566px;
`;

export const Container = styled.div`
  height: 310px;
  padding: 24px 34px;
  overflow-y: overlay;
`;

export const InputWrapper = styled.dl<{ isRequired: boolean }>`
  ${({ isRequired, theme }) => css`
    display: grid;
    grid-template-columns: 126px 270px;

    & > dt > label {
      ${theme.font.regular_14};
      color: ${theme.color.gray_07};

      &::after {
        content: ${isRequired ? "'*'" : null};
        margin-left: 3px;
        color: ${theme.color.error_01};
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 4px;
    }
  `}
`;

export const input = css`
  height: 34px;
`;

export const CustomerContainer = styled(Container)`
  height: fit-content;
  padding: 24px 35px 10px;
`;

export const select = css`
  & > button {
    padding: 0 14px 0 10px;
  }

  & > ul {
    max-height: 120px;
  }
`;

export const ValidText = styled.pre`
  ${({ theme }) => css`
    ${theme.font.regular_12};
    grid-column: 2 / -2;
    margin: 6px 0 4px 0;
    color: ${theme.color.error_01};
  `}
`;
