import { numberInputWithComma } from "./formatter";

export const findAllIndices = (str: string, substr: string) => {
  const indices = [];
  let idx = str.indexOf(substr);

  while (idx !== -1) {
    indices.push(idx);
    idx = str.indexOf(substr, idx + 1);
  }

  return indices;
};

const getMarkerNumberInput = (
  key: string,
  value: string,
  cursorIdx: number,
) => {
  switch (key) {
    case "Backspace":
      return value[cursorIdx - 1];

    case "Delete":
      return value[cursorIdx];
  }
};

const getDeletedMarkerWithValue = (
  e: React.KeyboardEvent<HTMLInputElement>,
  char: string,
) => {
  const key = e.key;
  const { value, selectionStart: cursorIdx } = e.target as HTMLInputElement;

  if (!cursorIdx) return;

  const marker = getMarkerNumberInput(key, value, cursorIdx);
  if (marker !== char) return;

  switch (key) {
    case "Backspace":
      return (
        value.slice(0, cursorIdx - 2) + value.slice(cursorIdx - 1, value.length)
      );
    case "Delete":
      return (
        value.slice(0, cursorIdx + 1) + value.slice(cursorIdx + 2, value.length)
      );
  }
};

export const getUpdatedCursorIdx = (
  currentValue: string,
  prevValue: string,
  char: string,
) => {
  const currentMarkers = findAllIndices(currentValue, char);
  const prevMarkers = findAllIndices(prevValue, char);
  const markerCnt =
    currentMarkers.length - prevMarkers.length !== 0
      ? currentMarkers.length - prevMarkers.length
      : 0;

  return markerCnt;
};

export const getCommaThousandUnitInputAfterChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  prevValue: string,
) => {
  const { value, selectionStart: cursorIdx } = e.target as HTMLInputElement;

  if (cursorIdx === null) return;

  const currentCommas = findAllIndices(
    numberInputWithComma(value, prevValue),
    ",",
  );
  const prevCommas = findAllIndices(prevValue, ",");
  const commaCnt =
    currentCommas.length - prevCommas.length !== 0
      ? currentCommas.length - prevCommas.length
      : 0;

  const newCursorIdx = cursorIdx + commaCnt;

  return {
    cursor: newCursorIdx > -1 ? newCursorIdx : 0,
    value: numberInputWithComma(value, prevValue),
  };
};

export const getCommaThousandUnitAfterDeleteMarker = (
  e: React.KeyboardEvent<HTMLInputElement>,
) => {
  const key = e.key;
  const { value, selectionStart: cursorIdx } = e.target as HTMLInputElement;
  const deletableKeys = ["Backspace", "Delete"];

  if (!cursorIdx || !deletableKeys.includes(key)) return;

  const deletedValue = getDeletedMarkerWithValue(e, ",");
  if (!deletedValue) return;

  e.preventDefault();

  const commaCnt = getUpdatedCursorIdx(
    numberInputWithComma(deletedValue, value),
    value,
    ",",
  );

  const additionalCursorCnt = key === "Backspace" ? -1 : 1;
  const newCursorIdx = cursorIdx + commaCnt + additionalCursorCnt;

  return {
    cursor: newCursorIdx > 0 ? newCursorIdx : 0,
    value: numberInputWithComma(deletedValue.replaceAll(",", "")),
  };
};

export const findSearchedCustomerInfo = (customers: any[], regNum: string) =>
  customers.find((customer) => customer.regNum === regNum);

export const getNullData = (key: string) => {
  switch (key) {
    case "name":
    case "phone":
      return "탈퇴한 사용자";
  }

  return "";
};
