import dayjs from "dayjs";

import type { MonthYear } from "types";

export const getUpdatedMonthYear = (
  monthYear: MonthYear,
  monthIncrement: number,
) => {
  return monthYear.startDate.clone().add(monthIncrement, "month");
};

export const getMonthYearDetails = (initialDate: dayjs.Dayjs) => {
  const month = initialDate.format("MM");
  const year = initialDate.format("YYYY");
  const date = initialDate.format("DD");
  const dayOfWeek = initialDate.format("dddd");
  const startMonth = dayjs(`${year}01`);
  const startDate = dayjs(`${year}${month}01`);
  const prevMonthStartDate = startDate.clone().subtract(1, "month");
  const nextMonthStartDate = startDate.clone().add(1, "month");
  const firstDOW = Number(startDate.format("d"));
  const lastDate = Number(startDate.clone().endOf("month").format("DD"));
  const monthName = startDate.format("MMMM");
  const prevMonthLastDate = Number(
    prevMonthStartDate.endOf("month").format("DD"),
  );
  const firstWeekPrevMonthDate = prevMonthStartDate.set(
    "date",
    prevMonthLastDate - firstDOW + 1,
  );
  const formattedMonthYear = initialDate.format("YYYY년 MM월");
  const formattedMaintenanceMonth = initialDate.format("MM월 정비내역");
  const formattedMaintenanceMonthYear =
    initialDate.format("MM월 DD일 정비내역");
  const value = initialDate;

  return {
    startMonth,
    startDate,
    prevMonthStartDate,
    nextMonthStartDate,
    firstDOW,
    lastDate,
    monthName,
    dayOfWeek,
    date,
    month,
    year,
    prevMonthLastDate,
    firstWeekPrevMonthDate,
    formattedMonthYear,
    formattedMaintenanceMonth,
    formattedMaintenanceMonthYear,
    value,
  };
};

export const getNewMonthYear = (
  prevData: MonthYear,
  monthIncrement: number,
) => {
  const newMonthYear = getUpdatedMonthYear(prevData, monthIncrement);

  return getMonthYearDetails(newMonthYear);
};
