import React, { useMemo } from "react";

import { RadioButton, ValidMessage } from "components";
import { APPROVED_MAINTENANCE_RADIO } from "assets";
import { APPROVED_MAINTENANCE } from "constants/index";
import ApprovedForm from "./approved/ApprovedForm";
import ChangeForm from "./change/ChangeForm";
import CancelForm from "./cancel/CancelForm";
import * as S from "./AcceptedForm.styled";

interface AcceptedFormProps {
  inputRefs: any;
  data: any;
  reservationData: any;
  handleChangeData: any;
  handleBlurData: any;
  handleSelectDate: any;
}

const AcceptedForm = ({
  inputRefs,
  data,
  reservationData,
  handleChangeData,
  handleBlurData,
  handleSelectDate,
}: AcceptedFormProps) => {
  const content = useMemo(() => {
    switch (data.approvedMaintenance.value) {
      case APPROVED_MAINTENANCE.APPROVED:
        return (
          <ApprovedForm
            id={`${data.approvedMaintenance.value}-panel`}
            data={reservationData}
            tabIndex={0}
          />
        );

      case APPROVED_MAINTENANCE.CHANGE:
        return (
          <ChangeForm
            id={`${data.approvedMaintenance.value}-panel`}
            inputRefs={inputRefs}
            data={data}
            tabIndex={0}
            handleChangeData={handleChangeData}
            handleBlurData={handleBlurData}
            handleSelectDate={handleSelectDate}
          />
        );

      case APPROVED_MAINTENANCE.CANCEL:
        return (
          <CancelForm
            id={`${data.approvedMaintenance.value}-panel`}
            inputRefs={inputRefs}
            reservationData={reservationData}
            data={data}
            tabIndex={0}
            handleChangeData={handleChangeData}
            handleBlurData={handleBlurData}
          />
        );
    }
  }, [data]);

  return (
    <S.Root>
      <S.InfoItem isRequired>
        <span>예약 승인 여부</span>
        <div>
          <S.RadioWrapper role="tablist">
            {APPROVED_MAINTENANCE_RADIO.radioItems.map((radio) => (
              <RadioButton
                css={S.radioButton}
                key={radio.value}
                id={`radio-repairInfo-${radio.value}`}
                role="tab"
                aria-controls={`${radio.value}-panel`}
                aria-selected={
                  data[APPROVED_MAINTENANCE_RADIO.key].value === radio.value
                }
                name={APPROVED_MAINTENANCE_RADIO.key}
                value={radio.value}
                checked={
                  data[APPROVED_MAINTENANCE_RADIO.key].value === radio.value
                }
                onChange={handleChangeData}
              >
                {radio.label}
              </RadioButton>
            ))}
          </S.RadioWrapper>
          {data.approvedMaintenance.value === APPROVED_MAINTENANCE.CHANGE && (
            <ValidMessage css={S.validText}>
              예약 변경을 원하시면 차주님께 꼭<br /> 유선연락 바랍니다. 연락
              없이 변경을
              <br />한 경우 예약이 취소될 수 있습니다.
            </ValidMessage>
          )}
        </div>
      </S.InfoItem>
      {content}
    </S.Root>
  );
};

export default AcceptedForm;
